<template>
  <span class="tristate tristate-switcher">
    <input type="radio" :class="{'active' :  value[perm] === false}" :id="'perm' + perm.toString() + '-state-off'"  name="tristateRadio" :value="false" v-model="value[perm]" @change="changeRadio">
    <input type="radio" :class="{'active' :  value[perm] === null}" :id="'perm' + perm.toString() + '-state-null'" name="tristateRadio" :value="null" v-model="value[perm]" checked @change="changeRadio">
    <input type="radio" :class="{'active' :  value[perm] ===  true}"  :id="'perm' + perm.toString() + '-state-on'" name="tristateRadio" :value="true" v-model="value[perm]" @change="changeRadio">
    <i></i>


  </span>
</template>

<script>
  export default {
    name: 'Tristate',
    props: ['value', 'perm'],
    methods: {
      changeRadio () {
        this.$emit('input', this.value)
      }
    }
  }
</script>

<style scoped>

</style>
