// initial state
const state = {
  userData: null,
  userError: 0,
  lock: false,
  sharedIPs: [],
  snaps: [],
  snap: null,
  newUsername: null,
  userServers: []
}

// getters
const getters = {
  userData: state => state.userData,
  userError: state => state.userError,
  userLock: state => state.lock,
  sharedIPs: state => state.sharedIPs,
  snapshot: state => state.snap,
  snapshots: state => state.snaps,
  newUsername: state => state.newUsername,
  userServers: state => state.userServers
}

// actions
const actions = {
  getUserData ({commit}, userData) {
    commit('setUserData', userData.data)
  },
  getSharedIPs ({commit}, userData) {
    commit('setSharedIPs', userData.data)
  },
  getSharedMACs ({commit}, userData) {
    commit('setSharedIPs', userData.data)
  },
  getSnapshots ({commit}, userData) {
    commit('setSnapshots', userData.data)
  },
  getSnapshot ({commit}, userData) {
    commit('setSnapshot', userData.data)
  },
  setError ({commit}, data) {
    commit('setError', data.data)
  },
  setLock ({commit}, data) {
    commit('setLock', data)
  },
  setUsername ({commit}, data) {
    commit('setUsername', data)
  },
  setUserNote({commit}, data) {
    commit('setUserNote', data)
  },
  setUserServers ({commit}, data) {
    commit('setUserServers', data)
  }}

// mutations
const mutations = {
  setUserData (state, userData) {
    state.userData = userData
  },
  setUserNote (state, note) {
    state.userData['note'] = note
  },
  setError (state, error) {
    state.userError = {error: error, time: Date.now()}
  },
  setLock (state, lock) {
    state.lock = lock
  },
  setSnapshots (state, lock) {
    state.snaps = lock
  },
  setSnapshot (state, lock) {
    state.snap = JSON.parse(lock)
  },
  setUsername (state, lock) {
    state.newUsername = lock
  },
  setSharedIPs (state, lock) {
    state.sharedIPs = lock
  },
  setUserServers (state, data) {
    state.userServers = data.data.servers.sort()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
