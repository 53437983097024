<template>
  <div>
    <b>{{ item.name }}</b> <span v-if="item.security_id > 0" class="badge badge-danger" :style="{'background-color' : roles[item.security_id].colour}">{{roles[item.security_id].name}}</span>
  </div>
</template>

<script>
  import Roles from '../../../_roles.js'

  export default {
    data () {
      return {
        roles: Roles
      }
    },
    props: {
      item: { required: true },
      searchText: { required: true }
    }
  }
</script>
