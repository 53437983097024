<template>
  <v-autocomplete :min-len='3' :wait="0" :items="items" v-model="item" ref="search" :get-label="getLabel" :component-item='template'
                  @update-items="updateItems" :auto-select-one-item="false" @change="changeItem" @item-selected="selectItem" :placeholder="placeholder">
  </v-autocomplete>
</template>

<script>
  import ItemTemplate from './ItemTemplate.vue'
  import { mapGetters } from 'vuex'
  export default {
    props: ['values', 'placeholder'],
    data () {
      return {
        item: null,
        items: [],
        template: ItemTemplate
      }
    },
    computed: {
      ...mapGetters(['pokemonError'])
    },
    methods: {
      getLabel (item) {
        if (item) {
          return item.Name
        }
        return ''
      },
      updateItems (text) {
        this.items = this.values.filter((item) => {
          return (new RegExp(text.toLowerCase())).test(item.Name.toLowerCase())
        }).slice(0, 5)
      },
      selectItem (item) {
        this.items = []
        this.$emit('input', item.ID)
        this.$emit('selectedItem', item)
      },
      changeItem (item) {
        this.$emit('input', null)
        this.$emit('change', item)
      }
    },
    watch: {
      pokemonError: function (e) {
        if (e.error === 1 || e.error === 10) {
          this.item = null
        }
      }
    }
  }
</script>
