<template>
  <div>
   <b-nav-item-dropdown right no-caret>
      <template v-if="Requests.length > 0" slot="button-content">
        <i class="icon-bell"></i>
        <span class="badge badge-pill badge-danger">{{Requests.length}}</span>
      </template>
      <b-dropdown-header tag="div" class="text-center"><strong>You have {{Requests.length}} requests</strong></b-dropdown-header>
     <b-dropdown-item @click="createModal(item)" v-for="item in Requests" :key="item.id" ><i class="fa fa-flash text-warning"></i>
       <span v-if="PokemonAdd.eq(item.type)">{{item.username}} requested {{findMonster(item.data)}}</span>
       <span v-if="UserNameChange.eq(item.type)">{{item.username}} requested a change to {{item.data}} </span>

     </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-modal size="md" v-model="modal" @ok="modal = false">
      <template v-if="modalRequest && PokemonAdd.eq(modalRequest.type)">
        <div v-if="modalRequest" slot="modal-title" class="w-100">
          {{modalRequest.username}}'s Pokemon Request
          <small v-for="serv in modalRequest.data.servers" :class="'badge-' + serv" class="badge text-capitalize">
            {{serv}}</small>
        </div>
        <span v-if="modalRequest">
        <img class="img-thumbnail float-md-right" :src="getMonsterImage(modalRequest.data.monster_id)"/>
        <strong>{{findMonster(modalRequest.data)}}</strong> <small>{{modalRequest.data.shiny ? '(Shiny)' : ''}}  LvL {{modalRequest.data.level}}</small><br>
        <small>
          <strong>IVs:</strong> {{modalRequest.data.ivs.join(', ')}}<br>
          <strong>Moves:</strong> {{joinMoves(modalRequest.data.moves)}}
        </small>
      </span>
      </template>
      <template v-if="modalRequest && UserNameChange.eq(modalRequest.type)">
        <div slot="modal-title" class="w-100">
          {{modalRequest.username}}'s Name Change Request
        </div>
        <span>
          {{modalRequest.username}} requested a name change to <strong>{{modalRequest.data}}</strong>
        </span>
      </template>

      <div slot="modal-footer" class="w-100">
        <b-button variant="danger" @click="deny(modalRequest.id)"><i class="fa fa-close"></i> Decline</b-button>

        <b-button variant="success" @click="accept(modalRequest.id)" class="float-right"><i class="fa fa-check"></i> Accept</b-button>

      </div>

    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Monsters from './../../../views/pokemon/_monsters.js'
  import Moves from './../../../views/pokemon/_moves.js'
  import Natures from './../../../views/pokemon/_natures'
  import VueNotifications from 'vue-notifications'

  export default {
    name: 'Requests',
    computed: {
      ...mapGetters(['Requests', 'RequestError'])
    },
    data () {
      return {
        modal: false,
        title: '',
        modalID: null,
        monsters: Monsters,
        natures: Natures,
        moves: Moves,
        modalRequest: null
      }
    },
    methods: {
      getMonsterImage (pid) {
        return '/static/img/monsters/' + Monsters.find(monster => monster.ID === pid).File + '.png'
      },
      createModal (request) {
        this.modal = true
        this.modalRequest = request
      },
      findMonster (data) {
        return Monsters.find(monster => monster.ID === data.monster_id).Name
      },
      accept (id) {
        this.emit('request', {'id': id, 'type': 1})
        this.modal = false
      },
      deny (id) {
        this.emit('request', {'id': id, 'type': 2})
        this.modal = false
      },
      joinMoves (moves) {
        return moves.map(mid => Moves.find(monster => monster.ID === mid).Name).join(', ')
      }
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Request successful!',
        message: 'Request was successful!'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Request failed!',
        message: 'Request failed, this user is currently online.'
      },
      UserTaken: {
        type: VueNotifications.types.error,
        title: 'Request failed!',
        message: 'Request failed, this name is alread ytaken.'
      }
    },
    watch: {
      RequestError: function (e) {
        switch (e.error) {
          case 1:
            this.modal = false
            this.ChangeSuccessful()
            break
          case 2:
            this.modal = false
            this.UserOnline()
            break
          case 3:
            this.modal = false
            this.UserTaken()
            break
        }
      }
    }
  }
</script>

<style scoped>

</style>
