<template>
  <div v-if="isExternalLink">
    <a :href="url" :class="classList">
      <i :class="icon"></i> {{name}}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </a>
  </div>
  <div v-else-if="isEmpty">
    <template v-if="checkPermission">
    <template v-if="itemLink">
      <router-link :to="itemLink + '/' + urlName" :class="classList">
      <i :class="icon"></i> {{name}}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
      </router-link>
      </template>
    <template v-else>
      <a href="#" :class="classList" @click="NoUser">
        <i :class="icon"></i> {{name}}
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
      </a>
    </template>
    </template>
  </div>
  <div v-else>
    <router-link :to="url" :class="classList" v-if="checkPermission">
      <i :class="icon"></i> {{name}}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </router-link>
  </div>
</template>

<script>
  import VueNotifications from 'vue-notifications'

  export default {
    name: 'sidebar-nav-link',
    data () {
      return {
        userLink: ''
      }
    },
    props: {
      name: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      urlName: {
        type: String,
        default: ''
      },
      permission: {
        type: String,
        default: ''
      },
      badge: {
        type: Object,
        default: () => {
        }
      },
      variant: {
        type: String,
        default: ''
      },
      classes: {
        type: String,
        default: ''
      }
    },
    computed: {
      classList () {
        return [
          'nav-link',
          this.linkVariant,
          ...this.itemClasses
        ]
      },
      linkVariant () {
        return this.variant ? `nav-link-${this.variant}` : ''
      },
      itemClasses () {
        return this.classes ? this.classes.split(' ') : []
      },
      isEmpty () {
        if (this.url.length === 0) {
          return true
        }
        return false
      },
      itemLink () {
        if (this.$route.params.username) {
          return '/user/' + this.$route.params.username
        } else {
          return ''
        }
      },
      checkPermission () {
        if (this.permission === '') {
          return true
        }
        return this.hasPermission(this.permission)
      },
      isExternalLink () {
        if (this.url.substring(0, 4) === 'http') {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      resolve (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : undefined
        }, obj || self)
      }
    },
    notifications: {
      NoUser: {
        type: VueNotifications.types.info,
        title: 'Error',
        message: 'You have to select a user first!'
      }
    }
  }
</script>
