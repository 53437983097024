// initial state
const state = {
  error: null
}

// getters
const getters = {
  massBanError: state => state.error
}

// actions
const actions = {
  setError ({commit}, data) {
    commit('setError', data.data)
  }
}

// mutations
const mutations = {
  setError (state, error) {
    state.error = {error: error, time: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
