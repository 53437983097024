<template>
  <div>
    <span>{{ item.Name }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      item: { required: true },
      searchText: { required: true }
    }
  }
</script>
