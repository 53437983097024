// initial state
const state = {
  stats: null
}

// getters
const getters = {
  serverStats: state => state.stats
}

// actions
const actions = {
  serverStats ({commit}, stats) {
    commit('setStats', stats.data)
  }
}

// mutations
const mutations = {
  setStats (state, lock) {
    state.stats = lock
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
