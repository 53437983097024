<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 700px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :suppressClickEdit="true"
                 :enableFilter="true"
                 :groupHeaders="true"
                 :stopEditingWhenGridLosesFocus="true"
                 :getContextMenuItems="getContextMenuItems"
                 :suppressRowClickSelection="true"
                 :toolPanelSuppressGroups="true"
                 :toolPanelSuppressValues="true">
    </ag-grid-vue>
    <item-add :modal.sync="addItem" :server="server"></item-add>
  </div>
</template>
<script>
  import ItemAdd from './ItemAdd'
  import {AgGridVue} from 'ag-grid-vue'
  import { mapGetters } from 'vuex'
  import VueNotifications from 'vue-notifications'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records', 'server', 'readonly'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        addItem: false
      }
    },
    computed: {
      ...mapGetters(['itemError'])
    },
    components: {
      'ag-grid-vue': AgGridVue,
      'ItemAdd': ItemAdd
    },
    methods: {
      createRowData () {
        return this.records
      },
      createColumnDefs () {
        return [
          {
            headerName: 'Item',
            field: 'item',
            filter: 'agSetColumnFilter',
            sort: 'asc'
          },
          {
            headerName: 'Qty',
            field: 'qty',
            filter: 'agNumberColumnFilter',
            editable: true,
            onCellValueChanged: this.onCellValueChanged
          }
        ]
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this
        return [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: 'Add',
            icon: '<i class="fa fa-plus"/>',
            disabled: !self.hasPermission(self.InventoryAddItem),
            action: function () {
              self.addItem = true
            }
          },
          {
            name: 'Edit',
            icon: '<i class="fa fa-pencil"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.InventoryEditItem),
            action: function () {
              self.gridOptions.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: 'qty'
              })
            }
          },
          {
            name: 'Delete',
            icon: '<i class="fa fa-trash"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.InventoryRemoveItem),
            action: function () {
              self.gridOptions.api.updateRowData({remove: [params.node.data]})
              console.log(params.node)
              self.emit('deleteItem', {'user_id': params.node.data.user_id, 'item_id': params.node.data.item_id, server: self.server})
            }
          },
          'separator',
          'export'
        ]
      },
      onCellValueChanged (params) {
        if (parseInt(params.oldValue) !== parseInt(params.newValue) && parseInt(params.newValue) >= 0) {
          this.emit('changeItem', {item_id: params.data.item_id, user_id: params.data.user_id, value: Number(params.newValue), server: this.server})
        }
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      ItemError: {
        type: VueNotifications.types.error,
        title: 'Invalid item name',
        message: 'Item does not exists.'
      }
    },
    watch: {
      itemError: function (e) {
        switch (e.error) {
          case 1:
            this.ChangeSuccessful()
            break
          case 2:
            this.UserOnline()
            break
          case 3:
            this.ItemError()
            break
        }
      }
    }
  }
</script>

<style>
</style>
