<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="10">
          <b-card v-if="inventory.data"
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-user"></i> <strong>Inventory</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
              <span v-if="servers[inventory.data.server].readonly" class="badge badge-danger">Read-only</span>
              <div class="card-actions">
                <server-menu :username="this.$route.params.username" :server.sync="inventory.data.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>
              <ItemRecords :records="inventory.data.data" :readonly="servers[inventory.data.server].readonly" :server="inventory.data.server"/>

            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import ItemRecords from './ItemRecords'
  import { mapGetters } from 'vuex'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'Inventory',
    components: {
      ServerMenu,
      ItemRecords
    },
    computed: mapGetters({
      inventory: 'allInventory'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getInventory', {'username': this.$route.params.username, 'server': this.$route.query.server})
    },
    methods: {
      switchServer (index) {
        if (index in this.cache) {
          this.loadData(this.cache[index])
        } else {
          this.$router.push({ path: 'inventory', query: { server: index } })
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getInventory', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
