// initial state
const state = {
  online: []
}

// getters
const getters = {
  currentOnline: state => state.online
}

// actions
const actions = {
  onlineUsers ({commit}, userData) {
    commit('setOnlineUsers', userData.data)
  }
}

// mutations
const mutations = {
  setOnlineUsers (state, userData) {
    state.online = userData
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
