<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="12">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header" v-if="pokedex.data">
              <i class="fa fa-product-hunt"></i> <strong>Pokedex</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
              <div class="card-actions">
                <server-menu :username="this.$route.params.username" :server.sync="pokedex.data.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>

            <PokedexRecords :records="pokedex.data.data" :server="pokedex.data.server"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import PokedexRecords from './PokedexRecords'
  import { mapGetters } from 'vuex'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'Pokedex',
    components: {
      ServerMenu,
      PokedexRecords
    },
    computed: mapGetters({
      pokedex: 'pokedex'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getPokedex', {'username': this.$route.params.username, 'server': this.$route.query.server})
    },
    methods: {
      switchServer (index) {
        this.activeServer = index
        if (index in this.cache) {
          this.loadData(this.cache[index])
        } else {
          this.$router.push({ path: 'pokedex', query: { server: index } })
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getPokedex', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
