import bigInt from 'big-integer'

// initial state
const state = {
  err: null,
  requests: []
}

// getters
const getters = {
  RequestError: state => state.err,
  Requests: state => state.requests
}

// actions
const actions = {
  requestError ({commit}, data) {
    commit('setRequestError', data.data)
  },
  getRequests ({commit}, data) {
    commit('setRequests', data.data)
  }
}

// mutations
const mutations = {
  setRequestError (state, error) {
    state.err = {error: error, time: Date.now()}
  },
  setRequests (state, data) {
    state.requests = []
    for (let r in data) {
      state.requests[r] = data[r]
      try {
        state.requests[r].data = JSON.parse(state.requests[r].data)
      } catch (e) {
      }
      state.requests[r].type = bigInt(state.requests[r].type)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
