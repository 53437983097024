// initial state
const state = {
  all: null,
  error: null
}

// getters
const getters = {
  allPokemon: state => state.all,
  pokemonError: state => state.error

}

// actions
const actions = {
  getPokemon ({ commit }, pokemon) {
    commit('setPokemons', pokemon.data)
  },
  setError ({commit}, data) {
    commit('setError', data.data)
  }
}

// mutations
const mutations = {
  setPokemons (state, products) {
    state.all = products
  },
  setError (state, error) {
    state.error = {error: error, time: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
