import store from './store'
import Permissions from './_permissions'

export default {
  methods: {
    emit: function (event, data) {
      this.$socket.send(JSON.stringify(({'event': event, 'data': JSON.stringify(data)})))
    },
    hasPermission: function (name) {
      if (typeof name === 'string') {
        for (let k1 in Permissions) {
          for (let k2 in Permissions[k1].Permissions) {
            if (name === k2) {
              if (Permissions[k1].Permissions[k2].Permission.and(store.getters.authUser.deny).neq(0)) {
                return false
              }
              if (Permissions[k1].Permissions[k2].Permission.and(store.getters.authUser.allow).neq(0)) {
                return true
              }
              return Permissions[k1].Permissions[k2].Permission.and(store.getters.authUser.permission).neq(0)
            }
          }
        }
      } else {
        if (name.and(store.getters.authUser.deny).neq(0)) {
          return false
        }
        if (name.and(store.getters.authUser.allow).neq(0)) {
          return true
        }
        return name.and(store.getters.authUser.permission).neq(0)
      }
      return false
    }
  },
  created () {
    for (let k1 in Permissions) {
      for (let k2 in Permissions[k1].Permissions) {
        this[k2] = Permissions[k1].Permissions[k2].Permission
      }
    }
  }
}
