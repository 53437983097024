<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" lg="6">
        <BlueCard/>
      </b-col>
      <b-col sm="6" lg="6">
        <YellowCard/>
      </b-col>
    </b-row>
    <poketime/>
    <b-row>
      <b-col md="12">
        <b-card v-if="stats !== null">
          <div slot="header">
            <i class="fa fa-tasks"></i> Statistics & Toplists
          </div>

          <b-row>
            <b-col sm="12" lg="4">
              <b-row>
                <b-col sm="6">
                  <div class="callout callout-silver noBorder">
                    <small class="text-muted">Active players</small><br>
                    <strong class="h4">{{formatPrice(stats.active_players["silver"][stats.active_players["silver"].length - 1].count)}}</strong>
                    <div class="chart-wrapper">
                      <callout-chart :data="valuesCount(stats.active_players['silver'])" :labels="valueLabels(stats.active_players['silver'])" variant="silver" width="80" height="30" />
                    </div>
                  </div>
                </b-col>
                <b-col sm="6">
                  <div class="callout callout-silver noBorder">
                    <small class="text-muted">New players</small><br>
                    <strong class="h4">{{formatPrice(stats.new_players["silver"][stats.new_players["silver"].length - 1].count)}}</strong>
                    <div class="chart-wrapper">
                      <callout-chart :data="valuesCount(stats.new_players['silver'])" :labels="valueLabels(stats.new_players['silver'])" variant="silver" width="80" height="30" />
                    </div>
                  </div>
                </b-col>
                <b-col sm="12">
                  <Callout variant="silver">
                    <strong class="h4">Money</strong>
                    <servermenu v-model="activeMoney"></servermenu>
                  </Callout>
                </b-col>
              </b-row>
              <hr class="mt-0">
              <ul class="icons-list">
               <template v-for="(item, index) in stats.money_toplist.filter(stat => activeMoney === null | stat.server === activeMoney).slice(0,15)">
                 <li>
                   <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + item.username + '&background=' + servers[item.server].hex.substr(1)" alt="" class="img-avatar"/>
                 <div class="desc">
                   <div class="title"><router-link :to="'/user/' + item.username + '/base'">{{item.username}}</router-link></div>
                   <small>{{Math.floor(item.playtime / 60)}}h Playtime</small>
                 </div>
                   <div class="value">
                     <div class="small text-muted">Money</div>
                     <strong>${{formatPrice(item.money)}}</strong>
                   </div>
                 </li>
               </template>
            </ul>
            </b-col>
             <b-col sm="12" lg="4">
               <b-row>
                  <b-col sm="6">
                    <div class="callout callout-silver noBorder">
                      <small class="text-muted">Average money</small><br>
                      <strong class="h4">${{formatPrice(stats.average_money["silver"][stats.average_money["silver"].length - 1].count)}}</strong>
                      <div class="chart-wrapper">
                        <callout-chart :data="valuesCount(stats.average_money['silver'])" :labels="valueLabels(stats.average_money['silver'])" variant="silver" width="80" height="30" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="6">
                    <div class="callout callout-gold noBorder">
                      <small class="text-muted">Active players</small><br>
                      <strong class="h4">{{formatPrice(stats.active_players["gold"][stats.active_players["gold"].length - 1].count)}}</strong>
                      <div class="chart-wrapper">
                        <callout-chart :data="valuesCount(stats.active_players['gold'])" :labels="valueLabels(stats.active_players['gold'])" variant="gold" width="80" height="30" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12">
                    <Callout variant="gold">
                      <strong class="h4">Ranked Wins</strong>
                      <servermenu v-model="activeWins"></servermenu>
                    </Callout>
                  </b-col>
                </b-row>
                <hr class="mt-0">
               <ul class="icons-list">
                 <template v-for="(item, index) in stats.wins_toplist.filter(stat => activeWins === null | stat.server === activeWins).slice(0,15)">
                   <li>
                     <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + item.username + '&background=' + servers[item.server].hex.substr(1)" alt="" class="img-avatar"/>
                     <div class="desc">
                     <div class="title">  <router-link :to="'/user/' + item.username + '/base'">{{item.username}}</router-link></div>
                       <small>{{item.losses}} Losses</small>
                     </div>
                     <div class="value">
                       <div class="small text-muted">Wins</div>
                       <strong>{{item.wins}}</strong>
                     </div>
                   </li>
                 </template>
               </ul>
              </b-col>
               <b-col sm="12" lg="4">
                <b-row>
                 <b-col sm="6">
                    <div class="callout callout-gold noBorder">
                      <small class="text-muted">New players</small><br>
                      <strong class="h4">{{formatPrice(stats.new_players["gold"][stats.new_players["gold"].length - 1].count)}}</strong>
                      <div class="chart-wrapper">
                        <callout-chart :data="valuesCount(stats.new_players['gold'])" :labels="valueLabels(stats.new_players['gold'])" variant="gold" width="80" height="30" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="6">
                    <div class="callout callout-gold noBorder">
                      <small class="text-muted">Average money</small><br>
                      <strong class="h4">${{formatPrice(stats.average_money["gold"][stats.average_money["gold"].length - 1].count)}}</strong>
                      <div class="chart-wrapper">
                        <callout-chart :data="valuesCount(stats.average_money['gold'])" :labels="valueLabels(stats.average_money['gold'])" variant="gold" width="80" height="30" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12">
                    <Callout variant="silver">
                      <strong class="h4">Ranked Losses</strong>
                      <servermenu v-model="activeLosses"></servermenu>
                    </Callout>
                  </b-col>
                </b-row>
                <hr class="mt-0">
                 <ul class="icons-list">
                   <template v-for="(item, index) in stats.losses_toplist.filter(stat => activeLosses === null | stat.server === activeLosses).slice(0,15)">
                     <li>
                       <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + item.username + '&background=' + servers[item.server].hex.substr(1)" alt="" class="img-avatar"/>
                       <div class="desc">
                         <div class="title"><router-link :to="'/user/' + item.username + '/base'">{{item.username}}</router-link></div>
                         <small>{{item.wins}} Wins</small>
                       </div>
                       <div class="value">
                         <div class="small text-muted">Losses</div>
                         <strong>{{item.losses}}</strong>
                       </div>
                     </li>
                   </template>
                 </ul>
               </b-col>

          </b-row>
          <br/>
          <b-table class="mb-0 table-outline" responsive="sm" hover :items="stats.online_toplist" :fields="tableFields" head-variant="light">
            <template v-slot:cell(username)="item">
              <div><router-link :to="'/user/' + item.item.username + '/base'" class="routerLink">{{item.item.username}}</router-link></div>
              <div class="small text-muted">
              Registered: {{item.item.reg_date}}
              </div>
            </template>
            <template v-slot:cell(server)="item">
              <span class="server-icon mb-0" :title="item.item.server" :class="'bg-' + serverColour(item.item.server)">{{item.item.server.toUpperCase()}}</span>
            </template>
            <i class="flag-icon flag-icon-pw h1" title="pw" id="pw"></i>
            <template v-slot:cell(hours)="item">
                <div class="clearfix">
                  <div class="float-left">
                    <strong>{{item.item.hours}}h</strong>
                  </div>
                  <div class="float-right">
                    <small class="text-muted">{{Math.floor(item.item.playtime / 60)}}h</small>
                  </div>
                </div>
                <b-progress height={} class="progress-xs" v-model="item.item.hours / 24 * 100" :variant="variant(item.item.hours)"></b-progress>
            </template>
            <template v-slot:cell(activity)="item">
              <div class="small text-muted">Online</div>
              <strong>Right now</strong>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import YellowCard from './dashboard/YellowCardTemplate'
  import CalloutChart from './dashboard/CalloutChart'
  import BlueCard from './dashboard/BlueCardTemplate'
  import Poketime from './dashboard/Poketime'
  import Servermenu from './dashboard/Servermenu'
  import {Callout} from '../components/'
  import Servers from '../_servers'
  import VueNotifications from 'vue-notifications'
  import moment from 'moment'
  import iziToast from 'izitoast'
  export default {
    name: 'dashboard',
    components: {
      Callout,
      BlueCard,
      CalloutChart,
      YellowCard,
      Poketime,
      Servermenu
    },
    computed: mapGetters({
      stats: 'stats',
      restartError: 'RestartError'
    }),
    data: function () {
      return {
        selected: 'Month',
        activeMoney: null,
        activeWins: null,
        servers: Servers,
        activeLosses: null,
        tableFields: [
          {
            key: 'username',
            label: 'Player'
          },
          {
            key: 'server',
            class: 'text-center'
          },
          {
            key: 'hours',
            label: 'Yesterdays playtime'
          },
          {
            key: 'activity',
            label: 'Activity'
          }]
      }
    },
    created () {
      this.emit('dashboard')
    },
    methods: {
      perc (value) {
        return Math.floor(value / 24 * 100)
      },
      reload () {
        this.emit('reloadDashboard')
        this.ProgressInfo()
      },
      variant (value) {
        let $variant
        if (value <= 10) {
          $variant = 'success'
        } else if (value <= 15) {
          $variant = 'warning'
        } else {
          $variant = 'danger'
        }
        return $variant
      },
      formatPrice (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      serverColour (server) {
        if (server in Servers) {
          return Servers[server].colour
        }
        return 'primary'
      },
      valuesCount (activePlayers) {
        let values = []
        for (let a in activePlayers) {
          values.push(activePlayers[a].count)
        }
        return values
      },
      valueLabels (activePlayers) {
        let values = []
        for (let a in activePlayers) {
          values.push(moment(activePlayers[a].date).format('dddd'))
        }
        return values
      }
    },
    watch: {
      restartError: function (e) {
        if (e.error) {
          iziToast.error({title: 'Server restart failed!', message: e.error, tiomeout: 3000, transitionIn: 'bounceInLeft'})
        } else {
          iziToast.success({title: 'Server restart!', message: 'Server restart request was sent, server should be up within the next seconds.', tiomeout: 3000, transitionIn: 'bounceInLeft'})
        }
      }
    },
    notifications: {
      ProgressInfo: {
        type: VueNotifications.types.info,
        title: 'Reload in progress!',
        message: 'This may take several seconds, please be patient.'
      }
    }
  }
</script>
