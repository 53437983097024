<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="12">
          <b-card
            header-tag="header"
            footer-tag="footer" v-if="tradeLogs">
            <div slot="header">
              <i class="icon-globe"></i> <strong>Tradelog</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
              <div class="card-actions" v-if="tradeLogs.data">
                <server-menu :username="this.$route.params.username" :server.sync="tradeLogs.data.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>
              <div class="float-right"><!----><input id="uid-6c09aheo6dr" type="checkbox" v-model="showAll" @change="updateLimit" name="Option 10" class="form-check-input" value="Option 1"><label for="uid-6c09aheo6dr" class="form-check-label"> Show all trades </label></div>
              <TradeRecords :records="tradeLogs.data.data" :server="tradeLogs.data.server"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import Servers from '../../../_servers'
  import TradeRecords from './TradeRecords'
  import { mapGetters } from 'vuex'
  import ServerMenu from "@/components/Servernenu.vue";
  export default {
    name: 'Pokedex',
    components: {
      ServerMenu,
      TradeRecords
    },
    computed: mapGetters({
      tradeLogs: 'Tradelogs'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        showAll: false,
        cache: {}
      }
    },
    created () {
      this.emit('searchTradelog', {'username': this.$route.params.username, 'server': this.$route.query.server, 'limit': this.showAll ? -1 : 100})
      this.$watch(
        () => this.$route.query,
        (toQuery, previousQuery) => {
          if (toQuery.server) {
            this.emit('searchTradelog', {'username': this.$route.params.username, 'server': toQuery.server, 'limit': this.showAll ? -1 : 100})
          }
        }
      )
    },
    methods: {
      updateLimit () {
        this.emit('searchTradelog', {'username': this.$route.params.username, 'server': this.$route.query.server, 'limit': this.showAll ? -1 : 100})
      },
      switchServer (index) {
        this.$router.push({ path: 'tradelog', query: { server: index } })
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('searchTradelog', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
