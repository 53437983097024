<template>
  <div>
  <v-autocomplete :items="items" v-model="item" ref="search" :get-label="getLabel" :component-item='template'
                  @input="input" @update-items="updateItems" :auto-select-one-item="false" @item-selected="selectedItem" placeholder="Search...">
  </v-autocomplete>
  </div>
</template>

<script>
  import ItemTemplate from './UsernameItem'
  import Vue from 'vue'
  import {mapGetters, mapActions} from 'vuex'

  export default {
    events: new Vue(),
    data () {
      return {
        item: [],
        template: ItemTemplate
      }
    },
    computed: mapGetters({
      items: 'autocompleteResults'
    }),
    methods: {
      ...mapActions(['resetItems']),
      getLabel (item) {
        if (item) {
          return item.name
        }
      },
      updateItems (text) {
        this.emit('autocomplete', text)
      },
      input (text) {
        if (!text) {
          this.$emit('input', null)
        }
      },
      selectedItem (item) {
        if (item.name) {
          this.$emit('input', item)
        }
        this.items = []
      }
    }
  }
</script>
