<template>
    <b-card no-body>
      <b-card-body class="p-3 clearfix">
        <i :class="['bg-' + colour, daytime()]" class="fa p-3 font-2xl mr-3 float-left"></i>
        <div v-bind:class="'text-' + colour" class="h5 mb-0 mt-2">{{time}}</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">Poketime</div>
      </b-card-body>
    </b-card>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Servers from '../../_servers'

  export default {
    name: 'PoketimeCard',
    props: ['server'],
    data: function () {
      return {
        time: 0,
        hour: 0,
        colour: Servers[this.server].colour
      }
    },
    computed: mapGetters({
      async: 'pokeTime'
    }),
    created () {
      let parent = this
      function updateTime () {
        let a = parent.async[parent.server]
        let now = new Date()
        let dt = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())
        let ms = parent.divmod(((dt.getHours() * 60 + dt.getMinutes()) * 60 + dt.getSeconds() + dt.getMilliseconds() + a) * 5, 60)
        let hm = parent.divmod(ms[0], 60)
        //  Math.floor(ms[1])])
        parent.hour = Math.floor(hm[0] % 24)
        parent.time = parent.pad(parent.hour, 2) + ':' + parent.pad(Math.floor(hm[1]), 2)
      }
      updateTime()
      setInterval(updateTime, 1000)
    },
    methods: {
      divmod: function (x, y) {
        let div = Math.trunc(x / y)
        let rem = x % y
        return [div, rem]
      },
      pad: function (num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
      daytime: function () {
        if (this.hour >= 4 && this.hour < 10) {
          return 'fa-sun-o'
        }
        if (this.hour >= 10 && this.hour < 20) {
          return 'fa-circle-thin'
        }
        return 'fa-moon-o'
      }
    }
  }
</script>

<style scoped>

</style>
