<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 508px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :rowData="records"
                 :suppressRowClickSelection="true"
                 :getContextMenuItems="getContextMenuItems">
    </ag-grid-vue>
    <b-modal :title="modalTitle" v-model="sharedIPs">
      <div class="list-group">
            <span v-for="ip in sharedIPs" class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <div class="mb-1"><span class="h6">{{ip.username}}  <span v-if="ip.banned === 'P'" class="badge badge-danger">Banned</span></span></div>
                <small>{{formatDate(ip.date)}}</small>
              </div>
              <div class="sharedInfo">
                <small>
                  Matches: {{ip.count}}<br>
                  Email: {{ip.email}}<br>
                  Registration: {{ip.reg_date}} ({{formatMinutes(ip.play_time)}})<br>
                  <strong class="float-right">${{formatPrice(ip.money)}}</strong>
                  PVP Ranking W/L: {{ip.ranked_wins}}/{{ip.ranked_losses}}, Rating: {{ip.ranked_score}}<br>
                </small>
              </div>
            </span>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>
  </div>
</template>
<script>
import {AgGridVue} from 'ag-grid-vue'
import { mapGetters } from 'vuex'
import {DefaultGridOptions} from "@/default_table_settings"
import moment from 'moment'

export default {
  props: ['records', 'server'],
  data () {
    return {
      gridOptions: null,
      showToolPanel: false,
      modalTitle: ''
    }
  },
  components: {
    'ag-grid-vue': AgGridVue
  },
  computed: mapGetters({
    sharedIPs: 'sharedIPs'
  }),
  methods: {
    createRowData () {
      return this.records
    },
    createColumnDefs () {
      let columns = [
        {
          headerName: 'IP',
          field: 'ip',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Agent',
          field: 'agent',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'MAC',
          field: 'mac'
        },
        {
          headerName: 'Date',
          field: 'date',
          sort: 'desc',
          sortable: true,
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Location',
          field: 'location',
          filter: 'agSetColumnFilter',
          valueGetter: function (value) {
            return value.data.location.location
          },
          cellRenderer: function (params) {
            return '<i class="flag-icon flag-icon-' + params.data.location.iso_code.toLowerCase() + '"></i> ' + params.value
          }
        }
      ]
      if (!this.hasPermission(this.UserMacView)) {
        columns.splice(2, 1)
      }
      return columns
    },
    formatMinutes (minutes) {
      let h = Math.floor(minutes / 60)
      let m = minutes % 60
      if (h <= 0) {
        return m + 'm'
      } else {
        return h + 'h'
      }
    },
    formatPrice (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate (date) {
      return moment(date).utc()
    },
    getContextMenuItems: function getContextMenuItems (params) {
      let self = this
      let menu = [
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: ' Check IP',
          icon: '<i class="fa fa-globe"/>',
          action: function () {
            let win = window.open('https://whatismyipaddress.com/ip/' + params.node.data.ip, '_blank')
            win.focus()
          }
        },
        {
          name: ' Shared IP',
          icon: '<i class="fa fa-share-alt"/>',
          action: function () {
            self.modalTitle = 'Shared IP - ' + params.node.data.ip
            self.emit('getSharedIPs', {'ip': params.node.data.ip, 'server': self.server})
          }
        },
        {
          name: ' Shared MAC',
          icon: '<i class="fa fa-share-alt"/>',
          action: function () {
            self.modalTitle = 'Shared MAC'
            self.emit('getSharedMACs', {'mac': params.node.data.mac, 'server': self.server})
          }
        },
        'separator',
        'export'
      ]

      if (!this.hasPermission(this.UserMacView)) {
        menu.splice(5, 1)
      }
      return menu
    },
    onGridSizeChanged () {
      this.gridOptions.api.sizeColumnsToFit()
    }
  },
  beforeMount () {
    this.gridOptions = Object.assign({}, DefaultGridOptions, {
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      sidebar: true
    })
    this.gridOptions.rowData = this.createRowData()
    this.gridOptions.columnDefs = this.createColumnDefs()
  },
  mounted () {
  }
}
</script>

<style>
</style>
