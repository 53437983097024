<template>
  <div class="wrapper">
    <div class="animated fadeIn">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-street-view"></i>  <strong>Chat log</strong>
              <div class="card-actions">
                <div class="btn-group" role="group">
                  <template v-for="(item, index) in servers">
                    <button v-if="!item.readonly" type="button" @click="switchServer(index)"
                            :class="['btn-' + item.colour, activeServer === index ? 'active' : '']"
                            class="btn btn-small">{{capitalizeFirstLetter(index)}}
                    </button>
                  </template>
                </div>
              </div>
            </div>

            <div>
              <ag-grid-vue style='width: 100%; height: 700px' class='ag-theme-balham-dark tradelog'
                           :gridOptions='gridOptions'
                           :getContextMenuItems="getContextMenuItems">
              </ag-grid-vue>
            </div>
          </b-card>
    </div>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import 'ag-grid-enterprise'
  import Servers from '../../_servers'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import {DefaultGridOptions, DefaultServerGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        EnterpriseDatasource () {},
        disableRecover: false,
        disableDelete: false,
        disableTransfer: false,
        cb: null,
        lock: false,
        servers: Servers,
        activeServer: Object.keys(Servers)[0]
      }
    },
    computed: mapGetters({
      chatlogs: 'Chatlogs'
    }),
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      switchServer (index) {
        this.activeServer = index
        this.gridOptions.api.setServerSideDatasource(new this.EnterpriseDatasource())
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      checkPermission (perm) {
        return perm.and(this.authUser.permission).neq(0)
      },
      createColumnDefs () {
        return [
          {
            headerName: 'Chat',
            field: 'chat',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals'],
              defaultOption: 'equals'
            }
          },
          {
            headerName: 'Username',
            field: 'username',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals'],
              defaultOption: 'equals'
            }
          },
          {
            headerName: 'Message',
            field: 'message',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              newRowsAction: 'keep',
              filterOptions: ['equals', 'contains'],
              defaultOption: 'equals'
            }
          },
          {
            headerName: 'Date',
            field: 'date',
            sort: 'desc',
            filter: 'agDateColumnFilter',
            sortable: true,
            valueFormatter: this.showDate
          }
        ]
      },
      showDate (params) {
        return moment(params.value).utc()
      },
      cellRenderer: function (param) {
        if (param.value) {
          return param.value.replace(/\n/g, '</br>')
        }
      },
      getContextMenuItems: function getContextMenuItems (params) {
        return [
          'copy',
          'separator',
          'toolPanel',
          'export'
        ]
      }
    },

    beforeMount () {
      let self = this

      this.gridOptions = Object.assign({}, DefaultServerGridOptions, {
        rowModelType: 'serverSide',
        animateRows: true,
        rowSelection: 'multiple',
        cacheBlockSize: 100,
        rowGroupPanelShow: 'never',
        serverSideInfiniteScroll: true
      })
      this.gridOptions.columnDefs = this.createColumnDefs()
      this.gridOptions.onGridReady = () => {
        self.EnterpriseDatasource.prototype.getRows = function (params) {
          let req = params.request
          req.server = self.activeServer
          self.emit('searchChatlog', req)
          self.cb = params.successCallback
        }
        self.gridOptions.api.setServerSideDatasource(new this.EnterpriseDatasource())
      }
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
      let self = this
    },
    watch: {
      chatlogs: function (e) {
        this.cb(e.rows, e.lastRow)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.gridOptions.api.setFilterModel(null)
      this.gridOptions.api.onFilterChanged()
      this.gridOptions.api.setServerSideDatasource(new this.EnterpriseDatasource())
      next()
    }
  }
</script>
