export default [
  {
    'ID': 1,
    'Name': 'Bulbasaur',
    'Ability': [
      'Overgrow',
      'None',
      'Chlorophyll'
    ],
    'Gender': 87.5,
    'File': 'fz21zlqlfgm34of6pwo4lg43gv3evgn9'
  },
  {
    'ID': 2,
    'Name': 'Ivysaur',
    'Ability': [
      'Overgrow',
      'None',
      'Chlorophyll'
    ],
    'Gender': 87.5,
    'File': 'rue8t7e996ky5kjmoeiiw0blu2dcc4cm'
  },
  {
    'ID': 3,
    'Name': 'Venusaur',
    'Ability': [
      'Overgrow',
      'None',
      'Chlorophyll'
    ],
    'Gender': 87.5,
    'File': '7hh7ltr7pkc9pi0cr8ousgir4va0wbid'
  },
  {
    'ID': 4,
    'Name': 'Charmander',
    'Ability': [
      'Blaze',
      'None',
      'Solar Power'
    ],
    'Gender': 87.5,
    'File': 't7ue83iw2xbt8purj635861jop82oahb'
  },
  {
    'ID': 5,
    'Name': 'Charmeleon',
    'Ability': [
      'Blaze',
      'None',
      'Solar Power'
    ],
    'Gender': 87.5,
    'File': 'z6eam4wnbopkpiyhlwaop1mzb08re4t0'
  },
  {
    'ID': 6,
    'Name': 'Charizard',
    'Ability': [
      'Blaze',
      'None',
      'Solar Power'
    ],
    'Gender': 87.5,
    'File': 'j6e03ha3p7fqs9kd0d8s9gq9w5bk0p56'
  },
  {
    'ID': 7,
    'Name': 'Squirtle',
    'Ability': [
      'Torrent',
      'None',
      'Rain Dish'
    ],
    'Gender': 87.5,
    'File': '7b3g6bk3eltfvuz5yenr3goeej8e7xra'
  },
  {
    'ID': 8,
    'Name': 'Wartortle',
    'Ability': [
      'Torrent',
      'None',
      'Rain Dish'
    ],
    'Gender': 87.5,
    'File': 'ogijwe538azlamk0zh4pv7g38j3ps548'
  },
  {
    'ID': 9,
    'Name': 'Blastoise',
    'Ability': [
      'Torrent',
      'None',
      'Rain Dish'
    ],
    'Gender': 87.5,
    'File': 'ednvqa5vdtl0bc6og6uyq8stos79int3'
  },
  {
    'ID': 10,
    'Name': 'Caterpie',
    'Ability': [
      'Shield Dust',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': 'prx94sxuoltpqqnkjafwd90prmouyv1w'
  },
  {
    'ID': 11,
    'Name': 'Metapod',
    'Ability': [
      'Shed Skin',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'c10jbh7brqosco6gtwdbjhs52k9gctth'
  },
  {
    'ID': 12,
    'Name': 'Butterfree',
    'Ability': [
      'Compound Eyes',
      'None',
      'Tinted Lens'
    ],
    'Gender': 50,
    'File': '1ejegycl8xmzx0wgk5p4m5o54zbrq0uj'
  },
  {
    'ID': 13,
    'Name': 'Weedle',
    'Ability': [
      'Shield Dust',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': '5n6aug300jodcwcodxpmg8anax3spsd6'
  },
  {
    'ID': 14,
    'Name': 'Kakuna',
    'Ability': [
      'Shed Skin',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '8bjl3ci7esir1tj5aaexc5qwjrruw5d8'
  },
  {
    'ID': 15,
    'Name': 'Beedrill',
    'Ability': [
      'Swarm',
      'None',
      'Sniper'
    ],
    'Gender': 50,
    'File': '3khr0mwezuoqm5pigai1rczo1brxm6e3'
  },
  {
    'ID': 16,
    'Name': 'Pidgey',
    'Ability': [
      'Keen Eye',
      'Tangled Feet',
      'Big Pecks'
    ],
    'Gender': 50,
    'File': 'ujkmq415hpvp68zhhwe24267kw9aknsf'
  },
  {
    'ID': 17,
    'Name': 'Pidgeotto',
    'Ability': [
      'Keen Eye',
      'Tangled Feet',
      'Big Pecks'
    ],
    'Gender': 50,
    'File': 'yldbc6ik9nz1f7xy1z5u2nmywoz0faga'
  },
  {
    'ID': 18,
    'Name': 'Pidgeot',
    'Ability': [
      'Keen Eye',
      'Tangled Feet',
      'Big Pecks'
    ],
    'Gender': 50,
    'File': 'qsz939xmcjjxa943cu8x6wxncwjobs51'
  },
  {
    'ID': 19,
    'Name': 'Rattata',
    'Ability': [
      'Run Away',
      'Guts',
      'Hustle'
    ],
    'Gender': 50,
    'File': 'p30xwm856d0gb3nq7semiq9krdq1ol6a'
  },
  {
    'ID': 20,
    'Name': 'Raticate',
    'Ability': [
      'Run Away',
      'Guts',
      'Hustle'
    ],
    'Gender': 50,
    'File': 'dldwyd583c7t7hj6lfhhaw1a49w4bzuk'
  },
  {
    'ID': 21,
    'Name': 'Spearow',
    'Ability': [
      'Keen Eye',
      'None',
      'Sniper'
    ],
    'Gender': 50,
    'File': 'kkq31qlly5zg4g9a1gqlfat4nc6ilub5'
  },
  {
    'ID': 22,
    'Name': 'Fearow',
    'Ability': [
      'Keen Eye',
      'None',
      'Sniper'
    ],
    'Gender': 50,
    'File': '3dexwqtsupkalhvgnzk0xlg31suydjid'
  },
  {
    'ID': 23,
    'Name': 'Ekans',
    'Ability': [
      'Intimidate',
      'Shed Skin',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'umecah9g1b5ss2w43cu4i7z96nuizb12'
  },
  {
    'ID': 24,
    'Name': 'Arbok',
    'Ability': [
      'Intimidate',
      'Shed Skin',
      'Unnerve'
    ],
    'Gender': 50,
    'File': '4mvp4aq0h7tl7ae6n28p8pbbf5otv6h2'
  },
  {
    'ID': 25,
    'Name': 'Pikachu',
    'Ability': [
      'Static',
      'None',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': '4gq4ymq2eknoj5gtc1sm7naylvs6b183'
  },
  {
    'ID': 26,
    'Name': 'Raichu',
    'Ability': [
      'Static',
      'None',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'blqbtg32tl4zr8zufw60s8hkt1iq03q8'
  },
  {
    'ID': 27,
    'Name': 'Sandshrew',
    'Ability': [
      'Sand Veil',
      'None',
      'Sand Rush'
    ],
    'Gender': 50,
    'File': 'dqj3lwjyx0uljgd3x36axhs53v97kzbd'
  },
  {
    'ID': 28,
    'Name': 'Sandslash',
    'Ability': [
      'Sand Veil',
      'None',
      'Sand Rush'
    ],
    'Gender': 50,
    'File': 'cjeegw99zacj2f7bt1e2gaz6ugywqvyf'
  },
  {
    'ID': 29,
    'Name': 'Nidoran F',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Hustle'
    ],
    'Gender': 0.01,
    'File': 'm7h2scbu6snzbxyoavt4s5ny60ndqmh4'
  },
  {
    'ID': 30,
    'Name': 'Nidorina',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Hustle'
    ],
    'Gender': 0.01,
    'File': 'gxfxk1ygobdd1i4qyad0hp28kodutv7q'
  },
  {
    'ID': 31,
    'Name': 'Nidoqueen',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Sheer Force'
    ],
    'Gender': 0.01,
    'File': 'hdvdkrb2zo6mr2gb6u0272svcuwpf5hr'
  },
  {
    'ID': 32,
    'Name': 'Nidoran M',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Hustle'
    ],
    'Gender': 100,
    'File': 'j3938bpx0jk0d30ywbqrda56xjbd2i6p'
  },
  {
    'ID': 33,
    'Name': 'Nidorino',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Hustle'
    ],
    'Gender': 100,
    'File': '5k0whji1ki6mvj2o89ezqghmaxv74z15'
  },
  {
    'ID': 34,
    'Name': 'Nidoking',
    'Ability': [
      'Poison Point',
      'Rivalry',
      'Sheer Force'
    ],
    'Gender': 100,
    'File': '1vico80trejuloi0w1uw2h5po68yttst'
  },
  {
    'ID': 35,
    'Name': 'Clefairy',
    'Ability': [
      'Cute Charm',
      'Magic Guard',
      'Friend Guard'
    ],
    'Gender': 25,
    'File': '4uvy4ym5k3q1bzeugmypytoxb742u0gd'
  },
  {
    'ID': 36,
    'Name': 'Clefable',
    'Ability': [
      'Cute Charm',
      'Magic Guard',
      'Unaware'
    ],
    'Gender': 25,
    'File': 'ha05wvhgzpdhennxwqqbflzp28f4inqx'
  },
  {
    'ID': 37,
    'Name': 'Vulpix',
    'Ability': [
      'Flash Fire',
      'None',
      'Drought'
    ],
    'Gender': 25,
    'File': 'bblrjlkjgdnf0xrnc3gpsmmlvt21lccs'
  },
  {
    'ID': 38,
    'Name': 'Ninetales',
    'Ability': [
      'Flash Fire',
      'None',
      'Drought'
    ],
    'Gender': 25,
    'File': '445gq5b1ej5gsqxhv2dc1mh8jcmd9c9c'
  },
  {
    'ID': 39,
    'Name': 'Jigglypuff',
    'Ability': [
      'Cute Charm',
      'Competitive',
      'Friend Guard'
    ],
    'Gender': 25,
    'File': 'qyxapzxnxf2llg49vjc4kerir9vcd4h2'
  },
  {
    'ID': 40,
    'Name': 'Wigglytuff',
    'Ability': [
      'Cute Charm',
      'Competitive',
      'Frisk'
    ],
    'Gender': 25,
    'File': 'o5ymdcqhyj02n8vd0avpd8axns6mzg5s'
  },
  {
    'ID': 41,
    'Name': 'Zubat',
    'Ability': [
      'Inner Focus',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'awqhlxaeqmuxcyxk662r6pi89m4agiif'
  },
  {
    'ID': 42,
    'Name': 'Golbat',
    'Ability': [
      'Inner Focus',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'q8go6zm8bmfngfb1d9rukeksr3jvsvpo'
  },
  {
    'ID': 43,
    'Name': 'Oddish',
    'Ability': [
      'Chlorophyll',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': '15el23sxsm93kgj6uuridakz9cg8w5mv'
  },
  {
    'ID': 44,
    'Name': 'Gloom',
    'Ability': [
      'Chlorophyll',
      'None',
      'Stench'
    ],
    'Gender': 50,
    'File': '6q2w8t0ujsiludj76mndp7wh7kswkypt'
  },
  {
    'ID': 45,
    'Name': 'Vileplume',
    'Ability': [
      'Chlorophyll',
      'None',
      'Effect Spore'
    ],
    'Gender': 50,
    'File': 'pi8nsuravsk1ud70sfrrrc03tzaer0cv'
  },
  {
    'ID': 46,
    'Name': 'Paras',
    'Ability': [
      'Effect Spore',
      'Dry Skin',
      'Damp'
    ],
    'Gender': 50,
    'File': 'c2yitn0asmvwq17yvwyqgg2k7pj46o5c'
  },
  {
    'ID': 47,
    'Name': 'Parasect',
    'Ability': [
      'Effect Spore',
      'Dry Skin',
      'Damp'
    ],
    'Gender': 50,
    'File': 'ke0kndx8op75qim0wm1p39iloqujgesh'
  },
  {
    'ID': 48,
    'Name': 'Venonat',
    'Ability': [
      'Compound Eyes',
      'Tinted Lens',
      'Run Away'
    ],
    'Gender': 50,
    'File': 'yqsve4mewax7if3gozysjmrdtozl4hc6'
  },
  {
    'ID': 49,
    'Name': 'Venomoth',
    'Ability': [
      'Shield Dust',
      'Tinted Lens',
      'Wonder Skin'
    ],
    'Gender': 50,
    'File': 'jxdm62xjd6ed82qzhqb0s5knrmwws3jn'
  },
  {
    'ID': 50,
    'Name': 'Diglett',
    'Ability': [
      'Sand Veil',
      'Arena Trap',
      'Sand Force'
    ],
    'Gender': 50,
    'File': '62p2qmwqw5pucilulms1m94z2qipulmq'
  },
  {
    'ID': 51,
    'Name': 'Dugtrio',
    'Ability': [
      'Sand Veil',
      'Arena Trap',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'i7z8hrtp0pyc93g74ispm0wx4ttahymx'
  },
  {
    'ID': 52,
    'Name': 'Meowth',
    'Ability': [
      'Pickup',
      'Technician',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'v5hcjsmmvnnw6u0b08xs7xzwr42oi1uk'
  },
  {
    'ID': 53,
    'Name': 'Persian',
    'Ability': [
      'Limber',
      'Technician',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'z1guhhql975xicqvysq359qu88184p26'
  },
  {
    'ID': 54,
    'Name': 'Psyduck',
    'Ability': [
      'Damp',
      'Cloud Nine',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': 'dpbvra7n9ymys7mi4n9s6wgj56xrl9ff'
  },
  {
    'ID': 55,
    'Name': 'Golduck',
    'Ability': [
      'Damp',
      'Cloud Nine',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': '8ucb91d3q9r9n99x4ij31ohp4gh6swgo'
  },
  {
    'ID': 56,
    'Name': 'Mankey',
    'Ability': [
      'Vital Spirit',
      'Anger Point',
      'Defiant'
    ],
    'Gender': 50,
    'File': 'g8el2opvtcin6zm1dnvkv41wqtgz4fjx'
  },
  {
    'ID': 57,
    'Name': 'Primeape',
    'Ability': [
      'Vital Spirit',
      'Anger Point',
      'Defiant'
    ],
    'Gender': 50,
    'File': 'gppg8lzxn3feouix1exfsypb24j17c82'
  },
  {
    'ID': 58,
    'Name': 'Growlithe',
    'Ability': [
      'Intimidate',
      'Flash Fire',
      'Justified'
    ],
    'Gender': 75,
    'File': '2urm1j4154m37113r6n4l3ott9ed22lk'
  },
  {
    'ID': 59,
    'Name': 'Arcanine',
    'Ability': [
      'Intimidate',
      'Flash Fire',
      'Justified'
    ],
    'Gender': 75,
    'File': 'zgir0ow2ufz2a5p8tkuz5uxqek33tmuv'
  },
  {
    'ID': 60,
    'Name': 'Poliwag',
    'Ability': [
      'Water Absorb',
      'Damp',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': 'erml97i3zd295kmm3fvr6sg41f0yno5r'
  },
  {
    'ID': 61,
    'Name': 'Poliwhirl',
    'Ability': [
      'Water Absorb',
      'Damp',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': '247l24y5bok5zm7mn3f24ifbqfz4gpht'
  },
  {
    'ID': 62,
    'Name': 'Poliwrath',
    'Ability': [
      'Water Absorb',
      'Damp',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': 'fthwlpbmlycyaf4jlazkeqok0a4alcb4'
  },
  {
    'ID': 63,
    'Name': 'Abra',
    'Ability': [
      'Synchronize',
      'Inner Focus',
      'Magic Guard'
    ],
    'Gender': 75,
    'File': 'f6axxcune7sdutj9gp7g1zpvo2ruccap'
  },
  {
    'ID': 64,
    'Name': 'Kadabra',
    'Ability': [
      'Synchronize',
      'Inner Focus',
      'Magic Guard'
    ],
    'Gender': 75,
    'File': 'fqdyk7x54tvxmepmntht8dmggmzbffo5'
  },
  {
    'ID': 65,
    'Name': 'Alakazam',
    'Ability': [
      'Synchronize',
      'Inner Focus',
      'Magic Guard'
    ],
    'Gender': 75,
    'File': 'dl0agcc38nqwr06u2vrshis5te4mb67z'
  },
  {
    'ID': 66,
    'Name': 'Machop',
    'Ability': [
      'Guts',
      'No Guard',
      'Steadfast'
    ],
    'Gender': 75,
    'File': 'f16d4primft6w3u61tr503rmbc3ekvvi'
  },
  {
    'ID': 67,
    'Name': 'Machoke',
    'Ability': [
      'Guts',
      'No Guard',
      'Steadfast'
    ],
    'Gender': 75,
    'File': '8lfjtrjuejqsdlbjrn7q3hpvda1v9yy5'
  },
  {
    'ID': 68,
    'Name': 'Machamp',
    'Ability': [
      'Guts',
      'No Guard',
      'Steadfast'
    ],
    'Gender': 75,
    'File': 'rj4labc77nfbdfjgwdl9pts1z84bxerk'
  },
  {
    'ID': 69,
    'Name': 'Bellsprout',
    'Ability': [
      'Chlorophyll',
      'None',
      'Gluttony'
    ],
    'Gender': 50,
    'File': '2n7rma105c5wbmy4o275cnfc5wlrydh5'
  },
  {
    'ID': 70,
    'Name': 'Weepinbell',
    'Ability': [
      'Chlorophyll',
      'None',
      'Gluttony'
    ],
    'Gender': 50,
    'File': 'hrr94vymgxtrgp1rxqzboj0wfwb901fe'
  },
  {
    'ID': 71,
    'Name': 'Victreebel',
    'Ability': [
      'Chlorophyll',
      'None',
      'Gluttony'
    ],
    'Gender': 50,
    'File': 'jk7ga3f12j4cdplw2ps4g1hfz6sdq1k9'
  },
  {
    'ID': 72,
    'Name': 'Tentacool',
    'Ability': [
      'Clear Body',
      'Liquid Ooze',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'u1ogfm9qn310k3blcpn2d1qu01i3fkz0'
  },
  {
    'ID': 73,
    'Name': 'Tentacruel',
    'Ability': [
      'Clear Body',
      'Liquid Ooze',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'p79e8xog9jsti1tp8hfufdqri09ho5pt'
  },
  {
    'ID': 74,
    'Name': 'Geodude',
    'Ability': [
      'Rock Head',
      'Sturdy',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'qrq3iiztr7dak0byehl1s2n1efkks11y'
  },
  {
    'ID': 75,
    'Name': 'Graveler',
    'Ability': [
      'Rock Head',
      'Sturdy',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'nm7wxw92ovo97z640dft8zpq7x0uno2z'
  },
  {
    'ID': 76,
    'Name': 'Golem',
    'Ability': [
      'Rock Head',
      'Sturdy',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'd3u5bgbh7herxxba66pp7shiykwem8ko'
  },
  {
    'ID': 77,
    'Name': 'Ponyta',
    'Ability': [
      'Run Away',
      'Flash Fire',
      'Flame Body'
    ],
    'Gender': 50,
    'File': 'gpej8e5717kgyjsxqhorh6xt9ucnrfsz'
  },
  {
    'ID': 78,
    'Name': 'Rapidash',
    'Ability': [
      'Run Away',
      'Flash Fire',
      'Flame Body'
    ],
    'Gender': 50,
    'File': 'whma9pztueku0jhjtwso4kcwev01pjit'
  },
  {
    'ID': 79,
    'Name': 'Slowpoke',
    'Ability': [
      'Oblivious',
      'Own Tempo',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'q163hm1la4fefj3n7462czchdv72i0j3'
  },
  {
    'ID': 80,
    'Name': 'Slowbro',
    'Ability': [
      'Oblivious',
      'Own Tempo',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'wqw5r45bskv0qgptydskfkmo70wty54g'
  },
  {
    'ID': 81,
    'Name': 'Magnemite',
    'Gender': null,
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Analytic'
    ],
    'File': 'dss25ff94uj9wdl0komoxrfeoz6tkmkr'
  },
  {
    'ID': 82,
    'Name': 'Magneton',
    'Gender': null,
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Analytic'
    ],
    'File': 'm77dc13g1ziawsqfb8xbszgyqob0md3q'
  },
  {
    'ID': 83,
    'Name': 'Farfetchd',
    'Ability': [
      'Keen Eye',
      'Inner Focus',
      'Defiant'
    ],
    'Gender': 50,
    'File': 'gmsl950dvenhx3nxrymmakhysk0248yj'
  },
  {
    'ID': 84,
    'Name': 'Doduo',
    'Ability': [
      'Run Away',
      'Early Bird',
      'Tangled Feet'
    ],
    'Gender': 50,
    'File': '82deagzkfjx3uug4s7c2o33aluu20utw'
  },
  {
    'ID': 85,
    'Name': 'Dodrio',
    'Ability': [
      'Run Away',
      'Early Bird',
      'Tangled Feet'
    ],
    'Gender': 50,
    'File': 'q2fk8wyarh33ugqhuqni87kpcuxwxnw0'
  },
  {
    'ID': 86,
    'Name': 'Seel',
    'Ability': [
      'Thick Fat',
      'Hydration',
      'Ice Body'
    ],
    'Gender': 50,
    'File': '6et9tlo9np4on1tcd06orpjmwihkzbwf'
  },
  {
    'ID': 87,
    'Name': 'Dewgong',
    'Ability': [
      'Thick Fat',
      'Hydration',
      'Ice Body'
    ],
    'Gender': 50,
    'File': 'tef89av4nhm48ml5p4yap94aui3rhxlv'
  },
  {
    'ID': 88,
    'Name': 'Grimer',
    'Ability': [
      'Stench',
      'Sticky Hold',
      'Poison Touch'
    ],
    'Gender': 50,
    'File': 'dkyzcpgg5szqm8rgo8davh59mbdl3unf'
  },
  {
    'ID': 89,
    'Name': 'Muk',
    'Ability': [
      'Stench',
      'Sticky Hold',
      'Poison Touch'
    ],
    'Gender': 50,
    'File': '2j76zzo1ouq6zkdgcqgt3lzbiy8e8na7'
  },
  {
    'ID': 90,
    'Name': 'Shellder',
    'Ability': [
      'Shell Armor',
      'Skill Link',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'otmwu3s5pa5jotnpau6recj8zdhxfiyk'
  },
  {
    'ID': 91,
    'Name': 'Cloyster',
    'Ability': [
      'Shell Armor',
      'Skill Link',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'ihv72bkchm1k9nrgpr26d63cg2czdisc'
  },
  {
    'ID': 92,
    'Name': 'Gastly',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'zrglfo0ut5d08ftpjm5job5vobmc3hxa'
  },
  {
    'ID': 93,
    'Name': 'Haunter',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '9xjtn47b447hsicdvrtu6rfj58eltmo6'
  },
  {
    'ID': 94,
    'Name': 'Gengar',
    'Ability': [
      'Cursed Body',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '0k8imyvr34svdboiq6q8np3347ipnulf'
  },
  {
    'ID': 95,
    'Name': 'Onix',
    'Ability': [
      'Rock Head',
      'Sturdy',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'ejtct9pg0uksknsn6n0zqkjbhsvefutm'
  },
  {
    'ID': 96,
    'Name': 'Drowzee',
    'Ability': [
      'Insomnia',
      'Forewarn',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': 'm8wr80wu836sqyynjk9rfop46bvu6hsm'
  },
  {
    'ID': 97,
    'Name': 'Hypno',
    'Ability': [
      'Insomnia',
      'Forewarn',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': 'dymoj1u9xq6mugumwvj4dutz7sh4cyfk'
  },
  {
    'ID': 98,
    'Name': 'Krabby',
    'Ability': [
      'Hyper Cutter',
      'Shell Armor',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': 'x0ncfe7u64t3pa4t84iv6y1bqywc6kq7'
  },
  {
    'ID': 99,
    'Name': 'Kingler',
    'Ability': [
      'Hyper Cutter',
      'Shell Armor',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': '98awipyahem3inb08kh5swxegn7pswdt'
  },
  {
    'ID': 100,
    'Name': 'Voltorb',
    'Gender': null,
    'Ability': [
      'Soundproof',
      'Static',
      'Aftermath'
    ],
    'File': 'w3o3ldt7llzwncju76k03gr71g2612k6'
  },
  {
    'ID': 101,
    'Name': 'Electrode',
    'Gender': null,
    'Ability': [
      'Soundproof',
      'Static',
      'Aftermath'
    ],
    'File': 'drt4rna17ais5330ks2k3gso1p26yqz4'
  },
  {
    'ID': 102,
    'Name': 'Exeggcute',
    'Ability': [
      'Chlorophyll',
      'None',
      'Harvest'
    ],
    'Gender': 50,
    'File': 'ejl5weltb3ucpy6vh6bjt7cdbxsu3wjm'
  },
  {
    'ID': 103,
    'Name': 'Exeggutor',
    'Ability': [
      'Chlorophyll',
      'None',
      'Harvest'
    ],
    'Gender': 50,
    'File': 'gnyqy3t6xjfgjy77k8dnwnn6ggamwv0n'
  },
  {
    'ID': 104,
    'Name': 'Cubone',
    'Ability': [
      'Rock Head',
      'Lightning Rod',
      'Battle Armor'
    ],
    'Gender': 50,
    'File': 'w76htwel721zallgyje5ga7akvqupoe9'
  },
  {
    'ID': 105,
    'Name': 'Marowak',
    'Ability': [
      'Rock Head',
      'Lightning Rod',
      'Battle Armor'
    ],
    'Gender': 50,
    'File': '57fnd6aqlhocz8b193i0tah4tvts2cjq'
  },
  {
    'ID': 106,
    'Name': 'Hitmonlee',
    'Ability': [
      'Limber',
      'Reckless',
      'Unburden'
    ],
    'Gender': 100,
    'File': '4qyjej2g584hj0hqk5b5ydugyp46pdi6'
  },
  {
    'ID': 107,
    'Name': 'Hitmonchan',
    'Ability': [
      'Keen Eye',
      'Iron Fist',
      'Inner Focus'
    ],
    'Gender': 100,
    'File': 'cfnle8wgyorned7fhy9ykc7820gq7sa2'
  },
  {
    'ID': 108,
    'Name': 'Lickitung',
    'Ability': [
      'Own Tempo',
      'Oblivious',
      'Cloud Nine'
    ],
    'Gender': 50,
    'File': 'pcpuuqfpp06talpuaogjo5l6lqzivbqk'
  },
  {
    'ID': 109,
    'Name': 'Koffing',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'vtmhskag86ahfq6lskfwkkyoe6zafdfk'
  },
  {
    'ID': 110,
    'Name': 'Weezing',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'irie6d8gtzk18q102z17tn1divhi7ndb'
  },
  {
    'ID': 111,
    'Name': 'Rhyhorn',
    'Ability': [
      'Lightning Rod',
      'Rock Head',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'y5ewr0p4a3gu4y6gmws81zkn35ij4chv'
  },
  {
    'ID': 112,
    'Name': 'Rhydon',
    'Ability': [
      'Lightning Rod',
      'Rock Head',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'ambcmnkkw6x6lttt292h1l90j7rn5sa8'
  },
  {
    'ID': 113,
    'Name': 'Chansey',
    'Ability': [
      'Natural Cure',
      'Serene Grace',
      'Healer'
    ],
    'Gender': 0.01,
    'File': 'rjal7pel3bzm4wm16zqqmkebxacwhfnq'
  },
  {
    'ID': 114,
    'Name': 'Tangela',
    'Ability': [
      'Chlorophyll',
      'Leaf Guard',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'bdtxl3e2j0a7oe31fney7vmbuo22gx1q'
  },
  {
    'ID': 115,
    'Name': 'Kangaskhan',
    'Ability': [
      'Early Bird',
      'Scrappy',
      'Inner Focus'
    ],
    'Gender': 0.01,
    'File': 'mp5phhfbi6gnnyvoh71uqh77mqeinv2o'
  },
  {
    'ID': 116,
    'Name': 'Horsea',
    'Ability': [
      'Swift Swim',
      'Sniper',
      'Damp'
    ],
    'Gender': 50,
    'File': 'aqeh9e54bc16yx5467hdqg70dpf7jg46'
  },
  {
    'ID': 117,
    'Name': 'Seadra',
    'Ability': [
      'Poison Point',
      'Sniper',
      'Damp'
    ],
    'Gender': 50,
    'File': '7usxjk33xh728m2xpewfsied6qlyzbn6'
  },
  {
    'ID': 118,
    'Name': 'Goldeen',
    'Ability': [
      'Swift Swim',
      'Water Veil',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'cc9qhfdarclp95l9sv5gkizia4c1rscl'
  },
  {
    'ID': 119,
    'Name': 'Seaking',
    'Ability': [
      'Swift Swim',
      'Water Veil',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'iyvxdn8kodpydsgm8ucyupje3nmi5qft'
  },
  {
    'ID': 120,
    'Name': 'Staryu',
    'Gender': null,
    'Ability': [
      'Illuminate',
      'Natural Cure',
      'Analytic'
    ],
    'File': 'h26l0jzlfcyr0g8qyz3oyilh4auzcpam'
  },
  {
    'ID': 121,
    'Name': 'Starmie',
    'Gender': null,
    'Ability': [
      'Illuminate',
      'Natural Cure',
      'Analytic'
    ],
    'File': 'glsqzk0lt5ejxiujkq8pbpe4hjua7rml'
  },
  {
    'ID': 122,
    'Name': 'Mr. Mime',
    'Ability': [
      'Soundproof',
      'Filter',
      'Technician'
    ],
    'Gender': 50,
    'File': 'id3yrv973xf2vvjyftj2sgn58wdf3kmm'
  },
  {
    'ID': 123,
    'Name': 'Scyther',
    'Ability': [
      'Swarm',
      'Technician',
      'Steadfast'
    ],
    'Gender': 50,
    'File': 'n6ull360ojxxki6a6krswus88uklvfw8'
  },
  {
    'ID': 124,
    'Name': 'Jynx',
    'Ability': [
      'Oblivious',
      'Forewarn',
      'Dry Skin'
    ],
    'Gender': 0.01,
    'File': '8nkigmrjftdeukxxfl797kvhudwvlhb0'
  },
  {
    'ID': 125,
    'Name': 'Electabuzz',
    'Ability': [
      'Static',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': 'qzymf88sqpq2n5nplsdsrtptj9851sbo'
  },
  {
    'ID': 126,
    'Name': 'Magmar',
    'Ability': [
      'Flame Body',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': 'd02kr1c28wtbhqjig7ntr81qd190ty2i'
  },
  {
    'ID': 127,
    'Name': 'Pinsir',
    'Ability': [
      'Hyper Cutter',
      'Mold Breaker',
      'Moxie'
    ],
    'Gender': 50,
    'File': 'erp29x33pfqi7zbzg1mejh9gafsmof3b'
  },
  {
    'ID': 128,
    'Name': 'Tauros',
    'Ability': [
      'Intimidate',
      'Anger Point',
      'Sheer Force'
    ],
    'Gender': 100,
    'File': '71cl3rz1yi2zwi3czir7m0fwg954kfsi'
  },
  {
    'ID': 129,
    'Name': 'Magikarp',
    'Ability': [
      'Swift Swim',
      'None',
      'Rattled'
    ],
    'Gender': 50,
    'File': '7a0i43hcq5lw6seypgjqmmnxwyktr510'
  },
  {
    'ID': 130,
    'Name': 'Gyarados',
    'Ability': [
      'Intimidate',
      'None',
      'Moxie'
    ],
    'Gender': 50,
    'File': '8490ija994x9vvyrdczewaw2bc2sqqv9'
  },
  {
    'ID': 131,
    'Name': 'Lapras',
    'Ability': [
      'Water Absorb',
      'Shell Armor',
      'Hydration'
    ],
    'Gender': 50,
    'File': 'i9ahdvqdhnmx16dvwfud7x4yeld8066h'
  },
  {
    'ID': 132,
    'Name': 'Ditto',
    'Gender': null,
    'Ability': [
      'Limber',
      'None',
      'Imposter'
    ],
    'File': 'zn4udyne61uidksuojd47jvewrhvwnzz'
  },
  {
    'ID': 133,
    'Name': 'Eevee',
    'Ability': [
      'Run Away',
      'Adaptability',
      'Anticipation'
    ],
    'Gender': 87.5,
    'File': '7bqwf7yec02bi2zuixmkym4pyo0tchsa'
  },
  {
    'ID': 134,
    'Name': 'Vaporeon',
    'Ability': [
      'Water Absorb',
      'None',
      'Hydration'
    ],
    'Gender': 87.5,
    'File': 'wmaeirm5qvukjruf1ijrjsqsqnoqvm4h'
  },
  {
    'ID': 135,
    'Name': 'Jolteon',
    'Ability': [
      'Volt Absorb',
      'None',
      'Quick Feet'
    ],
    'Gender': 87.5,
    'File': 'yrlofdpg2adtxxz27tcjpstts50b7lgf'
  },
  {
    'ID': 136,
    'Name': 'Flareon',
    'Ability': [
      'Flash Fire',
      'None',
      'Guts'
    ],
    'Gender': 87.5,
    'File': '22t0lb1pg3au0v65g5arrsneltro8spl'
  },
  {
    'ID': 137,
    'Name': 'Porygon',
    'Gender': null,
    'Ability': [
      'Trace',
      'Download',
      'Analytic'
    ],
    'File': '44wxr7ykm1krep2ct8sxh191m56b0huc'
  },
  {
    'ID': 138,
    'Name': 'Omanyte',
    'Ability': [
      'Swift Swim',
      'Shell Armor',
      'Weak Armor'
    ],
    'Gender': 87.5,
    'File': 'sn1pinbre6yw3jcwj75pdoh9c3fwttf2'
  },
  {
    'ID': 139,
    'Name': 'Omastar',
    'Ability': [
      'Swift Swim',
      'Shell Armor',
      'Weak Armor'
    ],
    'Gender': 87.5,
    'File': 'ycpci1bgspeo1m606pj7alw0ohh2jawy'
  },
  {
    'ID': 140,
    'Name': 'Kabuto',
    'Ability': [
      'Swift Swim',
      'Battle Armor',
      'Weak Armor'
    ],
    'Gender': 87.5,
    'File': 'qx1h24iy0k4cu1isq1y321oxwktgtb9j'
  },
  {
    'ID': 141,
    'Name': 'Kabutops',
    'Ability': [
      'Swift Swim',
      'Battle Armor',
      'Weak Armor'
    ],
    'Gender': 87.5,
    'File': 'i4s2rxyq7rk81i73ri0jibe1huehj2e6'
  },
  {
    'ID': 142,
    'Name': 'Aerodactyl',
    'Ability': [
      'Rock Head',
      'Pressure',
      'Unnerve'
    ],
    'Gender': 87.5,
    'File': 'ztw9cqqxcfkk8dqfiyezjk86w4n98m9u'
  },
  {
    'ID': 143,
    'Name': 'Snorlax',
    'Ability': [
      'Immunity',
      'Thick Fat',
      'Gluttony'
    ],
    'Gender': 87.5,
    'File': 'wd50v9focoz1syhdy9wvvflpkxx36nvc'
  },
  {
    'ID': 144,
    'Name': 'Articuno',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Snow Cloak'
    ],
    'File': '7jnprvtat6bv727htaw9n4i6qf6y5yh9'
  },
  {
    'ID': 145,
    'Name': 'Zapdos',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Static'
    ],
    'File': '959x832cv8rk90nox6gn6xirv3aa33q5'
  },
  {
    'ID': 146,
    'Name': 'Moltres',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Flame Body'
    ],
    'File': 'ur0tcmb49z89jpco8mzussa5qecyialr'
  },
  {
    'ID': 147,
    'Name': 'Dratini',
    'Ability': [
      'Shed Skin',
      'None',
      'Marvel Scale'
    ],
    'Gender': 50,
    'File': 'dwdfrg9esesobjuwgszk6cg2kmst4ud7'
  },
  {
    'ID': 148,
    'Name': 'Dragonair',
    'Ability': [
      'Shed Skin',
      'None',
      'Marvel Scale'
    ],
    'Gender': 50,
    'File': '2x0cduj6telvy2q3oxcgm1h365pio4oh'
  },
  {
    'ID': 149,
    'Name': 'Dragonite',
    'Ability': [
      'Inner Focus',
      'None',
      'Multiscale'
    ],
    'Gender': 50,
    'File': 'xcbcuwz6winfsxgiwocsgp5upakdz5tv'
  },
  {
    'ID': 150,
    'Name': 'Mewtwo',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Unnerve'
    ],
    'File': 'bvyoa4wwdcbuytci1rtpu5zym3n8xq73'
  },
  {
    'ID': 151,
    'Name': 'Mew',
    'Gender': null,
    'Ability': [
      'Synchronize',
      'None',
      'None'
    ],
    'File': 'tbs3zqywzy030b3yjz0lu3bzlyrv849u'
  },
  {
    'ID': 152,
    'Name': 'Chikorita',
    'Ability': [
      'Overgrow',
      'None',
      'Leaf Guard'
    ],
    'Gender': 87.5,
    'File': 'gqteb6zpzme0frj9y3oq64sfg31toosi'
  },
  {
    'ID': 153,
    'Name': 'Bayleef',
    'Ability': [
      'Overgrow',
      'None',
      'Leaf Guard'
    ],
    'Gender': 87.5,
    'File': '1ob5a21jh19zlb083om54ox18xkox2fa'
  },
  {
    'ID': 154,
    'Name': 'Meganium',
    'Ability': [
      'Overgrow',
      'None',
      'Leaf Guard'
    ],
    'Gender': 87.5,
    'File': 'urwp152psdrpc2jmin772jtum1vo4w0z'
  },
  {
    'ID': 155,
    'Name': 'Cyndaquil',
    'Ability': [
      'Blaze',
      'None',
      'Flash Fire'
    ],
    'Gender': 87.5,
    'File': 'a4xvaoq0zo4cw0rkycn9kzalkasbdbvf'
  },
  {
    'ID': 156,
    'Name': 'Quilava',
    'Ability': [
      'Blaze',
      'None',
      'Flash Fire'
    ],
    'Gender': 87.5,
    'File': 'mue4g3hxwxnet429jkb73qksw6qt0686'
  },
  {
    'ID': 157,
    'Name': 'Typhlosion',
    'Ability': [
      'Blaze',
      'None',
      'Flash Fire'
    ],
    'Gender': 87.5,
    'File': '0inn6mgjeu7oygzc205j3ho1cs3w4582'
  },
  {
    'ID': 158,
    'Name': 'Totodile',
    'Ability': [
      'Torrent',
      'None',
      'Sheer Force'
    ],
    'Gender': 87.5,
    'File': 'b5ekjgn4mem02x7mnzkbqj6qlyq8clsi'
  },
  {
    'ID': 159,
    'Name': 'Croconaw',
    'Ability': [
      'Torrent',
      'None',
      'Sheer Force'
    ],
    'Gender': 87.5,
    'File': 'o2kvy6x8d3v2so3c09s2gdcok21cf7z2'
  },
  {
    'ID': 160,
    'Name': 'Feraligatr',
    'Ability': [
      'Torrent',
      'None',
      'Sheer Force'
    ],
    'Gender': 87.5,
    'File': 'hg7ax4umn4zfp7sbibn2zeniyiprh3wd'
  },
  {
    'ID': 161,
    'Name': 'Sentret',
    'Ability': [
      'Run Away',
      'Keen Eye',
      'Frisk'
    ],
    'Gender': 50,
    'File': 'i2pagy7q7aha4elve0e7sydf7ogsro3o'
  },
  {
    'ID': 162,
    'Name': 'Furret',
    'Ability': [
      'Run Away',
      'Keen Eye',
      'Frisk'
    ],
    'Gender': 50,
    'File': '97ykjbogeyw26pbewfsfz9i74nepv8vo'
  },
  {
    'ID': 163,
    'Name': 'Hoothoot',
    'Ability': [
      'Insomnia',
      'Keen Eye',
      'Tinted Lens'
    ],
    'Gender': 50,
    'File': 'o4au6k0960vd5t0rhybq5y5ysyvuw9mt'
  },
  {
    'ID': 164,
    'Name': 'Noctowl',
    'Ability': [
      'Insomnia',
      'Keen Eye',
      'Tinted Lens'
    ],
    'Gender': 50,
    'File': 'ojegz3vj84uj322cd8dzv5twj76tsems'
  },
  {
    'ID': 165,
    'Name': 'Ledyba',
    'Ability': [
      'Swarm',
      'Early Bird',
      'Rattled'
    ],
    'Gender': 50,
    'File': '8zvhr9sdwjhck396je3hl56subzrdsno'
  },
  {
    'ID': 166,
    'Name': 'Ledian',
    'Ability': [
      'Swarm',
      'Early Bird',
      'Iron Fist'
    ],
    'Gender': 50,
    'File': 'tkefkx9c9878n5fqo90q6d37fk7pvxd4'
  },
  {
    'ID': 167,
    'Name': 'Spinarak',
    'Ability': [
      'Swarm',
      'Insomnia',
      'Sniper'
    ],
    'Gender': 50,
    'File': '9k8e46x1c6yv402beoswtvyf9du47yf0'
  },
  {
    'ID': 168,
    'Name': 'Ariados',
    'Ability': [
      'Swarm',
      'Insomnia',
      'Sniper'
    ],
    'Gender': 50,
    'File': 'v7blltnbyb7zswkkvzfrsp1ho58twhpi'
  },
  {
    'ID': 169,
    'Name': 'Crobat',
    'Ability': [
      'Inner Focus',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'w4wgsqvg2lqgpzd7ej78pg86gffcwxec'
  },
  {
    'ID': 170,
    'Name': 'Chinchou',
    'Ability': [
      'Volt Absorb',
      'Illuminate',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': '2x6clcp4sl7osp0d18n3uyjwdi7gtu7f'
  },
  {
    'ID': 171,
    'Name': 'Lanturn',
    'Ability': [
      'Volt Absorb',
      'Illuminate',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'x3vqr2j7i71fhmr5bma50m1aw4hzhe5p'
  },
  {
    'ID': 172,
    'Name': 'Pichu',
    'Ability': [
      'Static',
      'None',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'r4rv3g1xn5b2da4o3k2dtutp10rpgsib'
  },
  {
    'ID': 173,
    'Name': 'Cleffa',
    'Ability': [
      'Cute Charm',
      'Magic Guard',
      'Friend Guard'
    ],
    'Gender': 25,
    'File': '0m5m5j78to9wnrkh9reieztksbxsh9h0'
  },
  {
    'ID': 174,
    'Name': 'Igglybuff',
    'Ability': [
      'Cute Charm',
      'Competitive',
      'Friend Guard'
    ],
    'Gender': 25,
    'File': 'v107zh38dqn3gl8arodbf5to3cp31bts'
  },
  {
    'ID': 175,
    'Name': 'Togepi',
    'Ability': [
      'Hustle',
      'Serene Grace',
      'Super Luck'
    ],
    'Gender': 87.5,
    'File': 'ojisg2gqm749ngjidelwwl0n9jeuj1rc'
  },
  {
    'ID': 176,
    'Name': 'Togetic',
    'Ability': [
      'Hustle',
      'Serene Grace',
      'Super Luck'
    ],
    'Gender': 87.5,
    'File': 'rdut8zdlhfwa2p1zs4iq1a0du3bcedtc'
  },
  {
    'ID': 177,
    'Name': 'Natu',
    'Ability': [
      'Synchronize',
      'Early Bird',
      'Magic Bounce'
    ],
    'Gender': 50,
    'File': 'jg0o6ciyy98nx4d8djlj63zsz99huzoh'
  },
  {
    'ID': 178,
    'Name': 'Xatu',
    'Ability': [
      'Synchronize',
      'Early Bird',
      'Magic Bounce'
    ],
    'Gender': 50,
    'File': 'aw6y97tjqisolyxmk08x711v8th413je'
  },
  {
    'ID': 179,
    'Name': 'Mareep',
    'Ability': [
      'Static',
      'None',
      'Plus'
    ],
    'Gender': 50,
    'File': 'sciz69mbkm1g9fzb9uwtvmr6dl9yrtk7'
  },
  {
    'ID': 180,
    'Name': 'Flaaffy',
    'Ability': [
      'Static',
      'None',
      'Plus'
    ],
    'Gender': 50,
    'File': 'v7drbb21cwoiwsvaprjoau2kfhih7jza'
  },
  {
    'ID': 181,
    'Name': 'Ampharos',
    'Ability': [
      'Static',
      'None',
      'Plus'
    ],
    'Gender': 50,
    'File': 'i1a48w6k5indrmauqck30mte1s00gn5f'
  },
  {
    'ID': 182,
    'Name': 'Bellossom',
    'Ability': [
      'Chlorophyll',
      'None',
      'Healer'
    ],
    'Gender': 50,
    'File': '8a8mncbzzgbzhdw71xgt6xlo1stzc76m'
  },
  {
    'ID': 183,
    'Name': 'Marill',
    'Ability': [
      'Thick Fat',
      'Huge Power',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': '78t92knqllm8pi8sgf42ctx8t37c2xkw'
  },
  {
    'ID': 184,
    'Name': 'Azumarill',
    'Ability': [
      'Thick Fat',
      'Huge Power',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': 'u0nc1g6qwvsjzdadt5kg849tyqthdl0l'
  },
  {
    'ID': 185,
    'Name': 'Sudowoodo',
    'Ability': [
      'Sturdy',
      'Rock Head',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'nar7pj66ccw57vidgj7elb498yieq19z'
  },
  {
    'ID': 186,
    'Name': 'Politoed',
    'Ability': [
      'Water Absorb',
      'Damp',
      'Drizzle'
    ],
    'Gender': 50,
    'File': '9c8nqrmzkr1h5l8ntnoa2msh6glzkhde'
  },
  {
    'ID': 187,
    'Name': 'Hoppip',
    'Ability': [
      'Chlorophyll',
      'Leaf Guard',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': '0xa3yl7ksdblyrstfv8shox22hnzizh4'
  },
  {
    'ID': 188,
    'Name': 'Skiploom',
    'Ability': [
      'Chlorophyll',
      'Leaf Guard',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 't94ka7wpb588dr46x12zqwb5pceswj21'
  },
  {
    'ID': 189,
    'Name': 'Jumpluff',
    'Ability': [
      'Chlorophyll',
      'Leaf Guard',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 's5wjvnrbakznes8zhb89tsp0oh38sncu'
  },
  {
    'ID': 190,
    'Name': 'Aipom',
    'Ability': [
      'Run Away',
      'Pickup',
      'Skill Link'
    ],
    'Gender': 50,
    'File': 'v6be5xajdwha37b0ophb9o9ma1m383xr'
  },
  {
    'ID': 191,
    'Name': 'Sunkern',
    'Ability': [
      'Chlorophyll',
      'Solar Power',
      'Early Bird'
    ],
    'Gender': 50,
    'File': '0bak0jp8g2oykl2ddmwqeahotq1vqvix'
  },
  {
    'ID': 192,
    'Name': 'Sunflora',
    'Ability': [
      'Chlorophyll',
      'Solar Power',
      'Early Bird'
    ],
    'Gender': 50,
    'File': '7qydzqhquxjt6hnqbu09ni1g3s52podp'
  },
  {
    'ID': 193,
    'Name': 'Yanma',
    'Ability': [
      'Speed Boost',
      'Compound Eyes',
      'Frisk'
    ],
    'Gender': 50,
    'File': 'j5nxsgt6z9und5ul2mtza2ujua835gwq'
  },
  {
    'ID': 194,
    'Name': 'Wooper',
    'Ability': [
      'Damp',
      'Water Absorb',
      'Unaware'
    ],
    'Gender': 50,
    'File': 'hd1sugtndmps5cj0174oll6svjusnzjp'
  },
  {
    'ID': 195,
    'Name': 'Quagsire',
    'Ability': [
      'Damp',
      'Water Absorb',
      'Unaware'
    ],
    'Gender': 50,
    'File': '3fpgx8dqghzfdwoj9k7hpgz2zim4ho4f'
  },
  {
    'ID': 196,
    'Name': 'Espeon',
    'Ability': [
      'Synchronize',
      'None',
      'Magic Bounce'
    ],
    'Gender': 87.5,
    'File': '1c2xdielfe798x059rqabdmdpb737nqv'
  },
  {
    'ID': 197,
    'Name': 'Umbreon',
    'Ability': [
      'Synchronize',
      'None',
      'Inner Focus'
    ],
    'Gender': 87.5,
    'File': '5nk5o530a4js71jd5gj09lfi0ctsj0lr'
  },
  {
    'ID': 198,
    'Name': 'Murkrow',
    'Ability': [
      'Insomnia',
      'Super Luck',
      'Prankster'
    ],
    'Gender': 50,
    'File': 'o3291pah1wiuk4i30j4992m5kco2sirs'
  },
  {
    'ID': 199,
    'Name': 'Slowking',
    'Ability': [
      'Oblivious',
      'Own Tempo',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'n1c5l8ly2ncxn5oalxd8o0y4tfmps2gu'
  },
  {
    'ID': 200,
    'Name': 'Misdreavus',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '7blri75xyypxdas1p44ykc9jhkfitwxo'
  },
  {
    'ID': 201,
    'Name': 'Unown',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': '2q0exom7cfazb0748rs831mnxvgg3s3n'
  },
  {
    'ID': 202,
    'Name': 'Wobbuffet',
    'Ability': [
      'Shadow Tag',
      'None',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'mzatmsbn7r8r82kk9j6n7njly8agdali'
  },
  {
    'ID': 203,
    'Name': 'Girafarig',
    'Ability': [
      'Inner Focus',
      'Early Bird',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': 'hng55x1ut3vaad4j3n3r5dxu1479blau'
  },
  {
    'ID': 204,
    'Name': 'Pineco',
    'Ability': [
      'Sturdy',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'yo84wmfu1vk88d7fx6zriq02e2utmzme'
  },
  {
    'ID': 205,
    'Name': 'Forretress',
    'Ability': [
      'Sturdy',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'qp42865ouhif4qtitupqq3t6hbv186hi'
  },
  {
    'ID': 206,
    'Name': 'Dunsparce',
    'Ability': [
      'Serene Grace',
      'Run Away',
      'Rattled'
    ],
    'Gender': 50,
    'File': '554l5vsn9pjpe3hrc1cw0qfoymyqqed1'
  },
  {
    'ID': 207,
    'Name': 'Gligar',
    'Ability': [
      'Hyper Cutter',
      'Sand Veil',
      'Immunity'
    ],
    'Gender': 50,
    'File': 'ymfok88nqnar273p77wplddpd9pas4nm'
  },
  {
    'ID': 208,
    'Name': 'Steelix',
    'Ability': [
      'Rock Head',
      'Sturdy',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': 'pdjroac0nwqf7dhwh51tkfdy8wjk0gyb'
  },
  {
    'ID': 209,
    'Name': 'Snubbull',
    'Ability': [
      'Intimidate',
      'Run Away',
      'Rattled'
    ],
    'Gender': 25,
    'File': 'zqt5r5bbjzfk57oqqlvwacdm0psinbxx'
  },
  {
    'ID': 210,
    'Name': 'Granbull',
    'Ability': [
      'Intimidate',
      'Quick Feet',
      'Rattled'
    ],
    'Gender': 25,
    'File': 'yhlui6x75gz66zmbff5cim4c40b2wmq7'
  },
  {
    'ID': 211,
    'Name': 'Qwilfish',
    'Ability': [
      'Poison Point',
      'Swift Swim',
      'Intimidate'
    ],
    'Gender': 50,
    'File': '7jbf7levl8jmuy7hdhwajojuqcib3yy5'
  },
  {
    'ID': 212,
    'Name': 'Scizor',
    'Ability': [
      'Swarm',
      'Technician',
      'Light Metal'
    ],
    'Gender': 50,
    'File': 's6j71uxdpyanwd2bgm4vyohojqz5bxle'
  },
  {
    'ID': 213,
    'Name': 'Shuckle',
    'Ability': [
      'Sturdy',
      'Gluttony',
      'Contrary'
    ],
    'Gender': 50,
    'File': 'x2b5orce2ufj7s7qypf86mo3aldf7n7f'
  },
  {
    'ID': 214,
    'Name': 'Heracross',
    'Ability': [
      'Swarm',
      'Guts',
      'Moxie'
    ],
    'Gender': 50,
    'File': 'pllyctteg6uyzuytqibxgmnfau627vgi'
  },
  {
    'ID': 215,
    'Name': 'Sneasel',
    'Ability': [
      'Inner Focus',
      'Keen Eye',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'xpa67j9jdgc5zrabf3m1d04yu88u7cuj'
  },
  {
    'ID': 216,
    'Name': 'Teddiursa',
    'Ability': [
      'Pickup',
      'Quick Feet',
      'Honey Gather'
    ],
    'Gender': 50,
    'File': 'mumt9tod2slvfuxpwn0bcyeig9cdix0e'
  },
  {
    'ID': 217,
    'Name': 'Ursaring',
    'Ability': [
      'Guts',
      'Quick Feet',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'bdn35obfo40cb1n8xeo9qgi9hbg8bttp'
  },
  {
    'ID': 218,
    'Name': 'Slugma',
    'Ability': [
      'Magma Armor',
      'Flame Body',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'bmx2domq5eperzjthob8sr98qksfnxgo'
  },
  {
    'ID': 219,
    'Name': 'Magcargo',
    'Ability': [
      'Magma Armor',
      'Flame Body',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': '7w3yvkf94as95z5fc3dyw0e1szfpzgx9'
  },
  {
    'ID': 220,
    'Name': 'Swinub',
    'Ability': [
      'Oblivious',
      'Snow Cloak',
      'Thick Fat'
    ],
    'Gender': 50,
    'File': 'wdxax9dtbooqt5fmtp2zv2pdpxcvy5k9'
  },
  {
    'ID': 221,
    'Name': 'Piloswine',
    'Ability': [
      'Oblivious',
      'Snow Cloak',
      'Thick Fat'
    ],
    'Gender': 50,
    'File': 'xa7jtj8r8lnb2oe19knaxmn4b4n5zmx0'
  },
  {
    'ID': 222,
    'Name': 'Corsola',
    'Ability': [
      'Hustle',
      'Natural Cure',
      'Regenerator'
    ],
    'Gender': 25,
    'File': 'wnj4d2b7ijrh6vb2hr0n4yx031oacy2b'
  },
  {
    'ID': 223,
    'Name': 'Remoraid',
    'Ability': [
      'Hustle',
      'Sniper',
      'Moody'
    ],
    'Gender': 50,
    'File': 'sm14mz9ea0i1h49f4ntmouu82vnebp5l'
  },
  {
    'ID': 224,
    'Name': 'Octillery',
    'Ability': [
      'Suction Cups',
      'Sniper',
      'Moody'
    ],
    'Gender': 50,
    'File': '7l33ma7fivhsytvaudqheevx7bmq4vay'
  },
  {
    'ID': 225,
    'Name': 'Delibird',
    'Ability': [
      'Vital Spirit',
      'Hustle',
      'Insomnia'
    ],
    'Gender': 50,
    'File': 'iji90b3pwd65gijwc97m9uxotgdljl61'
  },
  {
    'ID': 226,
    'Name': 'Mantine',
    'Ability': [
      'Swift Swim',
      'Water Absorb',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'yvrpuoiek8k9fhlnhv4vne3ib3x13ofu'
  },
  {
    'ID': 227,
    'Name': 'Skarmory',
    'Ability': [
      'Keen Eye',
      'Sturdy',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'upf52o12fzhpkqle073t2e69undxirhb'
  },
  {
    'ID': 228,
    'Name': 'Houndour',
    'Ability': [
      'Early Bird',
      'Flash Fire',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'ievoy26y3bfe3w12pmitpk1bn8oqiuef'
  },
  {
    'ID': 229,
    'Name': 'Houndoom',
    'Ability': [
      'Early Bird',
      'Flash Fire',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'mfw6wqt81tgdxj6nvh34ywranqktqxfh'
  },
  {
    'ID': 230,
    'Name': 'Kingdra',
    'Ability': [
      'Swift Swim',
      'Sniper',
      'Damp'
    ],
    'Gender': 50,
    'File': '7e37h44l1doviqrl7vba42gby39xkwlt'
  },
  {
    'ID': 231,
    'Name': 'Phanpy',
    'Ability': [
      'Pickup',
      'None',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'pbn1v74lm12kwwqeg97w6kbgg4epmayu'
  },
  {
    'ID': 232,
    'Name': 'Donphan',
    'Ability': [
      'Sturdy',
      'None',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': '97hg0j4ppkzutia0vp9wi53yzk04ro4y'
  },
  {
    'ID': 233,
    'Name': 'Porygon2',
    'Gender': null,
    'Ability': [
      'Trace',
      'Download',
      'Analytic'
    ],
    'File': '394anxam2vxaz54ji8wqhyg9syy41352'
  },
  {
    'ID': 234,
    'Name': 'Stantler',
    'Ability': [
      'Intimidate',
      'Frisk',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': '3mmy56fe132anjfpge6dbehuzxxja000'
  },
  {
    'ID': 235,
    'Name': 'Smeargle',
    'Ability': [
      'Own Tempo',
      'Technician',
      'Moody'
    ],
    'Gender': 50,
    'File': 'p01vxjdxpfk18ktsp5kbdkrcaggifig6'
  },
  {
    'ID': 236,
    'Name': 'Tyrogue',
    'Ability': [
      'Guts',
      'Steadfast',
      'Vital Spirit'
    ],
    'Gender': 100,
    'File': '5mxtno1pcnsix0w3ug8c3k1k02gveie1'
  },
  {
    'ID': 237,
    'Name': 'Hitmontop',
    'Ability': [
      'Intimidate',
      'Technician',
      'Steadfast'
    ],
    'Gender': 100,
    'File': 'decjz2e4yb3gohvi7t7t3rbgx01krchs'
  },
  {
    'ID': 238,
    'Name': 'Smoochum',
    'Ability': [
      'Oblivious',
      'Forewarn',
      'Hydration'
    ],
    'Gender': 0.01,
    'File': 'q1ngjgqkbgck289zblysatoohyx9n9do'
  },
  {
    'ID': 239,
    'Name': 'Elekid',
    'Ability': [
      'Static',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': 'ohu6x3uyylifhpb9vq6b857nnj6jp55a'
  },
  {
    'ID': 240,
    'Name': 'Magby',
    'Ability': [
      'Flame Body',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': 'tyfyfpm6onrq8mpa2298r6iaz0d2n5jj'
  },
  {
    'ID': 241,
    'Name': 'Miltank',
    'Ability': [
      'Thick Fat',
      'Scrappy',
      'Sap Sipper'
    ],
    'Gender': 0.01,
    'File': 'a6fhjo133lwi0bd6ax47pyftfwabw7b0'
  },
  {
    'ID': 242,
    'Name': 'Blissey',
    'Ability': [
      'Natural Cure',
      'Serene Grace',
      'Healer'
    ],
    'Gender': 0.01,
    'File': '3o732mquks3orvgbw0zj3i54c230q9yx'
  },
  {
    'ID': 243,
    'Name': 'Raikou',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'suv98fuzeubl28x13x051dgvx2ju8513'
  },
  {
    'ID': 244,
    'Name': 'Entei',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'tt1ym6vdrjae0eam3ldf3ujj9zm9dyo6'
  },
  {
    'ID': 245,
    'Name': 'Suicune',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'f05658abjtrxvdmvdru1fv3givkng6n1'
  },
  {
    'ID': 246,
    'Name': 'Larvitar',
    'Ability': [
      'Guts',
      'None',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': '6mec6q27wsglnwbitq4ikutbpkcmocen'
  },
  {
    'ID': 247,
    'Name': 'Pupitar',
    'Ability': [
      'Shed Skin',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'j7whze4xwh12emc83twhpfy8pre2wkvu'
  },
  {
    'ID': 248,
    'Name': 'Tyranitar',
    'Ability': [
      'Sand Stream',
      'None',
      'Unnerve'
    ],
    'Gender': 50,
    'File': '02c3ldw3zmfxvt1rhsgcwrdvy47dlofb'
  },
  {
    'ID': 249,
    'Name': 'Lugia',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Multiscale'
    ],
    'File': 'zucm1bckjtlbbjfoif6sbtruo64xt9l5'
  },
  {
    'ID': 250,
    'Name': 'Ho-oh',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Regenerator'
    ],
    'File': 'i6zlrdixpikdu056gsxa6whhs5i5u9xj'
  },
  {
    'ID': 251,
    'Name': 'Celebi',
    'Gender': null,
    'Ability': [
      'Natural Cure',
      'None',
      'None'
    ],
    'File': 'a9k2x7tds7l75o1mc0tdkmokap50qisi'
  },
  {
    'ID': 252,
    'Name': 'Treecko',
    'Ability': [
      'Overgrow',
      'None',
      'Unburden'
    ],
    'Gender': 87.5,
    'File': 'bx99v8fkh38uc7npoobs7t81i3xpbinr'
  },
  {
    'ID': 253,
    'Name': 'Grovyle',
    'Ability': [
      'Overgrow',
      'None',
      'Unburden'
    ],
    'Gender': 87.5,
    'File': 'h48yvy7oc409t7jsg2i7awv6c0iokvcj'
  },
  {
    'ID': 254,
    'Name': 'Sceptile',
    'Ability': [
      'Overgrow',
      'None',
      'Unburden'
    ],
    'Gender': 87.5,
    'File': 'poez0808rywv4gucm6a2jpk5br2tgxkt'
  },
  {
    'ID': 255,
    'Name': 'Torchic',
    'Ability': [
      'Blaze',
      'None',
      'Speed Boost'
    ],
    'Gender': 87.5,
    'File': '72gl7tkoq4h260uks6dc1xoga2m7s0k4'
  },
  {
    'ID': 256,
    'Name': 'Combusken',
    'Ability': [
      'Blaze',
      'None',
      'Speed Boost'
    ],
    'Gender': 87.5,
    'File': '027onp55shxb50ah56b0s7nk6h5h4u1g'
  },
  {
    'ID': 257,
    'Name': 'Blaziken',
    'Ability': [
      'Blaze',
      'None',
      'Speed Boost'
    ],
    'Gender': 87.5,
    'File': 'zeabq4vriximiqxbdir85rtunbk2swda'
  },
  {
    'ID': 258,
    'Name': 'Mudkip',
    'Ability': [
      'Torrent',
      'None',
      'Damp'
    ],
    'Gender': 87.5,
    'File': 'q1xcfc55ft12vnaz55gnq2veqo4ybiwz'
  },
  {
    'ID': 259,
    'Name': 'Marshtomp',
    'Ability': [
      'Torrent',
      'None',
      'Damp'
    ],
    'Gender': 87.5,
    'File': 'x2zt23ugm0t75jsxqbk95hcodfm1q31k'
  },
  {
    'ID': 260,
    'Name': 'Swampert',
    'Ability': [
      'Torrent',
      'None',
      'Damp'
    ],
    'Gender': 87.5,
    'File': '6zn6bed4ne8nc9644p9zh22dbji1a4yo'
  },
  {
    'ID': 261,
    'Name': 'Poochyena',
    'Ability': [
      'Run Away',
      'Quick Feet',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'hirjbq4muhyw9cmw6zyyzgw8xslo9bj3'
  },
  {
    'ID': 262,
    'Name': 'Mightyena',
    'Ability': [
      'Intimidate',
      'Quick Feet',
      'Moxie'
    ],
    'Gender': 50,
    'File': 'jtbg18x3mxr0jnlmekvzibt771hyy2rx'
  },
  {
    'ID': 263,
    'Name': 'Zigzagoon',
    'Ability': [
      'Pickup',
      'Gluttony',
      'Quick Feet'
    ],
    'Gender': 50,
    'File': 'qdsjij31r07iwcs5zx8evwesh7ikaeks'
  },
  {
    'ID': 264,
    'Name': 'Linoone',
    'Ability': [
      'Pickup',
      'Gluttony',
      'Quick Feet'
    ],
    'Gender': 50,
    'File': 'njtlf2db4bln4rupxeaz9r72a4qfc2wo'
  },
  {
    'ID': 265,
    'Name': 'Wurmple',
    'Ability': [
      'Shield Dust',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': '0dt20z9vfvhexm7d2dfvju7hu1x50l9n'
  },
  {
    'ID': 266,
    'Name': 'Silcoon',
    'Ability': [
      'Shed Skin',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '1dcvpwogtnxdb6iwfmc7l3sow3ejdkm1'
  },
  {
    'ID': 267,
    'Name': 'Beautifly',
    'Ability': [
      'Swarm',
      'None',
      'Rivalry'
    ],
    'Gender': 50,
    'File': 'fftu92comcoyrwu6f817n3rfjahr1h87'
  },
  {
    'ID': 268,
    'Name': 'Cascoon',
    'Ability': [
      'Shed Skin',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'kd6ibb5eib2nszhvtb678lrrsjwy70on'
  },
  {
    'ID': 269,
    'Name': 'Dustox',
    'Ability': [
      'Shield Dust',
      'None',
      'Compound Eyes'
    ],
    'Gender': 50,
    'File': 'x0c8grud784lc51n5qkdpeheq2kzd8zn'
  },
  {
    'ID': 270,
    'Name': 'Lotad',
    'Ability': [
      'Swift Swim',
      'Rain Dish',
      'Own Tempo'
    ],
    'Gender': 50,
    'File': 'gg7v8z722bf010pl8a7kyx9grgtstq62'
  },
  {
    'ID': 271,
    'Name': 'Lombre',
    'Ability': [
      'Swift Swim',
      'Rain Dish',
      'Own Tempo'
    ],
    'Gender': 50,
    'File': 'nszfhq4vtkbm4jj79c3y1hnbcyi2qeln'
  },
  {
    'ID': 272,
    'Name': 'Ludicolo',
    'Ability': [
      'Swift Swim',
      'Rain Dish',
      'Own Tempo'
    ],
    'Gender': 50,
    'File': 'beeyhm1tzbnnggjmngckor8pfm35vqbj'
  },
  {
    'ID': 273,
    'Name': 'Seedot',
    'Ability': [
      'Chlorophyll',
      'Early Bird',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'wkxxjfrnrcpc3aya8ajibzamzsb95efx'
  },
  {
    'ID': 274,
    'Name': 'Nuzleaf',
    'Ability': [
      'Chlorophyll',
      'Early Bird',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'rq1244qgfa3a5s7lrgmaerr75vbvjonj'
  },
  {
    'ID': 275,
    'Name': 'Shiftry',
    'Ability': [
      'Chlorophyll',
      'Early Bird',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'qytabb08t0ca4yqxj0fgjnx3g5ut3e9u'
  },
  {
    'ID': 276,
    'Name': 'Taillow',
    'Ability': [
      'Guts',
      'None',
      'Scrappy'
    ],
    'Gender': 50,
    'File': 'fsucabd849vizn3p0ws3d1wwoor4remw'
  },
  {
    'ID': 277,
    'Name': 'Swellow',
    'Ability': [
      'Guts',
      'None',
      'Scrappy'
    ],
    'Gender': 50,
    'File': '3720cj5daifx31x4nvlpfi6c68wxqv5f'
  },
  {
    'ID': 278,
    'Name': 'Wingull',
    'Ability': [
      'Keen Eye',
      'Hydration',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'kfuusao15mgx6qtysywjovug7l4u6hnx'
  },
  {
    'ID': 279,
    'Name': 'Pelipper',
    'Ability': [
      'Keen Eye',
      'Drizzle',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'tzdtynp0y39uc22y8gceuowovwafpuj5'
  },
  {
    'ID': 280,
    'Name': 'Ralts',
    'Ability': [
      'Synchronize',
      'Trace',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'utwk4wynvhhdex0lj6vzd4zfbpklw3oi'
  },
  {
    'ID': 281,
    'Name': 'Kirlia',
    'Ability': [
      'Synchronize',
      'Trace',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'f6on3so2d2oq7r49qkzsafpjgw2n535k'
  },
  {
    'ID': 282,
    'Name': 'Gardevoir',
    'Ability': [
      'Synchronize',
      'Trace',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'gxbj8ecsaqkumbgiurroz2lrxs8o1t4o'
  },
  {
    'ID': 283,
    'Name': 'Surskit',
    'Ability': [
      'Swift Swim',
      'None',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'ifetln06vdqanot4w39pbko6stg4bo9s'
  },
  {
    'ID': 284,
    'Name': 'Masquerain',
    'Ability': [
      'Intimidate',
      'None',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'pf8dedze2vok9natmst3nekgbg1ymemg'
  },
  {
    'ID': 285,
    'Name': 'Shroomish',
    'Ability': [
      'Effect Spore',
      'Poison Heal',
      'Quick Feet'
    ],
    'Gender': 50,
    'File': 'ygyuqczutsbzoyvisw7wah0hd4h0h09a'
  },
  {
    'ID': 286,
    'Name': 'Breloom',
    'Ability': [
      'Effect Spore',
      'Poison Heal',
      'Technician'
    ],
    'Gender': 50,
    'File': 'a6ivyb1stt47fffu9l35a58qmqgdkof2'
  },
  {
    'ID': 287,
    'Name': 'Slakoth',
    'Ability': [
      'Truant',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'hsoqpklvgtrfex528wc5mjvvr91bfs0q'
  },
  {
    'ID': 288,
    'Name': 'Vigoroth',
    'Ability': [
      'Vital Spirit',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '8jaadfd8cbz8mklbo5iqllq53cdtk82e'
  },
  {
    'ID': 289,
    'Name': 'Slaking',
    'Ability': [
      'Truant',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'x5mpbmn0hv3rciiz7z74k0boasrtgx11'
  },
  {
    'ID': 290,
    'Name': 'Nincada',
    'Ability': [
      'Compound Eyes',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': 'qw4au96n25pnqpur0i9vjr3g52s3dtbh'
  },
  {
    'ID': 291,
    'Name': 'Ninjask',
    'Ability': [
      'Speed Boost',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': '3cllblpjlywaybxy3qey9ubof4ltzbz0'
  },
  {
    'ID': 292,
    'Name': 'Shedinja',
    'Gender': null,
    'Ability': [
      'Wonder Guard',
      'None',
      'None'
    ],
    'File': '4rkna31k4evalxo1uo1xthvmjbar1k0b'
  },
  {
    'ID': 293,
    'Name': 'Whismur',
    'Ability': [
      'Soundproof',
      'None',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'mh98ehykmz93ythmfi4yvskyyvgfzqlh'
  },
  {
    'ID': 294,
    'Name': 'Loudred',
    'Ability': [
      'Soundproof',
      'None',
      'Scrappy'
    ],
    'Gender': 50,
    'File': '5umbgyskzrf6h0opc29wnabtlzzijvps'
  },
  {
    'ID': 295,
    'Name': 'Exploud',
    'Ability': [
      'Soundproof',
      'None',
      'Scrappy'
    ],
    'Gender': 50,
    'File': 'mlre5n70u889r2ykec3cmmyau5b417c7'
  },
  {
    'ID': 296,
    'Name': 'Makuhita',
    'Ability': [
      'Thick Fat',
      'Guts',
      'Sheer Force'
    ],
    'Gender': 75,
    'File': '0d4lqxt9teq0262ecfaudt3dff9ualjl'
  },
  {
    'ID': 297,
    'Name': 'Hariyama',
    'Ability': [
      'Thick Fat',
      'Guts',
      'Sheer Force'
    ],
    'Gender': 75,
    'File': 'h89ez4apzivor68tcooaza062p7vk0oi'
  },
  {
    'ID': 298,
    'Name': 'Azurill',
    'Ability': [
      'Thick Fat',
      'Huge Power',
      'Sap Sipper'
    ],
    'Gender': 25,
    'File': '4drbv0cungg5j6b3qq2uejjxya3727la'
  },
  {
    'ID': 299,
    'Name': 'Nosepass',
    'Ability': [
      'Sturdy',
      'Magnet Pull',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'xy78n9vuv0adtb2s1pymdnryikjkpqk9'
  },
  {
    'ID': 300,
    'Name': 'Skitty',
    'Ability': [
      'Cute Charm',
      'Normalize',
      'Wonder Skin'
    ],
    'Gender': 25,
    'File': '7td6owwddqsjhf6foh54f0e4wtskdu35'
  },
  {
    'ID': 301,
    'Name': 'Delcatty',
    'Ability': [
      'Cute Charm',
      'Normalize',
      'Wonder Skin'
    ],
    'Gender': 25,
    'File': 'gwok2qcli7cjm0urwxx6h9gubkuum5e6'
  },
  {
    'ID': 302,
    'Name': 'Sableye',
    'Ability': [
      'Keen Eye',
      'Stall',
      'Prankster'
    ],
    'Gender': 50,
    'File': 'sk2yqiuj3jy0m5ez3tz79a7m5ilprizx'
  },
  {
    'ID': 303,
    'Name': 'Mawile',
    'Ability': [
      'Hyper Cutter',
      'Intimidate',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': 'lvllqitj2xn4qd4upa2q3t1w3wprydz7'
  },
  {
    'ID': 304,
    'Name': 'Aron',
    'Ability': [
      'Sturdy',
      'Rock Head',
      'Heavy Metal'
    ],
    'Gender': 50,
    'File': '9hazjjc9138uody9kuzrgfltpbabrhvf'
  },
  {
    'ID': 305,
    'Name': 'Lairon',
    'Ability': [
      'Sturdy',
      'Rock Head',
      'Heavy Metal'
    ],
    'Gender': 50,
    'File': 'ldnalvt2oevrqc0762ysz5u999qhx7ap'
  },
  {
    'ID': 306,
    'Name': 'Aggron',
    'Ability': [
      'Sturdy',
      'Rock Head',
      'Heavy Metal'
    ],
    'Gender': 50,
    'File': 'e54m773w7hfoy40per9iloibqy4bmdlf'
  },
  {
    'ID': 307,
    'Name': 'Meditite',
    'Ability': [
      'Pure Power',
      'None',
      'Telepathy'
    ],
    'Gender': 50,
    'File': '7art19idanqo5jaky3vojdpjsp2fof1g'
  },
  {
    'ID': 308,
    'Name': 'Medicham',
    'Ability': [
      'Pure Power',
      'None',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'uakhm6d6bujbpzv7bs3x5np4aht977s3'
  },
  {
    'ID': 309,
    'Name': 'Electrike',
    'Ability': [
      'Static',
      'Lightning Rod',
      'Minus'
    ],
    'Gender': 50,
    'File': 'z2i7bxrdyykq4iref0qgw2u61twxlyvq'
  },
  {
    'ID': 310,
    'Name': 'Manectric',
    'Ability': [
      'Static',
      'Lightning Rod',
      'Minus'
    ],
    'Gender': 50,
    'File': '8414s5fogfmhyjvz0927pe07oytay6zy'
  },
  {
    'ID': 311,
    'Name': 'Plusle',
    'Ability': [
      'Plus',
      'None',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'hisu9e3asvzrweijkkse3kstesmv70pd'
  },
  {
    'ID': 312,
    'Name': 'Minun',
    'Ability': [
      'Minus',
      'None',
      'Volt Absorb'
    ],
    'Gender': 50,
    'File': '05ksx62tjls3wl6ps7k62zowh90bnybt'
  },
  {
    'ID': 313,
    'Name': 'Volbeat',
    'Ability': [
      'Illuminate',
      'Swarm',
      'Prankster'
    ],
    'Gender': 100,
    'File': 'kwj167jvdrvzzvw16exzah4sy0kom4us'
  },
  {
    'ID': 314,
    'Name': 'Illumise',
    'Ability': [
      'Oblivious',
      'Tinted Lens',
      'Prankster'
    ],
    'Gender': 0.01,
    'File': 'dzy52lc97ttqi1rx7o5nitclrm8nrsl8'
  },
  {
    'ID': 315,
    'Name': 'Roselia',
    'Ability': [
      'Natural Cure',
      'Poison Point',
      'Leaf Guard'
    ],
    'Gender': 50,
    'File': '1zzqxdff9pw88t1upxlx35crwl2lhhqi'
  },
  {
    'ID': 316,
    'Name': 'Gulpin',
    'Ability': [
      'Liquid Ooze',
      'Sticky Hold',
      'Gluttony'
    ],
    'Gender': 50,
    'File': '903nj5dvlknh3lt5rqsfc5tgxlxxp6hm'
  },
  {
    'ID': 317,
    'Name': 'Swalot',
    'Ability': [
      'Liquid Ooze',
      'Sticky Hold',
      'Gluttony'
    ],
    'Gender': 50,
    'File': '4flbezztweyfrbv0vse9y64rs524dvh7'
  },
  {
    'ID': 318,
    'Name': 'Carvanha',
    'Ability': [
      'Rough Skin',
      'None',
      'Speed Boost'
    ],
    'Gender': 50,
    'File': 'hx0g3wry231b5u3x9bz6j9en5tfzw3qm'
  },
  {
    'ID': 319,
    'Name': 'Sharpedo',
    'Ability': [
      'Rough Skin',
      'None',
      'Speed Boost'
    ],
    'Gender': 50,
    'File': 'i6ssfnxih17a8x220vptmb74rieyafqb'
  },
  {
    'ID': 320,
    'Name': 'Wailmer',
    'Ability': [
      'Water Veil',
      'Oblivious',
      'Pressure'
    ],
    'Gender': 50,
    'File': 'o571lndt7qhqg3659kdt58ijbel1ehii'
  },
  {
    'ID': 321,
    'Name': 'Wailord',
    'Ability': [
      'Water Veil',
      'Oblivious',
      'Pressure'
    ],
    'Gender': 50,
    'File': '2gee9icuhh5lpg03zqycv5af5sy771by'
  },
  {
    'ID': 322,
    'Name': 'Numel',
    'Ability': [
      'Oblivious',
      'Simple',
      'Own Tempo'
    ],
    'Gender': 50,
    'File': 'yxpwzf88rd091mb0i1ki9ubf95bcpeqa'
  },
  {
    'ID': 323,
    'Name': 'Camerupt',
    'Ability': [
      'Magma Armor',
      'Solid Rock',
      'Anger Point'
    ],
    'Gender': 50,
    'File': 'lx44fgn8he67uzvby5zg42g557yf1a3k'
  },
  {
    'ID': 324,
    'Name': 'Torkoal',
    'Ability': [
      'White Smoke',
      'Drought',
      'Shell Armor'
    ],
    'Gender': 50,
    'File': 'vh6vovydz9kity2lzcg70p3ly615v8wv'
  },
  {
    'ID': 325,
    'Name': 'Spoink',
    'Ability': [
      'Thick Fat',
      'Own Tempo',
      'Gluttony'
    ],
    'Gender': 50,
    'File': '98stl1d0e8wnxa20i4oimh1pnn58ozvs'
  },
  {
    'ID': 326,
    'Name': 'Grumpig',
    'Ability': [
      'Thick Fat',
      'Own Tempo',
      'Gluttony'
    ],
    'Gender': 50,
    'File': 'qvhspk1tiwpcivvgymadvv9oa68xskgv'
  },
  {
    'ID': 327,
    'Name': 'Spinda',
    'Ability': [
      'Own Tempo',
      'Tangled Feet',
      'Contrary'
    ],
    'Gender': 50,
    'File': 'bol4m3024j2wdx9ithwg3buziwdffr7c'
  },
  {
    'ID': 328,
    'Name': 'Trapinch',
    'Ability': [
      'Hyper Cutter',
      'Arena Trap',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': 'c0qoznth55aoqmbeilfvtlbylrl007e7'
  },
  {
    'ID': 329,
    'Name': 'Vibrava',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ptxm3fa90tqlolmzz92ado4y6o8k3q9r'
  },
  {
    'ID': 330,
    'Name': 'Flygon',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'qrn31e18271x21vf0di84949mhd305hv'
  },
  {
    'ID': 331,
    'Name': 'Cacnea',
    'Ability': [
      'Sand Veil',
      'None',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'xomryhmjpf3daz1xcadckb71elunqjfz'
  },
  {
    'ID': 332,
    'Name': 'Cacturne',
    'Ability': [
      'Sand Veil',
      'None',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'xe82ibxzl0hpnmdzdi493u7n0sp9tetu'
  },
  {
    'ID': 333,
    'Name': 'Swablu',
    'Ability': [
      'Natural Cure',
      'None',
      'Cloud Nine'
    ],
    'Gender': 50,
    'File': '64by47t1fcnsdvbi9tlo7cvkgpngdn2s'
  },
  {
    'ID': 334,
    'Name': 'Altaria',
    'Ability': [
      'Natural Cure',
      'None',
      'Cloud Nine'
    ],
    'Gender': 50,
    'File': 'mov5odrc4kcf9o7jypngo3859f0548c9'
  },
  {
    'ID': 335,
    'Name': 'Zangoose',
    'Ability': [
      'Immunity',
      'None',
      'Toxic Boost'
    ],
    'Gender': 50,
    'File': 'dxzd99qj32rbefwe5ixb3wsthfl2mrjo'
  },
  {
    'ID': 336,
    'Name': 'Seviper',
    'Ability': [
      'Shed Skin',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': '98my3davzttg6h5bcl9tntbul28siose'
  },
  {
    'ID': 337,
    'Name': 'Lunatone',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'ogqfas0fc9uif5bbjvvbai4fqh9pqdmp'
  },
  {
    'ID': 338,
    'Name': 'Solrock',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': '10wis1nzik7f8xfc1y0hgkuj2ffxzi1e'
  },
  {
    'ID': 339,
    'Name': 'Barboach',
    'Ability': [
      'Oblivious',
      'Anticipation',
      'Hydration'
    ],
    'Gender': 50,
    'File': 'smqz5fqtei6ziksckjtbi7skh3exk6av'
  },
  {
    'ID': 340,
    'Name': 'Whiscash',
    'Ability': [
      'Oblivious',
      'Anticipation',
      'Hydration'
    ],
    'Gender': 50,
    'File': 'gjga3y5yo0z352jebcxee8kldflk7u9r'
  },
  {
    'ID': 341,
    'Name': 'Corphish',
    'Ability': [
      'Hyper Cutter',
      'Shell Armor',
      'Adaptability'
    ],
    'Gender': 50,
    'File': '1lyued2dj6ax8nvdxe8jjydywve2unre'
  },
  {
    'ID': 342,
    'Name': 'Crawdaunt',
    'Ability': [
      'Hyper Cutter',
      'Shell Armor',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'r250593ifuctlcq8rqjlat7xvxnoiz5c'
  },
  {
    'ID': 343,
    'Name': 'Baltoy',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'j8mcojiemvdfmwu4wjb5x8iuvxiekgt5'
  },
  {
    'ID': 344,
    'Name': 'Claydol',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': '7gjxk3nigmdl1u2hfp1v6j3915yu8cz0'
  },
  {
    'ID': 345,
    'Name': 'Lileep',
    'Ability': [
      'Suction Cups',
      'None',
      'Storm Drain'
    ],
    'Gender': 87.5,
    'File': '7yuftxtd4lmo0qj9cd75ku36o8fk3k4d'
  },
  {
    'ID': 346,
    'Name': 'Cradily',
    'Ability': [
      'Suction Cups',
      'None',
      'Storm Drain'
    ],
    'Gender': 87.5,
    'File': 'ieucet3nd0uk8a3pk5bdg5lj9lvkb1xu'
  },
  {
    'ID': 347,
    'Name': 'Anorith',
    'Ability': [
      'Battle Armor',
      'None',
      'Swift Swim'
    ],
    'Gender': 87.5,
    'File': '640w8r5rhd6zm96wql1fi7f6htzcesuw'
  },
  {
    'ID': 348,
    'Name': 'Armaldo',
    'Ability': [
      'Battle Armor',
      'None',
      'Swift Swim'
    ],
    'Gender': 87.5,
    'File': 'grnjvp6nr44nppd6rgknzgtk49ncyk3m'
  },
  {
    'ID': 349,
    'Name': 'Feebas',
    'Ability': [
      'Swift Swim',
      'Oblivious',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'd3suvfx8724lc4u1bqydbusbdroih7xt'
  },
  {
    'ID': 350,
    'Name': 'Milotic',
    'Ability': [
      'Marvel Scale',
      'Competitive',
      'Cute Charm'
    ],
    'Gender': 50,
    'File': '3w0xjn316rnfxthrsuls2mhy92kcxd4m'
  },
  {
    'ID': 351,
    'Name': 'Castform',
    'Ability': [
      'Forecast',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'y4k5hwyefyt52838nxo904902h88skoe'
  },
  {
    'ID': 352,
    'Name': 'Kecleon',
    'Ability': [
      'Color Change',
      'None',
      'Protean'
    ],
    'Gender': 50,
    'File': '691iwawmph3cf044pfm1t12ui1bcfdiu'
  },
  {
    'ID': 353,
    'Name': 'Shuppet',
    'Ability': [
      'Insomnia',
      'Frisk',
      'Cursed Body'
    ],
    'Gender': 50,
    'File': 'g5dwglvsoliu1yi0lsexlxlgl40twuuh'
  },
  {
    'ID': 354,
    'Name': 'Banette',
    'Ability': [
      'Insomnia',
      'Frisk',
      'Cursed Body'
    ],
    'Gender': 50,
    'File': 'i8oob498rnvmwy1g24e0xrab8bghvpep'
  },
  {
    'ID': 355,
    'Name': 'Duskull',
    'Ability': [
      'Levitate',
      'None',
      'Frisk'
    ],
    'Gender': 50,
    'File': '5ue699volbnex0hhd2z7978rd8psrce2'
  },
  {
    'ID': 356,
    'Name': 'Dusclops',
    'Ability': [
      'Pressure',
      'None',
      'Frisk'
    ],
    'Gender': 50,
    'File': 'oupy4zu3k0wayggf6q82bv5vic63212m'
  },
  {
    'ID': 357,
    'Name': 'Tropius',
    'Ability': [
      'Chlorophyll',
      'Solar Power',
      'Harvest'
    ],
    'Gender': 50,
    'File': 'nbr36m0ki57lwhjtoirdo5fmum2q3y8r'
  },
  {
    'ID': 358,
    'Name': 'Chimecho',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '3hn1jegreqjw27tz4acpyk882az8r3ax'
  },
  {
    'ID': 359,
    'Name': 'Absol',
    'Ability': [
      'Pressure',
      'Super Luck',
      'Justified'
    ],
    'Gender': 50,
    'File': 'im1iupul3b1ipz1y2uqkjk64gwumzlkp'
  },
  {
    'ID': 360,
    'Name': 'Wynaut',
    'Ability': [
      'Shadow Tag',
      'None',
      'Telepathy'
    ],
    'Gender': 50,
    'File': '2ucl6pos9bjoloaqsai2iefq1d384b0h'
  },
  {
    'ID': 361,
    'Name': 'Snorunt',
    'Ability': [
      'Inner Focus',
      'Ice Body',
      'Moody'
    ],
    'Gender': 50,
    'File': 'sg3c6atiq500y60wj7ng1lihce1rn6zh'
  },
  {
    'ID': 362,
    'Name': 'Glalie',
    'Ability': [
      'Inner Focus',
      'Ice Body',
      'Moody'
    ],
    'Gender': 50,
    'File': 'p7s2vhlvykzxx8mcaayiqbgebxaaoilf'
  },
  {
    'ID': 363,
    'Name': 'Spheal',
    'Ability': [
      'Thick Fat',
      'Ice Body',
      'Oblivious'
    ],
    'Gender': 50,
    'File': 'jq0wq5nyinj0bu289ho8j7je5b8q6hou'
  },
  {
    'ID': 364,
    'Name': 'Sealeo',
    'Ability': [
      'Thick Fat',
      'Ice Body',
      'Oblivious'
    ],
    'Gender': 50,
    'File': 'e5b8ooa5fxg7mjk8upvcu9c8jvq5bf1f'
  },
  {
    'ID': 365,
    'Name': 'Walrein',
    'Ability': [
      'Thick Fat',
      'Ice Body',
      'Oblivious'
    ],
    'Gender': 50,
    'File': 'zv1s42wc7b36lx8nalih6msdkpd5thd8'
  },
  {
    'ID': 366,
    'Name': 'Clamperl',
    'Ability': [
      'Shell Armor',
      'None',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'e2uw3c00jd1g0lifwd0067nufvjxdpjc'
  },
  {
    'ID': 367,
    'Name': 'Huntail',
    'Ability': [
      'Swift Swim',
      'None',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'utzbn50c7ncaahi6prmqvw045rmjsx60'
  },
  {
    'ID': 368,
    'Name': 'Gorebyss',
    'Ability': [
      'Swift Swim',
      'None',
      'Hydration'
    ],
    'Gender': 50,
    'File': 'qlkb2ylqgb9k1hsh0thy5t1jw76pvtxc'
  },
  {
    'ID': 369,
    'Name': 'Relicanth',
    'Ability': [
      'Swift Swim',
      'Rock Head',
      'Sturdy'
    ],
    'Gender': 87.5,
    'File': 'n4hoe6x9r559yosor9uz2mm99ea0yd9d'
  },
  {
    'ID': 370,
    'Name': 'Luvdisc',
    'Ability': [
      'Swift Swim',
      'None',
      'Hydration'
    ],
    'Gender': 25,
    'File': 'r3pyvu6jyjuqy4aazgnc8jhcniepzzlu'
  },
  {
    'ID': 371,
    'Name': 'Bagon',
    'Ability': [
      'Rock Head',
      'None',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': 'zase441hbbadeaocgidrrq2k0a72kh8x'
  },
  {
    'ID': 372,
    'Name': 'Shelgon',
    'Ability': [
      'Rock Head',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'nlv0et6udrkl9qx0a6u2tisvjv5fpkgh'
  },
  {
    'ID': 373,
    'Name': 'Salamence',
    'Ability': [
      'Intimidate',
      'None',
      'Moxie'
    ],
    'Gender': 50,
    'File': '4a1dlsgdkos5jro7wf580k519uub35hg'
  },
  {
    'ID': 374,
    'Name': 'Beldum',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Light Metal'
    ],
    'File': '8rft6571es0qpnbkn3zaepiptdgwif00'
  },
  {
    'ID': 375,
    'Name': 'Metang',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Light Metal'
    ],
    'File': 'uz6g87dbfs6byuac8vp27o55renh8tjk'
  },
  {
    'ID': 376,
    'Name': 'Metagross',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Light Metal'
    ],
    'File': 'wh5g0mojn3tvbcsuutnw67pernomqovb'
  },
  {
    'ID': 377,
    'Name': 'Regirock',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Sturdy'
    ],
    'File': '0vlzi18o27wd44eqafekdu6w3wk1hhh1'
  },
  {
    'ID': 378,
    'Name': 'Regice',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Ice Body'
    ],
    'File': 'zzw7fnamry72vzad83j4b19ffe30ymu2'
  },
  {
    'ID': 379,
    'Name': 'Registeel',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Ligh Metal'
    ],
    'File': 'nrupslhbm07yyq9gahdfcz4zxu54rzeg'
  },
  {
    'ID': 380,
    'Name': 'Latias',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 0.01,
    'File': 'xngi005uz6lp7tohhrmf9jzd10ggm3ql'
  },
  {
    'ID': 381,
    'Name': 'Latios',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': '2ixux86xya2usamizo4421myu76nefxy'
  },
  {
    'ID': 382,
    'Name': 'Kyogre',
    'Gender': null,
    'Ability': [
      'Drizzle',
      'None',
      'None'
    ],
    'File': '5dlejtqqif2ssbr8o3cg9qluu2y62io2'
  },
  {
    'ID': 383,
    'Name': 'Groudon',
    'Gender': null,
    'Ability': [
      'Drought',
      'None',
      'None'
    ],
    'File': 'qwiqxzzjasxzpg2h4demrk0lv5k5ohs9'
  },
  {
    'ID': 384,
    'Name': 'Rayquaza',
    'Gender': null,
    'Ability': [
      'Air Look',
      'None',
      'Delta Stream'
    ],
    'File': 'l910xuc3bz7l41wstnvelwofv3rjikbi'
  },
  {
    'ID': 385,
    'Name': 'Jirachi',
    'Gender': null,
    'Ability': [
      'Serene Grace',
      'None',
      'None'
    ],
    'File': 'p92dcf7jqfw0fpaozvmakaekb0i70a99'
  },
  {
    'ID': 386,
    'Name': 'Deoxys',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'f24iv4tla2hdvqzz7bfcuegfksuf5ml5'
  },
  {
    'ID': 387,
    'Name': 'Turtwig',
    'Ability': [
      'Overgrow',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'x9b2bkq2sv5xskp0v76h3ibic8mdu3xd'
  },
  {
    'ID': 388,
    'Name': 'Grotle',
    'Ability': [
      'Overgrow',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'f8ga52iqt1z1qjns7t0poafs17puo1x1'
  },
  {
    'ID': 389,
    'Name': 'Torterra',
    'Ability': [
      'Overgrow',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'gtj8qnmc6wi6t6uwuzj4tw1yh1ewg3iq'
  },
  {
    'ID': 390,
    'Name': 'Chimchar',
    'Ability': [
      'Blaze',
      'None',
      'Iron Fist'
    ],
    'Gender': 87.5,
    'File': '7wvdyub24fty7l3u02efin8dksu1l8nf'
  },
  {
    'ID': 391,
    'Name': 'Monferno',
    'Ability': [
      'Blaze',
      'None',
      'Iron Fist'
    ],
    'Gender': 87.5,
    'File': 'nzuy59tyyodk7kpmo9feft3271be8ngi'
  },
  {
    'ID': 392,
    'Name': 'Infernape',
    'Ability': [
      'Blaze',
      'None',
      'Iron Fist'
    ],
    'Gender': 87.5,
    'File': 'k4oayr915bjly7y6658ok6etc1ihzax4'
  },
  {
    'ID': 393,
    'Name': 'Piplup',
    'Ability': [
      'Torrent',
      'None',
      'Defiant'
    ],
    'Gender': 87.5,
    'File': 'dg7iejs3myewplojnzzs6n36bq6qbsm4'
  },
  {
    'ID': 394,
    'Name': 'Prinplup',
    'Ability': [
      'Torrent',
      'None',
      'Defiant'
    ],
    'Gender': 87.5,
    'File': 'izdo23am2pn8trkrz9qvr6ldcrclaeo0'
  },
  {
    'ID': 395,
    'Name': 'Empoleon',
    'Ability': [
      'Torrent',
      'None',
      'Defiant'
    ],
    'Gender': 87.5,
    'File': 'olasge97gloe40n9058l9m6kihf02d6r'
  },
  {
    'ID': 396,
    'Name': 'Starly',
    'Ability': [
      'Keen Eye',
      'None',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'ra0phttet66m6wvdsbmezaq58rx8qqxm'
  },
  {
    'ID': 397,
    'Name': 'Staravia',
    'Ability': [
      'Intimidate',
      'None',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'e1hbktl8eho44oujw4pbu9rdh3v3o4ti'
  },
  {
    'ID': 398,
    'Name': 'Staraptor',
    'Ability': [
      'Intimidate',
      'None',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'q5outgjhpez7oibkn0m0nsjdjqszeg1d'
  },
  {
    'ID': 399,
    'Name': 'Bidoof',
    'Ability': [
      'Simple',
      'Unaware',
      'Moody'
    ],
    'Gender': 50,
    'File': 'dmh2yv3ca56r0yhvygsp71n57yqfhiac'
  },
  {
    'ID': 400,
    'Name': 'Bibarel',
    'Ability': [
      'Simple',
      'Unaware',
      'Moody'
    ],
    'Gender': 50,
    'File': '8ur3xeo76cqh1i7tc96g5ydbqxtjqyej'
  },
  {
    'ID': 401,
    'Name': 'Kricketot',
    'Ability': [
      'Shed Skin',
      'None',
      'Run Away'
    ],
    'Gender': 50,
    'File': 'mealazftxkyttpyknnp4vft6hnelfem8'
  },
  {
    'ID': 402,
    'Name': 'Kricketune',
    'Ability': [
      'Swarm',
      'None',
      'Technician'
    ],
    'Gender': 50,
    'File': '5146kqwqqlj0jes4aro8yv31v0b73txo'
  },
  {
    'ID': 403,
    'Name': 'Shinx',
    'Ability': [
      'Rivalry',
      'Intimidate',
      'Guts'
    ],
    'Gender': 50,
    'File': '1vaioar8432tzopmy3pn9ewxaxac0wyd'
  },
  {
    'ID': 404,
    'Name': 'Luxio',
    'Ability': [
      'Rivalry',
      'Intimidate',
      'Guts'
    ],
    'Gender': 50,
    'File': 'rdgg23u76z1zzoihnmej0hjmr503cxza'
  },
  {
    'ID': 405,
    'Name': 'Luxray',
    'Ability': [
      'Rivalry',
      'Intimidate',
      'Guts'
    ],
    'Gender': 50,
    'File': '44yix4jx1kgezdshty6vfqed4sgtj0ov'
  },
  {
    'ID': 406,
    'Name': 'Budew',
    'Ability': [
      'Natural Cure',
      'Poison Point',
      'Leaf Guard'
    ],
    'Gender': 50,
    'File': 'eqcu7d0kjb67lu7ym9zdnrsd3fc53db3'
  },
  {
    'ID': 407,
    'Name': 'Roserade',
    'Ability': [
      'Natural Cure',
      'Poison Point',
      'Technician'
    ],
    'Gender': 50,
    'File': 'huvoahkghmujztutg8jr1nin5wvaswey'
  },
  {
    'ID': 408,
    'Name': 'Cranidos',
    'Ability': [
      'Mold Breaker',
      'None',
      'Sheer Force'
    ],
    'Gender': 87.5,
    'File': '0tjiven2ukbzosax3eiwsp6wqabk9avl'
  },
  {
    'ID': 409,
    'Name': 'Rampardos',
    'Ability': [
      'Mold Breaker',
      'None',
      'Sheer Force'
    ],
    'Gender': 87.5,
    'File': 'fov2w1u0bscvqbljv0buxmtzq1kt38gf'
  },
  {
    'ID': 410,
    'Name': 'Shieldon',
    'Ability': [
      'Sturdy',
      'None',
      'Soundproof'
    ],
    'Gender': 87.5,
    'File': 'ha7rmmkwohpu6mo8uk8g0pnealwbuzaz'
  },
  {
    'ID': 411,
    'Name': 'Bastiodon',
    'Ability': [
      'Sturdy',
      'None',
      'Soundproof'
    ],
    'Gender': 87.5,
    'File': 'i1wzcw5y048pb2yuxzlq31ui3wgjscpi'
  },
  {
    'ID': 412,
    'Name': 'Burmy',
    'Ability': [
      'Shed Skin',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'onalziguac99bqw5ysibk4a4a7xv0dv0'
  },
  {
    'ID': 413,
    'Name': 'Wormadam',
    'Ability': [
      'Anticipation',
      'None',
      'Overcoat'
    ],
    'Gender': 0.01,
    'File': '7qvh99g7vdam9p7i9mi3j0omodhrnv6q'
  },
  {
    'ID': 414,
    'Name': 'Mothim',
    'Ability': [
      'Swarm',
      'None',
      'Tinted Lens'
    ],
    'Gender': 100,
    'File': 'w78dsi1px9mll6n1drk8yrr86b6r941v'
  },
  {
    'ID': 415,
    'Name': 'Combee',
    'Ability': [
      'Honey Gather',
      'None',
      'Hustle'
    ],
    'Gender': 87.5,
    'File': 'tp362phiathx6z0fhdqpmnq9pd36low3'
  },
  {
    'ID': 416,
    'Name': 'Vespiquen',
    'Ability': [
      'Pressure',
      'None',
      'Unnerve'
    ],
    'Gender': 0.01,
    'File': 'ujkpynur0vhhjhhhj3wgm7og6leyr1vc'
  },
  {
    'ID': 417,
    'Name': 'Pachirisu',
    'Ability': [
      'Run Away',
      'Pickup',
      'Volt Absorb'
    ],
    'Gender': 50,
    'File': '4k2qwcxxrcji9xv6e5d4t0zrllihknho'
  },
  {
    'ID': 418,
    'Name': 'Buizel',
    'Ability': [
      'Swift Swim',
      'None',
      'Water Veil'
    ],
    'Gender': 50,
    'File': '8dcg91kt8apa6h7lg84na13mzohq5i2b'
  },
  {
    'ID': 419,
    'Name': 'Floatzel',
    'Ability': [
      'Swift Swim',
      'None',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'w4aez9qunmdj7lercd5a7d84356ox2tk'
  },
  {
    'ID': 420,
    'Name': 'Cherubi',
    'Ability': [
      'Chlorophyll',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '12qqy42m647wu39d3t31vft3vwm2ohk6'
  },
  {
    'ID': 421,
    'Name': 'Cherrim',
    'Ability': [
      'Flower Gift',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'l6ymv04elg13fn979hkij9vtkkvtapl0'
  },
  {
    'ID': 422,
    'Name': 'Shellos',
    'Ability': [
      'Sticky Hold',
      'Storm Drain',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'q8bqgfl5awu6y3btpur1wv8mvb3qxmkn'
  },
  {
    'ID': 423,
    'Name': 'Gastrodon',
    'Ability': [
      'Sticky Hold',
      'Storm Drain',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'k60igm49xivjsuhfp8apt2koeabn91di'
  },
  {
    'ID': 424,
    'Name': 'Ambipom',
    'Ability': [
      'Technician',
      'Pickup',
      'Skill Link'
    ],
    'Gender': 50,
    'File': 'wvske15adog4kc2p6b4x1jdsc02k9eu9'
  },
  {
    'ID': 425,
    'Name': 'Drifloon',
    'Ability': [
      'Aftermath',
      'Unburden',
      'Flare Boost'
    ],
    'Gender': 50,
    'File': 'asludhl1f0rm2fmmt9iwqhqa17uj41u6'
  },
  {
    'ID': 426,
    'Name': 'Drifblim',
    'Ability': [
      'Aftermath',
      'Unburden',
      'Flare Boost'
    ],
    'Gender': 50,
    'File': 'sy8qjj2zv1uomhmkzlwotn8rslwze86j'
  },
  {
    'ID': 427,
    'Name': 'Buneary',
    'Ability': [
      'Run Away',
      'Klutz',
      'Limber'
    ],
    'Gender': 50,
    'File': 'n042lxyjy0nxkvwjrchkhniden08dkq1'
  },
  {
    'ID': 428,
    'Name': 'Lopunny',
    'Ability': [
      'Cute Charm',
      'Klutz',
      'Limber'
    ],
    'Gender': 50,
    'File': 'm2qy8ukxps2ouhr3zw0664vef2r6k8oz'
  },
  {
    'ID': 429,
    'Name': 'Mismagius',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'trmfydsilrrvok2f1b653nchm9cbw2cy'
  },
  {
    'ID': 430,
    'Name': 'Honchkrow',
    'Ability': [
      'Insomnia',
      'Super Luck',
      'Moxie'
    ],
    'Gender': 50,
    'File': 'xvafejnc44r83s6yw4rizi1ykxmdxc3k'
  },
  {
    'ID': 431,
    'Name': 'Glameow',
    'Ability': [
      'Limber',
      'Own Tempo',
      'Keen Eye'
    ],
    'Gender': 25,
    'File': 'z153ouefop0fo6zi0v3900iyprfis0hn'
  },
  {
    'ID': 432,
    'Name': 'Purugly',
    'Ability': [
      'Thick Fat',
      'Own Tempo',
      'Defiant'
    ],
    'Gender': 25,
    'File': 'guf1swcd0hmwvgc7wsiizt56xcu9z02h'
  },
  {
    'ID': 433,
    'Name': 'Chingling',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'vfzfdgzflwaurkj8pidj1ttakh62enua'
  },
  {
    'ID': 434,
    'Name': 'Stunky',
    'Ability': [
      'Stench',
      'Aftermath',
      'Keen Eye'
    ],
    'Gender': 50,
    'File': 'r9g996ze050k9pfmx057e3un13e1ns2z'
  },
  {
    'ID': 435,
    'Name': 'Skuntank',
    'Ability': [
      'Stench',
      'Aftermath',
      'Keen Eye'
    ],
    'Gender': 50,
    'File': 'uy5af49l2mtquhyl47z9y5hwdzthxun6'
  },
  {
    'ID': 436,
    'Name': 'Bronzor',
    'Gender': null,
    'Ability': [
      'Levitate',
      'Heatproof',
      'Heavy Metal'
    ],
    'File': 'vcgjm9xe7rsgho8svu5ra2cmef3pem3f'
  },
  {
    'ID': 437,
    'Name': 'Bronzong',
    'Gender': null,
    'Ability': [
      'Levitate',
      'Heatproof',
      'Heavy Metal'
    ],
    'File': 'q7wa0zn99l046dt0u476qjtridef6mwu'
  },
  {
    'ID': 438,
    'Name': 'Bonsly',
    'Ability': [
      'Sturdy',
      'Rock Head',
      'Rattled'
    ],
    'Gender': 50,
    'File': '31qktxczroj0b3yrfrv1kcccnprlwqn8'
  },
  {
    'ID': 439,
    'Name': 'Mime Jr.',
    'Ability': [
      'Soundproof',
      'Filter',
      'Technician'
    ],
    'Gender': 50,
    'File': 'efy3s1sbsxsvvtvtsc15hu9pf4yo16wv'
  },
  {
    'ID': 440,
    'Name': 'Happiny',
    'Ability': [
      'Natural Cure',
      'Serene Grace',
      'Friend Guard'
    ],
    'Gender': 0.01,
    'File': 'rov0akl5wb8goei15j05c4dwofey6bmw'
  },
  {
    'ID': 441,
    'Name': 'Chatot',
    'Ability': [
      'Keen Eye',
      'Tangled Feet',
      'Big Pecks'
    ],
    'Gender': 50,
    'File': 'tzunm5f3dlofi11sgpujogmagpffj4tn'
  },
  {
    'ID': 442,
    'Name': 'Spiritomb',
    'Ability': [
      'Pressure',
      'None',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'qhr54doqh88cciychnfjhlmp7zlfjxsz'
  },
  {
    'ID': 443,
    'Name': 'Gible',
    'Ability': [
      'Sand Veil',
      'None',
      'Rough Skin'
    ],
    'Gender': 50,
    'File': 'xd8uy1a15195ao1k26m9td1o8p04u63z'
  },
  {
    'ID': 444,
    'Name': 'Gabite',
    'Ability': [
      'Sand Veil',
      'None',
      'Rough Skin'
    ],
    'Gender': 50,
    'File': 'k5cc07egruf05tda6laf9ynfjnskkkgc'
  },
  {
    'ID': 445,
    'Name': 'Garchomp',
    'Ability': [
      'Sand Veil',
      'None',
      'Rough Skin'
    ],
    'Gender': 50,
    'File': 'm20bvvbza5qbn9f9y2ghj2v2cx93h1on'
  },
  {
    'ID': 446,
    'Name': 'Munchlax',
    'Ability': [
      'Pickup',
      'Thick Fat',
      'Gluttony'
    ],
    'Gender': 87.5,
    'File': '71txi5jh9m1q0un3qgi3bo14i0zp8olb'
  },
  {
    'ID': 447,
    'Name': 'Riolu',
    'Ability': [
      'Steadfast',
      'Inner Focus',
      'Prankster'
    ],
    'Gender': 87.5,
    'File': 'z36ikeb29xds20478szbvw42fmdj5xsa'
  },
  {
    'ID': 448,
    'Name': 'Lucario',
    'Ability': [
      'Steadfast',
      'Inner Focus',
      'Justified'
    ],
    'Gender': 87.5,
    'File': '6ppjrcxr7gwi4dyz5xpv3368szix4ekq'
  },
  {
    'ID': 449,
    'Name': 'Hippopotas',
    'Ability': [
      'Sand Stream',
      'None',
      'Sand Force'
    ],
    'Gender': 50,
    'File': '246rzpyo22bfe4slvnc3quj1i2ka0m47'
  },
  {
    'ID': 450,
    'Name': 'Hippowdon',
    'Ability': [
      'Sand Stream',
      'None',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'qekwqwl40s9ix0yzcj0h1j4xukc5msus'
  },
  {
    'ID': 451,
    'Name': 'Skorupi',
    'Ability': [
      'Battle Armor',
      'Sniper',
      'Keen Eye'
    ],
    'Gender': 50,
    'File': '7sv1ymayw7fg14eu6q92tv1q9da3xzq2'
  },
  {
    'ID': 452,
    'Name': 'Drapion',
    'Ability': [
      'Battle Armor',
      'Sniper',
      'Keen Eye'
    ],
    'Gender': 50,
    'File': 'e9ag0mrdujptf5s5o0dbyziay2xvo0vf'
  },
  {
    'ID': 453,
    'Name': 'Croagunk',
    'Ability': [
      'Anticipation',
      'Dry Skin',
      'Poison Touch'
    ],
    'Gender': 50,
    'File': 'ymvlr2fu7dhatl6fum7yyshyscuzhpud'
  },
  {
    'ID': 454,
    'Name': 'Toxicroak',
    'Ability': [
      'Anticipation',
      'Dry Skin',
      'Poison Touch'
    ],
    'Gender': 50,
    'File': 'm3fh2gfx8hppyr2kj5g8a1lk0nwnayao'
  },
  {
    'ID': 455,
    'Name': 'Carnivine',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'yfehmk65u2hgxjd88qix2ns5bsvthl2w'
  },
  {
    'ID': 456,
    'Name': 'Finneon',
    'Ability': [
      'Swift Swim',
      'Storm Drain',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'xa7gbhsgy2ewyy498mjlrel1hue8bamn'
  },
  {
    'ID': 457,
    'Name': 'Lumineon',
    'Ability': [
      'Swift Swim',
      'Storm Drain',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'v8nenne39cvmh7oc9ewopl0kd6cvv8fv'
  },
  {
    'ID': 458,
    'Name': 'Mantyke',
    'Ability': [
      'Swift Swim',
      'Water Absorb',
      'Water Veil'
    ],
    'Gender': 50,
    'File': 'jcxr2ujup8qym1j8ac4jo3z8qssuy7jd'
  },
  {
    'ID': 459,
    'Name': 'Snover',
    'Ability': [
      'Snow Warning',
      'None',
      'Soundproof'
    ],
    'Gender': 50,
    'File': 'y85lgogvlqk93ewulqjwtgocuu90khe8'
  },
  {
    'ID': 460,
    'Name': 'Abomasnow',
    'Ability': [
      'Snow Warning',
      'None',
      'Soundproof'
    ],
    'Gender': 50,
    'File': 'nh2f7m7aev0w719esjhxny62eru3awod'
  },
  {
    'ID': 461,
    'Name': 'Weavile',
    'Ability': [
      'Pressure',
      'None',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'ej6ym963ofjzizuvjl6wip3vrruc3srv'
  },
  {
    'ID': 462,
    'Name': 'Magnezone',
    'Gender': null,
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Analytic'
    ],
    'File': '2qgxddctup2xvxfji0w7gs62wlfsfyvy'
  },
  {
    'ID': 463,
    'Name': 'Lickilicky',
    'Ability': [
      'Own Tempo',
      'Oblivious',
      'Cloud Nine'
    ],
    'Gender': 50,
    'File': 'v1hlg5ehhuxj0ot0517dy0xivj6dfq71'
  },
  {
    'ID': 464,
    'Name': 'Rhyperior',
    'Ability': [
      'Lightning Rod',
      'Solid Rock',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'ks42ep593y1ocslefik97thpetje2zvt'
  },
  {
    'ID': 465,
    'Name': 'Tangrowth',
    'Ability': [
      'Chlorophyll',
      'Leaf Guard',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'tu7pmqe22ujmmpo5dfbkdz2sqb3k9a7l'
  },
  {
    'ID': 466,
    'Name': 'Electivire',
    'Ability': [
      'Motor Drive',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': '92a3yt6rn28mpl43kh4uory79xv7it0t'
  },
  {
    'ID': 467,
    'Name': 'Magmortar',
    'Ability': [
      'Flame Body',
      'None',
      'Vital Spirit'
    ],
    'Gender': 75,
    'File': 'esjdpzzcyoky6zh84ay891vfttugb3ne'
  },
  {
    'ID': 468,
    'Name': 'Togekiss',
    'Ability': [
      'Hustle',
      'Serene Grace',
      'Super Luck'
    ],
    'Gender': 87.5,
    'File': 'v8dgkx8x61tlmvcyw1y1ic69iacf6ppx'
  },
  {
    'ID': 469,
    'Name': 'Yanmega',
    'Ability': [
      'Speed Boost',
      'Tinted Lens',
      'Frisk'
    ],
    'Gender': 50,
    'File': '379e31dh9plkk1ti6204u26tf2l84ozw'
  },
  {
    'ID': 470,
    'Name': 'Leafeon',
    'Ability': [
      'Leaf Guard',
      'None',
      'Chlorophyll'
    ],
    'Gender': 87.5,
    'File': '67ki7vij5nhii15yultpaz7ihph7bm34'
  },
  {
    'ID': 471,
    'Name': 'Glaceon',
    'Ability': [
      'Snow Cloak',
      'None',
      'Ice Body'
    ],
    'Gender': 87.5,
    'File': '0bc33pf8olfqsayyavtpgy9pyfd9nvgo'
  },
  {
    'ID': 472,
    'Name': 'Gliscor',
    'Ability': [
      'Hyper Cutter',
      'Sand Veil',
      'Poison Heal'
    ],
    'Gender': 50,
    'File': 'dnpndbr4eoaltwwq1kiezmrz2rl3lk1y'
  },
  {
    'ID': 473,
    'Name': 'Mamoswine',
    'Ability': [
      'Oblivious',
      'Snow Cloak',
      'Thick Fat'
    ],
    'Gender': 50,
    'File': 'wqjdyuqner6v9rl8oojjzg3r6rch0f5f'
  },
  {
    'ID': 474,
    'Name': 'Porygon-z',
    'Gender': null,
    'Ability': [
      'Adaptability',
      'Download',
      'Analytic'
    ],
    'File': 'x8h3b2b8jnf2fk4f23yj7qz00g375vwo'
  },
  {
    'ID': 475,
    'Name': 'Gallade',
    'Ability': [
      'Steadfast',
      'None',
      'Justified'
    ],
    'Gender': 100,
    'File': '17fbzwy33jogedzvz64g7otpxhjkgq41'
  },
  {
    'ID': 476,
    'Name': 'Probopass',
    'Ability': [
      'Sturdy',
      'Magnet Pull',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'iwk9lgjmrmpd74qd1ati8p5vf0vw5vi7'
  },
  {
    'ID': 477,
    'Name': 'Dusknoir',
    'Ability': [
      'Pressure',
      'None',
      'Frisk'
    ],
    'Gender': 50,
    'File': 'qga6l3bmglfck126f5y58v359y1rubuv'
  },
  {
    'ID': 478,
    'Name': 'Froslass',
    'Ability': [
      'Snow Cloak',
      'None',
      'Cursed Body'
    ],
    'Gender': 0.01,
    'File': 'hn87x5ckpidkzur72tswn4kt3srua1dv'
  },
  {
    'ID': 479,
    'Name': 'Rotom',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'mdpugqy0fmks9jldafykh0jwoht7edpe'
  },
  {
    'ID': 480,
    'Name': 'Uxie',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'nty3cgzw1jo70ujp6jcpcfq1rqdj6dht'
  },
  {
    'ID': 481,
    'Name': 'Mesprit',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'awnncni4i1mmag31g79z9l8hopuq9bgx'
  },
  {
    'ID': 482,
    'Name': 'Azelf',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'wnm6bwh4j6f5nekcr7qdcn2ihuitqrgy'
  },
  {
    'ID': 483,
    'Name': 'Dialga',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Telepathy'
    ],
    'File': 'ybqrq6iqmbii29zd2c636ihxeboml9nn'
  },
  {
    'ID': 484,
    'Name': 'Palkia',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'Telepathy'
    ],
    'File': 'aiz43thkg4fxk77f3oheh088heomzgsx'
  },
  {
    'ID': 485,
    'Name': 'Heatran',
    'Ability': [
      'Flash Fire',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'wwh2ovr1gon6f1dyz4s3sfmiro85ipf0'
  },
  {
    'ID': 486,
    'Name': 'Regigigas',
    'Gender': null,
    'Ability': [
      'Slow Start',
      'None',
      'None'
    ],
    'File': 'c9bbj4emul8pwhplo2hjilyq801gnw6q'
  },
  {
    'ID': 487,
    'Name': 'Giratina',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'qr16p5lp6x6w77zrp13rprkw9p1a17og'
  },
  {
    'ID': 488,
    'Name': 'Cresselia',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 0.01,
    'File': 'ho5ee87mxe0i4j6qiotjhaqqwmktrt8z'
  },
  {
    'ID': 489,
    'Name': 'Phione',
    'Gender': null,
    'Ability': [
      'Hydration',
      'None',
      'None'
    ],
    'File': 'h7fou4q547jvyvgg0v6bbhjampu20w97'
  },
  {
    'ID': 490,
    'Name': 'Manaphy',
    'Gender': null,
    'Ability': [
      'Hydration',
      'None',
      'None'
    ],
    'File': '195yngdi0hrrewniuejd1le35kjuxg84'
  },
  {
    'ID': 491,
    'Name': 'Darkrai',
    'Gender': null,
    'Ability': [
      'Bad Dreams',
      'None',
      'None'
    ],
    'File': 'ae8omapvzaza3fssuwpz98t7gtzqu37u'
  },
  {
    'ID': 492,
    'Name': 'Shaymin',
    'Gender': null,
    'Ability': [
      'Natural Cure',
      'None',
      'None'
    ],
    'File': 'vhas0tx0omcwwp243wpzuqzfagm5j469'
  },
  {
    'ID': 493,
    'Name': 'Arceus',
    'Gender': null,
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'File': 'dzzbkkl834aeuhio7aqhs08k2bj055c3'
  },
  {
    'ID': 494,
    'Name': 'Victini',
    'Gender': null,
    'Ability': [
      'Victory Star',
      'None',
      'None'
    ],
    'File': 'q8v6awvii67f2lq7b9hoajeyu8wr741r'
  },
  {
    'ID': 495,
    'Name': 'Snivy',
    'Ability': [
      'Overgrow',
      'None',
      'Contrary'
    ],
    'Gender': 87.5,
    'File': 'fwvrm3oa7tr7xj9wowe4m3fxzo0nedvb'
  },
  {
    'ID': 496,
    'Name': 'Servine',
    'Ability': [
      'Overgrow',
      'None',
      'Contrary'
    ],
    'Gender': 87.5,
    'File': 'txolhxihzdfz0wukzyjftjazsdhd77it'
  },
  {
    'ID': 497,
    'Name': 'Serperior',
    'Ability': [
      'Overgrow',
      'None',
      'Contrary'
    ],
    'Gender': 87.5,
    'File': 'z7ycgjuk5wt6s9i51vgrqapuggdabhiy'
  },
  {
    'ID': 498,
    'Name': 'Tepig',
    'Ability': [
      'Blaze',
      'None',
      'Thick Fat'
    ],
    'Gender': 87.5,
    'File': 'j1kzyxk4062qn7lftfldpc86evnbd0o1'
  },
  {
    'ID': 499,
    'Name': 'Pignite',
    'Ability': [
      'Blaze',
      'None',
      'Thick Fat'
    ],
    'Gender': 87.5,
    'File': 'wbfb53hfwxk3hydkb3ngdnjmfclu0d43'
  },
  {
    'ID': 500,
    'Name': 'Emboar',
    'Ability': [
      'Blaze',
      'None',
      'Reckless'
    ],
    'Gender': 87.5,
    'File': '391v13umektwh18mq5n4ki68l490kmdy'
  },
  {
    'ID': 501,
    'Name': 'Oshawott',
    'Ability': [
      'Torrent',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'fsoa7ai3dtmf1l1x8aad8nqcqsm4fl0v'
  },
  {
    'ID': 502,
    'Name': 'Dewott',
    'Ability': [
      'Torrent',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'vq46asiio2gj5wkn2nej7rc7iom02uwa'
  },
  {
    'ID': 503,
    'Name': 'Samurott',
    'Ability': [
      'Torrent',
      'None',
      'Shell Armor'
    ],
    'Gender': 87.5,
    'File': 'lbkt7xs5daqelzab73t21n919wo6g0wv'
  },
  {
    'ID': 504,
    'Name': 'Patrat',
    'Ability': [
      'Run Away',
      'Keen Eye',
      'Analytic'
    ],
    'Gender': 50,
    'File': '66unsqjyemet6w0rb32ziwwbp5rfe5mo'
  },
  {
    'ID': 505,
    'Name': 'Watchog',
    'Ability': [
      'Keen Eye',
      'Illuminate',
      'Analytic'
    ],
    'Gender': 50,
    'File': 'xvbbu775avswmp8jxlghl25ff9pvdpx6'
  },
  {
    'ID': 506,
    'Name': 'Lillipup',
    'Ability': [
      'Pickup',
      'Vital Spirit',
      'Run Away'
    ],
    'Gender': 50,
    'File': 'cqpdo2ns186r5muflb4bcah4wxconfa6'
  },
  {
    'ID': 507,
    'Name': 'Herdier',
    'Ability': [
      'Sand Rush',
      'Intimidate',
      'Scrappy'
    ],
    'Gender': 50,
    'File': 'q37zx54royrusug1jjgon0itshejwaln'
  },
  {
    'ID': 508,
    'Name': 'Stoutland',
    'Ability': [
      'Sand Rush',
      'Intimidate',
      'Scrappy'
    ],
    'Gender': 50,
    'File': 'rt0yqqq8a4n09d2atpuwsn3enh8tqaux'
  },
  {
    'ID': 509,
    'Name': 'Purrloin',
    'Ability': [
      'Limber',
      'Unburden',
      'Prankster'
    ],
    'Gender': 50,
    'File': '00r25gee8a5ouck7vn182ahkzjmc4hct'
  },
  {
    'ID': 510,
    'Name': 'Liepard',
    'Ability': [
      'Limber',
      'Unburden',
      'Prankster'
    ],
    'Gender': 50,
    'File': 'q9d2fr5j9ljrvyo6m2lb7ay4vsj4c9jo'
  },
  {
    'ID': 511,
    'Name': 'Pansage',
    'Ability': [
      'Gluttony',
      'None',
      'Overgrow'
    ],
    'Gender': 87.5,
    'File': '09k454oucgxv03bfb3yj9lcsl8maogdd'
  },
  {
    'ID': 512,
    'Name': 'Simisage',
    'Ability': [
      'Gluttony',
      'None',
      'Overgrow'
    ],
    'Gender': 87.5,
    'File': 'jpzaqndfvh2pysc3nx0qjgp2nznfn3fz'
  },
  {
    'ID': 513,
    'Name': 'Pansear',
    'Ability': [
      'Gluttony',
      'None',
      'Blaze'
    ],
    'Gender': 87.5,
    'File': 'nz648ad3n5ikv2xf0i2yt2n9kcgyxkgi'
  },
  {
    'ID': 514,
    'Name': 'Simisear',
    'Ability': [
      'Gluttony',
      'None',
      'Blaze'
    ],
    'Gender': 87.5,
    'File': '7r7nporef2gf7sq14d44jrqm3remvvf2'
  },
  {
    'ID': 515,
    'Name': 'Panpour',
    'Ability': [
      'Gluttony',
      'None',
      'Torrent'
    ],
    'Gender': 87.5,
    'File': 'ckxxr7xyt8x1z0cezaric2kuoy71x97f'
  },
  {
    'ID': 516,
    'Name': 'Simipour',
    'Ability': [
      'Gluttony',
      'None',
      'Torrent'
    ],
    'Gender': 87.5,
    'File': 'qmr59bf8x1s0smbrv8mp1pau752azu2f'
  },
  {
    'ID': 517,
    'Name': 'Munna',
    'Ability': [
      'Forewarn',
      'Synchronize',
      'Telepathy'
    ],
    'Gender': 50,
    'File': '0b5lzm2ux5dtmuvmt2fu9u9sa9al4nm3'
  },
  {
    'ID': 518,
    'Name': 'Musharna',
    'Ability': [
      'Forewarn',
      'Synchronize',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'kthvlthgy4efef5rp5lcc36z1v1wf32t'
  },
  {
    'ID': 519,
    'Name': 'Pidove',
    'Ability': [
      'Super Luck',
      'Big Pecks',
      'Rivalry'
    ],
    'Gender': 50,
    'File': 'zn9bcdt1c2atmbgx7wtkvvytewee3om5'
  },
  {
    'ID': 520,
    'Name': 'Tranquill',
    'Ability': [
      'Super Luck',
      'Big Pecks',
      'Rivalry'
    ],
    'Gender': 50,
    'File': 'f1cwwuw15dmfcflsivefl0w6ayjxg9wt'
  },
  {
    'ID': 521,
    'Name': 'Unfezant',
    'Ability': [
      'Super Luck',
      'Big Pecks',
      'Rivalry'
    ],
    'Gender': 50,
    'File': '8gbjxx6ba8t8y2gjx60sf238x9rcj5t9'
  },
  {
    'ID': 522,
    'Name': 'Blitzle',
    'Ability': [
      'Lightning Rod',
      'Motor Drive',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': 'l8mzmndschzodbmm5i42m0c9dr6d93ya'
  },
  {
    'ID': 523,
    'Name': 'Zebstrika',
    'Ability': [
      'Lightning Rod',
      'Motor Drive',
      'Sap Sipper'
    ],
    'Gender': 50,
    'File': 'p0y4ki6e8fmsx3ip24cy1jmvndx7padi'
  },
  {
    'ID': 524,
    'Name': 'Roggenrola',
    'Ability': [
      'Sturdy',
      'Weak Armor',
      'Sand Force'
    ],
    'Gender': 50,
    'File': '83gjnxuu2dyjxp6kg7cuxmp3p2u6y3nh'
  },
  {
    'ID': 525,
    'Name': 'Boldore',
    'Ability': [
      'Sturdy',
      'Weak Armor',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'ntc4pvrew2wkug6hvwdqw2ovvmheyqzj'
  },
  {
    'ID': 526,
    'Name': 'Gigalith',
    'Ability': [
      'Sturdy',
      'Sand Stream',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'avjat8rc0d9mcxu0ao274310hrfsoqdj'
  },
  {
    'ID': 527,
    'Name': 'Woobat',
    'Ability': [
      'Unaware',
      'Klutz',
      'Simple'
    ],
    'Gender': 50,
    'File': 'sz536a3voajh3ualm1fv4n01choag53s'
  },
  {
    'ID': 528,
    'Name': 'Swoobat',
    'Ability': [
      'Unaware',
      'Klutz',
      'Simple'
    ],
    'Gender': 50,
    'File': 'z7ngu3vwv9qh2lu1pqv198avj28hvdcp'
  },
  {
    'ID': 529,
    'Name': 'Drilbur',
    'Ability': [
      'Sand Rush',
      'Sand Force',
      'Mold Breaker'
    ],
    'Gender': 50,
    'File': '5ndsx4j7vkne9lynnz774yje2k0zt8to'
  },
  {
    'ID': 530,
    'Name': 'Excadrill',
    'Ability': [
      'Sand Rush',
      'Sand Force',
      'Mold Breaker'
    ],
    'Gender': 50,
    'File': '5d3yva0dd2nc0gihlm9por9dkvj0sk7l'
  },
  {
    'ID': 531,
    'Name': 'Audino',
    'Ability': [
      'Healer',
      'Regenerator',
      'Klutz'
    ],
    'Gender': 50,
    'File': 'duak5xu1t6avdk6nyoout12rafqomqsj'
  },
  {
    'ID': 532,
    'Name': 'Timburr',
    'Ability': [
      'Guts',
      'Sheer Force',
      'Iron Fist'
    ],
    'Gender': 75,
    'File': 'x4b5uyocwggt7x2g9b8hiknjiwy8ayuh'
  },
  {
    'ID': 533,
    'Name': 'Gurdurr',
    'Ability': [
      'Guts',
      'Sheer Force',
      'Iron Fist'
    ],
    'Gender': 75,
    'File': 'vv641yxovjhs92d8tzqpdbhoj704139r'
  },
  {
    'ID': 534,
    'Name': 'Conkeldurr',
    'Ability': [
      'Guts',
      'Sheer Force',
      'Iron Fist'
    ],
    'Gender': 75,
    'File': 'ml10p18gdnp2s8y2lw4f14mn6t4pp3lb'
  },
  {
    'ID': 535,
    'Name': 'Tympole',
    'Ability': [
      'Hydration',
      'Swift Swim',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'p4p5wzsxr2njllxfg9y114ps9klio608'
  },
  {
    'ID': 536,
    'Name': 'Palpitoad',
    'Ability': [
      'Hydration',
      'Swift Swim',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 's0bb172hd9nr1pqizkafa4k0t14snvsa'
  },
  {
    'ID': 537,
    'Name': 'Seismitoad',
    'Ability': [
      'Poison Touch',
      'Swift Swim',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'bqjvr8z7179q1mrx2c442tzmjb1z9s93'
  },
  {
    'ID': 538,
    'Name': 'Throh',
    'Ability': [
      'Guts',
      'Inner Focus',
      'Mold Breaker'
    ],
    'Gender': 100,
    'File': 'pvqrz1z68xhqhocymgw2wld6ik9hyhi7'
  },
  {
    'ID': 539,
    'Name': 'Sawk',
    'Ability': [
      'Sturdy',
      'Inner Focus',
      'Mold Breaker'
    ],
    'Gender': 100,
    'File': '0jrdganyj27dnqfjyak7eznjt4tcsv9w'
  },
  {
    'ID': 540,
    'Name': 'Sewaddle',
    'Ability': [
      'Swarm',
      'Chlorophyll',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'lexffxrisjge50zrelxwth3j71p61cgc'
  },
  {
    'ID': 541,
    'Name': 'Swadloon',
    'Ability': [
      'Leaf Guard',
      'Chlorophyll',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'jqycifihji6kuqekub08dlq9csqx8cg9'
  },
  {
    'ID': 542,
    'Name': 'Leavanny',
    'Ability': [
      'Swarm',
      'Chlorophyll',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'nvsto2wpmwshpskuycac9di8ozth3lud'
  },
  {
    'ID': 543,
    'Name': 'Venipede',
    'Ability': [
      'Swarm',
      'Poison Point',
      'Speed Boost'
    ],
    'Gender': 50,
    'File': 'qbr37oqycoqixbn1e0zrry8jr7bk9ltn'
  },
  {
    'ID': 544,
    'Name': 'Whirlipede',
    'Ability': [
      'Swarm',
      'Poison Point',
      'Speed Boost'
    ],
    'Gender': 50,
    'File': 'qniu9io85zvk28j47lhve456h7qir8rm'
  },
  {
    'ID': 545,
    'Name': 'Scolipede',
    'Ability': [
      'Swarm',
      'Poison Point',
      'Speed Boost'
    ],
    'Gender': 50,
    'File': '6wpr6c2d2x2sigf5ybpf6pgkf837kitl'
  },
  {
    'ID': 546,
    'Name': 'Cottonee',
    'Ability': [
      'Infiltrator',
      'Prankster',
      'Chlorophyll'
    ],
    'Gender': 50,
    'File': 'c1xsdo7e2alutqmd4lwv1n6v09spyodm'
  },
  {
    'ID': 547,
    'Name': 'Whimsicott',
    'Ability': [
      'Infiltrator',
      'Prankster',
      'Chlorophyll'
    ],
    'Gender': 50,
    'File': '44yh133ha0ma6m2vg1lufdbjx9p09wd4'
  },
  {
    'ID': 548,
    'Name': 'Petilil',
    'Ability': [
      'Own Tempo',
      'Chlorophyll',
      'Leaf Guard'
    ],
    'Gender': 0.01,
    'File': 'z6jty8y1eqrgnvquit1idru71nnvyna4'
  },
  {
    'ID': 549,
    'Name': 'Lilligant',
    'Ability': [
      'Own Tempo',
      'Chlorophyll',
      'Leaf Guard'
    ],
    'Gender': 0.01,
    'File': 'hugt0dehe42gh74v0udyr7u7xg6n5jel'
  },
  {
    'ID': 550,
    'Name': 'Basculin',
    'Ability': [
      'Rock Head',
      'Adaptability',
      'Mold Breaker'
    ],
    'Gender': 50,
    'File': '4inhcjuipcu7nyn6osfwsg0g90z4jbyi'
  },
  {
    'ID': 551,
    'Name': 'Sandile',
    'Ability': [
      'Moxie',
      'Intimidate',
      'Anger Point'
    ],
    'Gender': 50,
    'File': 'e3c8njfxztffh3etl0mkq1u55k8tib8e'
  },
  {
    'ID': 552,
    'Name': 'Krokorok',
    'Ability': [
      'Moxie',
      'Intimidate',
      'Anger Point'
    ],
    'Gender': 50,
    'File': '9r5modhouid5qva66fkos81wdayw6msi'
  },
  {
    'ID': 553,
    'Name': 'Krookodile',
    'Ability': [
      'Moxie',
      'Intimidate',
      'Anger Point'
    ],
    'Gender': 50,
    'File': '8ypn018p58ifwna1r160tklv4njic2lw'
  },
  {
    'ID': 554,
    'Name': 'Darumaka',
    'Ability': [
      'Hustle',
      'None',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': 'h39jklz6m13rdmnlu84jef3cg1346ewp'
  },
  {
    'ID': 555,
    'Name': 'Darmanitan',
    'Ability': [
      'Sheer Force',
      'None',
      'Zen Mode'
    ],
    'Gender': 50,
    'File': 'xncb9u4okg94a4r5s5msodei43uiqqcb'
  },
  {
    'ID': 556,
    'Name': 'Maractus',
    'Ability': [
      'Chlorophyll',
      'Water Absorb',
      'Storm Drain'
    ],
    'Gender': 50,
    'File': 'y8rigkl10pj1kgvpv0weo786ehudm8s0'
  },
  {
    'ID': 557,
    'Name': 'Dwebble',
    'Ability': [
      'Sturdy',
      'Shell Armor',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'mheum225bz64o43d1n09wbput9984qq3'
  },
  {
    'ID': 558,
    'Name': 'Crustle',
    'Ability': [
      'Sturdy',
      'Shell Armor',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': '00pf0w4oy5zja1owib3gb0iuir848fkz'
  },
  {
    'ID': 559,
    'Name': 'Scraggy',
    'Ability': [
      'Moxie',
      'Shed Skin',
      'Intimidate'
    ],
    'Gender': 50,
    'File': 't0dw4qz4zaxp5fpoyqd9mutnak7xfocj'
  },
  {
    'ID': 560,
    'Name': 'Scrafty',
    'Ability': [
      'Moxie',
      'Shed Skin',
      'Intimidate'
    ],
    'Gender': 50,
    'File': '5zsq2y4g1f7f64vuzdt9thqt6vedm6p1'
  },
  {
    'ID': 561,
    'Name': 'Sigilyph',
    'Ability': [
      'Magic Guard',
      'Wonder Skin',
      'Tinted Lens'
    ],
    'Gender': 50,
    'File': 'haj2zugxvxuku3hl1ejy9js6dyknm7qc'
  },
  {
    'ID': 562,
    'Name': 'Yamask',
    'Ability': [
      'Mummy',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'spqx3zrhcaez01zms3mikomqscmt5bjj'
  },
  {
    'ID': 563,
    'Name': 'Cofagrigus',
    'Ability': [
      'Mummy',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'yzad6bgqmq0rt7kh3xh2w1dggqy23zrx'
  },
  {
    'ID': 564,
    'Name': 'Tirtouga',
    'Ability': [
      'Sturdy',
      'Solid Rock',
      'Swift Swim'
    ],
    'Gender': 87.5,
    'File': 'reqgtuvrvyil5w6blx426mdv0ewkbosw'
  },
  {
    'ID': 565,
    'Name': 'Carracosta',
    'Ability': [
      'Sturdy',
      'Solid Rock',
      'Swift Swim'
    ],
    'Gender': 87.5,
    'File': 'audrq7ne1medacnw2rfapnjufsqi43f0'
  },
  {
    'ID': 566,
    'Name': 'Archen',
    'Ability': [
      'Defeatist',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '7uxktyiodkotv6fqugy1963kwbavnnu9'
  },
  {
    'ID': 567,
    'Name': 'Archeops',
    'Ability': [
      'Defeatist',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'rayob0g1wubbce31ncnwm2khmnbxyghz'
  },
  {
    'ID': 568,
    'Name': 'Trubbish',
    'Ability': [
      'Stench',
      'Sticky Hold',
      'Aftermath'
    ],
    'Gender': 50,
    'File': 'tqx07rldwhl3u6s0jxt4szopfqeldbbb'
  },
  {
    'ID': 569,
    'Name': 'Garbodor',
    'Ability': [
      'Stench',
      'Weak Armor',
      'Aftermath'
    ],
    'Gender': 50,
    'File': 'byieo81xnsyssrjv4caqo0h7ndywtf0z'
  },
  {
    'ID': 570,
    'Name': 'Zorua',
    'Ability': [
      'Illusion',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'lj582jqfxm92kddughrtvvkjggljnrk0'
  },
  {
    'ID': 571,
    'Name': 'Zoroark',
    'Ability': [
      'Illusion',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'hf1rrbgeiz28p06m7199cj2oknwbs5zm'
  },
  {
    'ID': 572,
    'Name': 'Minccino',
    'Ability': [
      'Technician',
      'Cute Charm',
      'Skill Link'
    ],
    'Gender': 25,
    'File': 'dwvtfhz8qgiefw8up7lnbf8bbkhwqjvv'
  },
  {
    'ID': 573,
    'Name': 'Cinccino',
    'Ability': [
      'Technician',
      'Cute Charm',
      'Skill Link'
    ],
    'Gender': 25,
    'File': 'czy7sxtbiuhpr3fl96jcjj5rae4ezd8b'
  },
  {
    'ID': 574,
    'Name': 'Gothita',
    'Ability': [
      'Frisk',
      'Competitive',
      'Shadow Tag'
    ],
    'Gender': 25,
    'File': 'z9jeycj6h7jtwdpbe1tg50u2wqgqvhqu'
  },
  {
    'ID': 575,
    'Name': 'Gothorita',
    'Ability': [
      'Frisk',
      'Competitive',
      'Shadow Tag'
    ],
    'Gender': 25,
    'File': '2ancdr17yxo34xce09mo7paq4pl6rrwt'
  },
  {
    'ID': 576,
    'Name': 'Gothitelle',
    'Ability': [
      'Frisk',
      'Competitive',
      'Shadow Tag'
    ],
    'Gender': 25,
    'File': '4ucqldfg7fylxtm0mnxx1skz8alk7qw7'
  },
  {
    'ID': 577,
    'Name': 'Solosis',
    'Ability': [
      'Overcoat',
      'Magic Guard',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'x15uunez60v6e9t41up73snkzp1zmwwl'
  },
  {
    'ID': 578,
    'Name': 'Duosion',
    'Ability': [
      'Overcoat',
      'Magic Guard',
      'Regenerator'
    ],
    'Gender': 50,
    'File': '5syjsjv7ns2fu9tuec33yp94fr2b1rs4'
  },
  {
    'ID': 579,
    'Name': 'Reuniclus',
    'Ability': [
      'Overcoat',
      'Magic Guard',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'hzkhqk3qudixnb0lvo9fvrwgzomrexqh'
  },
  {
    'ID': 580,
    'Name': 'Ducklett',
    'Ability': [
      'Keen Eye',
      'Big Pecks',
      'Hydration'
    ],
    'Gender': 50,
    'File': '6lm072z5ilax26kw7nnbtgjv6zjnmaz6'
  },
  {
    'ID': 581,
    'Name': 'Swanna',
    'Ability': [
      'Keen Eye',
      'Big Pecks',
      'Hydration'
    ],
    'Gender': 50,
    'File': 'bmtpn0nccbbzw7k02e5ra5en2h3336bg'
  },
  {
    'ID': 582,
    'Name': 'Vanillite',
    'Ability': [
      'Ice Body',
      'Snow Cloak',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'mb60yauqwkdekwx9u1v92g7fdt2499am'
  },
  {
    'ID': 583,
    'Name': 'Vanillish',
    'Ability': [
      'Ice Body',
      'Snow Cloak',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': 'aeykyx4vjszr6reqk65ys76a4i2jpblr'
  },
  {
    'ID': 584,
    'Name': 'Vanilluxe',
    'Ability': [
      'Ice Body',
      'Snow Warning',
      'Weak Armor'
    ],
    'Gender': 50,
    'File': '4s9ertzph3c6n3noxeifwiulzblqmw8c'
  },
  {
    'ID': 585,
    'Name': 'Deerling',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': 'nywoqrbx23gb4qu451ouk11d7zn3m89i'
  },
  {
    'ID': 586,
    'Name': 'Sawsbuck',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': 'mntq02jtwsemnhpc26kk92sbswil45p3'
  },
  {
    'ID': 587,
    'Name': 'Emolga',
    'Ability': [
      'Static',
      'None',
      'Motor Drive'
    ],
    'Gender': 50,
    'File': '31m2ot8x4nf7xlk3u4iegf2gw5lohl92'
  },
  {
    'ID': 588,
    'Name': 'Karrablast',
    'Ability': [
      'Swarm',
      'Shed Skin',
      'No Guard'
    ],
    'Gender': 50,
    'File': 'o92zqh300pxewimla1hapg1sa60pkwhb'
  },
  {
    'ID': 589,
    'Name': 'Escavalier',
    'Ability': [
      'Swarm',
      'Shell Armor',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'wj3qv73jt8i5kcrmbyc8ectnngio6fxf'
  },
  {
    'ID': 590,
    'Name': 'Foongus',
    'Ability': [
      'Effect Spore',
      'None',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'u09gfced9w3gh3sphlkcdewip26kyt4d'
  },
  {
    'ID': 591,
    'Name': 'Amoonguss',
    'Ability': [
      'Effect Spore',
      'None',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'ampv81b9daada5qhzde1nm4guztzipee'
  },
  {
    'ID': 592,
    'Name': 'Frillish',
    'Ability': [
      'Cursed Body',
      'Water Absorb',
      'Damp'
    ],
    'Gender': 50,
    'File': '6q35n6g6i8ldxoliajjbj3qd2b7n24kf'
  },
  {
    'ID': 593,
    'Name': 'Jellicent',
    'Ability': [
      'Cursed Body',
      'Water Absorb',
      'Damp'
    ],
    'Gender': 50,
    'File': 'ulk4sem7cnviclsd1v9rygguwc63ulu7'
  },
  {
    'ID': 594,
    'Name': 'Alomomola',
    'Ability': [
      'Healer',
      'Hydration',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'zu3wxfgnkrqq75lnk7yh3gead4twdbgf'
  },
  {
    'ID': 595,
    'Name': 'Joltik',
    'Ability': [
      'Unnerve',
      'Compound Eyes',
      'Swarm'
    ],
    'Gender': 50,
    'File': 'wmi655gzo8hap1xbilk5kxige82ftne3'
  },
  {
    'ID': 596,
    'Name': 'Galvantula',
    'Ability': [
      'Unnerve',
      'Compound Eyes',
      'Swarm'
    ],
    'Gender': 50,
    'File': 'ixhtuajbskfkrbygq6rg6qtqpcw0fgh3'
  },
  {
    'ID': 597,
    'Name': 'Ferroseed',
    'Ability': [
      'Iron Barbs',
      'None',
      'Anticipation'
    ],
    'Gender': 50,
    'File': 'yl0bfb8174ni3bboirmmvcppq88f74fi'
  },
  {
    'ID': 598,
    'Name': 'Ferrothorn',
    'Ability': [
      'Iron Barbs',
      'None',
      'Anticipation'
    ],
    'Gender': 50,
    'File': 'xtik1m6ey2t3j65k0kisoxc2n4bapyot'
  },
  {
    'ID': 599,
    'Name': 'Klink',
    'Gender': null,
    'Ability': [
      'Plus',
      'Minus',
      'Clear Body'
    ],
    'File': 'l2tee5h3lcahf5rxu2r1xrugjzxijjlz'
  },
  {
    'ID': 600,
    'Name': 'Klang',
    'Gender': null,
    'Ability': [
      'Plus',
      'Minus',
      'Clear Body'
    ],
    'File': 'g91psw05i5t5hwtm6onieq46007sdwrz'
  },
  {
    'ID': 601,
    'Name': 'Klinklang',
    'Gender': null,
    'Ability': [
      'Plus',
      'Minus',
      'Clear Body'
    ],
    'File': 'rvk5o544ggrh0ph5h73f09o365fmvvws'
  },
  {
    'ID': 602,
    'Name': 'Tynamo',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '08ivkehlhbcbdwtpn62bdsouc1jnotbm'
  },
  {
    'ID': 603,
    'Name': 'Eelektrik',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '7u2i4d2j0xh3msewjhl97b5x2vlsyx01'
  },
  {
    'ID': 604,
    'Name': 'Eelektross',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'qt0jk13kgdy3kcp09z535k79mphmwtai'
  },
  {
    'ID': 605,
    'Name': 'Elgyem',
    'Ability': [
      'Synchronize',
      'Telepathy',
      'Analytic'
    ],
    'Gender': 50,
    'File': 't5f71vzqdypry3iw6uv3kge1jko403pb'
  },
  {
    'ID': 606,
    'Name': 'Beheeyem',
    'Ability': [
      'Synchronize',
      'Telepathy',
      'Analytic'
    ],
    'Gender': 50,
    'File': 'ix52plooot1zdbmilskf5clyjayllsgo'
  },
  {
    'ID': 607,
    'Name': 'Litwick',
    'Ability': [
      'Flash Fire',
      'Flame Body',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': '90z5uwmm5q9kn09mtq2cxm687jc4febk'
  },
  {
    'ID': 608,
    'Name': 'Lampent',
    'Ability': [
      'Flash Fire',
      'Flame Body',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'z33x6oy7a6qdel68srifxtz96psx591c'
  },
  {
    'ID': 609,
    'Name': 'Chandelure',
    'Ability': [
      'Flash Fire',
      'Flame Body',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'mjyzsj7vtf03u8fa8l631f5es009yxob'
  },
  {
    'ID': 610,
    'Name': 'Axew',
    'Ability': [
      'Rivalry',
      'Mold Breaker',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'kqi84ojuayghxdf9610eh3eqocs7k8jb'
  },
  {
    'ID': 611,
    'Name': 'Fraxure',
    'Ability': [
      'Rivalry',
      'Mold Breaker',
      'Unnerve'
    ],
    'Gender': 50,
    'File': '7whhttiwf148sosv34ezaz00btymtybl'
  },
  {
    'ID': 612,
    'Name': 'Haxorus',
    'Ability': [
      'Rivalry',
      'Mold Breaker',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'y7atjsnawukir80nhzj55gz759rop3t5'
  },
  {
    'ID': 613,
    'Name': 'Cubchoo',
    'Ability': [
      'Snow Cloak',
      'Slush Rush',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'he2f1mgu7x7g29etyye8roc8f48q2p3p'
  },
  {
    'ID': 614,
    'Name': 'Beartic',
    'Ability': [
      'Snow Cloak',
      'Slush Rush',
      'Swift Swim'
    ],
    'Gender': 50,
    'File': '2njizdjwul6izguxdaw7r45mmquouaey'
  },
  {
    'ID': 615,
    'Name': 'Cryogonal',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'vwy6ruc9vkwpj0cvw35gf0dq72nbfrab'
  },
  {
    'ID': 616,
    'Name': 'Shelmet',
    'Ability': [
      'Shell Armor',
      'Hydration',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'crj9z3947axhfofvvazqhqqtsln1zq2s'
  },
  {
    'ID': 617,
    'Name': 'Accelgor',
    'Ability': [
      'Sticky Hold',
      'Hydration',
      'Unburden'
    ],
    'Gender': 50,
    'File': 'nqbu1xixt5gyjg2ivm4uu4yx9epxq0wd'
  },
  {
    'ID': 618,
    'Name': 'Stunfisk',
    'Ability': [
      'Static',
      'Limber',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': '54j2bnp081jb3dofbqh45fg6j89t0z7j'
  },
  {
    'ID': 619,
    'Name': 'Mienfoo',
    'Ability': [
      'Regenerator',
      'None',
      'Reckless'
    ],
    'Gender': 50,
    'File': '0n7w6kqybtxzj13a3wqujnueyyiznv46'
  },
  {
    'ID': 620,
    'Name': 'Mienshao',
    'Ability': [
      'Regenerator',
      'None',
      'Reckless'
    ],
    'Gender': 50,
    'File': 'a3xlwnqc8vmicm3wr0zga847mc9sfhue'
  },
  {
    'ID': 621,
    'Name': 'Druddigon',
    'Ability': [
      'Sheer Force',
      'Rough Skin',
      'Mold Breaker'
    ],
    'Gender': 50,
    'File': 'ev73nvcbbtmwewamq2pa4jdhaaymjhuy'
  },
  {
    'ID': 622,
    'Name': 'Golett',
    'Gender': null,
    'Ability': [
      'Klutz',
      'Iron Fist',
      'No Guard'
    ],
    'File': 'epafsn11sa0ahhyr4p5rg9lo67jxg4rd'
  },
  {
    'ID': 623,
    'Name': 'Golurk',
    'Gender': null,
    'Ability': [
      'Klutz',
      'Iron Fist',
      'No Guard'
    ],
    'File': 'ak9p1zgrxa6d4oy4mrboot641voxzs73'
  },
  {
    'ID': 624,
    'Name': 'Pawniard',
    'Ability': [
      'Defiant',
      'Inner Focus',
      'Pressure'
    ],
    'Gender': 50,
    'File': 'hmwq97qgsc4jnp94hv4qkcuy4bo53oid'
  },
  {
    'ID': 625,
    'Name': 'Bisharp',
    'Ability': [
      'Defiant',
      'Inner Focus',
      'Pressure'
    ],
    'Gender': 50,
    'File': 'bdhnj8urmyhm52j0sedcbbpgrcwc8xwb'
  },
  {
    'ID': 626,
    'Name': 'Bouffalant',
    'Ability': [
      'Reckless',
      'Sap Sipper',
      'Soundproof'
    ],
    'Gender': 50,
    'File': 'yka8w2ta0seq5fp4xcf7xz9z5kkc0dm3'
  },
  {
    'ID': 627,
    'Name': 'Rufflet',
    'Ability': [
      'Keen Eye',
      'Sheer Force',
      'Hustle'
    ],
    'Gender': 100,
    'File': 'jd50osmarxiiw8inmzo4u3qq0p0jprfv'
  },
  {
    'ID': 628,
    'Name': 'Braviary',
    'Ability': [
      'Keen Eye',
      'Sheer Force',
      'Defiant'
    ],
    'Gender': 100,
    'File': '1lbc82i39tkrjtuot4331yu5wcwcxd4q'
  },
  {
    'ID': 629,
    'Name': 'Vullaby',
    'Ability': [
      'Overcoat',
      'Big Pecks',
      'Weak Armor'
    ],
    'Gender': 0.01,
    'File': '1mzenrz1pfa1bv325g0u2v4sgfo2vtk4'
  },
  {
    'ID': 630,
    'Name': 'Mandibuzz',
    'Ability': [
      'Overcoat',
      'Big Pecks',
      'Weak Armor'
    ],
    'Gender': 0.01,
    'File': '6cfldcf369t0uhyvll1cotxltw7qotq5'
  },
  {
    'ID': 631,
    'Name': 'Heatmor',
    'Ability': [
      'Gluttony',
      'Flash Fire',
      'White Smoke'
    ],
    'Gender': 50,
    'File': 's4qxdmpg3mh8gz8u89lfj8cdsc3x693q'
  },
  {
    'ID': 632,
    'Name': 'Durant',
    'Ability': [
      'Swarm',
      'Hustle',
      'Truant'
    ],
    'Gender': 50,
    'File': 'zb0hnszg7259st884lt5e9gueff97yfv'
  },
  {
    'ID': 633,
    'Name': 'Deino',
    'Ability': [
      'Hustle',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'cp4he5w5ikpm5ifb34uc2b2q3aludnu6'
  },
  {
    'ID': 634,
    'Name': 'Zweilous',
    'Ability': [
      'Hustle',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '47bhaiv4r2bnczgm03wuxxkspl34zzz9'
  },
  {
    'ID': 635,
    'Name': 'Hydreigon',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'shgji457qg3j31ly8qhy9kc077o9mfma'
  },
  {
    'ID': 636,
    'Name': 'Larvesta',
    'Ability': [
      'Flame Body',
      'None',
      'Swarm'
    ],
    'Gender': 50,
    'File': '6e6rc4mynkrtvdzecwa3lvsaetqmfo46'
  },
  {
    'ID': 637,
    'Name': 'Volcarona',
    'Ability': [
      'Flame Body',
      'None',
      'Swarm'
    ],
    'Gender': 50,
    'File': 'v92p3tfoflr23fxigibv02d5wynu2rc7'
  },
  {
    'ID': 638,
    'Name': 'Cobalion',
    'Gender': null,
    'Ability': [
      'Justified',
      'None',
      'None'
    ],
    'File': 'lgpv8otuylgjfrsltzsr5s98qppl0z58'
  },
  {
    'ID': 639,
    'Name': 'Terrakion',
    'Gender': null,
    'Ability': [
      'Justified',
      'None',
      'None'
    ],
    'File': 'l3oxikxssqudowbodx9zg92hw67shu1w'
  },
  {
    'ID': 640,
    'Name': 'Virizion',
    'Gender': null,
    'Ability': [
      'Justified',
      'None',
      'None'
    ],
    'File': '6xbed2a1z6uwpz60df7cuua2ee1ci3yg'
  },
  {
    'ID': 641,
    'Name': 'Tornadus',
    'Gender': null,
    'Ability': [
      'Regenerator',
      'None',
      'Defiant'
    ],
    'File': '5q2gebz6fopmvz3q7mb1hf9z3d4wxao5'
  },
  {
    'ID': 642,
    'Name': 'Thundurus',
    'Gender': null,
    'Ability': [
      'Prankster',
      'None',
      'Defiant'
    ],
    'File': 'pzih71hld8m7v3vc9ks9j0s6cgzed7zm'
  },
  {
    'ID': 643,
    'Name': 'Reshiram',
    'Gender': null,
    'Ability': [
      'Turboblaze',
      'None',
      'None'
    ],
    'File': '8fpfgafhf70bg9gn2xiubc0m4aufp6wk'
  },
  {
    'ID': 644,
    'Name': 'Zekrom',
    'Gender': null,
    'Ability': [
      'Teravolt',
      'None',
      'None'
    ],
    'File': '8s0vy54i4tbg3tel7ova2voy84kvdil1'
  },
  {
    'ID': 645,
    'Name': 'Landorus',
    'Gender': null,
    'Ability': [
      'Intimidate',
      'None',
      'None'
    ],
    'File': 'rneqbnhrgmfyjuymsljbsfvxybr2k797'
  },
  {
    'ID': 646,
    'Name': 'Kyurem',
    'Gender': null,
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'File': 'dvy72rmqybjxr6klzllzh3hi9ai02qsn'
  },
  {
    'ID': 647,
    'Name': 'Keldeo',
    'Gender': null,
    'Ability': [
      'Justified',
      'None',
      'None'
    ],
    'File': 'o7y93haj4vdj13tqpy6mxpthhwip56il'
  },
  {
    'ID': 648,
    'Name': 'Meloetta',
    'Gender': null,
    'Ability': [
      'Serene Grace',
      'None',
      'None'
    ],
    'File': 'ej2eckgl3zg1z87r0zyb55hpw9qwaml9'
  },
  {
    'ID': 649,
    'Name': 'Genesect',
    'Gender': null,
    'Ability': [
      'Download',
      'None',
      'None'
    ],
    'File': 'ec6joqfj7x30cry3t24bq0hrxtt7uabx'
  },
  {
    'ID': 650,
    'Name': 'Chespin',
    'Ability': [
      'Overgrow',
      'None',
      'Bulletproof'
    ],
    'Gender': 87.5,
    'File': 'pv9z7ztdvm2z3ye41naxae97kid4t4x5'
  },
  {
    'ID': 651,
    'Name': 'Quilladin',
    'Ability': [
      'Overgrow',
      'None',
      'Bulletproof'
    ],
    'Gender': 87.5,
    'File': 'iu4nxg0ii7sgb494vo4htevrhly0cvwe'
  },
  {
    'ID': 652,
    'Name': 'Chesnaught',
    'Ability': [
      'Overgrow',
      'None',
      'Bulletproof'
    ],
    'Gender': 87.5,
    'File': 'fdjohkg4z961ey7d2743aio8ru20csg8'
  },
  {
    'ID': 653,
    'Name': 'Fennekin',
    'Ability': [
      'Blaze',
      'None',
      'Magician'
    ],
    'Gender': 87.5,
    'File': '4v7gjidof6ja785xzqfm7kg6jzn0v1gx'
  },
  {
    'ID': 654,
    'Name': 'Braixen',
    'Ability': [
      'Blaze',
      'None',
      'Magician'
    ],
    'Gender': 87.5,
    'File': 'zppep81m3pjweo63ot83mdyo5r9b9491'
  },
  {
    'ID': 655,
    'Name': 'Delphox',
    'Ability': [
      'Blaze',
      'None',
      'Magician'
    ],
    'Gender': 87.5,
    'File': '9vb8f4evcj8viipfg4z169yt1nxtcl63'
  },
  {
    'ID': 656,
    'Name': 'Froakie',
    'Ability': [
      'Torrent',
      'None',
      'Protean'
    ],
    'Gender': 87.5,
    'File': 'wypdc2qqpvko761wsewdi25g3tah8u2h'
  },
  {
    'ID': 657,
    'Name': 'Frogadier',
    'Ability': [
      'Torrent',
      'None',
      'Protean'
    ],
    'Gender': 87.5,
    'File': 'cmdwv3qh4sghcr4ufon3f2tvm92mtgvh'
  },
  {
    'ID': 658,
    'Name': 'Greninja',
    'Ability': [
      'Torrent',
      'None',
      'Protean'
    ],
    'Gender': 87.5,
    'File': 'akt1u3xnxpla3t9dkggvst1ttdm8dgcq'
  },
  {
    'ID': 659,
    'Name': 'Bunnelby',
    'Ability': [
      'Pickup',
      'Cheek Pouch',
      'Huge Power'
    ],
    'Gender': 50,
    'File': 'k35jvpcmsv95isvalcv1ssj8jlugb23i'
  },
  {
    'ID': 660,
    'Name': 'Diggersby',
    'Ability': [
      'Pickup',
      'Cheek Pouch',
      'Huge Power'
    ],
    'Gender': 50,
    'File': '495y8bqj4jnyyyhyb71ddq8jsfadwrys'
  },
  {
    'ID': 661,
    'Name': 'Fletchling',
    'Ability': [
      'Big Pecks',
      'None',
      'Gale Wings'
    ],
    'Gender': 50,
    'File': 'ggpesztdtwrkgwanmsu2nqv27e6xlcof'
  },
  {
    'ID': 662,
    'Name': 'Fletchinder',
    'Ability': [
      'Flame Body',
      'None',
      'Gale Wings'
    ],
    'Gender': 50,
    'File': 'oeg7xsg0gbaado6sre7n0nwjs6b6hesa'
  },
  {
    'ID': 663,
    'Name': 'Talonflame',
    'Ability': [
      'Flame Body',
      'None',
      'Gale Wings'
    ],
    'Gender': 50,
    'File': 'dz5sxttq0su7w4a1w0783t5v9evegq2c'
  },
  {
    'ID': 664,
    'Name': 'Scatterbug',
    'Ability': [
      'Compound Eyes',
      'None',
      'Friend Guard'
    ],
    'Gender': 50,
    'File': '27vm097whip79yx445jvbg1c66bsc1y0'
  },
  {
    'ID': 665,
    'Name': 'Spewpa',
    'Ability': [
      'Shed Skin',
      'None',
      'Friend Guard'
    ],
    'Gender': 50,
    'File': 'mxul25tudhqukz6b5ias097xu3d0l34g'
  },
  {
    'ID': 666,
    'Name': 'Vivillon',
    'Ability': [
      'Compound Eyes',
      'Shield Dust',
      'Friend Guard'
    ],
    'Gender': 50,
    'File': '3ixq31u8jptmk7830c5ci8qpmnutupk1'
  },
  {
    'ID': 667,
    'Name': 'Litleo',
    'Ability': [
      'Rivalry',
      'Unnerve',
      'Moxie'
    ],
    'Gender': 25,
    'File': 'a90wkpi9isvj8jougel54yi7d6ydn34i'
  },
  {
    'ID': 668,
    'Name': 'Pyroar',
    'Ability': [
      'Rivalry',
      'Unnerve',
      'Moxie'
    ],
    'Gender': 25,
    'File': '261gckrruk0vv0u864rg5b2lsmu4tmg5'
  },
  {
    'ID': 669,
    'Name': 'Flabebe',
    'Ability': [
      'Flower Veil',
      'None',
      'Symbiosis'
    ],
    'Gender': 0.01,
    'File': 'xyevjefgt0f7v9k7zedibx9oc0y7olve'
  },
  {
    'ID': 670,
    'Name': 'Floette',
    'Ability': [
      'Flower Veil',
      'None',
      'Symbiosis'
    ],
    'Gender': 0.01,
    'File': 'aemjr7p2hxol9me2fyo9s028xh77pwkx'
  },
  {
    'ID': 671,
    'Name': 'Florges',
    'Ability': [
      'Flower Veil',
      'None',
      'Symbiosis'
    ],
    'Gender': 0.01,
    'File': '3d08j71ed9lywbjzfqatxworlvhmehg9'
  },
  {
    'ID': 672,
    'Name': 'Skiddo',
    'Ability': [
      'Sap Sipper',
      'None',
      'Grass Pelt'
    ],
    'Gender': 50,
    'File': 'qqgd79eglt1cn42xmbww7dflllwvja5d'
  },
  {
    'ID': 673,
    'Name': 'Gogoat',
    'Ability': [
      'Sap Sipper',
      'None',
      'Grass Pelt'
    ],
    'Gender': 50,
    'File': 'op1wsuc02gq5t87xy3bkf0xhnegwpspz'
  },
  {
    'ID': 674,
    'Name': 'Pancham',
    'Ability': [
      'Mold Breaker',
      'Iron Fist',
      'Scrappy'
    ],
    'Gender': 50,
    'File': '8wo866u3xfw9toqaayz0wyr61eoutkd8'
  },
  {
    'ID': 675,
    'Name': 'Pangoro',
    'Ability': [
      'Mold Breaker',
      'Iron Fist',
      'Scrappy'
    ],
    'Gender': 50,
    'File': '1ojq3mz74aedts1xqe09uehkfmgtvn0b'
  },
  {
    'ID': 676,
    'Name': 'Furfrou',
    'Ability': [
      'Fur Coat',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'f4mdn0d4bwc59r3l3l6mv2phy0m5doqy'
  },
  {
    'ID': 677,
    'Name': 'Espurr',
    'Ability': [
      'Keen Eye',
      'Infiltrator',
      'Own Tempo'
    ],
    'Gender': 50,
    'File': 'l93i7fk5f1cpde2ibwjwudzt7faer5tr'
  },
  {
    'ID': 678,
    'Name': 'Meowstic',
    'Ability': [
      'Keen Eye',
      'Infiltrator',
      'Prankster'
    ],
    'Gender': 50,
    'File': '2ehk0uummbbnd12fnl32olcynzuetvay'
  },
  {
    'ID': 679,
    'Name': 'Honedge',
    'Ability': [
      'No Guard',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ed4tj4m0w6ppc33luxjl9zmub0xnbsi9'
  },
  {
    'ID': 680,
    'Name': 'Doublade',
    'Ability': [
      'No Guard',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'axmeoih8wdvgfejs34ahzyfdomta1owk'
  },
  {
    'ID': 681,
    'Name': 'Aegislash',
    'Ability': [
      'Stance Change',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'hojti5ntcxzuj6wtf7en1jzws1oqf5fv'
  },
  {
    'ID': 682,
    'Name': 'Spritzee',
    'Ability': [
      'Healer',
      'None',
      'Aroma Veil'
    ],
    'Gender': 50,
    'File': '85tmydnbz4hi7rz5eho4asjcy506clvb'
  },
  {
    'ID': 683,
    'Name': 'Aromatisse',
    'Ability': [
      'Healer',
      'None',
      'Aroma Veil'
    ],
    'Gender': 50,
    'File': 'nj2kb3smjh1u42py5ob8lt891499c2wt'
  },
  {
    'ID': 684,
    'Name': 'Swirlix',
    'Ability': [
      'Sweet Veil',
      'None',
      'Unburden'
    ],
    'Gender': 50,
    'File': 'yunb8truaveewvuyi4rodljvk1ft6khk'
  },
  {
    'ID': 685,
    'Name': 'Slurpuff',
    'Ability': [
      'Sweet Veil',
      'None',
      'Unburden'
    ],
    'Gender': 50,
    'File': 'bsi1su05wzvyr6jni0vhebvsxcr6dgqu'
  },
  {
    'ID': 686,
    'Name': 'Inkay',
    'Ability': [
      'Contrary',
      'Suction Cups',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'zpibt4vulk7rbbiff4u4t11uu037d3sm'
  },
  {
    'ID': 687,
    'Name': 'Malamar',
    'Ability': [
      'Contrary',
      'Suction Cups',
      'Infiltrator'
    ],
    'Gender': 50,
    'File': 'zymfw0372tq6fbeiwz2gxd5u9oan7r0v'
  },
  {
    'ID': 688,
    'Name': 'Binacle',
    'Ability': [
      'Sniper',
      'Tough Claws',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': '6x45tyc0nepi97mc47u6v7saek5bh557'
  },
  {
    'ID': 689,
    'Name': 'Barbaracle',
    'Ability': [
      'Sniper',
      'Tough Claws',
      'Pickpocket'
    ],
    'Gender': 50,
    'File': 'nuugji2ru52zhxpq6kokia4s0y6upsxc'
  },
  {
    'ID': 690,
    'Name': 'Skrelp',
    'Ability': [
      'Poison Touch',
      'Poison Point',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'os8uuff196vmlep3g5brns5r7tuna6dx'
  },
  {
    'ID': 691,
    'Name': 'Dragalge',
    'Ability': [
      'Poison Touch',
      'Poison Point',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'u5iayfixxc4nilhg722s9p58ah7hax5f'
  },
  {
    'ID': 692,
    'Name': 'Clauncher',
    'Ability': [
      'Mega Launcher',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'zi4adr8gloswnubdp0u7y7ve9ur7a3nt'
  },
  {
    'ID': 693,
    'Name': 'Clawitzer',
    'Ability': [
      'Mega Launcher',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 's3y6hh0yuknxw62lnlm7g8wgwn8vj173'
  },
  {
    'ID': 694,
    'Name': 'Helioptile',
    'Ability': [
      'Dry Skin',
      'Sand Veil',
      'Solar Power'
    ],
    'Gender': 50,
    'File': 'w9d9on5ot50ykw98rja2qpafdqesauf1'
  },
  {
    'ID': 695,
    'Name': 'Heliolisk',
    'Ability': [
      'Dry Skin',
      'Sand Veil',
      'Solar Power'
    ],
    'Gender': 50,
    'File': 'dnnfgg2l4pqft513n51xtf82fu5vq85a'
  },
  {
    'ID': 696,
    'Name': 'Tyrunt',
    'Ability': [
      'Strong Jaw',
      'None',
      'Sturdy'
    ],
    'Gender': 87.5,
    'File': 'nhbuiaqvxtsq5ym3v4k0oa8eofwrrstq'
  },
  {
    'ID': 697,
    'Name': 'Tyrantrum',
    'Ability': [
      'Strong Jaw',
      'None',
      'Rock Head'
    ],
    'Gender': 87.5,
    'File': 'okpj92c8ccpxskd2r2qmfxqjxdkux7jl'
  },
  {
    'ID': 698,
    'Name': 'Amaura',
    'Ability': [
      'Refrigerate',
      'None',
      'Snow Warning'
    ],
    'Gender': 87.5,
    'File': 'c8qk4tv7y90i31qcwj1vxxwwt7nig8ix'
  },
  {
    'ID': 699,
    'Name': 'Aurorus',
    'Ability': [
      'Refrigerate',
      'None',
      'Snow Warning'
    ],
    'Gender': 87.5,
    'File': '30esdhmhtccf3a0qwqrw7t8uhj2xes3t'
  },
  {
    'ID': 700,
    'Name': 'Sylveon',
    'Ability': [
      'Cute Charm',
      'None',
      'Pixilate'
    ],
    'Gender': 87.5,
    'File': '193ewp1u2i1fxcv8lfvopzstrv2g4jrk'
  },
  {
    'ID': 701,
    'Name': 'Hawlucha',
    'Ability': [
      'Unburden',
      'Limber',
      'Mold Breaker'
    ],
    'Gender': 50,
    'File': 'sly8r4ussca7f4fhrqskmpjp3fq8atek'
  },
  {
    'ID': 702,
    'Name': 'Dedenne',
    'Ability': [
      'Pickup',
      'Cheek Pouch',
      'Plus'
    ],
    'Gender': 50,
    'File': '5j9ukcsxpbsjdjrtn40igp62dk22g7qe'
  },
  {
    'ID': 703,
    'Name': 'Carbink',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'Sturdy'
    ],
    'File': 'qnv4yyyfq54glmofjz8si38dbffsfjs6'
  },
  {
    'ID': 704,
    'Name': 'Goomy',
    'Ability': [
      'Hydration',
      'Sap Sipper',
      'Gooey'
    ],
    'Gender': 50,
    'File': '0dsf2ra4zvhl8aesuxqcdhpis9o7utzb'
  },
  {
    'ID': 705,
    'Name': 'Sliggoo',
    'Ability': [
      'Hydration',
      'Sap Sipper',
      'Gooey'
    ],
    'Gender': 50,
    'File': 'ce6fxxs91xejli3p4fvd5o309q2f2atf'
  },
  {
    'ID': 706,
    'Name': 'Goodra',
    'Ability': [
      'Hydration',
      'Sap Sipper',
      'Gooey'
    ],
    'Gender': 50,
    'File': 'djqmnpew4gk4futeiacsuyyn7qqek7hd'
  },
  {
    'ID': 707,
    'Name': 'Klefki',
    'Ability': [
      'Prankster',
      'None',
      'Magician'
    ],
    'Gender': 50,
    'File': 'epox3b9a5i3roxtqgf2mgarxm30qx072'
  },
  {
    'ID': 708,
    'Name': 'Phantump',
    'Ability': [
      'Natural Cure',
      'Frisk',
      'Harvest'
    ],
    'Gender': 50,
    'File': 'bcnhbb6hzbyademdpc2d9bjday9msz3y'
  },
  {
    'ID': 709,
    'Name': 'Trevenant',
    'Ability': [
      'Natural Cure',
      'Frisk',
      'Harvest'
    ],
    'Gender': 50,
    'File': 'q93bj78zxb828sy64xakfd2104nyrawg'
  },
  {
    'ID': 710,
    'Name': 'Pumpkaboo',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': 'ear7xg3ysnwyzjckuplmfpnadvpn86ps'
  },
  {
    'ID': 711,
    'Name': 'Gourgeist',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': 'asnzcfphx1kt81ityngd6gzxtuogp35g'
  },
  {
    'ID': 712,
    'Name': 'Bergmite',
    'Ability': [
      'Ice Body',
      'Own Tempo',
      'Sturdy'
    ],
    'Gender': 50,
    'File': '35me4oue4dzq99k4is4br297fo8nh9ez'
  },
  {
    'ID': 713,
    'Name': 'Avalugg',
    'Ability': [
      'Ice Body',
      'Own Tempo',
      'Sturdy'
    ],
    'Gender': 50,
    'File': 'p1nlcwolbddgd930l6qyzddube8dhf62'
  },
  {
    'ID': 714,
    'Name': 'Noibat',
    'Ability': [
      'Frisk',
      'Infiltrator',
      'Telepathy'
    ],
    'Gender': 50,
    'File': '59eivfyrggkn5cf00py574869hz3w0sq'
  },
  {
    'ID': 715,
    'Name': 'Noivern',
    'Ability': [
      'Frisk',
      'Infiltrator',
      'Telepathy'
    ],
    'Gender': 50,
    'File': 'tu8kl2n6o1fw7dam7g2ytjxhocvtp7aj'
  },
  {
    'ID': 716,
    'Name': 'Xerneas',
    'Gender': null,
    'Ability': [
      'Fairy Aura',
      'None',
      'None'
    ],
    'File': 'uzgto9oosxhpy7c3z8cjmc6eyhtwpr6q'
  },
  {
    'ID': 717,
    'Name': 'Yveltal',
    'Gender': null,
    'Ability': [
      'Dark Aura',
      'None',
      'None'
    ],
    'File': 'i08gmd5xokbx8smrd3er78pqv9gfohcm'
  },
  {
    'ID': 718,
    'Name': 'Zygarde',
    'Gender': null,
    'Ability': [
      'Aura Break',
      'None',
      'None'
    ],
    'File': 'ga3ngf5ifr3b2q5seuyhzqayna96zboh'
  },
  {
    'ID': 719,
    'Name': 'Diancie',
    'Gender': null,
    'Ability': [
      'Clear Body',
      'None',
      'None'
    ],
    'File': '213jqx7knmmeuwyvgxfl4ppada9lnxlj'
  },
  {
    'ID': 720,
    'Name': 'Hoopa',
    'Gender': null,
    'Ability': [
      'Magician',
      'None',
      'None'
    ],
    'File': 'nbgkzkkmr05g9a8wryd3eqfi61n361n3'
  },
  {
    'ID': 721,
    'Name': 'Volcanion',
    'Gender': null,
    'Ability': [
      'Water Absorb',
      'None',
      'None'
    ],
    'File': '0cph8wicz4k85ilq4gqwj7vic9u4403c'
  },
  {
    'ID': 722,
    'Name': 'Rowlet',
    'Ability': [
      'Overgrow',
      'None',
      'Long Reach'
    ],
    'Gender': 87.5,
    'File': 'qkq0c9kgv36bw5fyxphjtjs4i4on1y6r'
  },
  {
    'ID': 723,
    'Name': 'Dartrix',
    'Ability': [
      'Overgrow',
      'None',
      'Long Reach'
    ],
    'Gender': 87.5,
    'File': 'cnbaudm3gp6r56v4m79knqhesxuznas2'
  },
  {
    'ID': 724,
    'Name': 'Decidueye',
    'Ability': [
      'Overgrow',
      'None',
      'Long Reach'
    ],
    'Gender': 87.5,
    'File': 'ukrgo8hbpopjihlkv8v7uy6o7jey0yok'
  },
  {
    'ID': 725,
    'Name': 'Litten',
    'Ability': [
      'Blaze',
      'None',
      'Intimidate'
    ],
    'Gender': 87.5,
    'File': 'uv5di5t6566uckznkie9bson1t94lr8p'
  },
  {
    'ID': 726,
    'Name': 'Torracat',
    'Ability': [
      'Blaze',
      'None',
      'Intimidate'
    ],
    'Gender': 87.5,
    'File': 'bzkvd63ry04jug0orhde1iwbd6id03sb'
  },
  {
    'ID': 727,
    'Name': 'Incineroar',
    'Ability': [
      'Blaze',
      'None',
      'Intimidate'
    ],
    'Gender': 87.5,
    'File': 'ct8rsj1t9pn68ri0mq6j8pofe84ls9cn'
  },
  {
    'ID': 728,
    'Name': 'Popplio',
    'Ability': [
      'Torrent',
      'None',
      'Liquid Voice'
    ],
    'Gender': 87.5,
    'File': 'k018fvgv9k5glr3f8pz6zuuujcbq3in0'
  },
  {
    'ID': 729,
    'Name': 'Brionne',
    'Ability': [
      'Torrent',
      'None',
      'Liquid Voice'
    ],
    'Gender': 87.5,
    'File': 'pf4x5a0unbvyoj0rt58n3obypnnzb4qj'
  },
  {
    'ID': 730,
    'Name': 'Primarina',
    'Ability': [
      'Torrent',
      'None',
      'Liquid Voice'
    ],
    'Gender': 87.5,
    'File': 'ay1wyjp95sh62tw2ciyq6x7pavthz7gf'
  },
  {
    'ID': 731,
    'Name': 'Pikipek',
    'Ability': [
      'Keen Eye',
      'Skill Link',
      'Pickup'
    ],
    'Gender': 50,
    'File': 'ewgm76gyykn9eep16dpshihx4a6l5q9r'
  },
  {
    'ID': 732,
    'Name': 'Trumbeak',
    'Ability': [
      'Keen Eye',
      'Skill Link',
      'Pickup'
    ],
    'Gender': 50,
    'File': 'ab2f3sj35frm8qrqiofd79ulzndg0oqt'
  },
  {
    'ID': 733,
    'Name': 'Toucannon',
    'Ability': [
      'Keen Eye',
      'Skill Link',
      'Sheer Force'
    ],
    'Gender': 50,
    'File': '1g76kpxiapr5dbrjxll6ivdf8q5lsv6u'
  },
  {
    'ID': 734,
    'Name': 'Yungoos',
    'Ability': [
      'Stakeout',
      'Strong Jaw',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'njgx73szgaer02nvbnoxp972c9a4kug0'
  },
  {
    'ID': 735,
    'Name': 'Gumshoos',
    'Ability': [
      'Stakeout',
      'Strong Jaw',
      'Adaptability'
    ],
    'Gender': 50,
    'File': 'r4f8psg80usv1ijehbqewpb6hicnozg5'
  },
  {
    'ID': 736,
    'Name': 'Grubbin',
    'Ability': [
      'Swarm',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '2kogaty9csvr7l19fz9d9vnw7y0ld7vi'
  },
  {
    'ID': 737,
    'Name': 'Charjabug',
    'Ability': [
      'Battery',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '204ktuooid6tjlmg3ubh4nj0z32xxbyr'
  },
  {
    'ID': 738,
    'Name': 'Vikavolt',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'fw4v0qtwjhiey5svrue9xq3ksy3a456b'
  },
  {
    'ID': 739,
    'Name': 'Crabrawler',
    'Ability': [
      'Hyper Cutter',
      'Iron Fist',
      'Anger Point'
    ],
    'Gender': 50,
    'File': 'oj5nb8kc3ngs8i14r4xadwa03cg9rh9z'
  },
  {
    'ID': 740,
    'Name': 'Crabominable',
    'Ability': [
      'Hyper Cutter',
      'Iron Fist',
      'Anger Point'
    ],
    'Gender': 50,
    'File': 'ot1w9se8aj9ounubzfgt7em45b176yrf'
  },
  {
    'ID': 741,
    'Name': 'Oricorio',
    'Ability': [
      'Dancer',
      'None',
      'None'
    ],
    'Gender': 25,
    'File': 'pxyxfzhicyue5in2bxr24pavdxwh0dm4'
  },
  {
    'ID': 742,
    'Name': 'Cutiefly',
    'Ability': [
      'Honey Gather',
      'Shield Dust',
      'Sweet Veil'
    ],
    'Gender': 50,
    'File': 'keeljcb99zmduioo5y9anautwsd7w7vl'
  },
  {
    'ID': 743,
    'Name': 'Ribombee',
    'Ability': [
      'Honey Gather',
      'Shield Dust',
      'Sweet Veil'
    ],
    'Gender': 50,
    'File': 'weyly4rf8atskgv92abeejrd4xmqsap4'
  },
  {
    'ID': 744,
    'Name': 'Rockruff',
    'Ability': [
      'Keen Eye',
      'Vital Spirit',
      'Steadfast'
    ],
    'Gender': 50,
    'File': 'owfoh2yg2k86e2q87ppoj79jv1rihlk3'
  },
  {
    'ID': 745,
    'Name': 'Lycanroc',
    'Ability': [
      'Keen Eye',
      'Sand Rush',
      'Steadfast'
    ],
    'Gender': 50,
    'File': '48v1ys0m0s6gcjkruzspbasy4c61fyti'
  },
  {
    'ID': 746,
    'Name': 'Wishiwashi',
    'Ability': [
      'Schooling',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'g0kt8k8dz3wrdxr2k0qp1r1eq15tfvur'
  },
  {
    'ID': 747,
    'Name': 'Mareanie',
    'Ability': [
      'Merciless',
      'Limber',
      'Regenerator'
    ],
    'Gender': 50,
    'File': '67y89wof3sx6ujgmo1nzg87fefggjflm'
  },
  {
    'ID': 748,
    'Name': 'Toxapex',
    'Ability': [
      'Merciless',
      'Limber',
      'Regenerator'
    ],
    'Gender': 50,
    'File': 'l5pd6ggov4nk899ljyg1on1g26efebsm'
  },
  {
    'ID': 749,
    'Name': 'Mudbray',
    'Ability': [
      'Own Tempo',
      'Stamina',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': 'fm3tjjqsa4h64mj5fybpx63axg9m5aev'
  },
  {
    'ID': 750,
    'Name': 'Mudsdale',
    'Ability': [
      'Own Tempo',
      'Stamina',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': 'egrlobwgd61vffapzjlvxlhpfynhvu8a'
  },
  {
    'ID': 751,
    'Name': 'Dewpider',
    'Ability': [
      'Water Bubble',
      'None',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'lhw7l8hn0rafdf17y81d5uiffku4lt0i'
  },
  {
    'ID': 752,
    'Name': 'Araquanid',
    'Ability': [
      'Water Bubble',
      'None',
      'Water Absorb'
    ],
    'Gender': 50,
    'File': 'o7hoa551o75zm3k90r20t2eplyg8bjvx'
  },
  {
    'ID': 753,
    'Name': 'Fomantis',
    'Ability': [
      'Leaf Guard',
      'None',
      'Contrary'
    ],
    'Gender': 50,
    'File': 'q11x0bfx3hxia2bsqox426tbyiuaw39e'
  },
  {
    'ID': 754,
    'Name': 'Lurantis',
    'Ability': [
      'Leaf Guard',
      'None',
      'Contrary'
    ],
    'Gender': 50,
    'File': '8942gfwrxwofcd8xl9d32vi1bes71gex'
  },
  {
    'ID': 755,
    'Name': 'Morelull',
    'Ability': [
      'Illuminate',
      'Effect Spore',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'gcg0yn26wmucfo2q6zj5el3vyshqozup'
  },
  {
    'ID': 756,
    'Name': 'Shiinotic',
    'Ability': [
      'Illuminate',
      'Effect Spore',
      'Rain Dish'
    ],
    'Gender': 50,
    'File': 'f3gkpelbz2tyhibqheptcivc7q9lklpb'
  },
  {
    'ID': 757,
    'Name': 'Salandit',
    'Ability': [
      'Corrosion',
      'None',
      'Oblivious'
    ],
    'Gender': 87.5,
    'File': '0zwjp5nqnsvl03vtojvbx34t50gayqpu'
  },
  {
    'ID': 758,
    'Name': 'Salazzle',
    'Ability': [
      'Corrosion',
      'None',
      'Oblivious'
    ],
    'Gender': 0.01,
    'File': '9kzmaadbnnu9i7hl8n1i0zbnom58k3lz'
  },
  {
    'ID': 759,
    'Name': 'Stufful',
    'Ability': [
      'Fluffy',
      'Klutz',
      'Cute Charm'
    ],
    'Gender': 50,
    'File': 'm2egfe1n2pa1apqa9dqsr3ej51woqqcy'
  },
  {
    'ID': 760,
    'Name': 'Bewear',
    'Ability': [
      'Fluffy',
      'Klutz',
      'Unnerve'
    ],
    'Gender': 50,
    'File': 'voi6d336ud4mcdsyfnjut83n9w8flo15'
  },
  {
    'ID': 761,
    'Name': 'Bounsweet',
    'Ability': [
      'Leaf Guard',
      'Oblivious',
      'Sweet Veil'
    ],
    'Gender': 0.01,
    'File': 'eg1ayit6um2ac41oc4bid3jzy3cxsa1g'
  },
  {
    'ID': 762,
    'Name': 'Steenee',
    'Ability': [
      'Leaf Guard',
      'Oblivious',
      'Sweet Veil'
    ],
    'Gender': 0.01,
    'File': 'p1w9olezvlaj3hysfm5nzmuhn4gt81s9'
  },
  {
    'ID': 763,
    'Name': 'Tsareena',
    'Ability': [
      'Leaf Guard',
      'Queenly Majesty',
      'Sweet Veil'
    ],
    'Gender': 0.01,
    'File': 'g1umqa1smzw6tnqmz8c6hsry16t4gpf8'
  },
  {
    'ID': 764,
    'Name': 'Comfey',
    'Ability': [
      'Flower Veil',
      'Triage',
      'Natural Cure'
    ],
    'Gender': 25,
    'File': 'v7gdt06tquwghoinr7yzljjgn99kpbph'
  },
  {
    'ID': 765,
    'Name': 'Oranguru',
    'Ability': [
      'Inner Focus',
      'Telepathy',
      'Symbiosis'
    ],
    'Gender': 50,
    'File': 'aihd8r2j0vb26kbr0upf4nvqxhjuehaj'
  },
  {
    'ID': 766,
    'Name': 'Passimian',
    'Ability': [
      'Receiver',
      'None',
      'Defiant'
    ],
    'Gender': 50,
    'File': 'gosmvyq11ewvghfoc3dbvmhpl3s9u8xy'
  },
  {
    'ID': 767,
    'Name': 'Wimpod',
    'Ability': [
      'Wimp Out',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'bge8fokk771d4wr3o9du0pz5u7so4zrp'
  },
  {
    'ID': 768,
    'Name': 'Golisopod',
    'Ability': [
      'Emergency Exit',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'dmil64ye2n9k7acmkepkvcfv7ppdz39p'
  },
  {
    'ID': 769,
    'Name': 'Sandygast',
    'Ability': [
      'Water Compaction',
      'None',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'n6gabp3g386w7cx2dk24nx0nk4qgk85r'
  },
  {
    'ID': 770,
    'Name': 'Palossand',
    'Ability': [
      'Water Compaction',
      'None',
      'Sand Veil'
    ],
    'Gender': 50,
    'File': 'xso1iumedx0z2yamdpmkyhj3iygmeyru'
  },
  {
    'ID': 771,
    'Name': 'Pyukumuku',
    'Ability': [
      'Innards Out',
      'None',
      'Unaware'
    ],
    'Gender': 50,
    'File': 'y1xhtf2lxig10bipetwrhebyhjpsvtnu'
  },
  {
    'ID': 772,
    'Name': 'Type: Null',
    'Gender': null,
    'Ability': [
      'Battle Armor',
      'None',
      'None'
    ],
    'File': '4e7da1ahei5fly9ecvnb3bdzh5kmt33g'
  },
  {
    'ID': 773,
    'Name': 'Silvally',
    'Gender': null,
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'File': 'jdh1lzy7ovdrlbna8tv1gl2ojp11bbeh'
  },
  {
    'ID': 774,
    'Name': 'Minior',
    'Gender': null,
    'Ability': [
      'Shields Down',
      'None',
      'None'
    ],
    'File': 'j6uvx7mgu17zr8e83gtzriypq0yk3f3z'
  },
  {
    'ID': 775,
    'Name': 'Komala',
    'Ability': [
      'Comatose',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '52bxfnb5lqnayhnoh5y4kx1matqkgf28'
  },
  {
    'ID': 776,
    'Name': 'Turtonator',
    'Ability': [
      'Shell Armor',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '70sc04u1mezamllmoaau01o8v6ggd0x3'
  },
  {
    'ID': 777,
    'Name': 'Togedemaru',
    'Ability': [
      'Iron Barbs',
      'Lightning Rod',
      'Sturdy'
    ],
    'Gender': 50,
    'File': 'h6s5gr6dovcky6l24i3pojx55wyssuee'
  },
  {
    'ID': 778,
    'Name': 'Mimikyu',
    'Ability': [
      'Disguise',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'srnjejgskx4mgakfcxkm0m304gz84h74'
  },
  {
    'ID': 779,
    'Name': 'Bruxish',
    'Ability': [
      'Dazzling',
      'Strong Jaw',
      'Wonder Skin'
    ],
    'Gender': 50,
    'File': 'mxbor2cde5siftvep9su5ai91419cus0'
  },
  {
    'ID': 780,
    'Name': 'Drampa',
    'Ability': [
      'Berserk',
      'Sap Sipper',
      'Cloud Nine'
    ],
    'Gender': 50,
    'File': 'vwy3t98cb4sxizg3n8io2hmsy2qmlgt0'
  },
  {
    'ID': 781,
    'Name': 'Dhelmise',
    'Gender': null,
    'Ability': [
      'Steelworker',
      'None',
      'None'
    ],
    'File': '0hn0jss6y8ipfpiegj4zyptgln73bhx3'
  },
  {
    'ID': 782,
    'Name': 'Jangmo-o',
    'Ability': [
      'Bulletproof',
      'Soundproof',
      'Overcoat'
    ],
    'Gender': 50,
    'File': '445tcl3r22yhf3zs0e2hehfzpovgf7ik'
  },
  {
    'ID': 783,
    'Name': 'Hakamo-o',
    'Ability': [
      'Bulletproof',
      'Soundproof',
      'Overcoat'
    ],
    'Gender': 50,
    'File': '6ymnvsin6deoct0dssxmbog52jk814ve'
  },
  {
    'ID': 784,
    'Name': 'Kommo-o',
    'Ability': [
      'Bulletproof',
      'Soundproof',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'txifsn2xalo4e8qge92vwz8po037of15'
  },
  {
    'ID': 785,
    'Name': 'Tapu Koko',
    'Gender': null,
    'Ability': [
      'Electric Surge',
      'None',
      'Telepathy'
    ],
    'File': 'b8qbxzb44eywkvohv5hxuyam0w2fp5l7'
  },
  {
    'ID': 786,
    'Name': 'Tapu Lele',
    'Gender': null,
    'Ability': [
      'Psychic Surge',
      'None',
      'Telepathy'
    ],
    'File': 'zpje8ruxyb2hbfdb7hysmpcip0uc6khc'
  },
  {
    'ID': 787,
    'Name': 'Tapu Bulu',
    'Gender': null,
    'Ability': [
      'Grassy Surge',
      'None',
      'Telepathy'
    ],
    'File': '4o55jaa7jcbl29zfsp6cx1ajhxksxz4i'
  },
  {
    'ID': 788,
    'Name': 'Tapu Fini',
    'Gender': null,
    'Ability': [
      'Misty Surge',
      'None',
      'Telepathy'
    ],
    'File': '3uxuqfk9fk30rdb751fm1wte0h42uh4n'
  },
  {
    'ID': 789,
    'Name': 'Cosmog',
    'Gender': null,
    'Ability': [
      'Unaware',
      'None',
      'None'
    ],
    'File': '7w3f05di5rmsufq5n71h3ma1o3iw3jmr'
  },
  {
    'ID': 790,
    'Name': 'Cosmoem',
    'Gender': null,
    'Ability': [
      'Sturdy',
      'None',
      'None'
    ],
    'File': '1az2a6juzrxoypgb1qv8w1jurzpgkgc8'
  },
  {
    'ID': 791,
    'Name': 'Solgaleo',
    'Gender': null,
    'Ability': [
      'Full Metal Body',
      'None',
      'None'
    ],
    'File': 'klfkwsadspdov2zprpc2ap6ae4o9u1cq'
  },
  {
    'ID': 792,
    'Name': 'Lunala',
    'Gender': null,
    'Ability': [
      'Shadow Shield',
      'None',
      'None'
    ],
    'File': 'ztxxeu7l7570ftyln1cwn3ggfqgbkq8u'
  },
  {
    'ID': 793,
    'Name': 'Nihilego',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': '42ulw5b9ia2v0kmu1ukvaxi95bjtyfv1'
  },
  {
    'ID': 794,
    'Name': 'Buzzwole',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'rkt7bqp63hpx1qrb1mpfssqpbv9mon9l'
  },
  {
    'ID': 795,
    'Name': 'Pheromosa',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'hnetnrp1soifn6y0bu50110ov6ga9wje'
  },
  {
    'ID': 796,
    'Name': 'Xurkitree',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': '991a3gga86hgq3yuya3cuxp7ar1r0m5m'
  },
  {
    'ID': 797,
    'Name': 'Celesteela',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'msh49qg07xz0f3ewzw89e4ns7pbv2nop'
  },
  {
    'ID': 799,
    'Name': 'Kartana',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'pktcjye88anr86knsjcmw1tf3uakr785'
  },
  {
    'ID': 799,
    'Name': 'Guzzlord',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': '8gyjg0d0nm9chqh4td13jy4mpzpr6zex'
  },
  {
    'ID': 800,
    'Name': 'Necrozma',
    'Gender': null,
    'Ability': [
      'Prism Armor',
      'None',
      'None'
    ],
    'File': 'ocerbx57dvbap9d41vw7xm4y7950hoca'
  },
  {
    'ID': 801,
    'Name': 'Magearna',
    'Gender': null,
    'Ability': [
      'Soul-Heart',
      'None',
      'None'
    ],
    'File': '4ohqgh2jg5me3l6o501g0lw5nt530556'
  },
  {
    'ID': 802,
    'Name': 'Marshadow',
    'Gender': null,
    'Ability': [
      'Technician',
      'None',
      'None'
    ],
    'File': '9cmxa0e0m1crany7wqy25vowggome5lz'
  },
  {
    'ID': 803,
    'Name': 'Poipole',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'xjrc161j620zb6z5nrj3ppotskz2cuay'
  },
  {
    'ID': 804,
    'Name': 'Naganadel',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': '3s8g6eq19um8y7ckchps0imo0gftpdt6'
  },
  {
    'ID': 805,
    'Name': 'Stakataka',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'a2cgnu08jai6dq6i85jtucoppyccoqbz'
  },
  {
    'ID': 806,
    'Name': 'Blacephalon',
    'Gender': null,
    'Ability': [
      'Beast Boost',
      'None',
      'None'
    ],
    'File': 'eq7leomkjrhj62w2t4tz6qw9ir823pyy'
  },
  {
    'ID': 807,
    'Name': 'Zeraora',
    'Gender': null,
    'Ability': [
      'Volt Absorb',
      'None',
      'None'
    ],
    'File': 'jndl73ennmez6efvkhe647t9rc0q41ei'
  },
  {
    'ID': 3000,
    'Name': 'Mega Venusaur',
    'Ability': [
      'Thick Fat',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 're5tu4nrohddgbnvlcdl7at5h35wtt4l'
  },
  {
    'ID': 3001,
    'Name': 'Mega Charizard X',
    'Ability': [
      'Tough Claws',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '2jsqtm10s4jlr3hh2c05orqs1fod2a0r'
  },
  {
    'ID': 3002,
    'Name': 'Mega Charizard Y',
    'Ability': [
      'Drought',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'filqvwtlz05drlxsft5tf1rt3bzi7lmp'
  },
  {
    'ID': 3003,
    'Name': 'Mega Blastoise',
    'Ability': [
      'Mega Launcher',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'puflj25srw1k5l6bwi2rdltgyjl1t6b3'
  },
  {
    'ID': 3004,
    'Name': 'Mega Alakazam',
    'Ability': [
      'Trace',
      'None',
      'None'
    ],
    'Gender': 75,
    'File': 'zcv3j0lyml1xg6ulh7dje9i0l3i8lai2'
  },
  {
    'ID': 3005,
    'Name': 'Mega Gengar',
    'Ability': [
      'Shadow Tag',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'emddhtz3l28yto0tygaq2espsipk65ge'
  },
  {
    'ID': 3006,
    'Name': 'Mega Kangaskhan',
    'Ability': [
      'Parental Bond',
      'None',
      'None'
    ],
    'Gender': null,
    'File': 'zeck0n486h63yirsr67oawcgui8vvmm2'
  },
  {
    'ID': 3007,
    'Name': 'Mega Pinsir',
    'Ability': [
      'Aerilate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '9nc8ujfwnkzesvpwqd4m9a6pbyiskok3'
  },
  {
    'ID': 3008,
    'Name': 'Mega Gyarados',
    'Ability': [
      'Mold Breaker',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '1hji30tmkn31kzv5w8wjbnk1s2yg4lu5'
  },
  {
    'ID': 3009,
    'Name': 'Mega Aerodactyl',
    'Ability': [
      'Tough Claws',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '92rlm9z5socz9lgj4mtj183w4etts6yg'
  },
  {
    'ID': 3010,
    'Name': 'Mega Mewtwo X',
    'Gender': null,
    'Ability': [
      'Steadfast',
      'None',
      'None'
    ],
    'File': '8oi9xrp6tleteumzgv45h9xk24l8y2w9'
  },
  {
    'ID': 3011,
    'Name': 'Mega Mewtwo Y',
    'Gender': null,
    'Ability': [
      'Insomnia',
      'None',
      'None'
    ],
    'File': 'lnxe5xa5vmh95v4lu5jwhegw06fpd8z8'
  },
  {
    'ID': 3012,
    'Name': 'Mega Ampharos',
    'Ability': [
      'Mold Breaker',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'l7krtsfc85x9a64lh5g72c7hipap58q7'
  },
  {
    'ID': 3013,
    'Name': 'Mega Scizor',
    'Ability': [
      'Technician',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'jtdunm96cmdn3odufr2ieodomsjqa8fy'
  },
  {
    'ID': 3014,
    'Name': 'Mega Heracross',
    'Ability': [
      'Skill Link',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'g2vf2ay3ffrn3jutw47bwgd14xqttuj4'
  },
  {
    'ID': 3015,
    'Name': 'Mega Houndoom',
    'Ability': [
      'Solar Power',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ezfub3hkvr83s0riqkuvvw5tnpo367dx'
  },
  {
    'ID': 3016,
    'Name': 'Mega Tyranitar',
    'Ability': [
      'Sand Stream',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ry5fqc7q2j9py8wzzaeyf4u5syf853d2'
  },
  {
    'ID': 3017,
    'Name': 'Mega Blaziken',
    'Ability': [
      'Speed Boost',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '2qiqydydtqyccy1wlpd9n6u8tsynjond'
  },
  {
    'ID': 3018,
    'Name': 'Mega Gardevoir',
    'Ability': [
      'Pixilate',
      'None',
      'None'
    ],
    'Gender': null,
    'File': 'x5ohfifpt828p1gxz2025mnokgppg039'
  },
  {
    'ID': 3019,
    'Name': 'Mega Mawile',
    'Ability': [
      'Huge Power',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '67r36kjmtxhizqj2krmiuw5l6pnindps'
  },
  {
    'ID': 3020,
    'Name': 'Mega Aggron',
    'Ability': [
      'Filter',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '2o4kjxj6c8w74m6rsz2uh5a159j7hz6x'
  },
  {
    'ID': 3021,
    'Name': 'Mega Medicham',
    'Ability': [
      'Pure Power',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'esmo10g0amslfxrqmq4weurre2bvx3ws'
  },
  {
    'ID': 3022,
    'Name': 'Mega Manectric',
    'Ability': [
      'Intimidate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'pcdanh3d8y81nyfnqdlozozn0a4oqkm4'
  },
  {
    'ID': 3023,
    'Name': 'Mega Banette',
    'Ability': [
      'Prankster',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '9kc1fhmaply99ci9ekh9yr8pp0z5fr8d'
  },
  {
    'ID': 3024,
    'Name': 'Mega Absol',
    'Ability': [
      'Magic Bounce',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'fbuadopow5kxtv7rhyjjmq5mzh4a9lsw'
  },
  {
    'ID': 3025,
    'Name': 'Mega Garchomp',
    'Ability': [
      'Sand Force',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'qz0be93paqffjlaytfw1tp4gxprjn8mv'
  },
  {
    'ID': 3026,
    'Name': 'Mega Lucario',
    'Ability': [
      'Adaptability',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': 'jz62c9f3dfeifqhtmheyemtu7o06xgm8'
  },
  {
    'ID': 3027,
    'Name': 'Mega Abomasnow',
    'Ability': [
      'Snow Warning',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'oqkj6dhef1vzkcnzlekdc5mtra9s1wsg'
  },
  {
    'ID': 3028,
    'Name': 'Mega Beedrill',
    'Ability': [
      'Adaptability',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'bgtap6np0j9n26tq6mt7u4lghvuqe52o'
  },
  {
    'ID': 3029,
    'Name': 'Mega Pidgeot',
    'Ability': [
      'No Guard',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ql6882oetu8w2ur0qkppc5cnfpkot14c'
  },
  {
    'ID': 3030,
    'Name': 'Mega Slowbro',
    'Ability': [
      'Shell Armor',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'y1va4zo0r8ctgbcwe8cewb2r5pkd5qua'
  },
  {
    'ID': 3031,
    'Name': 'Mega Steelix',
    'Ability': [
      'Sand Force',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '53ziuflpv6p3gb8j6llmfjygk008imu0'
  },
  {
    'ID': 3032,
    'Name': 'Mega Sceptile',
    'Ability': [
      'Lightning Rod',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '9n91r7w25bc5f0jtwf121j79338k46t2'
  },
  {
    'ID': 3033,
    'Name': 'Mega Swampert',
    'Ability': [
      'Swift Swim',
      'None',
      'None'
    ],
    'Gender': 87.5,
    'File': '5wzpv78klvdt06pvfag2b1e1530pv4jx'
  },
  {
    'ID': 3034,
    'Name': 'Mega Sableye',
    'Ability': [
      'Magic Bounce',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'mu5es4ijxqvg6zlxk719w33lzpwin4l5'
  },
  {
    'ID': 3035,
    'Name': 'Mega Sharpedo',
    'Ability': [
      'Strong Jaw',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '1sp0wpihf4qdditc60ivkfgsjkpuwgz0'
  },
  {
    'ID': 3036,
    'Name': 'Mega Camerrupt',
    'Ability': [
      'Sheer Force',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'aposzo8pe1attetv7saz2mgnt4k48rt0'
  },
  {
    'ID': 3037,
    'Name': 'Mega Altaria',
    'Ability': [
      'Pixilate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '8bsi3be5ldkt011rgy8s78x3tpe2ajlx'
  },
  {
    'ID': 3038,
    'Name': 'Mega Glalie',
    'Ability': [
      'Refrigerate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '2gnp1r9yko342z83a9scrw2k1zgi5mwc'
  },
  {
    'ID': 3039,
    'Name': 'Mega Salamence',
    'Ability': [
      'Aerilate',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ygebr1qwqe1dpzprmnduw4bbaih04kf9'
  },
  {
    'ID': 3040,
    'Name': 'Mega Metagross',
    'Gender': null,
    'Ability': [
      'Tough Claws',
      'None',
      'None'
    ],
    'File': 'ohaebfbs1o50n5q7zaw23az5l2xgv2w0'
  },
  {
    'ID': 3041,
    'Name': 'Mega Latias',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': null,
    'File': 'qcscuiql7xnl961jctb6zatjclaxzcjj'
  },
  {
    'ID': 3042,
    'Name': 'Mega Latios',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': '9erv12he7qu06qfl2lden0pmp6res82t'
  },
  {
    'ID': 3043,
    'Name': 'Mega Rayquaza',
    'Gender': null,
    'Ability': [
      'Delta Stream',
      'None',
      'None'
    ],
    'File': 'sqhpe9kpa7vzrxmgvp7tz4rzlthkt3xr'
  },
  {
    'ID': 3045,
    'Name': 'Mega Lopunny',
    'Ability': [
      'Scrappy',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'a82jc23hbkh3pmzi3wvy74uytpo7lld1'
  },
  {
    'ID': 3046,
    'Name': 'Mega Gallade',
    'Ability': [
      'Inner Focus',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': 'hwd1lce4qi9xjfb90bv9iwd9fttkryit'
  },
  {
    'ID': 3047,
    'Name': 'Mega Audino',
    'Ability': [
      'Healer',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'v3x0rrxvlxkcezuj5yu2t1k7fo0plrfp'
  },
  {
    'ID': 3048,
    'Name': 'Mega Diancie',
    'Gender': null,
    'Ability': [
      'Magic Bounce',
      'None',
      'None'
    ],
    'File': 'kta1gilto5hrf63qc4y22l0nmxro0f5n'
  },
  {
    'ID': 3049,
    'Name': 'Primal Kyogre',
    'Gender': null,
    'Ability': [
      'Primordial Sea',
      'None',
      'None'
    ],
    'File': 'uhsowk7jkf3kj04d3ua7nnrnwyppymt3'
  },
  {
    'ID': 3050,
    'Name': 'Primal Groudon',
    'Gender': null,
    'Ability': [
      'Desolate Land',
      'None',
      'None'
    ],
    'File': 'p9arfudon5znie3lygipcrqpzvd6e9wc'
  },
  {
    'ID': 3051,
    'Name': 'Hoopa Unbound',
    'Gender': null,
    'Ability': [
      '0',
      'Magician',
      'None'
    ],
    'File': 'ogga8y9xnnyu32h2d30oiruiwznw696h'
  },
  {
    'ID': 3052,
    'Name': 'Mewtwo',
    'Gender': null,
    'Ability': [
      'Unaware',
      'None',
      'None'
    ],
    'File': 'vz6rcclcil027my5g1ivu1j7jbgr9wo6'
  },
  {
    'ID': 3053,
    'Name': 'Crystal Onix',
    'Ability': [
      'Water Absorb',
      'None',
      'Filter'
    ],
    'Gender': null,
    'File': 'm5e9nbhdhgvfjye7446sm9p43elmqi8d'
  },
  {
    'ID': 3054,
    'Name': 'XD001',
    'Gender': null,
    'Ability': [
      'Multiscale',
      'Pressure',
      'Mold Breaker'
    ],
    'File': 'w14tddsozecaqn94w2sqj74fqrr4gmfv'
  },
  {
    'ID': 3057,
    'Name': 'Rattata-Alolan',
    'Ability': [
      'Gluttony',
      'Hustle',
      'Thick Fat'
    ],
    'Gender': 50,
    'File': 'p3gjyyps82ln035kvxss80tqxi5ljei6'
  },
  {
    'ID': 3058,
    'Name': 'Raticate-Alolan',
    'Ability': [
      'Gluttony',
      'Hustle',
      'Thick Fat'
    ],
    'Gender': 50,
    'File': '21fv8k51gluprsmi9afj29s5fryoafkr'
  },
  {
    'ID': 3059,
    'Name': 'Raichu-Alolan',
    'Ability': [
      'Static',
      'None',
      'Lightning Rod'
    ],
    'Gender': 50,
    'File': 'dnuemggx4a2zty05sw907dyhkjywpueh'
  },
  {
    'ID': 3060,
    'Name': 'Sandshrew-Alolan',
    'Ability': [
      'Snow Cloak',
      'None',
      'Slush Rush'
    ],
    'Gender': 50,
    'File': 'ihkezrpwwyibe7rja0y8zs8e72y7w3ox'
  },
  {
    'ID': 3061,
    'Name': 'Sandslash-Alolan',
    'Ability': [
      'Snow Cloak',
      'None',
      'Slush Rush'
    ],
    'Gender': 50,
    'File': 'nssyh9inpqkuldgdqranntsib05bjvji'
  },
  {
    'ID': 3062,
    'Name': 'Vulpix-Alolan',
    'Ability': [
      'Snow Cloak',
      'None',
      'Snow Warning'
    ],
    'Gender': 25,
    'File': 'q6gxmads1ovjvkq0dwb1502f363a5hfv'
  },
  {
    'ID': 3063,
    'Name': 'Ninetales-Alolan',
    'Ability': [
      'Snow Cloak',
      'None',
      'Snow Warning'
    ],
    'Gender': 25,
    'File': 'yyea2xk3jlyv7amsy89bdzjpwebqj54y'
  },
  {
    'ID': 3064,
    'Name': 'Diglett-Alolan',
    'Ability': [
      'Sand Veil',
      'Tangling Hair',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'ff7ln3nt6cehkmeahqaojiktqu54y1t3'
  },
  {
    'ID': 3065,
    'Name': 'Dugtrio-Alolan',
    'Ability': [
      'Sand Veil',
      'Tangling Hair',
      'Sand Force'
    ],
    'Gender': 50,
    'File': 'wm6t5h8tpdabs6crg9jp2lb0swjiijqe'
  },
  {
    'ID': 3066,
    'Name': 'Meowth-Alolan',
    'Ability': [
      'Pickup',
      'Technician',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'kzcv4juzildfzki2pd4ymmvxxb5pab60'
  },
  {
    'ID': 3067,
    'Name': 'Persian-Alolan',
    'Ability': [
      'Fur Coat',
      'Technician',
      'Rattled'
    ],
    'Gender': 50,
    'File': 'a19jvjhdsjmqi8va1hvfc3l6x5ebecdu'
  },
  {
    'ID': 3068,
    'Name': 'Geodude-Alolan',
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Galvanize'
    ],
    'Gender': 50,
    'File': 'zonykma1dehto758qermrcxz7kc9qxr2'
  },
  {
    'ID': 3069,
    'Name': 'Graveler-Alolan',
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Galvanize'
    ],
    'Gender': 50,
    'File': '6ejc2p9zzfnyw4pteo33kzkxkprzxsmb'
  },
  {
    'ID': 3070,
    'Name': 'Golem-Alolan',
    'Ability': [
      'Magnet Pull',
      'Sturdy',
      'Galvanize'
    ],
    'Gender': 50,
    'File': 'mbfnduz43e69tthdgb8c5kbrqanau9ss'
  },
  {
    'ID': 3071,
    'Name': 'Grimer-Alolan',
    'Ability': [
      'Poison Touch',
      'Gluttony',
      'Power of Alchemy'
    ],
    'Gender': 50,
    'File': '9apu3hkb1suv595c2f8rngub6msaqboh'
  },
  {
    'ID': 3072,
    'Name': 'Muk-Alolan',
    'Ability': [
      'Poison Touch',
      'Gluttony',
      'Power of Alchemy'
    ],
    'Gender': 50,
    'File': 'z41izdgm5lam1xl9fz00cik2e918jcbc'
  },
  {
    'ID': 3073,
    'Name': 'Exeggutor-Alolan',
    'Ability': [
      'Frisk',
      'None',
      'Harvest'
    ],
    'Gender': 50,
    'File': '0blk9lk0aml6xtc58hkzep4d70qw78yz'
  },
  {
    'ID': 3074,
    'Name': 'Marowak-Alolan',
    'Ability': [
      'Cursed Body',
      'Lightning Rod',
      'Rock Head'
    ],
    'Gender': 50,
    'File': 'j2aope9wr02ydxzy97ykb46svhpej3xo'
  },
  {
    'ID': 3075,
    'Name': 'Rotom-Heat',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'a7d0t5nbz2lk3kcxvyj2pydwpans4h21'
  },
  {
    'ID': 3076,
    'Name': 'Rotom-Wash',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'liwbs8lnjjdwdjfvms6l2irlioe9hxwg'
  },
  {
    'ID': 3077,
    'Name': 'Rotom-Frost',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': '8bord13cuhchb3crgs7zg1ngpr9lkubj'
  },
  {
    'ID': 3078,
    'Name': 'Rotom-Fan',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'f6qf35ml114u98ppvdg92l8l9lue8kdo'
  },
  {
    'ID': 3079,
    'Name': 'Rotom-Mow',
    'Gender': null,
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'File': 'ik5pvnikzgz2cbr1i6vvkcbq0tzfiv5l'
  },
  {
    'ID': 3080,
    'Name': 'Shaymin-Sky',
    'Gender': null,
    'Ability': [
      'Serene Grace',
      'None',
      'None'
    ],
    'File': 'h35jpvnyf87prtukm9cu18ct1h2v17a6'
  },
  {
    'ID': 3081,
    'Name': 'Burmy-Sandy',
    'Ability': [
      'Shed Skin',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'qzfctj25j5apm6xn1ya67vu2duj8t7s0'
  },
  {
    'ID': 3082,
    'Name': 'Burmy-Trash',
    'Ability': [
      'Shed Skin',
      'None',
      'Overcoat'
    ],
    'Gender': 50,
    'File': 'vmcfhf8svwev0s4d50szpq62mvdez146'
  },
  {
    'ID': 3083,
    'Name': 'Wormadam-Sandy',
    'Ability': [
      'Anticipation',
      'None',
      'Overcoat'
    ],
    'Gender': 0.01,
    'File': 'wq4n2x1awv7pv64z36g7crawemiky819'
  },
  {
    'ID': 3084,
    'Name': 'Wormadam-Trash',
    'Ability': [
      'Anticipation',
      'None',
      'Overcoat'
    ],
    'Gender': 0.01,
    'File': 'ef0wfqwpkn7olqfnp9tttf47hlsatl2t'
  },
  {
    'ID': 3085,
    'Name': 'Castform-Sunny',
    'Ability': [
      'Forecast',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'r0wtrq0aqbzh46ntnu430yorn443l0q9'
  },
  {
    'ID': 3086,
    'Name': 'Castform-Rainy',
    'Ability': [
      'Forecast',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'p0r439vufjrv84u7r46qlqwesuqve3l7'
  },
  {
    'ID': 3087,
    'Name': 'Castform-Snowy',
    'Ability': [
      'Forecast',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': '6ioid0vt8cew8ib6p7midwczj44wba22'
  },
  {
    'ID': 3088,
    'Name': 'Oricorio-Pau',
    'Ability': [
      'Dancer',
      'None',
      'None'
    ],
    'Gender': 25,
    'File': 'kx6gwnpr2ytkkdqu7xwktd1vywdf8pwg'
  },
  {
    'ID': 3089,
    'Name': 'Oricorio-Pom-Pom',
    'Ability': [
      'Dancer',
      'None',
      'None'
    ],
    'Gender': 25,
    'File': 'fpjmt3eq4mbxoildid8zlny1hpkz58dt'
  },
  {
    'ID': 3090,
    'Name': 'Oricorio-Sensu',
    'Ability': [
      'Dancer',
      'None',
      'None'
    ],
    'Gender': 25,
    'File': '2xehtc58seb9xai2ncgvog286eejtfqm'
  },
  {
    'ID': 3091,
    'Name': 'Lycanroc-Dusk',
    'Ability': [
      'Tough Claws',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': 'ikz2ini3n9gh271nlqphpqwm6t6okdap'
  },
  {
    'ID': 3092,
    'Name': 'Lycanroc-Midnight',
    'Ability': [
      'Keen Eye',
      'Vital Spirit',
      'No Guard'
    ],
    'Gender': 50,
    'File': 'rblm61dtsd02zbctbhxlhtv5bt9ng5dc'
  },
  {
    'ID': 3093,
    'Name': 'Deerling-Summer',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': '40xi74dio3umymduwhkwt3hiscstqxf4'
  },
  {
    'ID': 3094,
    'Name': 'Deerling-Autumn',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': 'k0em4qpjqnsd0abw22euyweu5ojoetvi'
  },
  {
    'ID': 3095,
    'Name': 'Deerling-Winter',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': 'ayaxcctzjy5fe0j5cpenxvrli3ra8xe7'
  },
  {
    'ID': 3096,
    'Name': 'Sawsbuck-Summer',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': 'sdg886q1zxvh50r4z3iznaus1jm6rj94'
  },
  {
    'ID': 3097,
    'Name': 'Sawsbuck-Autumn',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3098,
    'Name': 'Sawsbuck-Winter',
    'Ability': [
      'Sap Sipper',
      'Chlorophyll',
      'Serene Grace'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3099,
    'Name': 'Baby Lugia',
    'Ability': [
      'Marvel Scale',
      'None',
      'Multiscale'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3100,
    'Name': 'MissingNo.',
    'Ability': [
      '???',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3101,
    'Name': 'Landorus',
    'Ability': [
      'Intimidate',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3102,
    'Name': 'Thundurus',
    'Ability': [
      'Volt Absorb',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3103,
    'Name': 'Tornadus',
    'Ability': [
      'Regenerator',
      'None',
      'None'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3104,
    'Name': 'Aegislash',
    'Ability': [
      'Stance Change',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3105,
    'Name': 'Froakie',
    'Ability': [
      'Torrent',
      'None',
      'Protean'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3106,
    'Name': 'Frogadier',
    'Ability': [
      'Torrent',
      'None',
      'Protean'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3107,
    'Name': 'Greninja',
    'Ability': [
      'Torrent',
      'None',
      'Battle Bond'
    ],
    'Gender': 100,
    'File': ''
  },
  {
    'ID': 3108,
    'Name': 'Substitute',
    'Ability': [
      'None',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3109,
    'Name': 'Darmanitan-Zen',
    'Ability': [
      'Sheer Force',
      'None',
      'Zen Mode'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3111,
    'Name': 'Meowstic',
    'Ability': [
      'Keen Eye',
      'Infiltrator',
      'Competitive'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3112,
    'Name': 'Farfetchd-Galarian',
    'Ability': [
      'Steadfast',
      'None',
      'Scrappy'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3113,
    'Name': 'Weezing-Galar',
    'Ability': [
      'Levitate',
      'Neutralizing Gas',
      'Misty Surge'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3114,
    'Name': 'Mr. Mime-Galar',
    'Ability': [
      'Vital Spirit',
      'Screen Cleaner',
      'Ice Body'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3115,
    'Name': 'Corsola-Galar',
    'Ability': [
      'Weak Armor',
      'None',
      'Cursed Body'
    ],
    'Gender': 25,
    'File': ''
  },
  {
    'ID': 3116,
    'Name': 'Zigzagoon-Galar',
    'Ability': [
      'Pickup',
      'Gluttony',
      'Quick Feet'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3117,
    'Name': 'Linoone-Galar',
    'Ability': [
      'Pickup',
      'Gluttony',
      'Quick Feet'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3118,
    'Name': 'Darumaka-Galar',
    'Ability': [
      'Hustle',
      'None',
      'Inner Focus'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3119,
    'Name': 'Darmanitan-Galar',
    'Ability': [
      'Gorilla Tactics',
      'None',
      'Zen Mode'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3120,
    'Name': 'Darmanitan-Zen-Galar',
    'Ability': [
      'Zen Mode',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3121,
    'Name': 'Yamask-Galar',
    'Ability': [
      'Wandering Spirit',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3122,
    'Name': 'Stunfisk-Galar',
    'Ability': [
      'Mimicry',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3123,
    'Name': 'Zacian-Crowned',
    'Ability': [
      'Intrepid Sword',
      'NONE',
      'NONE'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3124,
    'Name': 'Zamazenta-Crowned',
    'Ability': [
      'Dauntless Shield',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3125,
    'Name': 'Eiscue-Noice',
    'Ability': [
      'Ice Face',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3126,
    'Name': 'Morpeko-Hangry',
    'Ability': [
      'Hunger Switch',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3127,
    'Name': 'Toxtricity-Low',
    'Ability': [
      'Punk Rock',
      'Minus',
      'Technician'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3128,
    'Name': 'Meowth-Galar',
    'Ability': [
      'Pickup',
      'Tough Claws',
      'Unnerve'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3129,
    'Name': 'Ponyta-Galar',
    'Ability': [
      'Run Away',
      'Pastel Veil',
      'Anticipation'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3130,
    'Name': 'Rapidash-Galar',
    'Ability': [
      'Run Away',
      'Pastel Veil',
      'Anticipation'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3132,
    'Name': 'Deoxys-Attack',
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3133,
    'Name': 'Deoxys-Defense',
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3134,
    'Name': 'Deoxys-Speed',
    'Ability': [
      'Pressure',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3135,
    'Name': 'Keldeo-Resolute',
    'Ability': [
      'Justified',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3136,
    'Name': 'Zygarde-10%',
    'Ability': [
      'Aura Break',
      'Power Construct',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3137,
    'Name': 'Zygarde-Complete',
    'Ability': [
      'Power Construct',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3138,
    'Name': 'Giratina-Origin',
    'Ability': [
      'Levitate',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3139,
    'Name': 'Kyurem-Black',
    'Ability': [
      'Teravolt',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3140,
    'Name': 'Kyurem-White',
    'Ability': [
      'Turboblaze',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3141,
    'Name': 'Meloetta-Pirouette',
    'Ability': [
      'Serene Grace',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3142,
    'Name': 'Arceus-Fighting',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3143,
    'Name': 'Arceus-Flying',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3144,
    'Name': 'Arceus-Poison',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3145,
    'Name': 'Arceus-Ground',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3146,
    'Name': 'Arceus-Rock',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3147,
    'Name': 'Arceus-Bug',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3148,
    'Name': 'Arceus-Ghost',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3149,
    'Name': 'Arceus-Steel',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3150,
    'Name': 'Arceus-Fire',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3151,
    'Name': 'Arceus-Water',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3152,
    'Name': 'Arceus-Grass',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3153,
    'Name': 'Arceus-Electric',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3154,
    'Name': 'Arceus-Psychic',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3155,
    'Name': 'Arceus-Ice',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3156,
    'Name': 'Arceus-Dragon',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3157,
    'Name': 'Arceus-Dark',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3158,
    'Name': 'Arceus-Fairy',
    'Ability': [
      'Multitype',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3159,
    'Name': 'Pumpkaboo-Small',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3160,
    'Name': 'Pumpkaboo-Large',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3161,
    'Name': 'Pumpkaboo-Super',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3162,
    'Name': 'Gourgeist-Small',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3163,
    'Name': 'Gourgeist-Large',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3164,
    'Name': 'Gourgeist-Super',
    'Ability': [
      'Pickup',
      'Frisk',
      'Insomnia'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3165,
    'Name': 'Wishiwashi-School',
    'Ability': [
      'Schooling',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3166,
    'Name': 'Silvally-Fighting',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3167,
    'Name': 'Silvally-Flying',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3168,
    'Name': 'Silvally-Poison',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3169,
    'Name': 'Silvally-Ground',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3170,
    'Name': 'Silvally-Rock',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3171,
    'Name': 'Silvally-Bug',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3172,
    'Name': 'Silvally-Ghost',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3173,
    'Name': 'Silvally-Steel',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3174,
    'Name': 'Silvally-Fire',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3175,
    'Name': 'Silvally-Water',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3176,
    'Name': 'Silvally-Grass',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3177,
    'Name': 'Silvally-Electric',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3178,
    'Name': 'Silvally-Psychic',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3179,
    'Name': 'Silvally-Ice',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3180,
    'Name': 'Silvally-Dragon',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3181,
    'Name': 'Silvally-Dark',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3182,
    'Name': 'Silvally-Fairy',
    'Ability': [
      'RKS System',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3183,
    'Name': 'Necrozma-Dusk-Mane',
    'Ability': [
      'Prism Armor',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3184,
    'Name': 'Necrozma-Dawn-Wings',
    'Ability': [
      'Prism Armor',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3185,
    'Name': 'Necrozma-Ultra',
    'Ability': [
      'Prism Armor',
      'None',
      'None'
    ],
    'Gender': null,
    'File': ''
  },
  {
    'ID': 3186,
    'Name': 'Mimikyu',
    'Ability': [
      'Disguise',
      'None',
      'None'
    ],
    'Gender': 50,
    'File': ''
  },
  {
    'ID': 3187,
    'Name': 'Baby XD001',
    'Ability': [
      'Marvel Scale',
      'None',
      'Multiscale'
    ],
    'Gender': null,
    'File': ''
  }
]
