<script>
import { Line } from 'vue-chartjs'

// const brandPrimary = '#20a8d8'
// const brandSuccess = '#4dbd74'
// const brandInfo = '#63c2de'
// const brandWarning = '#f8cb00'
// const brandDanger = '#f86c6b'

export default {
  extends: Line,
  props: ['data', 'height', 'width', 'variant', 'labels'],
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          backgroundColor: 'transparent',
          borderColor: this.variant ? this.variant : '#c2cfd6',
          data: this.data
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 10
        }
      },
      scales: {
        xAxes: [{
          display: false
        }],
        yAxes: [{
          display: false
        }]
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3
        }
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip')
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
            tooltipEl.innerHTML = '<table></table>'
            document.body.appendChild(tooltipEl)
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }
          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
            tooltipEl.classList.add('no-transform')
          }
          function getBody (bodyItem) {
            return bodyItem.lines
          }
          if (tooltipModel.body) {
            let titleLines = tooltipModel.title || []
            let bodyLines = tooltipModel.body.map(getBody)

            let innerHtml = '<thead>'

            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>'
            })
            innerHtml += '</thead><tbody>'

            bodyLines.forEach(function (body, i) {
              let colors = tooltipModel.labelColors[i]
              let style = 'background:' + colors.backgroundColor
              style += ' border-color:' + colors.borderColor
              style += ' border-width: 2px'
              let span = '<span style="' + style + '"></span>'
              innerHtml += '<tr><td>' + span + body + '</td></tr>'
            })
            innerHtml += '</tbody>'

            let tableRoot = tooltipEl.querySelector('table')
            tableRoot.innerHTML = innerHtml
          }

          // `this` will be the overall tooltip
          let position = this._chart.canvas.getBoundingClientRect()

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
          tooltipEl.style.top = position.top + tooltipModel.caretY + 'px'
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
        }
      }
    })
  }
}
</script>
