<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-user"></i></span>
    </div>
    <input class="form-control" v-on:keyup="validUsername" v-model="user" :disabled="userLock" :class="error ? 'is-invalid' : ''" :readonly="allowed" type="text"
           id="username" title="">
    <span class="input-group-append"><button class="btn btn-primary" :disabled="userLock || error != null || allowed" @click="changeUsername" type="button">Change</button></span>
    <div class="invalid-feedback">{{error}}</div>

  </div>
</template>
<script>
  import VueNotifications from 'vue-notifications'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'username',
    props: ['user', 'userid', 'allowed'],
    data () {
      return {
        error: null
      }
    },
    computed: {
      ...mapGetters([
        'userLock'
      ])
    },
    methods: {
      validUsername () {
        if (this.user.length < 3) {
          this.error = 'This username is too short.'
        } else if (this.user.length > 15) {
          this.error = 'This username is too long.'
        } else if (!this.isAlphaNumeric(this.user)) {
          this.error = 'The use of non-alphanumeric characters is not allowed.'
        } else {
          this.error = null
        }
      },
      isAlphaNumeric (str) {
        let code, i, len
        for (i = 0, len = str.length; i < len; i++) {
          code = str.charCodeAt(i)
          if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false
          }
        }
        return true
      },
      changeUsername () {
        this.setLock(true)
        this.ChangeInfo()
        this.emit('changeUsername', {'new_username': this.user, 'username': this.$route.params.username})
        this.setUsername(this.user)
      },
      ...mapActions(['setLock', 'setUsername'])
    },
    notifications: {
      ChangeInfo: {
        type: VueNotifications.types.info,
        title: 'Rename in progress!',
        message: 'This may take several seconds, please be patient.'
      }
    }
  }
</script>
