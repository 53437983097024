<template>

  <div class="wrapper">
    <div class="animated fadeIn">
      <h5 v-if="!connections" class="text-center">Loading...</h5>
      <div v-else>
      <b-row >

        <b-col cols="12" lg="3" md="6">

          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-wifi"></i> <strong>IP</strong>
            </div>
            <div>
              <CountRecords :records="connections.ip_connections"/>
            </div>
          </b-card>
        </b-col>
        <b-col  cols="12" lg="3"  md="4">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-sign-in"></i> <strong>Registration IP</strong>
            </div>
            <div>
              <UsernameRecords :records="connections.reg_ip_connections"/>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" lg="3"  md="4">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-laptop"></i><strong>MAC</strong>
              <div class="card-actions">
                <server-menu :username="this.$route.params.username" :server.sync="connections.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>
              <CountRecords :records="connections.mac_connections"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
      <b-col cols="12" lg="3"  md="4">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-envelope"></i> <strong>Email</strong>
            </div>
            <div>
              <UsernameRecords :records="connections.email_connections"/>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" lg="3"  md="4">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-exchange"></i> <strong>Trades</strong>
            </div>
            <div>
              <CountRecords :records="connections.trade_connections"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
      </div>
    </div>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import UsernameRecords from './UsernameRecords'
  import CountRecords from './CountRecords'
  import { mapGetters } from 'vuex'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'Variables',
    components: {
      ServerMenu,
      CountRecords,
      UsernameRecords
    },
    computed: mapGetters({
      connections: 'allConnections'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getConnections', {'username': this.$route.params.username, 'server': this.$route.query.server})
    },
    methods: {
      switchServer (index) {
        if (index in this.cache) {
          this.loadData(this.cache[index])
        } else {
          this.$router.push({ path: 'connections', query: { server: index } })
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getConnections', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
