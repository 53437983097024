// initial state
const state = {
  all: [],
  count: 0,
  news: null
}

// getters
const getters = {
  Newslog: state => state.all,
  NewsLogCount: state => state.count,
  News: state => state.news

}

// actions
const actions = {
  getNewslog ({ commit }, trades) {
    commit('setNewslog', trades.data)
  },
  getNews ({ commit }, trades) {
    commit('setNews', trades.data)
  },
  clearNewslog ({ commit }) {
    commit('clearNewslog')
  }
}

// mutations
const mutations = {
  setNewslog (state, log) {
    state.all = log.entries
    if (log.count > 0) {
      state.count = log.count
    }
  },
  setNews (state, news) {
    state.news = news
  },
  clearNewslog (state) {
    state.all = []
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
