 <template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col xl="6" md="12">
          <b-card v-if="userData"
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-user"></i> <strong>User</strong>
              <span v-if="userData.security_id in roles" class="badge badge-primary">{{roles[userData.security_id].name}}</span>
              <span :class="userData.online ? 'badge-success' : 'badge-danger'" class="badge">{{userData.online ? 'Online' : 'Offline'}}</span>
              <span v-if="userData.banned === 'P'" class="badge badge-danger">Banned</span>
              <span v-if="servers[userData.server].readonly" class="badge badge-danger">Read-only</span>
              <div class="card-actions">
                <server-menu :username="this.$route.params.username" :server.sync="userData.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>

            <b-media>
              <template #aside>
                <img class="img-thumbnail"
                     :src="'https://character.pokemonrevolution.net/' + style()"/>
              </template>
              <div>
                <div class="form-group row ml-1">
                  <div class="col-md-4">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">ID</span>
                      </div>
                      <input class="form-control" readonly name="input2-group1" type="text" :value="userData.id" data-cip-id="input2-group1">
                    </div>
                  </div>
                  <div class="col-md-8">

                    <div class="form-group row ml-1">
                      <username :allowed="!hasPermission(UserNameChange)"  :userid="userData.id" :user="userData.username"/>
                    </div>
                  </div>
                  </div>


              </div>
            </b-media>
              <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Guild</span>
                    </div>
                    <input class="form-control" readonly name="input2-group1" type="text" :value="userData.guild" data-cip-id="input2-group1">
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Discord</span>
                    </div>
                    <input class="form-control" readonly name="input2-group1" type="text" :value="userData.discord_tag" data-cip-id="input2-group1">
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="authUser.security === 1 || authUser.security === 5">
                <div class="col-md-6 col-sm-12">
                  <role :user="userData.username" :current-role="userData.security_id" :key="userData.id"></role>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-8">
                  <email :readonly="servers[userData.server].readonly || !hasPermission(UserIPsEmailView)" :allowed="!hasPermission(UserEmailChange)" :username="userData.username" :email="userData.email"/>
                </div>
                <div class="col-md-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-gamepad"></i></span>
                    </div>
                    <input readonly class="form-control" :value=convertMinsToHrsMins(userData.playtime) type="text" data-cip-id="input1-group1">
                  </div>

                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <money :allowed="!hasPermission(UserMoneyChange)" :userid="userData.id" :price="userData.money" :server="userData.server"/>
                </div>
                <div class="col-md-6">
                  <coins :allowed="!hasPermission(UserCoinChange)" :userid="userData.id" :coins="userData.coins" :server="userData.server"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-5">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-id-card"></i></span>
                    </div>
                    <input class="form-control" readonly :value="userData.reg_date"
                           type="text" data-cip-id="input1-group1">
                    <span class="input-group-append">
                 </span>
                  </div>
                </div>
                <div class="col-md-7 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Membership</span>
                    </div>
                    <input class="form-control" readonly :value="userData.member_expire" type="text" data-cip-id="input1-group1">
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-7">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Last online</span>
                    </div>
                    <input class="form-control"  readonly name="input2-group1" type="text" :value="userData.last_active" data-cip-id="input2-group1">
                  </div>
                </div>
                <div class="col-md-5 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Baninfo</span>
                    </div>
                    <input class="form-control" readonly :value="userData.banInfo" type="text" data-cip-id="input1-group1">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group">

                    <div class="input-group-prepend">
                      <span class="input-group-text">Login Message</span>
                    </div>
                    <input id="basicTextarea" v-model="loginMessage" :disabled="servers[userData.server].readonly || userLock" type="text" placeholder="Message..." class="form-control" data-cip-id="basicTextarea">
                    <span class="input-group-append">
                   <button class="btn btn-primary" @click="setLoginMessage" :disabled="servers[userData.server].readonly || !hasPermission(UserLoginMessage) || userLock || loginMessage.length === 0" type="button">Send</button>
                </span>
                  </div>
                </div>

              </div>
<br>
              <div class="form-group">
                <a v-if="servers[userData.server].readonly" :disabled="!userData.merge_token || userLock" :class="!userData.merge_token || userLock ? 'disabled' : ''" target="_blank" class="btn btn-teal" :href="'https://pokemonrevolution.net/merge/?token=' + userData.merge_token"><i class="fa fa-bolt" ></i> Force merge</a>
                <template v-else-if="!servers[userData.server].test">
                  <button v-for="(item, index) in servers" :disabled="!hasPermission(UserServerTransfer) || userLock"
                          :key="index" class="btn btn-teal" v-if="index !== userData.server && !item.readonly && !item.test" @click="transferServer(index)"><i class="fa fa-exchange"></i> Server transfer
                  </button>
                </template>
                <b-button v-if="userData.server === 'silver'" class="btn" type="button" variant="purple" :disabled="!hasPermission(UserUnmerge) || userLock" @click="showUnmergeModal"> <i class="fa fa-user-times"></i> Unmerge</b-button>
                <b-button class="btn" type="button" variant="primary" :disabled="!hasPermission(MoveClothesToInventory) || userLock" @click="moveClothesToInventory"> <i class="fa fa-eject"></i> Move clothes To inventory</b-button>
                <b-button class="btn" type="button" variant="danger" :disabled="!hasPermission(UserReset) || userLock" @click="showModal"> <i class="fa fa-rotate-left"></i> Reset Account</b-button>

              </div>
            </div>
          </b-card>
        </b-col>
        <b-col xl="6" md="12">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-globe"></i> <strong>Last IPs</strong>

            </div>
            <div>
              <IPHistory v-if="hasPermission(UserIPsEmailView) && userData.ip_history" :records="userData.ip_history" :server="userData.server"/>
              <h5 class="text-center" v-else>You don't have the permission to see IP's!</h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="12">
          <PvP :user-data="userData" :userLock="userLock" :servers="servers"></PvP>
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-lock"></i> <strong>Ban</strong>

            </div>
            <div>
              <div class="form-group row">

                <div class="col-md-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Reason</span>
                    </div>
                    <input v-model="reason" class="form-control" name="input2-group1"
                           placeholder="Reason..." type="text" data-cip-id="input2-group1">
                  </div>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-8">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Type</span>
                    </div>
                    <select id="basicSelect" v-model="selectBan" class="form-control" data-cip-id="cIPJQ342845643">
                      <option value="">Please select</option>
                      <option value="P">Permanent</option>
                      <option value="T">Temporary</option>
                      <option value="E">Trade</option>
                      <option value="M">Quiet</option>
                      <option  :disabled="!hasPermission(UserBanMac)" value="A">MAC</option>
                      <option value="X">PVP</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Days</span>
                    </div>
                    <input class="form-control"
                           placeholder="Days..." v-model="days" :disabled="selectBan === 'P' || selectBan === 'S' || selectBan === 'A' || selectBan === ''" type="number" data-cip-id="input2-group1">
                  </div>
                </div>
              </div>
              <div class="form-group row">

                <div class="col-md-12">
                  <div class="input-group ">
                    <div id="basicInlineCustomCheckboxes" role="group" class="">
                      <div class="custom-control active custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                                      id="customInChk1"
                                                                                                      value="silver"
                                                                                                      v-model="checked"
                                                                                                      class="custom-control-input">
                        <label for="customInChk1" class="custom-control-label">Silver</label></div>
                      <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                               id="customInChk2"
                                                                                               value="gold"
                                                                                               v-model="checked"
                                                                                               class="custom-control-input">
                        <label for="customInChk2" class="custom-control-label">Gold</label></div>
                      <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                               id="customInChk3"
                                                                                               value="red"
                                                                                               v-model="checked"
                                                                                               class="custom-control-input">
                        <label for="customInChk3" class="custom-control-label">Red</label></div>
                      <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                               id="customInChk4"
                                                                                               value="blue"
                                                                                               v-model="checked"
                                                                                               class="custom-control-input">
                        <label for="customInChk4" class="custom-control-label">Blue</label></div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-danger" @click="banUser" :disabled="!hasPermission(UserBan) || userLock || selectBan === '' || this.checked.length === 0 || (selectBan !== 'P' && selectBan !== 'A' && days <= 0)" type="submit" ><i class="fa fa-ban"></i> Ban</button>
              <button class="btn btn-primary" @click="unBan" :disabled="!hasPermission(UserBan) || userData.banned === '' || userLock || this.checked.length === 0"><i class="fa fa-dot-circle-o"></i> Unban</button>
            </div>
          </b-card>
        </b-col>
        <b-col  xl="6" md="12">
          <UserNotes y v-if="hasPermission(UserNotesView)" :note="userData.note" :userLock="userLock"  :key="userData.id"></UserNotes>
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-history"></i> <strong>History</strong>
            </div>
            <div>
              <BanHistory :records="userData.ban_history" v-if="hasPermission(UserInfractionView)"/>
              <h5 class="text-center" v-else>You don't have the permission to see Infractions!</h5>

            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal title="Are you absolutely sure?" v-model="resetModal">
      <p class="text-center"><mark>Unexpected bad things will happen if you don't read this!</mark></p>
      <p>This action <b>cannot</b> be undone. This will permanently delete all pokemon, money, coins, items, variables and pokedex entries from <b>{{userData.username}}</b>'s account.</p>
      <p>Please type in the username you want to reset to confirm.</p>
      <input class="form-control" v-model="resetInput"  ref="resetUsername" id="name" type="text" autofocus/>
      <div slot="modal-footer" class="w-100">
        <b-btn size="large" class="btn-block" variant="danger" :disabled="resetInput.toLowerCase() !== userData.username.toLowerCase()" @click="wipeAccount">
          I understand the consequences, reset this account
        </b-btn>
      </div>
    </b-modal>
    <b-modal title="Are you absolutely sure?" v-model="unmergeModal">
      <p class="text-center"><mark>Unexpected bad things will happen if you don't read this!</mark></p>
      <p>This action <b>cannot</b> be undone. This will unmerge <b>{{userData.username}}</b>'s account. This means that everything related to this account will be permanently deleted on <b>Silver</b> server.</p>
      <p>Please type in the username you want to unmerge to confirm.</p>
      <input class="form-control" v-model="unmergeInput"  ref="unmergeUsername" type="text" autofocus/>
      <div slot="modal-footer" class="w-100">
        <b-btn size="large" class="btn-block" variant="danger" :disabled="unmergeInput.toLowerCase() !== userData.username.toLowerCase()" @click="unmergeUser">
          I understand the consequences, unmerge this account
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import IPHistory from './IPHistory'
  import BanHistory from './BanHistory'
  import PvP from './pvp'
  import UserNotes from './UserNotes.vue'
  import Username from './Username'
  import Email from './Email'
  import Coins from './Coins'
  import Money from './Money'
  import Role from './Role'
  import { mapGetters, mapActions } from 'vuex'
  import VueNotifications from 'vue-notifications'
  import Roles from '../../../_roles'
  import iziToast from 'izitoast'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'Base',
    components: {
      ServerMenu,
      IPHistory,
      Username,
      Money,
      Email,
      Coins,
      PvP,
      UserNotes,
      Role,
      BanHistory
    },
    computed: mapGetters({
      userData: 'userData',
      authUser: 'authUser',
      userError: 'userError',
      userLock: 'userLock',
      newUsername: 'newUsername'
    }),
    data () {
      return {
        id: 0,
        resetModal: false,
        resetInput: '',
        rating: 0,
        unmergeInput: '',
        servers: Servers,
        cache: {},
        unmergeModal: false,
        loginMessage: '',
        newUser: null,
        roles: Roles,
        checked: ['gold', 'silver'],
        selectBan: '',
        days: 0,
        reason: ''
      }
    },
    created () {
      this.emit('getUserData', {'username': this.$route.params.username, 'server': this.$route.query.server})
    },
    watch: {
      userError: function (e) {
        this.serverError(e)
      },
      userData: function (e) {
        this.rating = e.rating
      }
    },
    methods: {
      showModal () {
        this.resetInput = ''
        this.resetModal = true
      },
      showUnmergeModal () {
        this.resetInput = ''
        this.unmergeModal = true
      },
      wipeAccount () {
        this.resetModal = false
        this.emit('wipeUser', {'id': this.userData.id, 'server': this.userData.server})
      },
      serverError (val) {
        switch (val.error) {
          case 3:
            this.UsernameTaken()
            break
          case 1:
            this.ChangeSuccessful()
            break
          case 2:
            this.UserOnline()
            break
          case 5:
            this.TransferErrorNotMerged()
            break
          case 7:
            this.OpenLendingTrades()
            break
          case 4:
            this.UserNotFound()
            return
          case 2002:
            this.UnmergeTradeError()
            break
          case 2003:
            this.UnmergeError()
            break
          case 2010:
            this.UnmergeTransferError()
            break
        }
        if (val.error === 1 && this.newUsername !== null) {
          this.$router.push('/user/' + this.newUsername + '/base')
          this.emit('getUserData', {'username': this.newUsername, 'server': this.$route.query.server})
        } else {
          this.emit('getUserData', {'username': this.$route.params.username, 'server': this.$route.query.server})
        }
        this.setUsername(null)
        this.setLock(false)
      },
      switchServer (index) {
        this.$router.push({ path: 'base', query: { server: index } })
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      convertMinsToHrsMins (mins) {
        let h = Math.floor(mins / 60)
        let m = mins % 60
        h = h < 10 ? '0' + h : h // (or alternatively) h = String(h).padStart(2, '0')
        m = m < 10 ? '0' + m : m // (or alternatively) m = String(m).padStart(2, '0')
        return `${h}h:${m}m`
      },
      style () {
        return btoa(this.userData.style)
      },
      moveClothesToInventory () {
        this.emit('moveClothesToInventory', {
          username: this.userData.username,
          server: this.userData.server
        })
        this.setLock(true)
      },

      unmergeUser () {
        this.unmergeModal = false
        this.emit('unmergeUser', this.$route.params.username)
      },
      transferServer (targetServer) {
        let self = this
        iziToast.question({
          timeout: 20000,
          close: true,
          overlay: true,
          toastOnce: true,
          id: 'questionTransfer',
          zindex: 99999,
          message: 'Are you sure that you want to transfer <strong>' + this.$route.params.username + '</strong> to <strong>' + self.capitalizeFirstLetter(targetServer) + '</strong>?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              iziToast.hide({}, toast)
              self.TransferProgress()
              self.setLock(true)
              self.emit('serverTransfer', {
                username: self.userData.username,
                target_server: targetServer,
                source_server: self.userData.server
              })
            }, true]
          ]
        })
      },
      setLoginMessage () {
        this.emit('setLoginMessage', {
          id: this.userData.id,
          value: this.loginMessage,
          server: this.userData.server
        })
        this.setLock(true)
      },
      banUser () {
        this.emit('banUser', {
          username: this.userData.username,
          type: this.selectBan,
          days: Number(this.days),
          servers: this.checked,
          reason: this.reason
        })
        this.setLock(true)
      },
      unBan () {
        this.emit('unBan', {
          username: this.userData.username,
          servers: this.checked,
          reason: this.reason
        })
        this.setLock(true)
      },
      ...mapActions(['setLock', 'setUsername'])
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      },
      UsernameTaken: {
        type: VueNotifications.types.error,
        title: 'Rename failed!',
        message: 'This username is already taken.'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      UserNotFound: {
        type: VueNotifications.types.error,
        title: 'Username not found!',
        message: 'Username doesn\'t exist.'
      },
      TransferProgress: {
        type: VueNotifications.types.info,
        title: 'Transfer in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      NotMerged: {
        type: VueNotifications.types.error,
        title: 'This account was not merged yet!',
        message: 'You might want to check Red and Blue!'
      },
      TransferErrorNotMerged: {
        type: VueNotifications.types.error,
        title: 'Server Transfer failed!',
        message: ' This account was not merged yet!'
      },
      UnmergeError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'Unmerge failed somehow!'
      },
      UnmergeTradeError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'This user has trades on Silver.'
      },
      UnmergeTransferError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'This user already transferred servers.'
      },
      OpenLendingTrades: {
        type: VueNotifications.types.error,
        title: 'Transfer failed!',
        message: 'You cannot transfer a user that has a pending lending trade.'
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getUserData', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
