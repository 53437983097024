import Vue from 'vue'
import Router from 'vue-router'
// Containers
import Full from '@/containers/Full'
// Views
import Dashboard from '@/views/Dashboard'

import User from '@/views/user/base'
import Permission from '@/views/admin/Permissions'
import Auditlog from '@/views/admin/Auditlog'
import Pokemon from '@/views/pokemon/'
import Inventory from '@/views/user/inventory'
import Variables from '@/views/user/variables'
import Pokedex from '@/views/user/pokedex'
import Connections from '@/views/user/connections'
import Tradelog from '@/views/user/tradelog'
import PVPlog from '@/views/user/pvplog'
import Massban from '@/views/admin/Massban'
import Debug from '@/views/admin/Debug'
import Userlog from '@/views/userlog'
import Snapshots from '@/views/user/snapshots'
import ListNews from '@/views/news/List'
import CreateNews from '@/views/news/Create'
import EditNews from '@/views/news/Edit'
import GlobalVars from '@/views/globalvars/'
import History from '@/views/user/history'
import Chatlog from '@/views/chatlog'
import Guild from '@/views/user/guild'
import Watchlist from '@/views/gm/watchlist'
import ToolsLog from '@/views/admin/ToolsLog'
import ViewSnapshot from '@/views/user/snapshot'
import SuspectMatches from '@/views/gm/suspect_matches.vue'
// Views - Pages
import Page404 from '@/views/pages/Page404'
import Login from '@/views/pages/Login'
import store from '../store'
import MailLog from "@/views/admin/MailLog.vue";

Vue.use(Router)

export default new Router({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      beforeEnter: requireAuth,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          beforeEnter: requireAuth
        },
        {
          path: 'userlog',
          name: 'Userlog',
          component: Userlog,
          beforeEnter: requireAuth
        },
        {
          path: 'pokemon',
          name: 'Pokemon',
          component: Pokemon,
          beforeEnter: requireAuth
        },
        {
          path: 'globalvars',
          name: 'Global Vars',
          component: GlobalVars,
          beforeEnter: requireAuth
        },
        {
          path: 'admin',
          name: 'Admin',
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'permission',
              name: 'Permission',
              component: Permission,
              beforeEnter: requireAuth
            },
            {
              path: 'auditlog',
              name: 'Auditlog',
              component: Auditlog,
              beforeEnter: requireAuth
            },
            {
              path: 'toolslog',
              name: 'ToolsLog',
              component: ToolsLog,
              beforeEnter: requireAuth
            },
            {
              path: 'MailLog',
              name: 'Mail Log',
              component: MailLog,
              beforeEnter: requireAuth
            },
            {
              path: 'chatlog',
              name: 'Chat log',
              component: Chatlog,
              beforeEnter: requireAuth
            },
            {
              path: 'massban',
              name: 'Massban',
              component: Massban,
              beforeEnter: requireAuth
            },
            {
              path: 'debug',
              name: 'Debug',
              component: Debug,
              beforeEnter: requireAuth
            }
          ]
        },
        {
          path: 'news',
          name: 'News',
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'News',
              component: ListNews,
              beforeEnter: requireAuth
            },
            {
              path: 'create',
              name: 'Write News',
              component: CreateNews,
              beforeEnter: requireAuth
            },
            {
              path: 'edit/:id',
              name: 'Edit News',
              component: EditNews,
              beforeEnter: requireAuth
            }
          ]
        },
        {
          path: 'gm',
          name: 'GM',
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'watchlist',
              name: 'Watchlist',
              component: Watchlist,
              beforeEnter: requireAuth
            },
            {
              path: 'suspect_matches',
              name: 'Suspect Matches',
              component: SuspectMatches,
              beforeEnter: requireAuth
            },
          ]
        },
        {
          path: '/user/:username',
          name: 'User',
          component: {
            render (c) {
              return c('router-view')
            }
          },
          redirect: '/user/:username/base',
          children: [
            {
              path: 'base',
              name: 'Base',
              component: User,
              beforeEnter: requireAuth
            },
            {
              path: 'history',
              name: 'History',
              component: History,
              beforeEnter: requireAuth
            },
            {
              path: 'inventory',
              name: 'Inventory',
              component: Inventory,
              beforeEnter: requireAuth
            },
            {
              path: 'variables',
              name: 'Variables',
              component: Variables,
              beforeEnter: requireAuth
            },
            {
              path: 'connections',
              name: 'Connections',
              component: Connections,
              beforeEnter: requireAuth
            },
            {
              path: 'tradelog',
              name: 'Tradelog',
              component: Tradelog,
              beforeEnter: requireAuth
            },
            {
              path: 'pvplog',
              name: 'PVP log',
              component: PVPlog,
              beforeEnter: requireAuth
            },
            {
              path: 'guild',
              name: 'Guild History',
              component: Guild,
              beforeEnter: requireAuth
            },
            {
              path: 'pokedex',
              name: 'Pokedex',
              component: Pokedex,
              beforeEnter: requireAuth
            },
            {
              path: 'snapshots',
              name: 'Snapshots',
              component: Snapshots,
              beforeEnter: requireAuth
            },
            {
              path: 'snapshot/:id',
              name: 'View Snapshot',
              component: ViewSnapshot,
              beforeEnter: requireAuth
            },
          ]
        }
      ]
    },
    {
      path: '/auth',
      name: 'Auth',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {path: '*', component: Page404}

  ]
})



function requireAuth (to, from, next) {
  function proceed () {
    if (store.getters.authUser) {
        next()
    } else {
      next({
        path: '/auth/login',
        query: {redirect: to.fullPath}
      })
    }
  }
  if (store.getters.authUser === null) {
    store.watch(
      function (state) {
        return state.socket.user
      },
      function (val) {
        proceed()
      })
  } else {
    proceed()
  }
}
