// initial state
const state = {
  all: null,
  error: 0
}

// getters
const getters = {
  allInventory: state => state.all,
  itemError: state => state.error
}

// actions
const actions = {
  getInventory ({ commit }, data) {
    commit('setInventory', data)
  },
  setError ({commit}, data) {
    commit('setError', data.data)
  }
}

// mutations
const mutations = {
  setInventory (state, all) {
    state.all = all
  },
  setError (state, error) {
    state.error = {error: error, time: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
