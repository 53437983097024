<template>
  <div class="wrapper">
    <div class="animated fadeIn">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-legal"></i> <strong>Suspect Matches</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
              <div class="card-actions">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <template v-for="(item, index) in servers" v-if="!item.readonly">
                    <button type="button" @click="switchServer(index)"
                            :class="['btn-' + item.colour, activeServer === index ? 'active' : '']"
                            class="btn btn-small">{{capitalizeFirstLetter(index)}}
                    </button>
                  </template>
                </div>
              </div>
            </div>

            <div>
              <ag-grid-vue style='width: 100%; height: 700px' class='ag-theme-alpine-dark tradelog'
                           :gridOptions='gridOptions'
                           :enableColResize="true"
                           :enableFilter="true"
                           :masterDetail="true"
                           :rowData="pvplogs.data"
                           :groupHeaders="true"
                           @first-data-rendered="onFirstDataRendered"
                           :getContextMenuItems="getContextMenuItems">
              </ag-grid-vue>
            </div>
          </b-card>
    </div>
    <b-modal :title="modalTitle" v-model="sharedIPs">
      <div class="list-group">
            <span v-for="ip in sharedIPs" class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <div class="mb-1"><span class="h6">{{ip.username}}  <span v-if="ip.banned === 'P'" class="badge badge-danger">Banned</span></span></div>
                <small>{{formatDate(ip.date)}}</small>
              </div>
              <div class="sharedInfo">
                <small>
                  Matches: {{ip.count}}<br>
                  Email: {{ip.email}}<br>
                  Registration: {{ip.reg_date}} ({{formatMinutes(ip.play_time)}})<br>
                  <strong class="float-right">${{formatPrice(ip.money)}}</strong>
                  PVP Ranking W/L: {{ip.ranked_wins}}/{{ip.ranked_losses}}, Rating: {{ip.ranked_score}}<br>
                </small>
              </div>
            </span>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>
  </div>
</template>
<script>
import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-enterprise'
import Servers from '../../_servers'
import moment from 'moment'
import {mapGetters} from 'vuex'
import ServerMenu from '@/components/Servernenu.vue'
import {DefaultGridOptions} from "@/default_table_settings";

  const None	= 0
  const  PvP	= 1
  const  Wild	 = 1 << 1
  const  Trainer  = 1 << 2
  const  Ranked   = 1 << 3
  const  Random	 = 1 << 4
  const  Friendly = 1 << 5
  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        disableRecover: false,
        disableDelete: false,
        activeServer: Object.keys(Servers)[0],
        disableTransfer: false,
        modalTitle: '',
        cb: null,
        loading: false,
        lock: false,
        servers: Servers,
      }
    },
    computed: mapGetters({
      pvplogs: 'PVPlogs',
      sharedIPs: 'sharedIPs'
    }),
    components: {
      ServerMenu,
      'ag-grid-vue': AgGridVue
    },
    methods: {
      onFirstDataRendered(params) {
        params.api.forEachNode(function (node) {
        });
      },
      switchServer (index) {
        this.activeServer = index
        this.$router.push({ path: 'suspect_matches', query: { server: index } })
        this.loading = true
      },
      formatDate (date) {
        if(!date.value)
          return ''
        return moment(date.value).format('DD/MM/YYYY, HH:mm:ss')
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      formatMinutes (minutes) {
        let h = Math.floor(minutes / 60)
        let m = minutes % 60
        if (h <= 0) {
          return m + 'm'
        } else {
          return h + 'h'
        }
      },
      formatPrice (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      checkPermission (perm) {
        return perm.and(this.authUser.permission).neq(0)
      },
      createColumnDefs () {
        return [
          {
            headerName: 'ID',
            field: 'ID',
            hide: true
          },
          {
            headerName: "User",
            valueGetter: (params) => {
              return params.data.user.name;

            }
          },
          {
            headerName: 'Opponent',
            valueGetter: (params) => {
              return params.data.opponent.name;

            }
          },
          {
            headerName: "User IP",
            valueGetter: (params) => {
              return params.data.user.IP;

            }
          },
          {
            headerName: "User MAC",
            valueGetter: (params) => {
              return params.data.user.MAC;
            },
          },
          {
            headerName: "User Rating Change",
            valueGetter: (params) => {
              console.log(params.data.user)
              return params.data.user.ratingChange;
            },
          },
          {
            headerName: "User Team",
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              let pokes = []
              for (const poke of Object.values(params.data.user.pokemon)) {
                pokes.push(`${poke.name} (${poke.ability}, '${poke.heldItem}') ${poke.skills.join('; ')}'`)
              }
              return (pokes) ? pokes.join('\n') : ''
            },
            cellRenderer: (params) => {
              if (params.value) {
                return params.value.replace(/\n/g, '</br>')
              }
            },
            autoHeight: true
          },
          {
            headerName: "Opponent Team",
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              let pokes = []
              for (const poke of Object.values(params.data.opponent.pokemon)) {
                pokes.push(`${poke.name} (${poke.ability}, '${poke.heldItem}') ${poke.skills.join('; ')}'`)
              }
              return (pokes) ? pokes.join('\n') : ''
            },
            cellRenderer: (params) => {
              if (params.value) {
                return params.value.replace(/\n/g, '</br>')
              }
            },
            autoHeight: true
          },
          {
            headerName: "Opponent IP",
            valueGetter: (params) => {
              return params.data.opponent.IP;
            }
          },
          {
            headerName: "Opponent MAC",
            valueGetter: (params) => {
              return params.data.opponent.MAC;
            }
          },
          {
            headerName: "Opponent Rating Change",
            valueGetter: (params) => {
              console.log(params.data.user)
              return params.data.opponent.ratingChange;
            },
          },
          {
            headerName: 'Battle Start',
            field: 'startTime',
            valueFormatter: this.formatDate

          },
          {
            headerName: 'Battle End',
            field: 'endTime',
            filter: 'agDateColumnFilter',
            valueFormatter: this.formatDate
          },
          {
            headerName: 'Duration',
            filter: 'agNumberColumnFilter',
            valueGetter: (params) => {
              let end_time = moment(params.data.endTime)
              if(params.data.startTime) {
                let start_time = moment(params.data.startTime)
                let dif = moment.duration(end_time.diff(start_time));
                return moment.utc(dif.as('milliseconds')).format('HH:mm:ss')
              }
              return ''
            }
          },
          {
            headerName: 'Won',
            field: 'won',
          },
          {
            headerName: 'Type',
            field: 'type',
            valueGetter: (params) => {
              let types = []
              if(params.data.type & PvP) {
                types.push("PvP")
              }
              if(params.data.type  & Wild) {
                types.push("Wild")
              }
              if(params.data.type  & Trainer) {
                types.push("Trainer")
              }
              if(params.data.type & Ranked) {
                types.push("Ranked")
              }
              if(params.data.type  & Random) {
                types.push("Random")
              }
              if(params.data.type  & Friendly) {
                types.push("Friendly")
              }
              return types.join(', ')
            }
          },
          {
            headerName: 'End Reason',
            field: 'endReason',
            valueFormatter: (data) => {
              if(data.value)
                return data.value
              return 'unknown'
            }
          }

        ]
      },
      cellRenderer: function (param) {
        if (param.value) {
          return param.value.replace(/\n/g, '</br>')
        }
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this
        let menu = [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: ' Check IP',
            icon: '<i class="fa fa-globe"/>',
            action: function () {
              let win = window.open('https://whatismyipaddress.com/ip/' + params.value, '_blank')
              win.focus()
            }
          },
          {
            name: ' Shared IP',
            icon: '<i class="fa fa-share-alt"/>',
            action: function () {
              self.modalTitle = 'Shared IP - ' + params.value
              self.emit('getSharedIPs', {'ip': params.value, 'server': self.pvplogs.server})
            }
          },
          'separator',
          'export'
        ]
        return menu
      }
    },

    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        animateRows: true,
        rowClassRules: {
          "row-pass": params => params.api.getValue("won", params.node) === true,
          "row-fail": params => !params.api.getValue("won", params.node)
        },
        masterDetail: true,
        rowSelection: 'multiple'
    })
      if(this.$route.query.server)
        this.activeServer = this.$route.query.server
      this.emit('suspect_matches', this.activeServer)

      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    watch: {
      pvplogs: function (e) {
        this.loading = false

      }
    },
    beforeRouteUpdate (to, from, next) {
      if(to.query.server)
        this.activeServer = to.query.server
      this.emit('suspect_matches', this.activeServer)

      next()
    }
  }
</script>
