<template>
  <div class="btn-group" role="group" >
      <template v-for="(item, name) in servers">
        <button :key="name" type="button" @click="click(name)" :disabled="loading" v-if="userServers.includes(name)  && (old_servers || !item.readonly)"
                :class="['btn-' + item.colour, server === name ? 'active' : '']"
                class="btn btn-small">{{capitalizeFirstLetter(name)}}
        </button>
      </template>
  </div>
</template>


<script>
  import Servers from '../_servers'
  import { mapGetters } from 'vuex'

  export default {
    name: 'server-menu',
    props: {
      username: {
        type: String
      },
      server: {
        type: String
      },
      loading: {
        type: Boolean
      },
      old_servers: {
        type: Boolean,
        default: true,
      }
    },
    mounted () {
      this.emit('getUserServers', this.username)
    },
    computed: {
      ...mapGetters(['userServers'])
    },
    data () {
      return {
        content: this.server,
        servers: Servers
      }
    },
    methods: {
      click (item) {
        this.$emit('switch', item)

      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    }
  }
</script>

