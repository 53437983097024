<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="12">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="fa fa-history"></i> <strong>History</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
            </div>
            <div>

            <HistoryRecords :records="userHistory.data" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import HistoryRecords from './UserHistory'
  import { mapGetters } from 'vuex'

  export default {
    name: 'History',
    components: {
      HistoryRecords
    },
    computed: mapGetters({
      userHistory: 'userHistory'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getUserHistory', this.$route.params.username)
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getUserHistory', {'username': to.params.username})
      next()
    }
  }
</script>
