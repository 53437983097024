<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-card
        header-tag="header"
        footer-tag="footer">
        <div slot="header">
          <i class="icon-lock"></i> <strong>Debug</strong>

        </div>
        <div>
          <div class="form-group row">
            <div class="col-md-12">
              <textarea id="textarea-input" v-model="text" name="textarea-input" rows="10" class="form-control" placeholder="JSON stuff here"></textarea>
            </div>
          </div>

          <div class="form-group row">

            <div class="col-md-12">
              <br>
              <button class="btn btn-danger" @click="send"  type="submit" > Send</button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>

</template>


<script>
  export default {
    name: 'Auditlog',
    data () {
      return {
        text: ''
      }
    },
    methods: {
      send () {
        this.$socket.send(this.text)
      }
    }
  }
</script>
