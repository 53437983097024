<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-terminal"></i>Modtools Logs
        </div>
        <div class="card-body">
          <div class="form-group">
            <textarea class="form-control log" readonly id="exampleFormControlTextarea1" rows="30">{{toolsLog}}</textarea>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>


<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Modtools Log',
  computed: mapGetters({
    toolsLog: 'ToolsLog'
  }),
  mounted () {
    this.ClearLog()
    this.emit('getToolslog')
  },
  methods: {
    ...mapActions([
      'ClearLog'
    ])
  }
}
</script>
