<script>
  import {Line} from 'vue-chartjs'

  export default {
    extends: Line,
    props: ['height', 'data'],
    data: function () {
      return {
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            enabled: true,
            custom: function (tooltip) {
              if (!tooltip) return
              tooltip.displayColors = false
            },
            callbacks: {
              // use label callback to return the desired label
              label: function (tooltipItem, data) {
                return tooltipItem.yLabel
              },
              // remove title
              title: function (tooltipItem, data) {
                return ''
              }
            }
          },
          scales: {
            xAxes: [{
              display: false
            }],
            yAxes: [{
              display: false
            }]
          },
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      }
    },
    mounted () {
      this.renderChart(this.data, this.options)
    },
    methods: {
      update () {
        this.$data._chart.update()
      }
    }
  }
</script>
