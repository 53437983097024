<template>
  <div>

    <template v-if="editable">
      <textarea v-if="multiline" class="live-edit" type="text" v-model="modelvalue"></textarea>
      <input v-click-outside="stopEdit" ref="edit" @keyup.enter="stopEdit" v-else class="live-edit" type="text" v-model="modelvalue" :placeholder="placeholder"/>
    </template>
    <span v-else v-on:click="editable = true">{{modelvalue || placeholder}}</span>

  </div>
</template>
<script>
  export default {
    name: 'LiveEdit',
    props: {
      value: {
        type: [String, Object],
        required: true
      },
      multiline: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String
      },
      id: {
        type: Number
      }
    },
    methods: {
      stopEdit () {
        this.editable = false
        this.$emit('change', {id: this.id, value: this.modelvalue})
      }
    },
    data () {
      return {
        modelvalue: this.value,
        editable: false
      }
    },
    watch: {
      modelvalue: function (val) {
        this.$emit('input', val)
      }
    }
  }
</script>

<style scoped>
  .live-edit {
    font-size: 1em !important;
  }
</style>
