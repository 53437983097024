<template>
  <div>
    <b-row>
      <div class="col-md-4 col-sm-12 col-xl-3">
        <input data-v-715a1058="" id="name" type="text" v-model="searchText" placeholder="Filter here" class="form-control"  @keyup="externalFilterChanged" >
      </div>
    </b-row><br>
    <ag-grid-vue style='width: 100%; height: 800px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :rowData="records"
                 :enableSorting="true"
                 :masterDetail="true"
                 :detailCellRenderer="detailCellRenderer"
                 :groupDefaultExpanded="groupDefaultExpanded"
                 :suppressClickEdit="true"
                 :isExternalFilterPresent="isExternalFilterPresent"
                 :doesExternalFilterPass="doesExternalFilterPass"
                 :enableFilter="true"
                 :suppressRowClickSelection="true"
                 :getContextMenuItems="getContextMenuItems"></ag-grid-vue>

  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import 'ag-grid-enterprise'
  import DetailCellRenderer from './detailCellRenderer.js'
  import moment from 'moment'

  export default {
    props: ['records', 'server'],
    data () {
      return {
        gridOptions: null,
        searchText: '',
        sortByMoney: false,
        suppressMovable: true,
        detailCellRendererParams: null,
        detailCellRenderer: null,
        groupDefaultExpanded: null,
      }
    },
    components: {
      'ag-grid-vue': AgGridVue,
      DetailCellRenderer
    },
    methods: {
      onFirstDataRendered(params) {
        params.api.forEachNode(function (node) {
          node.setExpanded(node.id === '1');
        });
      },
      copyStringToClipboard (str) {
        let el = document.createElement('textarea')
        el.value = str
        el.setAttribute('readonly', '')
        el.style = {position: 'absolute', left: '-9999px'}
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      },
      showDate (params) {
        if (params.value) {
          return moment(params.value).utc().format('MMMM Do YYYY, HH:mm:ss')
        }
        return ''
      },
      formatMoney (money) {
        return money.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0})
      },
      createRowData () {
        return this.records
      },
      externalFilterChanged () {
        this.gridOptions.api.onFilterChanged()
      },
      isExternalFilterPresent () {
        return this.searchText.length !== 0
      },
      doesExternalFilterPass (node) {
        if (this.searchText.length === 0) { return true }
        let lowerText = this.searchText.toLowerCase()
        for (let i in node.data.users) {
          if (node.data.users[i].money.toString().includes(lowerText)) {
            return true
          }
          for (let p in node.data.users[i].pokemon) {
            if (node.data.users[i].pokemon[p].ID != null && node.data.users[i].pokemon[p].ID.toString().includes(lowerText)) {
              return true
            }
            if (node.data.users[i].pokemon[p].Pokemon != null && node.data.users[i].pokemon[p].Pokemon.toLowerCase().includes(lowerText)) {
              return true
            }
            if (node.data.users[i].pokemon[p].Item != null && node.data.users[i].pokemon[p].Item.toLowerCase().includes(lowerText)) {
              return true
            }
          }
          for (let p in node.data.users[i].items) {
            if (node.data.users[i].items[p].item.toLowerCase().includes(lowerText)) {
              return true
            }
          }
        }
        return false
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this
        return [
          {
            name: 'Copy',
            action: function () {
              let data = params.node.data
              let clipboard = 'Trade - '
              if (data.is_mail) {
                clipboard = 'Mail Trade - '
              }
              if (data.lending > 0) {
                clipboard = 'Lending Trade - '
              }
              clipboard += self.$route.params.username + ' <> ' + data.trade_partner + ' - ' + moment(data.date).utc().toString() + '\r\n'
              for (let i in data.users) {
                let user = data.users[i]
                clipboard += '#' + user.username + '\r\n'
                if (user.money > 0) {
                  clipboard += self.formatMoney(user.money) + '\r\n'
                }
                for (let p in user.pokemon) {
                  let poke = user.pokemon[p]
                  if (poke.Special === 1) {
                    clipboard += 'Shiny '
                  }
                  clipboard += poke.Pokemon + ' Lvl. ' + poke.LVL + ' '
                  if (poke.Item) {
                    clipboard += '(' + poke.Item + ') '
                  }
                  if (poke.Form && poke.Form > 0) {
                    clipboard += 'Form: ' + poke.Form + ' '
                  }
                  if (poke.ID) {
                    clipboard += 'UID: ' + poke.ID + ' '
                  }
                  if (poke.GID) {
                    clipboard += 'GID: ' + poke.GID + ' '
                  }
                  clipboard += '\r\n'
                }
                if (user.items && user.pokemon) {
                  clipboard += '\r\n'
                }
                for (let x in user.items) {
                  let item = user.items[x]
                  clipboard += item.qty + 'x ' + item.item
                  clipboard += '\r\n'
                }
                clipboard += '\r\n'
              }
              self.copyStringToClipboard(clipboard)
            }
          },
          'separator',
          'toolPanel',
          'export'
        ]
      },
      createColumnDefs () {
        return [
          {
            headerName: 'ID',
            field: 'id',
            cellRenderer: 'agGroupCellRenderer'
          },
          {
            headerName: 'Username',
            filter: 'agSetColumnFilter',
            field: 'trade_partner'
          },
          {
            headerName: 'Lending',
            field: 'lending',
            valueGetter (params) {
              switch (params.data.lending) {
                case 0:
                  return 'None'
                case 1:
                  return 'Start'
                case 2:
                  return 'Returned'
              }
              return params.data.lending
            }
          },
          {
            headerName: 'Lending End',
            field: 'lending_date',
            filter: 'agDateColumnFilter',
            valueFormatter: this.showDate
          },
          {
            headerName: 'Mail',
            field: 'is_mail'
          },
          {
            headerName: 'Date',
            field: 'date',
            sortable: true,
            sort: 'desc',
            filter: 'agDateColumnFilter',
            valueFormatter: this.showDate
          }
        ]
      }
    },

    beforeMount () {
      this.groupDefaultExpanded = 1
      this.detailCellRenderer = 'DetailCellRenderer';

      this.gridOptions = {
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        suppressNoRowsOverlay: false,
        masterDetail: true,
        enableRangeSelection: true,
        getRowHeight: function (params) {
          if (params.node && params.node.detail) {
            let totalCalc = 0

            for (let i in params.data.users) {
              let calc = 0
              if (params.data.users[i].money > 0) {
                calc += 28
              }
              if (params.data.users[i].pokemon) {
                calc += params.data.users[i].pokemon.length * 28
              }
              if (params.data.users[i].items) {
                calc += params.data.users[i].items.length * 18
              }
              if (calc > totalCalc) {
                totalCalc = calc
              }
            }
            return totalCalc + 80
          }
          return 28
        }
      }
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.forEachNode(node => {
        node.setExpanded(true)
      })
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
