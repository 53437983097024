<template>
  <div>
    <b-modal title="Add item" size="lg" v-model="modal" @ok="modal = false">
      <div class="row">
        <div class="col-8">
          <div class="form-group">
            <label for="name">Item</label>
            <search :values="items" id="name" placeholder="Name..." v-model="item"></search>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="qty">QTY</label>
            <input class="form-control" id="qty" type="number" v-model="qty">
          </div>
        </div>
      </div>

      <div slot="modal-footer" class="w-100">
        <b-btn :disabled="item.length === 0 || loading" @click="addItem" class="float-right" variant="primary"><i class="fa fa-plus"></i>
          Add
        </b-btn>
      </div>

    </b-modal>
  </div>
</template>
<script>
  import Search from './Search'
  import Items from './_items.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PokemonAdd',
    props: ['modal', 'server'],
    components: {
      Search
    },
    data () {
      return {
        item: '',
        loading: false,
        qty: 1,
        items: Items
      }
    },
    computed: {
      ...mapGetters(['itemError'])
    },
    methods: {
      close () {
        this.$emit('input', false)
      },
      addItem () {
        this.emit('addItem', {username: this.$route.params.username, qty: Number(this.qty), item: this.item, server: this.server})
        this.loading = true
        this.qty = 1
      }
    },
    watch: {
      modal: function (e) {
        this.$emit('update:modal', e)
      },
      itemError: function (e) {
        this.loading = false
      }
    }
  }
</script>
