<template>
  <b-row>
    <b-col v-for="ser in servers" v-if="!serverObj[ser].readonly && !serverObj[ser].test " :key="ser" sm="6" lg="6">
      <poketime-card :server="ser"></poketime-card>
    </b-col>
  </b-row>
</template>

<script>
  import PoketimeCard from './PoketimeCard'
  import Servers from '../../_servers'

  export default {
    components: {PoketimeCard, Servers},
    name: 'poketime',
    data () {
      return {
        servers: Object.keys(Servers).reverse(),
        serverObj: Servers
      }
    }
  }
</script>

<style scoped>

</style>
