<template>
  <div v-if="Auditlog">
    <template v-if="missingReasons > 0">
      <div class="text-danger d-flex justify-content-end" >You have {{missingReasons}} important log {{missingReasons === 1 ? 'entry' : 'entries'}} without a reason.</div>
      <div class="form-group d-flex justify-content-end mt-1">
        <div role="group" class="form-check form-check-inline"><!---->
          <input id="uid-6wxuu1fc5cf" type="checkbox" name="Option 11" v-model="displayReason" @change="activeExternalFilter"
                 class="form-check-input" value="Option 2">
          <label for="uid-6wxuu1fc5cf" class="form-check-label"> Show me the missing entries. </label>
        </div>
      </div>
    </template>
      <ag-grid-vue class="ag-theme-balham-dark" style="width: 100%; height: 70vh"
                   :gridOptions='gridOptions'
                   :rowData="Auditlog"
                   :isExternalFilterPresent="isExternalFilterPresent"
                   :doesExternalFilterPass="doesExternalFilterPass"

      />

  </div>
</template>
<style>
.test-container {
  height: 100%;

}

.test-header {
  font-size: 13px;
  margin-bottom: 10px;
}
</style>
<script>
import {AgGridVue} from 'ag-grid-vue'
import moment from 'moment'
import Servers from '@/_servers'
import Permissions from '@/_permissions'
import iziToast from "izitoast";
import {mapGetters} from "vuex";
import {DefaultGridOptions} from "@/default_table_settings";

export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        displayReason: false,
        showToolPanel: false,
        permissions: Permissions
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    computed: {
      missingReasons () {
        let miss = 0
        for (const record of this.Auditlog) {
          if (record.warning && record.reason === '') { miss++ }
        }
        return miss
      },
      ...mapGetters(['Auditlog'])
    },
    methods: {
      isExternalFilterPresent() {
        return this.displayReason
      },
      doesExternalFilterPass (node) {
        if(node.data) {
          return !node.data.reason && node.data.warning
        }
        return true;
      },
      activeExternalFilter(change) {
        this.gridOptions.api.onFilterChanged();
      },
      createColumnDefs: function () {
        let self = this
        return [
          {
            headerName: 'ID',
            field: 'id',
            hide: true
          },
          {
            headerName: 'Type',
            field: 'type',
            filter: 'agSetColumnFilter',
            cellRenderer: function (params) {
              return '<i class="fa ' + self.getIcon(params.value) + '"></i> ' + params.value
            }
          },
          {
            headerName: 'Message',
            field: 'message',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Reason',
            field: 'reason',
            filter: 'agTextColumnFilter',
            cellRenderer: function (params) {
              return params.value || !params.data.warning ? params.value : '<span class="text-danger">[Please insert a reason]</span>'
            },
            onCellValueChanged: this.onReasonChange,
            editable: true
          },
          {
            headerName: 'Important',
            field: 'warning',
            filter: 'agTextColumnFilter',
            cellRenderer: params => {
              return `${params.value ? 'Yes' : 'No'}`
            },
            hide: true
          },
          {
            headerName: 'Servers',
            field: 'servers',
            cellRenderer: function (params) {
              let msg = ''
              params.value.sort()
              for (const key in params.value) {
                msg += `<span class="text-${self.getColour(params.value[key])} text-capitalize">${params.value[key]}, </span>`
              }
              msg = msg.slice(0, -9) + '</span>'

              return msg
            }
          },
          {
            headerName: 'Date',
            field: 'date',
            sort: 'desc',
            filter: 'agDateColumnFilter',
            cellRenderer: this.showDate
          }
        ]
      },
      showDate(params) {
        return moment(params.value).utc().format('MM/DD/YYYY HH:mm:ss')
      },
      getIcon(type) {
        for (let i in this.permissions) {
          for (let i2 in this.permissions[i].Permissions) {
            if (type === i2 && 'icon' in this.permissions[i].Permissions[i2]) {
              return 'fa-' + this.permissions[i].Permissions[i2].icon
            }
          }
        }
        return 'fa-pencil'
      },
      getColour(server) {
        if (server in Servers) {
          return Servers[server].colour
        }
        return server
      },
      onReasonChange(params) {
        if (params.oldValue !== params.newValue) {
          //params.api.showLoadingOverlay();
          this.emit('updateLogReason', {'id': Number(params.data.id), 'reason': params.newValue})
        }
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        rowSelection: 'multiple',
        columnDefs: this.createColumnDefs(),
        defaultColDef: {
          autoHeight: true,
          resizable: true,
          cellRenderer(params) {
            return params.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }
        },
        onGridReady: params => {
          // get filter instance
          const filterInstance = this.gridOptions.api.getFilterInstance('warning');
          filterInstance.setModel({
            type: 'equal',
            filter: 1
          });
          this.gridOptions.api.onFilterChanged()
          this.gridOptions.api.sizeColumnsToFit()
        },
      })

    }
  }
</script>

<style>
</style>
