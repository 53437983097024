 <template>
   <b-card header="Notes">
       <b-textarea @change="changeNote" v-model="modifiedNote" :readonly="!hasPermission(UserNotesEdit)"></b-textarea>
   </b-card>
</template>
<script>
  import VueNotifications from 'vue-notifications'
  export default {
    name: 'PvP',
    props: ['note', 'userLock'],

    data () {
      return {
        modifiedNote: this.note,
      }
    },
    methods: {
      changeNote () {
        this.emit('changeUserNote', {'username' : this.$route.params.username, 'note': this.modifiedNote})
       // this.ChangeSuccessful()
      },
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      }
    }
  }
</script>
