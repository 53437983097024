<template>
  <b-card no-body class="bg-gold">
    <b-card-body class="pb-0">
      <b-dropdown v-if="hasPermission(ForceRestart) || hasPermission(PVPStatisticsDL)" class="float-right" variant="transparent p-0" right>
        <template slot="button-content">
          <i class="icon-settings"></i>
        </template>
        <b-dropdown-header>Server Tools</b-dropdown-header>
        <b-dropdown-item v-if="hasPermission(ForceRestart)" @click="restartServer">  <i class="fa fa-server text-white"></i> Force Restart</b-dropdown-item>
        <b-dropdown-item v-if="hasPermission(PVPStatisticsDL)" href="/getcsv/ratings/gold"> <i class="fa fa-street-view text-white"></i> Download Top 50 PVP Ratings</b-dropdown-item>
        <b-dropdown-item  v-if="hasPermission(PVPStatisticsDL)" href="/getcsv/losses/gold">   <i class="fa fa-street-view text-white"></i> Download Top 100 PVP Losses</b-dropdown-item>
        <b-dropdown-item v-if="hasPermission(PVPStatisticsDL)" href="/getcsv/random_ratings/gold"> <i class="fa fa-street-view text-white"></i> Download Top 50 Random Ratings</b-dropdown-item>
        <b-dropdown-item  v-if="hasPermission(PVPStatisticsDL)" href="/getcsv/random_losses/gold">   <i class="fa fa-street-view text-white"></i> Download Top 100 Random Losses</b-dropdown-item>
      </b-dropdown>
      <template v-if="online">
        <h4 class="mb-0">{{count.toLocaleString()}}</h4>
        <p>Players online</p>
      </template>
      <template v-else>
        <h4 class="mb-0">Gold</h4>
        <p>Offline</p>
      </template>
    </b-card-body>
    <card-line3-chart ref="lineChart" :data="dataCollection" class="chart-wrapper" style="height:70px;"
                      height="70"/>
  </b-card>
</template>


<script>
  import CardLine3Chart from './CardLine3Chart'
  import {mapGetters} from 'vuex'
  import iziToast from 'izitoast'

  let count = 0
  let chartData = []
  let labels = []
  export default {
    name: 'YellowCard',
    components: {
      CardLine3Chart
    },
    computed: mapGetters({
      statusYellow: 'statusGold',
      authUser: 'authUser'
    }),
    data: function () {
      return {
        count: count,
        online: true,
        dataCollection: null
      }
    },
    created () {
      this.fillData()
    },
    methods: {
      fillData () {
        this.dataCollection = {
          labels: labels,
          datasets: [{
            label: 'lol',
            backgroundColor: 'rgba(255,255,255,.2)',
            borderColor: 'rgba(255,255,255,.55)',
            data: chartData
          }]
        }
      },
      restartServer () {
        let self = this
        iziToast.question({
          timeout: 20000,
          close: true,
          overlay: true,
          toastOnce: true,
          id: 'question',
          zindex: 99999,
          message: 'Are you sure that you want to restart <strong>Gold</strong>?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              iziToast.hide({}, toast)
              self.emit('restartServer', {
                server: 'gold'
              })
            }, true]
          ]
        })
      },
      restartServerCooldown () {
        let self = this
        iziToast.question({
          timeout: 20000,
          close: true,
          overlay: true,
          toastOnce: true,
          id: 'question',
          zindex: 99999,
          message: 'Are you sure that you want to restart <strong>Gold</strong>?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              iziToast.hide({}, toast)
              self.emit('restartServerCooldown', {
                server: 'gold'
              })
            }, true]
          ]
        })
      }
    },
    watch: {
      statusYellow: function (status) {
        for (let i = 0; i < status.length; i++) {
          if (chartData.length >= 34) {
            chartData.shift()
            labels.shift()
          }
          chartData.push(status[i]['player_count'])
          labels.push('none')
        }
        this.count = status[status.length - 1]['player_count']
        this.online = status[status.length - 1]['online']
        this.$refs.lineChart.update()
      }
    }
  }
</script>


