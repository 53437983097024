// initial state
const state = {
  all: null
}

// getters
const getters = {
  Tradelogs: state => state.all

}

// actions
const actions = {
  getTradelog ({ commit }, trades) {
    commit('setTradelog', trades)
  },
  setTradelog ({ commit }, trades) {
    commit('setTradelog', trades)
  }
}

// mutations
const mutations = {
  setTradelog (state, products) {
    state.all = products
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
