<template>
  <b-button-group size="sm" class="float-right">
    <b-button @click="switchServer('silver')" :variant="activeServer === 'silver' ? 'silver' : ''">Silver</b-button>
    <b-button @click="switchServer(null)" :variant="activeServer ===  null ? 'dark-cyan' : ''">All</b-button>
    <b-button @click="switchServer('gold')" :variant="activeServer === 'gold' ? 'gold' : ''">Gold</b-button>
  </b-button-group>
</template>

<script>
  export default {
    name: 'MoneyToplist',
    props: ['stats', 'value'],
    data () {
      return {
        activeServer: this.value
      }
    },
    methods: {
      switchServer (value) {
        this.activeServer = value
        this.$emit('input', value)
      }
    }
  }
</script>

<style scoped>

</style>
