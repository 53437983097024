// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'
import VueNativeSock from 'vue-native-websocket'
import VueNotifications from 'vue-notifications'
import iziToast from 'izitoast'// https://github.com/dolce/iziToast
import 'izitoast/dist/css/iziToast.min.css'
import '../node_modules/ag-grid-community/styles/ag-grid.css'
import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css'
import '../node_modules/ag-grid-community/styles/ag-theme-balham.css'

import store from './store'
import mixin from './PermissionMixin'
import {LicenseManager} from 'ag-grid-enterprise'
import { mapGetters } from 'vuex'
import * as moment from 'moment-timezone'
import vClickOutside from 'v-click-outside'
import Router from "vue-router";

// need if you use ag-grid enterprise features
moment.tz.setDefault('Etc/UTC')
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}
router.beforeEach((to, from, next) =>  {
  if(!hasQueryParams(to) && hasQueryParams(from) && to.params.username === from.params.username){
    next({name: to.name, query: from.query})
  }
  else {
    next()
  }
})

LicenseManager.setLicenseKey('CompanyName=S&J Retail Ltd,LicensedApplication=Forecaster,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-034444,SupportServicesEnd=13_December_2023_[v2]_MTcwMjQyNTYwMDAwMA==20aed75c73ae71845e8f31fdf3372b4f')
function toast ({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = 'warning'
  return iziToast[type]({title, message, timeout, transitionIn: 'bounceInLeft'})
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}
let protocol = 'ws'
if (location.protocol === 'https:') {
  protocol = 'wss'
}

let host = location.hostname + ':6090'
if (location.hostname === 'tools.pokemonrevolution.net') {
  host = 'tools.pokemonrevolution.net'
}
Vue.use(VueNativeSock, protocol + '://' + host + '/echo', {
  store: store,
  format: 'json',
  reconnection: true,
  connectManually: true,
  reconnectionDelay: 3000
})
Vue.use(vClickOutside)

Vue.use(VueNotifications, options)
Vue.use(Autocomplete)
Vue.use(BootstrapVue)
Vue.mixin(mixin)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
  computed: {
    ...mapGetters(['isConnected', 'reconnecting', 'authUser'])
  },
  created () {
    this.$connect()
  },
  watch: {
    isConnected: function (val) {
      if (this.authUser) {
        if (val) {
          this.ConnectSuccess()
        } else {
          this.ConnectionLost()
        }
      }
    },
    reconnecting: function (val) {
      if (this.authUser && val > 0) {
        this.Reconnect()
      }
    },
    authUser: function (val) {
      if (val === false) {
        if (this.$socket) {
          VueNativeSock.reconnection = false
          this.$disconnect()
        }
      }
    }
  },
  notifications: {
    ConnectionLost: {
      type: VueNotifications.types.error,
      title: 'Connection lost',
      message: 'You lost connection to the server.'
    },
    Reconnect: {
      type: VueNotifications.types.info,
      title: 'Reconnecting...',
      message: 'Trying to reconnect to the server.'
    },
    ConnectSuccess: {
      type: VueNotifications.types.success,
      title: 'Connection successful',
      message: 'Let\'s ban them all!'
    }
  }
})
