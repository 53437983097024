import bigInt from 'big-integer'

export default {
  'Base': {
    'Name': 'Base Permissions',
    'Permissions': {
      'AbleToLogin': {
        'Name': 'Login',
        'Description': 'Members with this permission have access to the mod tools.',
        'Permission': bigInt(2).pow(1)
      },
      'UserLoginMessage': {
        'Name': 'Login Message',
        'Description': 'Members with this permission can send login messages.',
        'Permission': bigInt(2).pow(2)
      },
      'UserView': {
        'Name': 'Base View',
        'Description': 'Members with this permission can view the base user page.',
        'Permission': bigInt(2).pow(3),
        'icon': 'user'
      },
      'UserIPsEmailView': {
        'Name': 'User IP\'s and E-Mail\'s View',
        'Description': 'Members with this permission can view E-Mail and IP addresses.',
        'Permission': bigInt(2).pow(4)
      },
      'UserMacView': {
        'Name': 'User Macs View',
        'Description': 'Members with this permission can view mac addresses.',
        'Permission': bigInt(2).pow(52)
      },
      'UserInfractionView': {
        'Name': ' User Infractions View',
        'Description': 'Members with this permission can view user\'s infractions.',
        'Permission': bigInt(2).pow(54)
      },
      'UserHistoryView': {
        'Name': ' User History View',
        'Description': 'Members with this permission can view user\'s website-related histories.',
        'Permission': bigInt(2).pow(55)
      },
      'UserGuildHistoryView': {
        'Name': ' User Guild History View',
        'Description': 'Members with this permission can view user\'s guild histories.',
        'Permission': bigInt(2).pow(61)
      },
      'UserNameChange': {
        'Name': 'Username Change',
        'Description': 'Members with this permission can change usernames.',
        'Permission': bigInt(2).pow(5),
        'icon': 'user'
      },
      'UserEmailChange': {
        'Name': 'E-Mail Change',
        'Description': 'Members with this permission can change a user\'s e-mail address.',
        'Permission': bigInt(2).pow(6),
        'icon': 'envelope',
        'Important': true
      },
      'UserMoneyChange': {
        'Name': 'Money Change',
        'Description': 'Members with this permission can change a user\'s money.',
        'Permission': bigInt(2).pow(7),
        'Important': true,
        'icon': 'dollar'
      },
      'UserCoinChange': {
        'Name': 'Coin Change',
        'Description': 'Members with this permission can change a user\'s coins.',
        'Permission': bigInt(2).pow(8),
        'Important': true,
        'icon': 'dollar'
      },
      'UserBan': {
        'Name': 'Ban Users',
        'Description': 'Members with this permission can ban and unban users.',
        'Permission': bigInt(2).pow(9),
        'icon': 'ban'
      }
    }
  },
  'Advanced': {
    'Name': 'Advanced Permissions',
    'Permissions': {
      'ForceRestart': {
        'Name': 'Server Force Restart',
        'Description': 'Members with this permission can force a server restart.',
        'Permission': bigInt(2).pow(10),
        'icon': 'server'
      },
      'PVPStatisticsDL': {
        'Name': 'PVP Statistics Download',
        'Description': 'Members with this permission can download PVP statistics.',
        'Permission': bigInt(2).pow(11),
        'icon': 'street-view'
      },
      'UserRatingChange': {
        'Name': 'PVP Rating Change',
        'Description': 'Members with this permission can change a user\'s PVP rating.',
        'Permission': bigInt(2).pow(12),
        'icon': 'mail-reply',
        'Important': true
      },
      'UserForceMerge': {
        'Name': 'Force Merge',
        'Description': 'Members with this permission can force merge users.',
        'Permission': bigInt(2).pow(13)
      },
      'UserReset': {
        'Name': 'User Reset',
        'Description': 'Members with this permission can reset and demote accounts.',
        'icon': 'refresh',
        'Permission': bigInt(2).pow(14)
      },
      'UserServerTransfer': {
        'Name': 'Server Transfer',
        'Description': 'Members with this permission can transfer accounts between servers.',
        'icon': 'users',
        'Permission': bigInt(2).pow(15)
      },
      'UserUnmerge': {
        'Name': 'Unmerge User',
        'Description': 'Members with this permission can delete silver accounts with no trades.',
        'Permission': bigInt(2).pow(16),
        'icon': 'user-times',
        'Important': true
      },
      'UserBanMac': {
        'Name': 'Ban MAC',
        'Description': 'Members with this permission can ban MAC addresses.',
        'Permission': bigInt(2).pow(17),
        'icon': 'ban',
        'Important': true
      },
      'MoveClothesToInventory': {
        'Name': 'Undress To Inventory',
        'Description': 'Members with this permission can undress characters and move their clothes to their inventory.',
        'Permission': bigInt(2).pow(65),
      },
      'UserNotesView': {
        'Name': 'User Notes View',
        'Description': 'Members with this permission can view the notes of a user.',
        'Permission': bigInt(2).pow(75),
        'icon': 'fa fa-notes'
      },
      'UserNotesEdit': {
        'Name': 'User Notes Edit',
        'Description': 'Members with this permission can edit the notes of a user.',
        'Permission': bigInt(2).pow(76),
        'icon': 'fa fa-notes'
      }
    }
  },
  'PROEditor': {
    'Name': 'PRO Editor Permissions',
    'Permissions': {
      'PROEditorApprenticeRestrictions': {
        'Name': 'Apprentice Restrictions',
        'Description': 'Members with this permission can request and upload a limited amount of maps from the map server.',
        'Permission': bigInt(2).pow(78),
        'Important': true
      },
      'PROEditorScriptUpload': {
        'Name': 'Script Upload',
        'Description': 'Members with this permission can request and upload scripts directly from the map server.',
        'Permission': bigInt(2).pow(79),
        'Important': true
      },
      'PROEditorMapUpload': {
        'Name': 'Map Upload',
        'Description': 'Members with this permission can request and upload Maps directly from the map server.',
        'Permission': bigInt(2).pow(80),
        'Important': true
      }
    }
  },
  'Spawns': {
    'Name': 'Spawns Permissions',
    'Permissions': {
      'SpawnsView': {
        'Name': 'Spawns View',
        'Description': 'Members with this permission have access to all spawns, displaying their raw rarity as double. Used in the Spawn Editor to import spawns.',
        'Permission': bigInt(2).pow(67),
        'Important': true
      }
    }
  },
  'Pokemon': {
    'Name': 'Pokemon Permissions',
    'Permissions': {
      'PokemonView': {
        'Name': 'Pokemon View',
        'Description': 'Members with this permission can view the Pokemon page.',
        'Permission': bigInt(2).pow(18)
      },
      'PokemonRecover': {
        'Name': 'Pokemon Recover',
        'Description': 'Members with this permission can recover deleted Pokemon.',
        'Permission': bigInt(2).pow(19),
        'icon': 'arrow-up'
      },
      'PokemonRecoverScript': {
        'Name': 'Pokemon Recover Script',
        'Description': 'Members with this permission can recover script deleted pokemon.',
        'Permission': bigInt(2).pow(77),
        'Important': true,
        'icon': 'arrow-up'
      },
      'PokemonDelete': {
        'Name': 'Pokemon Delete',
        'Description': 'Members with this permission can delete Pokemon.',
        'Permission': bigInt(2).pow(20),
        'icon': 'trash'
      },
      'PokemonOwnerTransfer': {
        'Name': 'Pokemon Transfer',
        'Description': 'Members with this permission can transfer Pokemon ownership.',
        'Permission': bigInt(2).pow(21),
        'Important': true,
        'icon': 'arrows-h'
      },
      'PokemonDelevel': {
        'Name': 'Pokemon Delevel',
        'Description': 'Members with this permission can reduce Pokemon levels.',
        'Permission': bigInt(2).pow(22),
        'icon': 'level-down'
      },
      'PokemonAdd': {
        'Name': 'Pokemon Add',
        'Description': 'Members with this permission can generate and approve new Pokemon.',
        'Permission': bigInt(2).pow(23),
        'Important': true,
        'icon': 'plus'
      },
      'PokemonAddView': {
        'Name': 'Pokemon Add Request',
        'Description': 'Members with this permission can generate new Pokemon and request them to be added.',
        'Permission': bigInt(2).pow(24)
      },
      'PokemonEditAbility': {
        'Name': 'Pokemon Edit Ability',
        'Description': 'Members with this permission can change a Pokemon\'s ability.',
        'Permission': bigInt(2).pow(53),
        'icon': 'pencil',
        'Important': true
      },
      'PokemonEditNature': {
        'Name': 'Pokemon Edit Nature',
        'Description': 'Members with this permission can change a Pokemon\'s nature.',
        'Permission': bigInt(2).pow(56),
        'icon': 'pencil',
        'Important': true
      },
      'PokemonEditOT': {
        'Name': 'Pokemon Edit OT',
        'Description': 'Members with this permission can change a Pokemon\'s OT.',
        'Permission': bigInt(2).pow(57),
        'icon': 'pencil',
        'Important': true
      },
      'PokemonEditTradeLock': {
        'Name': 'Pokemon Edit Trade lock',
        'Description': 'Members with this permission can trade lock and unlock a Pokemon.',
        'Permission': bigInt(2).pow(59),
        'icon': 'pencil',
        'Important': true
      },
      'PokemonEditIVS': {
        'Name': 'Pokemon Edit IVs',
        'Description': 'Members with this permission can change a Pokemon\'s Individual Values.',
        'Permission': bigInt(2).pow(60),
        'icon': 'pencil',
        'Important': true
      },
      'PokemonEditForm': {
        'Name': 'Pokemon Edit Form',
        'Description': 'Members with this permission can change a Pokemon\'s form.',
        'Permission': bigInt(2).pow(66),
        'Important': true
      }
    }
  },
  'Others': {
    'Name': 'Other Permissions',
    'Permissions': {
      'TradelogsView': {
        'Name': 'Tradelog View',
        'Description': 'Members with this permission can view the trade log page.',
        'Permission': bigInt(2).pow(25)
      },
      'ConnectionsView': {
        'Name': 'Connections View',
        'Description': 'Members with this permission can view the connection page.',
        'Permission': bigInt(2).pow(26)
      },
      'ViewPokedex': {
        'Name': 'Pokedex View',
        'Description': 'MMembers with this permission can view the Pokedex page.',
        'Permission': bigInt(2).pow(27)
      },
      'PVPlogView': {
        'Name': 'PVP Log View',
        'Description': 'Members with this permission can view the PVP log page.',
        'Permission': bigInt(2).pow(28),
        'Important': true
      },
      'AuditlogView': {
        'Name': 'Audit Log View',
        'Description': 'Members with this permission can view a record of all the significant events that have occurred.',
        'Permission': bigInt(2).pow(29)
      },
      'ChatlogView': {
        'Name': 'Chat Log View',
        'Description': 'Members with this permission have access to view the in-game chat log.',
        'Permission': bigInt(2).pow(58)
      },
      'WatchlistView': {
        'Name': 'Watchlist View',
        'Description': 'Members with this permission can access the watch list of suspicious users.',
        'Permission': bigInt(2).pow(62)
      },
      'WatchlistAdd': {
        'Name': 'Watchlist Add',
        'Description': 'Members with this permission can add users to the suspicious users watch list.',
        'Permission': bigInt(2).pow(63)
      },
      'WatchlistDelete': {
        'Name': 'Watchlist Delete',
        'Description': 'Members with this permission can remove users from the suspicious users watch list.',
        'Permission': bigInt(2).pow(64)
      },
      'SuspectMatches': {
        'Name': 'Suspect Matches',
        'Description': 'Members with this permission can view the suspect matches page, which presents users who participated in illegitimate ranked matches matches.',
        'Permission': bigInt(2).pow(72),
        'icon': 'fa fa-legal'
      }
    }
  },
  'Variables': {
    'Name': 'Variable Permissions',
    'Permissions': {
      'VariablesView': {
        'Name': 'Variables View',
        'Description': 'Members with this permission can view the variable page.',
        'Permission': bigInt(2).pow(30)
      },
      'VariablesEdit': {
        'Name': 'Variables Edit',
        'Description': 'Members with this permission can edit users\' variables.',
        'Permission': bigInt(2).pow(31),
        'Important': true,
        'icon': 'pencil'
      },
      'VariablesEditDays': {
        'Name': 'Variables Edit Days',
        'Description': 'Members with this permission can edit the days of user variables.',
        'Permission': bigInt(2).pow(74),
        'Important': true,
        'icon': 'fa fa-calender'
      },
      'VariablesDelete': {
        'Name': 'Variables Delete',
        'Description': 'Members with this permission can delete users\' variables.',
        'Permission': bigInt(2).pow(32),
        'Important': true,
        'icon': 'minus-square'
      },
      'GlobalVarsView': {
        'Name': 'Global Variables View',
        'Description': 'Members with this permission can view global variables.',
        'Permission': bigInt(2).pow(50)
      },
      'GlobalVarsDelete': {
        'Name': 'Global Variables Delete',
        'Description': 'Members with this permission can delete global variables.',
        'Permission': bigInt(2).pow(51),
        'Important': true
      }
    }
  },
  'Snapshot': {
    'Name': 'Snapshot Permissions',
    'Permissions': {
      'ViewSnapshots': {
        'Name': 'Snapshots View',
        'Description': 'Members with this permission can view the snapshot page.',
        'Permission': bigInt(2).pow(33)
      },
      'SnapshotCreate': {
        'Name': 'Snapshot Create',
        'Description': 'Members with this permission can create a copy of a user\'s snapshot data.',
        'Permission': bigInt(2).pow(34),
        'icon': 'cloud-download'
      },
      'SnapshotRestore': {
        'Name': 'Snapshot Restore',
        'Description': 'Members with this permission can replace a user\'s current data with the data saved in a snapshot.',
        'Permission': bigInt(2).pow(35),
        'Important': true,
        'icon': 'cloud-upload'
      },
      'SnapshotBrowse': {
        'Name': 'Snapshot Browse',
        'Description': 'Members with this permission can browse through the stored data of a user\'s snapshot.',
        'Permission': bigInt(2).pow(71),
        'Important': true,
        'icon': 'fa fa-folder-open'
      }
    }
  },
  'Inventory': {
    'Name': 'Inventory Permissions',
    'Permissions': {
      'InventoryView': {
        'Name': 'View Inventory',
        'Description': 'Members with this permission can view the inventory page.',
        'Permission': bigInt(2).pow(36)
      },
      'InventoryAddItem': {
        'Name': 'Item Add',
        'Description': 'Members with this permission can add items to users\' inventories.',
        'Permission': bigInt(2).pow(37),
        'Important': true,
        'icon': 'plus'
      },
      'InventoryEditItem': {
        'Name': 'Item Edit',
        'Description': 'Members with this permission can change the amount of items users have.',
        'Permission': bigInt(2).pow(38),
        'Important': true,
        'icon': 'pencil'
      },
      'InventoryRemoveItem': {
        'Name': 'Item Delete',
        'Description': 'Members with this permission can remove items from users inventories.',
        'Permission': bigInt(2).pow(39),
        'icon': 'minus-square'
      }
    }
  },
  'Massbans': {
    'Name': 'Massban Permissions',
    'Permissions': {
      'MassbanView': {
        'Name': 'Massban View',
        'Description': 'Members with this permission can view the mass ban page.',
        'Permission': bigInt(2).pow(40)
      },
      'MassbanUsers': {
        'Name': 'Massban Users',
        'Description': 'Members with this permission can ban multiple users at once using usernames as the criteria.',
        'Permission': bigInt(2).pow(41),
        'icon': 'ban'
      },
      'MassbanEMails': {
        'Name': 'Massban E-Mails',
        'Description': 'Members with this permission can ban multiple users at once using e-mail addresses as the criteria.',
        'Permission': bigInt(2).pow(42),
        'icon': 'ban'
      },
      'MassbanIPs': {
        'Name': 'Massban IPs',
        'Description': 'Members with this permission can ban multiple users at once using IP addresses as the criteria.',
        'Permission': bigInt(2).pow(43),
        'icon': 'ban'
      }
    }
  },
  'News': {
    'Name': 'News Permissions',
    'Permissions': {
      'NewsView': {
        'Name': 'News View',
        'Description': 'Members with this permission can view the news page.',
        'Permission': bigInt(2).pow(44)
      },
      'NewsCreate': {
        'Name': 'News Create',
        'Description': 'Members with this permission can write news that will be displayed on the homepage.',
        'Permission': bigInt(2).pow(45)
      },
      'NewsEdit': {
        'Name': 'News Edit',
        'Description': 'Members with this permission can edit the news on the homepage.',
        'Permission': bigInt(2).pow(46)
      },
      'NewsDelete': {
        'Name': 'News Delete',
        'Description': 'Members with this permission can delete the news on the homepage.',
        'Permission': bigInt(2).pow(47)
      }
    }
  },
  'ToolsPermissions': {
    'Name': 'Tools Permissions',
    'Permissions': {
      'PermissionView': {
        'Name': 'Permissions View',
        'Description': 'Members with this permission can view the permissions page and assigned permissions.',
        'Permission': bigInt(2).pow(48)
      },
      'PermissionChange': {
        'Name': 'Permission Edit',
        'Description': 'Members with this permission can edit the user and group permissions.',
        'Permission': bigInt(2).pow(49),
        'Important': true
      },
      'SystemLogView': {
        'Name': 'Log View',
        'Description': 'Members with this permission can view the system mod tools logs.',
        'Permission': bigInt(2).pow(68),
        'Important': true
      },
      'MailLogView': {
        'Name': 'Mail Log View',
        'Description': 'Members with this permission can view the mail log.',
        'Permission': bigInt(2).pow(73),
        'Important': true
      },
      'ToolsRestart': {
        'Name': 'Restart Tools',
        'Description': 'Members with this permission can restart the mod tools.',
        'Permission': bigInt(2).pow(69),
        'Important': true
      },
      'CurrentOnlineToolTip': {
        'Name': 'Current Online Tooltip',
        'Description': 'Members with this permission can view the current online tooltip.',
        'Permission': bigInt(2).pow(70),
        'Important': true
      }
    }
  }
}
