
export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-globe'
    },
    {
      title: true,
      name: 'User',
    },
    {name: 'Base',
      url: '',
      urlName: 'base',
      icon: 'icon-target',
      permission: 'UserView'
    },
    {name: 'History',
      url: '',
      urlName: 'history',
      icon: 'fa fa-history',
      permission: 'UserHistoryView'
    },
    {name: 'Variables',
      url: '',
      urlName: 'variables',
      icon: 'icon-game-controller',
      permission: 'VariablesView'
    },
    {name: 'Connections',
      url: '',
      urlName: 'connections',
      icon: 'icon-eye',
      permission: 'ConnectionsView'
    },
    {name: 'Inventory',
      url: '',
      urlName: 'inventory',
      icon: 'icon-user',
      permission: 'InventoryView'
    },
    {name: 'Trade log',
      url: '',
      urlName: 'tradelog',
      icon: 'icon-note',
      permission: 'TradelogsView'
    },
    {name: 'PVP log',
      url: '',
      urlName: 'pvplog',
      icon: 'fa fa-street-view',
      permission: 'PVPlogView'
    },
    {name: 'Guild History',
      url: '',
      urlName: 'guild',
      icon: 'icon-people',
      permission: 'UserGuildHistoryView'
    },
    {name: 'Pokedex',
      url: '',
      urlName: 'pokedex',
      icon: 'fa fa-product-hunt',
      permission: 'ViewPokedex'
    },
    {name: 'Snapshots',
      url: '',
      urlName: 'snapshots',
      icon: 'fa fa-cloud',
      permission: 'ViewSnapshots'
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'GM Tools'
    },
    {
      name: 'Watchlist',
      url: '/gm/watchlist',
      icon: 'icon-list',
      permission: 'WatchlistView'
    },
    {
      name: 'Mass bans',
      url: '/admin/massban',
      icon: 'icon-lock',
      permission: 'MassbanView'
    },
    {
      name: 'Suspect matches',
      url: '/gm/suspect_matches',
      icon: 'fa fa-legal',
      permission: 'SuspectMatches'
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Extras'
    },
    {
      name: 'News',
      url: '/news/list',
      permission: 'NewsView',
      icon: 'icon-cursor'
    },
    {name: 'Pokemon',
      url: '/pokemon',
      urlName: 'pokemon',
      icon: 'icon-disc',
      permission: 'PokemonView'
    },

    {
      name: 'Global Vars',
      url: '/globalvars',
      icon: 'icon-globe',
      permission: 'GlobalVarsView'
    },
    {name: 'Chat log',
      url: '/admin/chatlog',
      icon: 'fa fa-street-view',
      permission: 'ChatlogView'
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Tools'
    },
    {name: 'Userlog',
      url: '/userlog',
      urlName: 'userlog',
      icon: 'icon-user'
    },
    {
      name: 'Auditlog',
      url: '/admin/auditlog',
      icon: 'fa fa-book',
      permission: 'AuditlogView'
    },
    {
      name: 'Permission',
      url: '/admin/permission',
      icon: 'fa fa-user-secret',
      permission: 'PermissionView'
    },
    {
      name: 'System logs',
      url: '/admin/toolslog',
      icon: 'fa fa-terminal',
      permission: 'SystemLogView'
    },
    {
      name: 'Mail logs',
      url: '/admin/maillog',
      icon: 'fa fa-envelope',
      permission: 'MailLogView'
    }
  ]
}
