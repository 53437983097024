<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="10">
          <b-card v-if="allVariables"
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-game-controller"></i> <strong>Variables</strong>
              <span class="badge badge-primary">{{this.$route.params.username}}</span>
              <span v-if="servers[allVariables.data.server].readonly" class="badge badge-danger">Read-only</span>
              <div class="card-actions" v-if="allVariables.data">
                <server-menu :username="this.$route.params.username" :server.sync="allVariables.data.server" @switch="switchServer"></server-menu>
              </div>
            </div>
            <div>

            <VariableRecords :readonly="servers[allVariables.data.server].readonly" :records="allVariables.data.data" :server="allVariables.data.server"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Servers from '../../../_servers'
  import VariableRecords from './VariableRecords'
  import { mapGetters } from 'vuex'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'Variables',
    components: {
      ServerMenu,
      VariableRecords
    },
    computed: mapGetters({
      allVariables: 'variables'
    }),
    data () {
      return {
        id: 0,
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getVariables', {'username': this.$route.params.username, 'server': this.$route.query.server})
    },
    methods: {
      switchServer (index) {
        if (index in this.cache) {
          this.loadData(this.cache[index])
        } else {
          this.$router.push({ path: 'variables', query: { server: index } })
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getVariables', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
