export default [
  {
    'ID': 1,
    'Name': 'Absorb'
  },
  {
    'ID': 2,
    'Name': 'Acid'
  },
  {
    'ID': 3,
    'Name': 'Acid Armor'
  },
  {
    'ID': 4,
    'Name': 'Aerial Ace'
  },
  {
    'ID': 5,
    'Name': 'Aeroblast'
  },
  {
    'ID': 6,
    'Name': 'Agility'
  },
  {
    'ID': 7,
    'Name': 'Air Cutter'
  },
  {
    'ID': 8,
    'Name': 'Amnesia'
  },
  {
    'ID': 9,
    'Name': 'Ancient Power'
  },
  {
    'ID': 10,
    'Name': 'Arm Thrust'
  },
  {
    'ID': 11,
    'Name': 'Aromatherapy'
  },
  {
    'ID': 12,
    'Name': 'Assist'
  },
  {
    'ID': 13,
    'Name': 'Astonish'
  },
  {
    'ID': 14,
    'Name': 'Attract'
  },
  {
    'ID': 15,
    'Name': 'Aurora Beam'
  },
  {
    'ID': 16,
    'Name': 'Barrage'
  },
  {
    'ID': 17,
    'Name': 'Barrier'
  },
  {
    'ID': 18,
    'Name': 'Baton Pass'
  },
  {
    'ID': 19,
    'Name': 'Beat Up'
  },
  {
    'ID': 20,
    'Name': 'Belly Drum'
  },
  {
    'ID': 21,
    'Name': 'Bide'
  },
  {
    'ID': 22,
    'Name': 'Bind'
  },
  {
    'ID': 23,
    'Name': 'Bite'
  },
  {
    'ID': 24,
    'Name': 'Blaze Kick'
  },
  {
    'ID': 25,
    'Name': 'Blizzard'
  },
  {
    'ID': 27,
    'Name': 'Block'
  },
  {
    'ID': 28,
    'Name': 'Body Slam'
  },
  {
    'ID': 29,
    'Name': 'Bone Club'
  },
  {
    'ID': 30,
    'Name': 'Bone Rush'
  },
  {
    'ID': 31,
    'Name': 'Bonemerang'
  },
  {
    'ID': 32,
    'Name': 'Bounce'
  },
  {
    'ID': 33,
    'Name': 'Brick Break'
  },
  {
    'ID': 34,
    'Name': 'Bubble'
  },
  {
    'ID': 35,
    'Name': 'Bubblebeam'
  },
  {
    'ID': 36,
    'Name': 'Bulk Up'
  },
  {
    'ID': 37,
    'Name': 'Bullet Seed'
  },
  {
    'ID': 38,
    'Name': 'Calm Mind'
  },
  {
    'ID': 39,
    'Name': 'Camouflage'
  },
  {
    'ID': 40,
    'Name': 'Charge'
  },
  {
    'ID': 41,
    'Name': 'Charm'
  },
  {
    'ID': 42,
    'Name': 'Clamp'
  },
  {
    'ID': 43,
    'Name': 'Comet Punch'
  },
  {
    'ID': 44,
    'Name': 'Confuse Ray'
  },
  {
    'ID': 45,
    'Name': 'Confusion'
  },
  {
    'ID': 46,
    'Name': 'Constrict'
  },
  {
    'ID': 48,
    'Name': 'Conversion'
  },
  {
    'ID': 49,
    'Name': 'Conversion 2'
  },
  {
    'ID': 50,
    'Name': 'Cosmic Power'
  },
  {
    'ID': 51,
    'Name': 'Cotton Spore'
  },
  {
    'ID': 52,
    'Name': 'Counter'
  },
  {
    'ID': 53,
    'Name': 'Covet'
  },
  {
    'ID': 54,
    'Name': 'Crabhammer'
  },
  {
    'ID': 55,
    'Name': 'Cross Chop'
  },
  {
    'ID': 56,
    'Name': 'Crunch'
  },
  {
    'ID': 57,
    'Name': 'Crush Claw'
  },
  {
    'ID': 58,
    'Name': 'Curse'
  },
  {
    'ID': 59,
    'Name': 'Defense Curl'
  },
  {
    'ID': 60,
    'Name': 'Destiny Bond'
  },
  {
    'ID': 61,
    'Name': 'Detect'
  },
  {
    'ID': 62,
    'Name': 'Dig'
  },
  {
    'ID': 63,
    'Name': 'Disable'
  },
  {
    'ID': 64,
    'Name': 'Dizzy Punch'
  },
  {
    'ID': 65,
    'Name': 'Doom Desire'
  },
  {
    'ID': 67,
    'Name': 'Double Kick'
  },
  {
    'ID': 69,
    'Name': 'Double Team'
  },
  {
    'ID': 70,
    'Name': 'Double-Edge'
  },
  {
    'ID': 71,
    'Name': 'Double Slap'
  },
  {
    'ID': 72,
    'Name': 'Dragon Claw'
  },
  {
    'ID': 73,
    'Name': 'Dragon Dance'
  },
  {
    'ID': 74,
    'Name': 'Dragon Rage'
  },
  {
    'ID': 75,
    'Name': 'Dragonbreath'
  },
  {
    'ID': 76,
    'Name': 'Dream Eater'
  },
  {
    'ID': 77,
    'Name': 'Drill Peck'
  },
  {
    'ID': 79,
    'Name': 'Dynamic Punch'
  },
  {
    'ID': 80,
    'Name': 'Earthquake'
  },
  {
    'ID': 81,
    'Name': 'Egg Bomb'
  },
  {
    'ID': 82,
    'Name': 'Ember'
  },
  {
    'ID': 83,
    'Name': 'Encore'
  },
  {
    'ID': 84,
    'Name': 'Endeavor'
  },
  {
    'ID': 85,
    'Name': 'Endure'
  },
  {
    'ID': 86,
    'Name': 'Eruption'
  },
  {
    'ID': 87,
    'Name': 'Explosion'
  },
  {
    'ID': 88,
    'Name': 'Extrasensory'
  },
  {
    'ID': 89,
    'Name': 'Extreme Speed'
  },
  {
    'ID': 90,
    'Name': 'Feint Attack'
  },
  {
    'ID': 91,
    'Name': 'Fake Out'
  },
  {
    'ID': 92,
    'Name': 'Fake Tears'
  },
  {
    'ID': 93,
    'Name': 'False Swipe'
  },
  {
    'ID': 94,
    'Name': 'Feather Dance'
  },
  {
    'ID': 95,
    'Name': 'Fire Blast'
  },
  {
    'ID': 96,
    'Name': 'Fire Punch'
  },
  {
    'ID': 97,
    'Name': 'Fire Spin'
  },
  {
    'ID': 98,
    'Name': 'Fissure'
  },
  {
    'ID': 99,
    'Name': 'Flail'
  },
  {
    'ID': 100,
    'Name': 'Flame Wheel'
  },
  {
    'ID': 101,
    'Name': 'Flamethrower'
  },
  {
    'ID': 102,
    'Name': 'Flatter'
  },
  {
    'ID': 103,
    'Name': 'Fly'
  },
  {
    'ID': 104,
    'Name': 'Focus Energy'
  },
  {
    'ID': 105,
    'Name': 'Focus Punch'
  },
  {
    'ID': 106,
    'Name': 'Follow Me'
  },
  {
    'ID': 107,
    'Name': 'Foresight'
  },
  {
    'ID': 108,
    'Name': 'Fury Attack'
  },
  {
    'ID': 109,
    'Name': 'Fury Cutter'
  },
  {
    'ID': 110,
    'Name': 'Fury Swipes'
  },
  {
    'ID': 111,
    'Name': 'Future Sight'
  },
  {
    'ID': 112,
    'Name': 'Giga Drain'
  },
  {
    'ID': 113,
    'Name': 'Glare'
  },
  {
    'ID': 114,
    'Name': 'Grass Whistle'
  },
  {
    'ID': 115,
    'Name': 'Growl'
  },
  {
    'ID': 116,
    'Name': 'Growth'
  },
  {
    'ID': 117,
    'Name': 'Grudge'
  },
  {
    'ID': 118,
    'Name': 'Guillotine'
  },
  {
    'ID': 119,
    'Name': 'Gust'
  },
  {
    'ID': 120,
    'Name': 'Hail'
  },
  {
    'ID': 121,
    'Name': 'Harden'
  },
  {
    'ID': 122,
    'Name': 'Haze'
  },
  {
    'ID': 123,
    'Name': 'Acrobatics'
  },
  {
    'ID': 124,
    'Name': 'Headbutt'
  },
  {
    'ID': 125,
    'Name': 'Heal Bell'
  },
  {
    'ID': 126,
    'Name': 'Heat Wave'
  },
  {
    'ID': 127,
    'Name': 'Helping Hand'
  },
  {
    'ID': 128,
    'Name': 'High Jump Kick'
  },
  {
    'ID': 129,
    'Name': 'Hidden Power'
  },
  {
    'ID': 130,
    'Name': 'Horn Attack'
  },
  {
    'ID': 131,
    'Name': 'Horn Drill'
  },
  {
    'ID': 132,
    'Name': 'Howl'
  },
  {
    'ID': 133,
    'Name': 'Hydro Pump'
  },
  {
    'ID': 134,
    'Name': 'Hyper Beam'
  },
  {
    'ID': 135,
    'Name': 'Hyper Fang'
  },
  {
    'ID': 136,
    'Name': 'Hyper Voice'
  },
  {
    'ID': 137,
    'Name': 'Hypnosis'
  },
  {
    'ID': 138,
    'Name': 'Ice Ball'
  },
  {
    'ID': 139,
    'Name': 'Ice Beam'
  },
  {
    'ID': 140,
    'Name': 'Ice Punch'
  },
  {
    'ID': 141,
    'Name': 'Icicle Spear'
  },
  {
    'ID': 142,
    'Name': 'Icy Wind'
  },
  {
    'ID': 143,
    'Name': 'Imprison'
  },
  {
    'ID': 144,
    'Name': 'Ingrain'
  },
  {
    'ID': 145,
    'Name': 'Iron Defense'
  },
  {
    'ID': 146,
    'Name': 'Iron Tail'
  },
  {
    'ID': 147,
    'Name': 'Jump Kick'
  },
  {
    'ID': 148,
    'Name': 'Karate Chop'
  },
  {
    'ID': 149,
    'Name': 'Kinesis'
  },
  {
    'ID': 150,
    'Name': 'Knock Off'
  },
  {
    'ID': 151,
    'Name': 'Leaf Blade'
  },
  {
    'ID': 152,
    'Name': 'Leech Life'
  },
  {
    'ID': 153,
    'Name': 'Leech Seed'
  },
  {
    'ID': 154,
    'Name': 'Leer'
  },
  {
    'ID': 155,
    'Name': 'Lick'
  },
  {
    'ID': 156,
    'Name': 'Light Screen'
  },
  {
    'ID': 158,
    'Name': 'Lock-On'
  },
  {
    'ID': 159,
    'Name': 'Lovely Kiss'
  },
  {
    'ID': 160,
    'Name': 'Low Kick'
  },
  {
    'ID': 161,
    'Name': 'Luster Purge'
  },
  {
    'ID': 162,
    'Name': 'Mach Punch'
  },
  {
    'ID': 163,
    'Name': 'Magic Coat'
  },
  {
    'ID': 164,
    'Name': 'Magical Leaf'
  },
  {
    'ID': 165,
    'Name': 'Magnitude'
  },
  {
    'ID': 166,
    'Name': 'Mean Look'
  },
  {
    'ID': 167,
    'Name': 'Meditate'
  },
  {
    'ID': 168,
    'Name': 'Mega Drain'
  },
  {
    'ID': 169,
    'Name': 'Mega Kick'
  },
  {
    'ID': 170,
    'Name': 'Mega Punch'
  },
  {
    'ID': 171,
    'Name': 'Megahorn'
  },
  {
    'ID': 172,
    'Name': 'Memento'
  },
  {
    'ID': 173,
    'Name': 'Metal Claw'
  },
  {
    'ID': 174,
    'Name': 'Metal Sound'
  },
  {
    'ID': 175,
    'Name': 'Meteor Mash'
  },
  {
    'ID': 176,
    'Name': 'Metronome'
  },
  {
    'ID': 177,
    'Name': 'Milk Drink'
  },
  {
    'ID': 178,
    'Name': 'Mimic'
  },
  {
    'ID': 179,
    'Name': 'Mind Reader'
  },
  {
    'ID': 180,
    'Name': 'Minimize'
  },
  {
    'ID': 181,
    'Name': 'Mirror Coat'
  },
  {
    'ID': 182,
    'Name': 'Mirror Move'
  },
  {
    'ID': 183,
    'Name': 'Mist'
  },
  {
    'ID': 184,
    'Name': 'Mist Ball'
  },
  {
    'ID': 185,
    'Name': 'Moonlight'
  },
  {
    'ID': 186,
    'Name': 'Morning Sun'
  },
  {
    'ID': 187,
    'Name': 'Mud Shot'
  },
  {
    'ID': 188,
    'Name': 'Mud-Slap'
  },
  {
    'ID': 189,
    'Name': 'Mud Sport'
  },
  {
    'ID': 190,
    'Name': 'Muddy Water'
  },
  {
    'ID': 191,
    'Name': 'Nature Power'
  },
  {
    'ID': 192,
    'Name': 'Needle Arm'
  },
  {
    'ID': 193,
    'Name': 'Night Shade'
  },
  {
    'ID': 194,
    'Name': 'Nightmare'
  },
  {
    'ID': 195,
    'Name': 'Octazooka'
  },
  {
    'ID': 196,
    'Name': 'Odor Sleuth'
  },
  {
    'ID': 197,
    'Name': 'Outrage'
  },
  {
    'ID': 198,
    'Name': 'Pain Split'
  },
  {
    'ID': 199,
    'Name': 'Pay Day'
  },
  {
    'ID': 200,
    'Name': 'Peck'
  },
  {
    'ID': 201,
    'Name': 'Perish Song'
  },
  {
    'ID': 202,
    'Name': 'Petal Dance'
  },
  {
    'ID': 203,
    'Name': 'Pin Missile'
  },
  {
    'ID': 204,
    'Name': 'Poison Fang'
  },
  {
    'ID': 205,
    'Name': 'Poison Gas'
  },
  {
    'ID': 206,
    'Name': 'Poison Sting'
  },
  {
    'ID': 207,
    'Name': 'Poison Tail'
  },
  {
    'ID': 208,
    'Name': 'Poison Powder'
  },
  {
    'ID': 209,
    'Name': 'Pound'
  },
  {
    'ID': 210,
    'Name': 'Powder Snow'
  },
  {
    'ID': 211,
    'Name': 'Present'
  },
  {
    'ID': 212,
    'Name': 'Protect'
  },
  {
    'ID': 213,
    'Name': 'Psybeam'
  },
  {
    'ID': 214,
    'Name': 'Psych Up'
  },
  {
    'ID': 215,
    'Name': 'Psychic'
  },
  {
    'ID': 216,
    'Name': 'Psycho Boost'
  },
  {
    'ID': 217,
    'Name': 'Psywave'
  },
  {
    'ID': 218,
    'Name': 'Pursuit'
  },
  {
    'ID': 219,
    'Name': 'Quick Attack'
  },
  {
    'ID': 220,
    'Name': 'Rage'
  },
  {
    'ID': 221,
    'Name': 'Rain Dance'
  },
  {
    'ID': 222,
    'Name': 'Rapid Spin'
  },
  {
    'ID': 223,
    'Name': 'Razor Leaf'
  },
  {
    'ID': 224,
    'Name': 'Razor Wind'
  },
  {
    'ID': 225,
    'Name': 'Recover'
  },
  {
    'ID': 226,
    'Name': 'Recycle'
  },
  {
    'ID': 227,
    'Name': 'Reflect'
  },
  {
    'ID': 228,
    'Name': 'Refresh'
  },
  {
    'ID': 229,
    'Name': 'Rest'
  },
  {
    'ID': 230,
    'Name': 'Revenge'
  },
  {
    'ID': 231,
    'Name': 'Reversal'
  },
  {
    'ID': 232,
    'Name': 'Roar'
  },
  {
    'ID': 233,
    'Name': 'Rock Blast'
  },
  {
    'ID': 234,
    'Name': 'Rock Slide'
  },
  {
    'ID': 235,
    'Name': 'Rock Throw'
  },
  {
    'ID': 236,
    'Name': 'Rock Tomb'
  },
  {
    'ID': 237,
    'Name': 'Role Play'
  },
  {
    'ID': 238,
    'Name': 'Rolling Kick'
  },
  {
    'ID': 239,
    'Name': 'Rollout'
  },
  {
    'ID': 240,
    'Name': 'Sacred Fire'
  },
  {
    'ID': 241,
    'Name': 'Safeguard'
  },
  {
    'ID': 242,
    'Name': 'Sand Tomb'
  },
  {
    'ID': 243,
    'Name': 'Sand-Attack'
  },
  {
    'ID': 244,
    'Name': 'Sandstorm'
  },
  {
    'ID': 246,
    'Name': 'Scary Face'
  },
  {
    'ID': 247,
    'Name': 'Scratch'
  },
  {
    'ID': 248,
    'Name': 'Screech'
  },
  {
    'ID': 249,
    'Name': 'Seismic Toss'
  },
  {
    'ID': 250,
    'Name': 'Selfdestruct'
  },
  {
    'ID': 251,
    'Name': 'Shadow Ball'
  },
  {
    'ID': 252,
    'Name': 'Shadow Punch'
  },
  {
    'ID': 253,
    'Name': 'Sharpen'
  },
  {
    'ID': 254,
    'Name': 'Sheer Cold'
  },
  {
    'ID': 255,
    'Name': 'Signal Beam'
  },
  {
    'ID': 256,
    'Name': 'Silver Wind'
  },
  {
    'ID': 257,
    'Name': 'Sing'
  },
  {
    'ID': 258,
    'Name': 'Sketch'
  },
  {
    'ID': 259,
    'Name': 'Skull Bash'
  },
  {
    'ID': 260,
    'Name': 'Sky Attack'
  },
  {
    'ID': 261,
    'Name': 'Sky Uppercut'
  },
  {
    'ID': 262,
    'Name': 'Slack Off'
  },
  {
    'ID': 263,
    'Name': 'Slam'
  },
  {
    'ID': 264,
    'Name': 'Slash'
  },
  {
    'ID': 265,
    'Name': 'Sleep Powder'
  },
  {
    'ID': 266,
    'Name': 'Sleep Talk'
  },
  {
    'ID': 267,
    'Name': 'Sludge'
  },
  {
    'ID': 268,
    'Name': 'Sludge Bomb'
  },
  {
    'ID': 269,
    'Name': 'Smelling Salts'
  },
  {
    'ID': 270,
    'Name': 'Smog'
  },
  {
    'ID': 271,
    'Name': 'SmokeScreen'
  },
  {
    'ID': 273,
    'Name': 'Snatch'
  },
  {
    'ID': 274,
    'Name': 'Snore'
  },
  {
    'ID': 275,
    'Name': 'Softboiled'
  },
  {
    'ID': 276,
    'Name': 'Solar Beam'
  },
  {
    'ID': 277,
    'Name': 'SonicBoom'
  },
  {
    'ID': 278,
    'Name': 'Spark'
  },
  {
    'ID': 279,
    'Name': 'Spider Web'
  },
  {
    'ID': 280,
    'Name': 'Spike Cannon'
  },
  {
    'ID': 281,
    'Name': 'Spikes'
  },
  {
    'ID': 282,
    'Name': 'Spit Up'
  },
  {
    'ID': 283,
    'Name': 'Spite'
  },
  {
    'ID': 284,
    'Name': 'Splash'
  },
  {
    'ID': 285,
    'Name': 'Spore'
  },
  {
    'ID': 286,
    'Name': 'Steel Wing'
  },
  {
    'ID': 287,
    'Name': 'Stockpile'
  },
  {
    'ID': 288,
    'Name': 'Stomp'
  },
  {
    'ID': 289,
    'Name': 'String Shot'
  },
  {
    'ID': 290,
    'Name': 'Stun Spore'
  },
  {
    'ID': 291,
    'Name': 'Submission'
  },
  {
    'ID': 292,
    'Name': 'Substitute'
  },
  {
    'ID': 293,
    'Name': 'Sunny Day'
  },
  {
    'ID': 294,
    'Name': 'Super Fang'
  },
  {
    'ID': 295,
    'Name': 'Superpower'
  },
  {
    'ID': 296,
    'Name': 'Supersonic'
  },
  {
    'ID': 297,
    'Name': 'Swagger'
  },
  {
    'ID': 298,
    'Name': 'Swallow'
  },
  {
    'ID': 299,
    'Name': 'Sweet Kiss'
  },
  {
    'ID': 300,
    'Name': 'Sweet Scent'
  },
  {
    'ID': 301,
    'Name': 'Swift'
  },
  {
    'ID': 302,
    'Name': 'Swords Dance'
  },
  {
    'ID': 303,
    'Name': 'Synthesis'
  },
  {
    'ID': 304,
    'Name': 'Tackle'
  },
  {
    'ID': 305,
    'Name': 'Tail Glow'
  },
  {
    'ID': 306,
    'Name': 'Tail Whip'
  },
  {
    'ID': 307,
    'Name': 'Take Down'
  },
  {
    'ID': 308,
    'Name': 'Taunt'
  },
  {
    'ID': 309,
    'Name': 'Teeter Dance'
  },
  {
    'ID': 310,
    'Name': 'Teleport'
  },
  {
    'ID': 311,
    'Name': 'Thief'
  },
  {
    'ID': 312,
    'Name': 'Thrash'
  },
  {
    'ID': 313,
    'Name': 'Thunder'
  },
  {
    'ID': 314,
    'Name': 'Thunder Wave'
  },
  {
    'ID': 315,
    'Name': 'Thunderbolt'
  },
  {
    'ID': 316,
    'Name': 'Thunder Punch'
  },
  {
    'ID': 317,
    'Name': 'ThunderShock'
  },
  {
    'ID': 318,
    'Name': 'Soul Crush'
  },
  {
    'ID': 319,
    'Name': 'Tickle'
  },
  {
    'ID': 320,
    'Name': 'Torment'
  },
  {
    'ID': 321,
    'Name': 'Toxic'
  },
  {
    'ID': 322,
    'Name': 'Transform'
  },
  {
    'ID': 323,
    'Name': 'Tri Attack'
  },
  {
    'ID': 324,
    'Name': 'Trick'
  },
  {
    'ID': 325,
    'Name': 'Triple Kick'
  },
  {
    'ID': 326,
    'Name': 'Twineedle'
  },
  {
    'ID': 327,
    'Name': 'Twister'
  },
  {
    'ID': 328,
    'Name': 'Uproar'
  },
  {
    'ID': 329,
    'Name': 'BLANK'
  },
  {
    'ID': 330,
    'Name': 'ViceGrip'
  },
  {
    'ID': 331,
    'Name': 'Vine Whip'
  },
  {
    'ID': 332,
    'Name': 'Vital Throw'
  },
  {
    'ID': 333,
    'Name': 'Water Gun'
  },
  {
    'ID': 334,
    'Name': 'Water Pulse'
  },
  {
    'ID': 335,
    'Name': 'Water Sport'
  },
  {
    'ID': 336,
    'Name': 'Water Spout'
  },
  {
    'ID': 337,
    'Name': 'Waterfall'
  },
  {
    'ID': 338,
    'Name': 'Weather Ball'
  },
  {
    'ID': 339,
    'Name': 'Whirlpool'
  },
  {
    'ID': 340,
    'Name': 'Whirlwind'
  },
  {
    'ID': 341,
    'Name': 'Will-o-Wisp'
  },
  {
    'ID': 342,
    'Name': 'Wing Attack'
  },
  {
    'ID': 343,
    'Name': 'Wish'
  },
  {
    'ID': 344,
    'Name': 'Withdraw'
  },
  {
    'ID': 345,
    'Name': 'Wrap'
  },
  {
    'ID': 346,
    'Name': 'Yawn'
  },
  {
    'ID': 347,
    'Name': 'Zap Cannon'
  },
  {
    'ID': 348,
    'Name': 'Cut'
  },
  {
    'ID': 350,
    'Name': 'Surf'
  },
  {
    'ID': 351,
    'Name': 'Strength'
  },
  {
    'ID': 352,
    'Name': 'Flash'
  },
  {
    'ID': 353,
    'Name': 'Struggle'
  },
  {
    'ID': 356,
    'Name': 'Return'
  },
  {
    'ID': 357,
    'Name': 'Frustration'
  },
  {
    'ID': 358,
    'Name': 'Rock Smash'
  },
  {
    'ID': 359,
    'Name': 'Facade'
  },
  {
    'ID': 360,
    'Name': 'Skill Swap'
  },
  {
    'ID': 361,
    'Name': 'Secret Power'
  },
  {
    'ID': 362,
    'Name': 'Dive'
  },
  {
    'ID': 363,
    'Name': 'Blast Burn'
  },
  {
    'ID': 364,
    'Name': 'Hydro Cannon'
  },
  {
    'ID': 365,
    'Name': 'Overheat'
  },
  {
    'ID': 366,
    'Name': 'Frenzy Plant'
  },
  {
    'ID': 367,
    'Name': 'Volt Tackle'
  },
  {
    'ID': 368,
    'Name': 'Shock Wave'
  },
  {
    'ID': 369,
    'Name': 'Roost'
  },
  {
    'ID': 370,
    'Name': 'Gravity'
  },
  {
    'ID': 371,
    'Name': 'Miracle Eye'
  },
  {
    'ID': 372,
    'Name': 'Wake-Up Slap'
  },
  {
    'ID': 373,
    'Name': 'Hammer Arm'
  },
  {
    'ID': 374,
    'Name': 'Gyro Ball'
  },
  {
    'ID': 375,
    'Name': 'Healing Wish'
  },
  {
    'ID': 376,
    'Name': 'Brine'
  },
  {
    'ID': 377,
    'Name': 'Natural Gift'
  },
  {
    'ID': 378,
    'Name': 'Feint'
  },
  {
    'ID': 379,
    'Name': 'Pluck'
  },
  {
    'ID': 380,
    'Name': 'Tailwind'
  },
  {
    'ID': 381,
    'Name': 'Acupressure'
  },
  {
    'ID': 382,
    'Name': 'Metal Burst'
  },
  {
    'ID': 383,
    'Name': 'U-Turn'
  },
  {
    'ID': 384,
    'Name': 'Close Combat'
  },
  {
    'ID': 385,
    'Name': 'Payback'
  },
  {
    'ID': 386,
    'Name': 'Assurance'
  },
  {
    'ID': 387,
    'Name': 'Embargo'
  },
  {
    'ID': 388,
    'Name': 'Fling'
  },
  {
    'ID': 389,
    'Name': 'Psycho Shift'
  },
  {
    'ID': 390,
    'Name': 'Trump Card'
  },
  {
    'ID': 391,
    'Name': 'Heal Block'
  },
  {
    'ID': 392,
    'Name': 'Wring Out'
  },
  {
    'ID': 393,
    'Name': 'Power Trick'
  },
  {
    'ID': 394,
    'Name': 'Gastro Acid'
  },
  {
    'ID': 395,
    'Name': 'Lucky Chant'
  },
  {
    'ID': 396,
    'Name': 'Me First'
  },
  {
    'ID': 397,
    'Name': 'Copycat'
  },
  {
    'ID': 398,
    'Name': 'Power Swap'
  },
  {
    'ID': 399,
    'Name': 'Guard Swap'
  },
  {
    'ID': 400,
    'Name': 'Punishment'
  },
  {
    'ID': 401,
    'Name': 'Last Resort'
  },
  {
    'ID': 402,
    'Name': 'Worry Seed'
  },
  {
    'ID': 403,
    'Name': 'Sucker Punch'
  },
  {
    'ID': 404,
    'Name': 'Toxic Spikes'
  },
  {
    'ID': 405,
    'Name': 'Heart Swap'
  },
  {
    'ID': 406,
    'Name': 'Aqua Ring'
  },
  {
    'ID': 407,
    'Name': 'Magnet Rise'
  },
  {
    'ID': 408,
    'Name': 'Flare Blitz'
  },
  {
    'ID': 409,
    'Name': 'Force Palm'
  },
  {
    'ID': 410,
    'Name': 'Aura Sphere'
  },
  {
    'ID': 411,
    'Name': 'Rock Polish'
  },
  {
    'ID': 412,
    'Name': 'Poison Jab'
  },
  {
    'ID': 413,
    'Name': 'Dark Pulse'
  },
  {
    'ID': 414,
    'Name': 'Night Slash'
  },
  {
    'ID': 415,
    'Name': 'Aqua Tail'
  },
  {
    'ID': 416,
    'Name': 'Seed Bomb'
  },
  {
    'ID': 417,
    'Name': 'Air Slash'
  },
  {
    'ID': 418,
    'Name': 'X-Scissor'
  },
  {
    'ID': 419,
    'Name': 'Bug Buzz'
  },
  {
    'ID': 420,
    'Name': 'Dragon Pulse'
  },
  {
    'ID': 421,
    'Name': 'Dragon Rush'
  },
  {
    'ID': 422,
    'Name': 'Power Gem'
  },
  {
    'ID': 423,
    'Name': 'Drain Punch'
  },
  {
    'ID': 424,
    'Name': 'Vacuum Wave'
  },
  {
    'ID': 425,
    'Name': 'Focus Blast'
  },
  {
    'ID': 426,
    'Name': 'Energy Ball'
  },
  {
    'ID': 427,
    'Name': 'Brave Bird'
  },
  {
    'ID': 428,
    'Name': 'Earth Power'
  },
  {
    'ID': 429,
    'Name': 'Switcheroo'
  },
  {
    'ID': 430,
    'Name': 'Giga Impact'
  },
  {
    'ID': 431,
    'Name': 'Nasty Plot'
  },
  {
    'ID': 432,
    'Name': 'Bullet Punch'
  },
  {
    'ID': 433,
    'Name': 'Avalanche'
  },
  {
    'ID': 434,
    'Name': 'Ice Shard'
  },
  {
    'ID': 435,
    'Name': 'Shadow Claw'
  },
  {
    'ID': 436,
    'Name': 'Thunder Fang'
  },
  {
    'ID': 437,
    'Name': 'Ice Fang'
  },
  {
    'ID': 438,
    'Name': 'Fire Fang'
  },
  {
    'ID': 439,
    'Name': 'Shadow Sneak'
  },
  {
    'ID': 440,
    'Name': 'Mud Bomb'
  },
  {
    'ID': 441,
    'Name': 'Psycho Cut'
  },
  {
    'ID': 442,
    'Name': 'Zen Headbutt'
  },
  {
    'ID': 443,
    'Name': 'Mirror Shot'
  },
  {
    'ID': 444,
    'Name': 'Flash Cannon'
  },
  {
    'ID': 445,
    'Name': 'Rock Climb'
  },
  {
    'ID': 446,
    'Name': 'Defog'
  },
  {
    'ID': 447,
    'Name': 'Trick Room'
  },
  {
    'ID': 448,
    'Name': 'Draco Meteor'
  },
  {
    'ID': 449,
    'Name': 'Discharge'
  },
  {
    'ID': 450,
    'Name': 'Lava Plume'
  },
  {
    'ID': 451,
    'Name': 'Leaf Storm'
  },
  {
    'ID': 452,
    'Name': 'Power Whip'
  },
  {
    'ID': 453,
    'Name': 'Rock Wrecker'
  },
  {
    'ID': 454,
    'Name': 'Cross Poison'
  },
  {
    'ID': 455,
    'Name': 'Gunk Shot'
  },
  {
    'ID': 456,
    'Name': 'Iron Head'
  },
  {
    'ID': 457,
    'Name': 'Magnet Bomb'
  },
  {
    'ID': 458,
    'Name': 'Stone Edge'
  },
  {
    'ID': 459,
    'Name': 'Captivate'
  },
  {
    'ID': 460,
    'Name': 'Stealth Rock'
  },
  {
    'ID': 461,
    'Name': 'Grass Knot'
  },
  {
    'ID': 462,
    'Name': 'Chatter'
  },
  {
    'ID': 463,
    'Name': 'Judgment'
  },
  {
    'ID': 464,
    'Name': 'Bug Bite'
  },
  {
    'ID': 465,
    'Name': 'Charge Beam'
  },
  {
    'ID': 466,
    'Name': 'Wood Hammer'
  },
  {
    'ID': 467,
    'Name': 'Aqua Jet'
  },
  {
    'ID': 468,
    'Name': 'Attack Order'
  },
  {
    'ID': 469,
    'Name': 'Defend Order'
  },
  {
    'ID': 470,
    'Name': 'Heal Order'
  },
  {
    'ID': 471,
    'Name': 'Head Smash'
  },
  {
    'ID': 472,
    'Name': 'Double Hit'
  },
  {
    'ID': 473,
    'Name': 'Roar Of Time'
  },
  {
    'ID': 474,
    'Name': 'Spacial Rend'
  },
  {
    'ID': 475,
    'Name': 'Lunar Dance'
  },
  {
    'ID': 476,
    'Name': 'Crush Grip'
  },
  {
    'ID': 477,
    'Name': 'Magma Storm'
  },
  {
    'ID': 478,
    'Name': 'Dark Void'
  },
  {
    'ID': 479,
    'Name': 'Seed Flare'
  },
  {
    'ID': 480,
    'Name': 'Ominous Wind'
  },
  {
    'ID': 481,
    'Name': 'Shadow Force'
  },
  {
    'ID': 482,
    'Name': 'Inferno'
  },
  {
    'ID': 483,
    'Name': 'Flame Burst'
  },
  {
    'ID': 484,
    'Name': 'Rage Powder'
  },
  {
    'ID': 485,
    'Name': 'Quiver Dance'
  },
  {
    'ID': 486,
    'Name': 'Hurricane'
  },
  {
    'ID': 487,
    'Name': 'Drill Run'
  },
  {
    'ID': 488,
    'Name': 'Acid Spray'
  },
  {
    'ID': 489,
    'Name': 'Coil'
  },
  {
    'ID': 490,
    'Name': 'Flame Charge'
  },
  {
    'ID': 491,
    'Name': 'Chip Away'
  },
  {
    'ID': 492,
    'Name': 'Synchronoise'
  },
  {
    'ID': 493,
    'Name': 'Echoed Voice'
  },
  {
    'ID': 494,
    'Name': 'Electro Ball'
  },
  {
    'ID': 495,
    'Name': 'Bestow'
  },
  {
    'ID': 496,
    'Name': 'Stored Power'
  },
  {
    'ID': 497,
    'Name': 'After You'
  },
  {
    'ID': 498,
    'Name': 'Round'
  },
  {
    'ID': 499,
    'Name': 'Heavy Slam'
  },
  {
    'ID': 500,
    'Name': 'Work Up'
  },
  {
    'ID': 501,
    'Name': 'Psyshock'
  },
  {
    'ID': 502,
    'Name': 'Wide Guard'
  },
  {
    'ID': 503,
    'Name': 'Quick Guard'
  },
  {
    'ID': 504,
    'Name': 'Retaliate'
  },
  {
    'ID': 505,
    'Name': 'Sludge Wave'
  },
  {
    'ID': 506,
    'Name': 'Soak'
  },
  {
    'ID': 507,
    'Name': 'Bulldoze'
  },
  {
    'ID': 508,
    'Name': 'Wonder Room'
  },
  {
    'ID': 509,
    'Name': 'Final Gambit'
  },
  {
    'ID': 510,
    'Name': 'Smack Down'
  },
  {
    'ID': 511,
    'Name': 'Steamroller'
  },
  {
    'ID': 512,
    'Name': 'Autotomize'
  },
  {
    'ID': 513,
    'Name': 'Petal Blizzard'
  },
  {
    'ID': 514,
    'Name': 'Fell Stinger'
  },
  {
    'ID': 515,
    'Name': 'Belch'
  },
  {
    'ID': 516,
    'Name': 'Play Nice'
  },
  {
    'ID': 517,
    'Name': 'Wild Charge'
  },
  {
    'ID': 518,
    'Name': 'Moonblast'
  },
  {
    'ID': 519,
    'Name': 'Venoshock'
  },
  {
    'ID': 520,
    'Name': 'Play Rough'
  },
  {
    'ID': 521,
    'Name': 'Nuzzle'
  },
  {
    'ID': 522,
    'Name': 'Baby-Doll Eyes'
  },
  {
    'ID': 523,
    'Name': 'Disarming Voice'
  },
  {
    'ID': 524,
    'Name': 'Hex'
  },
  {
    'ID': 525,
    'Name': 'Megavolt'
  },
  {
    'ID': 526,
    'Name': 'Shell Smash'
  },
  {
    'ID': 527,
    'Name': 'Razor Shell'
  },
  {
    'ID': 528,
    'Name': 'Eerie Impulse'
  },
  {
    'ID': 529,
    'Name': 'Magic Room'
  },
  {
    'ID': 530,
    'Name': 'Dragon Tail'
  },
  {
    'ID': 531,
    'Name': 'Circle Throw'
  },
  {
    'ID': 532,
    'Name': 'Volt Switch'
  },
  {
    'ID': 533,
    'Name': 'Sticky Web'
  },
  {
    'ID': 534,
    'Name': 'Horn Leech'
  },
  {
    'ID': 535,
    'Name': 'Scald'
  },
  {
    'ID': 536,
    'Name': 'Dazzling Gleam'
  },
  {
    'ID': 537,
    'Name': 'Foul Play'
  },
  {
    'ID': 538,
    'Name': 'Accelerock'
  },
  {
    'ID': 539,
    'Name': 'Anchor Shot'
  },
  {
    'ID': 540,
    'Name': 'Aurora Veil'
  },
  {
    'ID': 541,
    'Name': 'Beak Blast'
  },
  {
    'ID': 542,
    'Name': 'Blue Flare'
  },
  {
    'ID': 543,
    'Name': 'Bolt Strike'
  },
  {
    'ID': 544,
    'Name': 'Boomburst'
  },
  {
    'ID': 545,
    'Name': 'Brutal Swing'
  },
  {
    'ID': 546,
    'Name': 'Burn Up'
  },
  {
    'ID': 547,
    'Name': 'Clanging Scales'
  },
  {
    'ID': 548,
    'Name': 'Clear Smog'
  },
  {
    'ID': 549,
    'Name': 'Core Enforcer'
  },
  {
    'ID': 550,
    'Name': 'Crafty Shield'
  },
  {
    'ID': 551,
    'Name': 'Darkest Lariat'
  },
  {
    'ID': 552,
    'Name': 'Dragon Ascent'
  },
  {
    'ID': 553,
    'Name': 'Dragon Hammer'
  },
  {
    'ID': 554,
    'Name': 'Draining Kiss'
  },
  {
    'ID': 555,
    'Name': 'Dual Chop'
  },
  {
    'ID': 556,
    'Name': 'Electric Terrain'
  },
  {
    'ID': 557,
    'Name': 'Electrify'
  },
  {
    'ID': 558,
    'Name': 'Entrainment'
  },
  {
    'ID': 559,
    'Name': 'Fairy Lock'
  },
  {
    'ID': 560,
    'Name': 'Fairy Wind'
  },
  {
    'ID': 561,
    'Name': 'Fire Lash'
  },
  {
    'ID': 562,
    'Name': 'Fire Pledge'
  },
  {
    'ID': 563,
    'Name': 'First Impression'
  },
  {
    'ID': 564,
    'Name': 'Fleur Cannon'
  },
  {
    'ID': 565,
    'Name': 'Floral Healing'
  },
  {
    'ID': 566,
    'Name': 'Flying Press'
  },
  {
    'ID': 567,
    'Name': 'Forest’s Curse'
  },
  {
    'ID': 568,
    'Name': 'Freeze Shock'
  },
  {
    'ID': 569,
    'Name': 'Freeze-Dry'
  },
  {
    'ID': 570,
    'Name': 'Frost Breath'
  },
  {
    'ID': 571,
    'Name': 'Fusion Bolt'
  },
  {
    'ID': 572,
    'Name': 'Fusion Flare'
  },
  {
    'ID': 573,
    'Name': 'Gear Grind'
  },
  {
    'ID': 574,
    'Name': 'Gear Up'
  },
  {
    'ID': 575,
    'Name': 'Grass Pledge'
  },
  {
    'ID': 576,
    'Name': 'Grassy Terrain'
  },
  {
    'ID': 577,
    'Name': 'Guard Split'
  },
  {
    'ID': 578,
    'Name': 'Happy Hour'
  },
  {
    'ID': 579,
    'Name': 'Head Charge'
  },
  {
    'ID': 580,
    'Name': 'Heal Pulse'
  },
  {
    'ID': 581,
    'Name': 'Heart Stamp'
  },
  {
    'ID': 582,
    'Name': 'Heat Crash'
  },
  {
    'ID': 583,
    'Name': 'High Horsepower'
  },
  {
    'ID': 584,
    'Name': 'Hold Back'
  },
  {
    'ID': 585,
    'Name': 'Hold Hands'
  },
  {
    'ID': 586,
    'Name': 'Hyperspace Fury'
  },
  {
    'ID': 587,
    'Name': 'Hyperspace Hole'
  },
  {
    'ID': 588,
    'Name': 'Ice Burn'
  },
  {
    'ID': 589,
    'Name': 'Ice Hammer'
  },
  {
    'ID': 590,
    'Name': 'Icicle Crash'
  },
  {
    'ID': 591,
    'Name': 'Incinerate'
  },
  {
    'ID': 592,
    'Name': 'Infestation'
  },
  {
    'ID': 593,
    'Name': 'Instruct'
  },
  {
    'ID': 594,
    'Name': 'Ion Deluge'
  },
  {
    'ID': 595,
    'Name': 'Land’s Wrath'
  },
  {
    'ID': 596,
    'Name': 'Leaf Tornado'
  },
  {
    'ID': 597,
    'Name': 'Leafage'
  },
  {
    'ID': 598,
    'Name': 'Light of Ruin'
  },
  {
    'ID': 599,
    'Name': 'Liquidation'
  },
  {
    'ID': 600,
    'Name': 'Lunge'
  },
  {
    'ID': 601,
    'Name': 'Mat Block'
  },
  {
    'ID': 602,
    'Name': 'Mind Blown'
  },
  {
    'ID': 603,
    'Name': 'Misty Terrain'
  },
  {
    'ID': 604,
    'Name': 'Moongeist Beam'
  },
  {
    'ID': 605,
    'Name': 'Multi-Attack'
  },
  {
    'ID': 606,
    'Name': 'Nature’s Madness'
  },
  {
    'ID': 607,
    'Name': 'Night Daze'
  },
  {
    'ID': 608,
    'Name': 'Oblivion Wing'
  },
  {
    'ID': 609,
    'Name': 'Origin Pulse'
  },
  {
    'ID': 610,
    'Name': 'Parabolic Charge'
  },
  {
    'ID': 611,
    'Name': 'Phantom Force'
  },
  {
    'ID': 612,
    'Name': 'Photon Geyser'
  },
  {
    'ID': 613,
    'Name': 'Plasma Fists'
  },
  {
    'ID': 614,
    'Name': 'Pollen Puff'
  },
  {
    'ID': 615,
    'Name': 'Powder'
  },
  {
    'ID': 616,
    'Name': 'Power Split'
  },
  {
    'ID': 617,
    'Name': 'Power Trip'
  },
  {
    'ID': 618,
    'Name': 'Precipice Blades'
  },
  {
    'ID': 619,
    'Name': 'Prismatic Laser'
  },
  {
    'ID': 620,
    'Name': 'Psychic Fangs'
  },
  {
    'ID': 621,
    'Name': 'Psychic Terrain'
  },
  {
    'ID': 622,
    'Name': 'Psystrike'
  },
  {
    'ID': 623,
    'Name': 'Purify'
  },
  {
    'ID': 624,
    'Name': 'Quash'
  },
  {
    'ID': 625,
    'Name': 'Reflect Type'
  },
  {
    'ID': 626,
    'Name': 'Relic Song'
  },
  {
    'ID': 627,
    'Name': 'Revelation Dance'
  },
  {
    'ID': 628,
    'Name': 'Sacred Sword'
  },
  {
    'ID': 629,
    'Name': 'Searing Shot'
  },
  {
    'ID': 630,
    'Name': 'Secret Sword'
  },
  {
    'ID': 631,
    'Name': 'Shadow Bone'
  },
  {
    'ID': 632,
    'Name': 'Shell Trap'
  },
  {
    'ID': 633,
    'Name': 'Simple Beam'
  },
  {
    'ID': 634,
    'Name': 'Sky Drop'
  },
  {
    'ID': 635,
    'Name': 'Smart Strike'
  },
  {
    'ID': 636,
    'Name': 'Solar Blade'
  },
  {
    'ID': 637,
    'Name': 'Sparkling Aria'
  },
  {
    'ID': 638,
    'Name': 'Spectral Thief'
  },
  {
    'ID': 639,
    'Name': 'Speed Swap'
  },
  {
    'ID': 640,
    'Name': 'Spirit Shackle'
  },
  {
    'ID': 641,
    'Name': 'Spotlight'
  },
  {
    'ID': 642,
    'Name': 'Steam Eruption'
  },
  {
    'ID': 643,
    'Name': 'Stomping Tantrum'
  },
  {
    'ID': 644,
    'Name': 'Storm Throw'
  },
  {
    'ID': 645,
    'Name': 'Strength Sap'
  },
  {
    'ID': 646,
    'Name': 'Sunsteel Strike'
  },
  {
    'ID': 647,
    'Name': 'Tail Slap'
  },
  {
    'ID': 648,
    'Name': 'Tearful Look'
  },
  {
    'ID': 649,
    'Name': 'Techno Blast'
  },
  {
    'ID': 650,
    'Name': 'Telekinesis'
  },
  {
    'ID': 651,
    'Name': 'Thousand Arrows'
  },
  {
    'ID': 652,
    'Name': 'Thousand Waves'
  },
  {
    'ID': 653,
    'Name': 'Throat Chop'
  },
  {
    'ID': 654,
    'Name': 'Topsy-Turvy'
  },
  {
    'ID': 655,
    'Name': 'Toxic Thread'
  },
  {
    'ID': 656,
    'Name': 'Trick-or-Treat'
  },
  {
    'ID': 657,
    'Name': 'Trop Kick'
  },
  {
    'ID': 658,
    'Name': 'Water Pledge'
  },
  {
    'ID': 659,
    'Name': 'Water Shuriken'
  },
  {
    'ID': 660,
    'Name': 'Zing Zap'
  },
  {
    'ID': 661,
    'Name': 'Corkscrew Crash'
  },
  {
    'ID': 662,
    'Name': 'Devastating Drake'
  },
  {
    'ID': 663,
    'Name': 'Diamond Storm'
  },
  {
    'ID': 664,
    'Name': 'Electroweb'
  },
  {
    'ID': 665,
    'Name': 'Extreme Evoboost'
  },
  {
    'ID': 666,
    'Name': 'Fiery Dance'
  },
  {
    'ID': 667,
    'Name': 'Flower Shield'
  },
  {
    'ID': 668,
    'Name': 'Genesis Supernova'
  },
  {
    'ID': 669,
    'Name': 'Geomancy'
  },
  {
    'ID': 670,
    'Name': 'Gigavolt Havoc'
  },
  {
    'ID': 671,
    'Name': 'Glaciate'
  },
  {
    'ID': 672,
    'Name': 'Guardian of Alola'
  },
  {
    'ID': 673,
    'Name': 'Hone Claws'
  },
  {
    'ID': 674,
    'Name': 'Hydro Vortex'
  },
  {
    'ID': 675,
    'Name': 'Inferno Overdrive'
  },
  {
    'ID': 676,
    'Name': 'Kings Shield'
  },
  {
    'ID': 677,
    'Name': 'Laser Focus'
  },
  {
    'ID': 678,
    'Name': 'Low Sweep'
  },
  {
    'ID': 679,
    'Name': 'Magnetic Flux'
  },
  {
    'ID': 680,
    'Name': 'Malicious Moonsault'
  },
  {
    'ID': 681,
    'Name': 'Mystical Fire'
  },
  {
    'ID': 682,
    'Name': 'Never-Ending Nightmare'
  },
  {
    'ID': 683,
    'Name': 'Noble Roar'
  },
  {
    'ID': 684,
    'Name': 'Oceanic Operetta'
  },
  {
    'ID': 685,
    'Name': 'Parting Shot'
  },
  {
    'ID': 686,
    'Name': 'Power-Up Punch'
  },
  {
    'ID': 687,
    'Name': 'Pulverizing Pancake'
  },
  {
    'ID': 688,
    'Name': 'Recharge'
  },
  {
    'ID': 689,
    'Name': 'Rototiller'
  },
  {
    'ID': 690,
    'Name': 'Shift Gear'
  },
  {
    'ID': 691,
    'Name': 'Shore Up'
  },
  {
    'ID': 692,
    'Name': 'Sinister Arrow Raid'
  },
  {
    'ID': 693,
    'Name': 'Snarl'
  },
  {
    'ID': 694,
    'Name': 'Soul-Stealing 7-Star Strike'
  },
  {
    'ID': 695,
    'Name': 'Spiky Shield'
  },
  {
    'ID': 696,
    'Name': 'Stoked Sparksurfer'
  },
  {
    'ID': 697,
    'Name': 'Subzero Slammer'
  },
  {
    'ID': 698,
    'Name': 'Supersonic Skystrike'
  },
  {
    'ID': 699,
    'Name': 'Tectonic Rage'
  },
  {
    'ID': 700,
    'Name': 'Twinkle Tackle'
  },
  {
    'ID': 701,
    'Name': 'V-create'
  },
  {
    'ID': 702,
    'Name': 'Venom Drench'
  },
  {
    'ID': 703,
    'Name': 'Light That Burns the Sky'
  },
  {
    'ID': 704,
    'Name': 'Searing Sunraze Smash'
  },
  {
    'ID': 705,
    'Name': 'Menacing Moonraze Maelstrom'
  },
  {
    'ID': 706,
    'Name': 'Lets Snuggle Forever'
  },
  {
    'ID': 707,
    'Name': 'Splintered Stormshards'
  },
  {
    'ID': 708,
    'Name': 'Clangorous Soulblaze'
  },
  {
    'ID': 709,
    'Name': 'Struggle Bug'
  },
  {
    'ID': 710,
    'Name': 'Cotton Guard'
  },
  {
    'ID': 711,
    'Name': 'Savage Spin-Out'
  },
  {
    'ID': 712,
    'Name': 'Shattered Psyche'
  },
  {
    'ID': 713,
    'Name': 'Hidden Power Fire'
  },
  {
    'ID': 714,
    'Name': 'Hidden Power Grass'
  },
  {
    'ID': 715,
    'Name': 'Hidden Power Ice'
  },
  {
    'ID': 716,
    'Name': 'Hidden Power Fighting'
  },
  {
    'ID': 717,
    'Name': 'Hidden Power Psychic'
  },
  {
    'ID': 718,
    'Name': 'Hidden Power Electric'
  },
  {
    'ID': 719,
    'Name': 'Hidden Power Ground'
  },
  {
    'ID': 720,
    'Name': 'Hidden Power Rock'
  },
  {
    'ID': 721,
    'Name': 'Hidden Power Flying'
  },
  {
    'ID': 722,
    'Name': 'Hidden Power Shadow'
  },
  {
    'ID': 723,
    'Name': 'Shadow Blast'
  },
  {
    'ID': 724,
    'Name': 'Shadow Blitz'
  },
  {
    'ID': 725,
    'Name': 'Shadow Bolt'
  },
  {
    'ID': 726,
    'Name': 'Shadow Break'
  },
  {
    'ID': 727,
    'Name': 'Shadow Chill'
  },
  {
    'ID': 728,
    'Name': 'Shadow Down'
  },
  {
    'ID': 729,
    'Name': 'Shadow End'
  },
  {
    'ID': 730,
    'Name': 'Shadow Fire'
  },
  {
    'ID': 731,
    'Name': 'Shadow Half'
  },
  {
    'ID': 732,
    'Name': 'Shadow Hold'
  },
  {
    'ID': 733,
    'Name': 'Shadow Mist'
  },
  {
    'ID': 734,
    'Name': 'Shadow Panic'
  },
  {
    'ID': 735,
    'Name': 'Shadow Rave'
  },
  {
    'ID': 736,
    'Name': 'Shadow Rush'
  },
  {
    'ID': 737,
    'Name': 'Shadow Shed'
  },
  {
    'ID': 738,
    'Name': 'Shadow Sky'
  },
  {
    'ID': 739,
    'Name': 'Shadow Storm'
  },
  {
    'ID': 740,
    'Name': 'Shadow Wave'
  }
]
