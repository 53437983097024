// initial state
const state = {
  all: null,
  error: 0
}

// getters
const getters = {
  pokedex: state => state.all
}

// actions
const actions = {
  getPokedex ({ commit }, data) {
    commit('setPokedex', data)
  },
  setError ({commit}, data) {
    commit('setError', data.data)
  }
}

// mutations
const mutations = {
  setPokedex (state, all) {
    state.all = all
  },
  setError (state, error) {
    state.error = {error: error, time: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
