export default {
  gold: {
    colour: 'gold',
    hex: '#a8965c',
    readonly: false,
    test: false
  },
  silver: {
    hex: '#999999',
    colour: 'silver',
    readonly: false,
    test: false
  },
  red: {
    colour: 'danger',
    readonly: true,
    test: false
  },
  blue: {
    colour: 'primary',
    readonly: true,
    test: false
  },
  test: {
    colour: 'success',
    readonly: false,
    test: true
  }
}
