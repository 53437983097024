// initial state
const state = {
  all: null
}

// getters
const getters = {
  Watchlist: state => state.all

}

// actions
const actions = {
  getWatchlist ({ commit }, trades) {
    commit('setWatchlist', trades.data)
  },
  deleteWatchlist ({ commit }, trades) {
    commit('deleteWatchlist', trades.data)
  },
  updateWatchlist ({ commit }, watches) {
    commit('updateWatchlist', watches.data)
  }
}

// mutations
const mutations = {
  setWatchlist (state, products) {
    state.all = products
  },
  deleteWatchlist (state, username) {
    state.all.watchlist = state.all.watchlist.filter(entry => entry.Username.toLowerCase() !== username.toLowerCase());
  },
  updateWatchlist (state, watches) {
    watches.forEach(newEntry => {
      const existingEntry = state.all.watchlist.find(entry =>
        entry.Username.toLowerCase() === newEntry.Username.toLowerCase()
      );

      if (existingEntry) {
        // Replace the entry with the new entry
        state.all.watchlist = state.all.watchlist.map(entry => {
          if (entry.Username.toLowerCase() === newEntry.Username.toLowerCase()) {
            return newEntry;
          }
          return entry;
        });
      } else {
        // Add the new entry to the watchlist
        state.all.watchlist.push(newEntry);
      }
    });
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
