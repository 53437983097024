<template>
  <div>
    <b>{{ item.name }}</b><br>
    <small>{{item.email}}</small>
  </div>
</template>

<script>
  export default {
    props: {
      item: { required: true },
      searchText: { required: true }
    }
  }
</script>
