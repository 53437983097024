// initial state
const state = {
  results: null
}

// getters
const getters = {
  autocompleteResults: state => state.results
}

// actions
const actions = {
  autocomplete ({commit}, result) {
    commit('setResults', result.data)
  },
  resetItems ({commit}) {
    commit('setResults', [])
  }
}

// mutations
const mutations = {
  setResults (state, data) {
    state.results = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
