// initial state
const state = {
  all: {data: null}
}

// getters
const getters = {
  PVPlogs: state => state.all

}

// actions
const actions = {
  getPVPlog ({ commit }, trades) {
    commit('setPVPlog', trades.data)
  }
}

// mutations
const mutations = {
  setPVPlog (state, products) {
    state.all = products
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
