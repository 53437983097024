<template>
  <footer class="app-footer">
    <span class="onlineList"><i class="fa fa-user"></i> Currently online: </span>
    <template v-for="(staff, index) in sortedOnline">
    <router-link :id="'staff-online-' + staff.id" :to="'/user/' + staff.user + '/base'" :style="{color: role(staff.security)}">{{staff.user}}</router-link>
      <template v-if="index+1 < currentOnline.length">, <br></template>
      <b-tooltip :target="'staff-online-' + staff.id" triggers="hover" v-if="staff.ip">
        <span v-for="ip in staff.ip.join(',').split(' ')" class="">{{ip}}</span>
      </b-tooltip>
    </template>
  </footer>
</template>
<script>
    import {mapGetters} from 'vuex'
    import Roles from '../_roles'

    export default {
      name: 'c-footer',
      computed: {
        ...mapGetters(['currentOnline']),
        sortedOnline () {
          function compare (a, b) {
            if (a.user < b.user) {
              return -1
            }
            if (a.user > b.user) {
              return 1
            }
            return 0
          }

          return this.currentOnline.sort(compare)
        }
      },
      methods: {
        role (r) {
          return Roles[r].colour
        }
      }
    }
</script>
