<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 600px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :suppressClickEdit="true"
                 :enableFilter="true"
                 :groupHeaders="true"
                 :suppressRowClickSelection="true"
>
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import moment from 'moment'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records', 'server'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        suppressMovable: true
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      showDate (params) {
        return moment(params.value).utc()
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      },
      createColumnDefs () {
        return [
          {
            headerName: 'Guild Left',
            field: 'left',
            filter: 'agSetColumnFilter'
          },
          {
            headerName: 'Guild Joined',
            field: 'joined',
            filter: 'agSetColumnFilter'
          },
          {
            headerName: 'Date',
            field: 'date',
            sort: 'desc',
            filter: 'agDateColumnFilter',
            suppressFilter: true,
            valueFormatter: this.showDate
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
