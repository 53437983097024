// initial state
const state = {
  all: null,
  error: 0
}

// getters
const getters = {
  allConnections: state => state.all,
  connectionError: state => state.error
}

// actions
const actions = {
  getConnections ({ commit }, data) {
    let regIPConnections = []
    let passwordConnections = []
    let emailConnections = []
    for (let i in data.data.reg_ip_connections) {
      regIPConnections.push({'username': data.data.reg_ip_connections[i]})
    }
    for (let i in data.data.password_connections) {
      passwordConnections.push({'username': data.data.password_connections[i]})
    }
    for (let i in data.data.email_connections) {
      emailConnections.push({'username': data.data.email_connections[i]})
    }
    data.data.reg_ip_connections = regIPConnections
    data.data.password_connections = passwordConnections
    data.data.email_connections = emailConnections
    commit('setConnections', data.data)
  },
  setError ({commit}, data) {
    commit('setError', data.data)
  }
}

// mutations
const mutations = {
  setConnections (state, all) {
    state.all = all
  },
  setError (state, error) {
    state.error = {error: error, time: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
