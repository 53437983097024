<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 190px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :rowData="records"
                 :suppressRowClickSelection="true">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      banType (type) {
        switch (type) {
          case 'P':
            return 'Permanently banned'
          case 'T':
            return 'Temporarily banned'
          case 'E':
            return 'Trade banned'
          case 'M':
            return 'Quiet banned'
          case 'N':
            return 'Notification'
          case '-':
          case 'U':
            return 'Unbanned'
          case 'A':
            return 'MAC banned'
          case 'V':
            return 'UserVar updated'
          case 'X':
            return 'PVP banned'
          case 'S':
            return 'Single-player banned'
          case 'Z':
            return 'Server Transfer'
          case 'C':
            return 'Username changed'
          default:
            return ''
        }
      },
      createColumnDefs () {
        let self = this
        return [
          {
            headerName: 'Staff',
            field: 'staff',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Action',
            field: 'type',
            filter: 'agTextColumnFilter',
            valueGetter: function (params) {
              return self.banType(params.data.type)
            }
          },
          {
            headerName: 'Reason',
            field: 'reason',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Duration',
            field: 'days',
            filter: 'agNumberColumnFilter',
            valueGetter: function (params) {
              if (params.data.days === 0) {
                return ''
              }
              return params.data.days + ' days'
            }
          },
          {
            headerName: 'Date',
            field: 'date',
            sortable: true,
            filter: 'agTextColumnFilter'
          }
        ]
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
