<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="icon-reload"></i>Auditlog
        </div>
        <div class="card-body">

          <div class="d-flex justify-content-end">
            <div class="align-self-end pl-3 align-self-center">
              Display
              <div class="form-inline">
                <div role="group" class="form-check form-check-inline"><!---->
                  <input v-model="displayImportant" id="uid-ja6svm85ye" type="checkbox" name="Option 11"
                         class="form-check-input" value="Option 1">
                  <label for="uid-ja6svm85ye" class="form-check-label"> Only important ones </label><!----><!---->
                  <!----><!----></div>
                <div role="group" class="form-check form-check-inline"><!---->
                  <input id="uid-6wxuu1fc5cf" type="checkbox" name="Option 11" v-model="displayReason"
                         class="form-check-input" value="Option 2">
                  <label for="uid-6wxuu1fc5cf" class="form-check-label"> Missing reasons </label><!----><!----><!---->
                  <!----></div>
              </div>

            </div>
            <div class="align-self-end pl-3  align-self-center">
              <span>Filter by User </span>
              <Autocomplete v-model="filterbyUser" />
            </div>
            <div class="align-self-end pl-3 align-self-center">
              Filter by Action
              <select id="basicSelect" data-cip-id="cIPJQ342845643" class="form-control" v-model="filterbyAction">
                <option value="">All</option>
                <option :value="action" v-for="action in actions">{{ action }}</option>
              </select>
            </div>

          </div>
          <br>
          <div class="spinner-border"></div>
          <div class="list-group">
            <span v-for="log in auditlog" class="list-group-item list-group-item-action ">
                              <div class="align-self-end float-right"><small>{{ formatDate(log.date) }}</small></div>

              <div class="d-flex mb-2">
                <div class="align-self-center" style="width: 20px">
                              <span class="h5"><i :class="getIcon(log.type)" class="fa"></i></span>
                </div>
                <div class="align-self-center pl-3">
                  <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + log.username" alt="" height="50" width="50"
                       class="rounded-circle"/>
                </div>

                <div class="d-flex align-self-center flex-column">
                  <div class="align-self-start pl-3">{{ log.username }}
                    <span v-for="serv in log.servers" :class="'badge-' + getColour(serv)" class="badge text-capitalize">{{ serv }}</span>
                    <mark class="auditReason" v-if="log.reason.length > 0">{{ log.reason }}</mark>
                  </div>
                <span class="pl-3">{{ log.message }}</span>
                </div>


              </div>

            </span>

          </div>
          <br>
          <b-pagination size="md" :total-rows="count" v-model="page" v-on:change="fetchData"
                        :per-page="100"></b-pagination>
        </div>

      </div>
    </div>
  </div>

</template>


<script>
import Permissions from '../../_permissions'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import Servers from '../../_servers'
import Autocomplete from './Permissions/UsernameSearch.vue'

export default {
  name: 'Auditlog',
  computed: mapGetters({
    auditlog: 'Auditlog',
    count: 'LogCount'
  }),
  data () {
    return {
      actions: ['ChangeRole',
        'ForceRestart',
        'GlobalVarsDelete',
        'InventoryAddItem',
        'InventoryEditItem',
        'InventoryRemoveItem',
        'MassbanIPs',
        'MassbanUsers',
        'NewsCreate',
        'NewsDelete',
        'NewsEdit',
        'PokemonAdd',
        'PokemonDelete',
        'PokemonDelevel',
        'PokemonEditAbility',
        'PokemonEditIVS',
        'PokemonEditNature',
        'PokemonEditOT',
        'PokemonEditTradelock',
        'PokemonOwnerTransfer',
        'PokemonRecover',
        'PokemonView',
        'SnapshotCreate',
        'UserBan',
        'UserCoinChange',
        'UserEmailChange',
        'UserMoneyChange',
        'UserNameChange',
        'UserRatingChange',
        'UserReset',
        'UserServerTransfer',
        'UserUnmerge',
        'VariablesDelete', 'VariablesEdit'],
      page: 1,
      filterbyUser: '',
      displayImportant: true,
      displayReason: false,
      filterbyAction: '',
      permissions: Permissions
    }
  },
  created () {
    this.clearAuditlog()
  },
  components: {
    Autocomplete
  },
  mounted () {
    this.emit('getAuditlog', {
      'page': this.page,
      'count': true,
      'display_only_important': this.displayImportant,
      'display_only_reasons': this.displayReason,
      'filterbyAction': this.filterbyAction,
      'filter_by_user': this.filterbyUser !== null ? this.filterbyUser.name : ''
    })
  },
  watch: {
    displayImportant () {
      this.updateLog()
    },
    displayReason () {
      this.updateLog()
    },
    filterbyAction () {
      this.updateLog()
    },
    filterbyUser () {
      this.updateLog()
    }
  },
  methods: {
    ...mapActions(['clearAuditlog']),
    updateLog () {
      this.clearAuditlog()
      this.page = 1
      this.emit('getAuditlog', {
        'page': this.page,
        'count': true,
        'display_only_important': this.displayImportant,
        'display_only_reasons': this.displayReason,
        'filterbyAction': this.filterbyAction,
        'filter_by_user': this.filterbyUser !== null ? this.filterbyUser.name : ''
      })
    },
    fetchData (e) {
      this.clearAuditlog()
      this.emit('getAuditlog', {
        'page': e,
        'count': false,
        'display_only_important': this.displayImportant,
        'display_only_reasons': this.displayReason,
        'filterbyAction': this.filterbyAction,
        'filter_by_user': this.filterbyUser !== null ? this.filterbyUser.name : ''
      })
    },
    important (perm) {
      if ('Important' in perm) {
        return 'switch-warning-outline-alt'
      }
      return 'switch-primary-outline-alt'
    },
    sortedServer (log) {
      log.sort()
      return log
    },
    getColour (server) {
      if (server in Servers) {
        return Servers[server].colour
      }
      return server
    },
    getIcon (type) {
      for (let i in this.permissions) {
        for (let i2 in this.permissions[i].Permissions) {
          if (type === i2 && 'icon' in this.permissions[i].Permissions[i2]) {
            return 'fa-' + this.permissions[i].Permissions[i2].icon
          }
        }
      }
      return 'fa-pencil'
    },
    formatDate (date) {
      return moment(date).format('MMMM Do YYYY, hh:mm:ss')
    }
  }
}
</script>
