<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">Rank</span>
    </div>
    <select v-model="rank" class="form-control" id="select1" name="select1">
      <option :disabled="index === 5 || index === 1" :value="index" v-for="(role, index) in roles">{{role.name}}</option>
    </select>
    <span class="input-group-append">
      <button :disabled="rank===currentRole || userLock" @click="changeRole"  class="btn btn-primary" type="button">Change</button>
    </span>
  </div>
</template>
<script>
  import VueNotifications from 'vue-notifications'
  import { mapGetters, mapActions } from 'vuex'
  import Roles from '../../../_roles'

  export default {
    name: 'username',
    props: ['user', 'currentRole'],
    data () {
      return {
        error: null,
        rank: this.currentRole,
        roles: Roles
      }
    },
    computed: {
      ...mapGetters([
        'userLock'
      ])
    },
    methods: {
      changeRole () {
        this.setLock(true)
        this.ChangeInfo()
        this.emit('changeRole', {'username': this.$route.params.username, 'security': this.rank})
      },
      ...mapActions(['setLock'])
    },
    notifications: {
      ChangeInfo: {
        type: VueNotifications.types.info,
        title: 'Changing in progress!',
        message: 'This may take several seconds, please be patient.'
      }
    }
  }
</script>
