<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-card
        header-tag="header"
        footer-tag="footer">
        <div slot="header">
          <i class="icon-lock"></i> <strong>Mass bans</strong>

        </div>
        <div>
          <div class="form-group row">
            <div class="col-md-12">
              <textarea id="textarea-input" v-model="text" name="textarea-input" rows="10" class="form-control" placeholder="Separated with lines"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Reason</span>
                </div>
                <input v-model="reason" class="form-control" name="input2-group1"
                       placeholder="Reason..." type="text" data-cip-id="input2-group1">
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Type</span>
                </div>
                <select id="basicSelect" v-model="selectBan" class="form-control" data-cip-id="cIPJQ342845643">
                  <option value="">Please select</option>
                  <option :disabled="!hasPermission(MassbanUsers)" value="username">Username</option>
                  <option :disabled="!hasPermission(MassbanEMails)" value="email">Email</option>
                  <option :disabled="!hasPermission(MassbanIPs)" value="ip">IP address</option>
                </select>
              </div>
            </div>

          </div>
          <div class="form-group row">

            <div class="col-md-12">
              <div class="input-group ">
                <div id="basicInlineCustomCheckboxes" role="group" tabindex="-1" class="">
                  <div class="custom-control active custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                                  id="customInChk1"
                                                                                                  value="silver"
                                                                                                  v-model="checked"
                                                                                                  class="custom-control-input">
                    <label for="customInChk1" class="custom-control-label">Silver</label></div>
                  <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                           id="customInChk2"
                                                                                           value="gold"
                                                                                           v-model="checked"
                                                                                           class="custom-control-input">
                    <label for="customInChk2" class="custom-control-label">Gold</label></div>
                  <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                           id="customInChk3"
                                                                                           value="red"
                                                                                           v-model="checked"
                                                                                           class="custom-control-input">
                    <label for="customInChk3" class="custom-control-label">Red</label></div>
                  <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                           id="customInChk4"
                                                                                           value="blue"
                                                                                           v-model="checked"
                                                                                           class="custom-control-input">
                    <label for="customInChk4" class="custom-control-label">Blue</label></div>
                </div>
              </div>
              <br>
              <button class="btn btn-danger" @click="banButton" :disabled="loading || text.length === 0 || selectBan === '' || this.checked.length === 0" type="submit" ><i class="fa fa-ban"></i> Ban</button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>

</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  import Servers from '../../_servers'
  import VueNotifications from 'vue-notifications'

  export default {
    name: 'Auditlog',
    data () {
      return {
        loading: false,
        text: '',
        selectBan: '',
        reason: '',
        checked: ['gold', 'silver']
      }
    },
    computed: mapGetters({
      error: 'massBanError'
    }),
    methods: {
      ...mapActions(['clearAuditlog']),
      important (perm) {
        if ('Important' in perm) {
          return 'switch-warning-outline-alt'
        }
        return 'switch-primary-outline-alt'
      },
      getColour (server) {
        if (server in Servers) {
          return Servers[server].colour
        }
        return 'primary'
      },
      checkIsIPV4 (entry) {
        let blocks = entry.split('.')
        if (blocks.length === 4) {
          return blocks.every(function (block) {
            return parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255
          })
        }
      },
      validateEmail (email) {
        let re = /\S+@\S+\.\S+/
        return re.test(String(email).toLowerCase())
      },
      isAlphaNumeric (str) {
        let code, i, len
        for (i = 0, len = str.length; i < len; i++) {
          code = str.charCodeAt(i)
          if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false
          }
        }
        return true
      },
      banButton () {
        let lines = this.text.split('\n')
        let objects = []
        for (let i in lines) {
          if (lines[i]) {
            if (this.selectBan === 'email' && !this.validateEmail(lines[i])) {
              this.InvalidEmails()
              return
            } else if (this.selectBan === 'ip' && !this.checkIsIPV4(lines[i])) {
              this.InvalidIPs()
              return
            } else if (this.selectBan === 'username' && !this.isAlphaNumeric(lines[i])) {
              this.InvalidUsernames()
              return
            }
            objects.push(lines[i])
          }
        }
        if (lines.length > 5) {
          this.ChangeInfo()
        }
        this.loading = true
        this.emit('massBan', {'type': this.selectBan, 'servers': this.checked, 'values': objects, 'reason': this.reason})
      }
    },
    notifications: {
      InvalidIPs: {
        type: VueNotifications.types.error,
        title: 'Mass ban failed!',
        message: 'Your IPs are invalid, please enter valid IPs!'
      },
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Mass ban was successful!',
        message: 'Mass ban was successful!'
      },
      InvalidEmails: {
        type: VueNotifications.types.error,
        title: 'Mass ban failed!',
        message: 'Your E-Mails are invalid, please enter valid E-Mails!'
      },
      InvalidUsernames: {
        type: VueNotifications.types.error,
        title: 'Mass ban failed!',
        message: 'Your Usernames are invalid, please enter valid Usernames!'
      },
      ChangeInfo: {
        type: VueNotifications.types.info,
        title: 'Rename in progress!',
        message: 'This may take several seconds, please be patient.'
      }
    },
    watch: {
      error: function (val) {
        let notFound = []
        let online = []
        let error = val.error
        let count = 0
        for (let i in error) {
          if (error[i] === 3) {
            notFound.push(i)
          } else if (error[i] === 2) {
            online.push(i)
          } else {
            count++
          }
        }
        this.text = ''
        if (notFound.length > 0) {
          this.text += 'Following user/s doesn\'t exists:\n' + notFound.join('\n') + '\n'
        }
        if (online.length > 0) {
          this.text += 'Following user/s are online:\n' + online.join('\n') + '\n'
        }
        this.text += 'Banned ' + count + ' users/ips/emails.'
        this.loading = false
      }
    }
  }
</script>
