<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="icon-cursor"></i>News
          <router-link :to="'/news/create'" class="btn btn-green btn-sm float-right" href="" :disabled="!hasPermission(NewsCreate)">
            <i class="fa fa-magic"></i> Create
          </router-link>
        </div>
        <div class="card-body">
          <div class="list-group">
            <span v-for="log in newslog" class="list-group-item list-group-item-action flex-column align-items-start" :key="log.id">
              <div class="d-flex w-100 justify-content-between">
                <div class="mb-1"><span class="h5"> {{log.title}}</span></div>
                <small></small>
                <div class="float-right">
                  <router-link :to="'/news/edit/' + log.id " :disabled="!hasPermission(NewsDelete)"  class=" btn btn-warning btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                <button type="button" :disabled="!hasPermission(NewsEdit)" @click="deleteNews(log.id)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Delete</button>
                </div>
              </div>
              <p class="mb-1">Created by {{log.username}} - {{formatDate(log.date)}}</p>
            </span>
          </div>
          <br>
          <b-pagination size="md" :total-rows="count" v-model="page"  v-on:change="fetchData" :per-page="100"></b-pagination>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'

  export default {
    name: 'Newslog',
    computed: mapGetters({
      newslog: 'Newslog',
      count: 'NewsLogCount'
    }),
    data () {
      return {
        page: 1,
        onlyWarn: true
      }
    },
    created () {
      this.clearNewslog()
    },
    mounted () {
      this.emit('getNewslog', {'page': this.page, 'count': true})
    },
    methods: {
      ...mapActions(['clearNewslog']),
      deleteNews (id) {
        this.emit('deleteNews', {'id': id})
        this.clearNewslog()
        this.emit('getNewslog', {'page': this.page, 'count': false})
      },
      fetchData (e) {
        this.clearNewslog()
        this.emit('getNewslog', {'page': e, 'count': false})
      },
      formatDate (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')
      }
    }
  }
</script>
