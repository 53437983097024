import Permissions from '../../_permissions'

// initial state
const state = {
  all: [],
  users: {},
  error: 0
}

// getters
const getters = {
  allPermissions: state => state.all,
  userPermissions: state => state.users,
  PermissionsError: state => state.error
}

// actions
const actions = {
  getPermissions ({commit}, data) {
    let format = {}
    let formatUsers = {}
    for (let i3 in data.data.role_permissions) {
      let roleFormat = []
      for (let i in Permissions) {
        for (let i2 in Permissions[i].Permissions) {
          if (Permissions[i].Permissions[i2].Permission.and(data.data.role_permissions[i3]).neq(0)) {
            roleFormat.push(Permissions[i].Permissions[i2].Permission)
          }
        }
      }
      format[i3] = roleFormat
    }
    for (let i3 in data.data.user_permissions) {
      let userFormat = {user: data.data.user_permissions[i3].user, perm: {}}
      for (let i in Permissions) {
        for (let i2 in Permissions[i].Permissions) {
          if (Permissions[i].Permissions[i2].Permission.and(data.data.user_permissions[i3].perm.deny).neq(0)) {
            userFormat.perm[Permissions[i].Permissions[i2].Permission] = false
          } else if (Permissions[i].Permissions[i2].Permission.and(data.data.user_permissions[i3].perm.allow).neq(0)) {
            userFormat.perm[Permissions[i].Permissions[i2].Permission] = true
          } else {
            userFormat.perm[Permissions[i].Permissions[i2].Permission] = null
          }
        }
      }
      formatUsers[i3] = userFormat
    }
    commit('setPermissions', format)
    commit('setUserPermissions', formatUsers)
  },
  addUserPermission ({commit}, name) {
    commit('addUserPermission', name)
  },
  deleteUserPermission ({commit}, name) {
    commit('deleteUserPermission', name)
  }
}

// mutations
const mutations = {
  setPermissions (state, all) {
    state.all = all
  },
  setUserPermissions (state, all) {
    state.users = all
  },
  deleteUserPermission (state, user) {
    delete state.users[user.id]
  },
  addUserPermission (state, name) {
    let userFormat = {user: name, perm: {}}
    for (let i in Permissions) {
      for (let i2 in Permissions[i].Permissions) {
        userFormat.perm[Permissions[i].Permissions[i2].Permission] = null
      }
    }
    state.users[name.id] = userFormat
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
