// initial state
const state = {
  stats: null,
  error: null,
  serverStatus: {'silver': [], 'gold': []},
  pokeTime: {'silver': 0, 'gold': 0}
}

// getters
const getters = {
  stats: state => state.stats,
  RestartError: state => state.error,
  statusSilver: state => state.serverStatus.silver,
  statusGold: state => state.serverStatus.gold,
  pokeTime: state => state.pokeTime
}

// actions
const actions = {
  getDashboard ({ commit }, pokemon) {
    commit('setDashboard', pokemon.data)
  },
  restartError ({ commit }, error) {
    commit('setRestartError', error.data)
  },
  status ({ commit }, newStatus) {
    commit('addStatus', newStatus.data)
  }
}

// mutations
const mutations = {
  setDashboard (state, dashboard) {
    state.stats = dashboard.stats
    state.serverStatus = dashboard.status
    state.pokeTime = dashboard.poke_time
    for (let key in dashboard.poke_time) {
      if (key in state.pokeTime) {
        state.pokeTime[key] = dashboard.poke_time[key] - Date.now() / 1000
      }
    }
  },
  addStatus (state, newStatus) {
    for (let key in newStatus) {
      if (key in state.serverStatus) {
        state.serverStatus[key].push(newStatus[key][0])
      }
    }
  },
  setRestartError (state, newStatus) {
    state.error = {error: newStatus, date: Date.now()}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
