<template>
  <div class="wrapper">
    <div class="animated fadeIn">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-globe"></i> <strong>Pokemon</strong>
              <span v-if="servers[activeServer].readonly" class="badge badge-primary">Read-only</span>
              <div class="card-actions">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <template v-for="(item, index) in servers">
                    <button type="button" @click="switchServer(index)"
                            :class="['btn-' + item.colour, activeServer === index ? 'active' : '']"
                            class="btn btn-small">{{capitalizeFirstLetter(index)}}
                    </button>
                  </template>
                </div>
              </div>
            </div>

            <div>
            <pokemon-add :modal.sync="addPokemon"></pokemon-add>
              <ag-grid-vue style='width: 100%; height: 700px' class='ag-theme-balham-dark'
                           :gridOptions='gridOptions'
                           :getContextMenuItems="getContextMenuItems">

              </ag-grid-vue>
            </div>
          </b-card>
    </div>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import 'ag-grid-enterprise'
  import Servers from '../../_servers'
  import VueNotifications from 'vue-notifications'
  import { mapGetters } from 'vuex'
  import PokemonAdd from './PokemonAdd'
  import Regions from './_regions'
  import Natures from './_natures'
  import Monsters from './_monsters'
  import iziToast from 'izitoast'
  import {DefaultServerGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        EnterpriseDatasource () {},
        disableRecover: false,
        disableDelete: false,
        disableTransfer: false,
        cb: null,
        addPokemon: false,
        lock: false,
        servers: Servers,
        activeServer: Object.keys(Servers)[0]
      }
    },
    computed: {
      ...mapGetters(['pokemonError', 'allPokemon'])
    },
    components: {
      PokemonAdd,
      'ag-grid-vue': AgGridVue,
      'pokemon-add': PokemonAdd
    },
    methods: {
      onAbilityChange (params) {
        if (params.oldValue === params.newValue) {
          return
        }
        let abs = this.getPokemonfromMonsters(params.data.Pokemon).Ability
        let index = abs.indexOf(params.newValue)
        if (index === -1) {
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'Ability', value: index + 1, server: this.activeServer})
      },
      onNatureChange (params) {
        if (params.oldValue === params.newValue) {
          return
        }
        let index = Natures.indexOf(params.newValue)
        if (index === -1) {
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'Nature', value: index, server: this.activeServer})
      },
      onFormChange (params) {
        if (params.oldValue === params.newValue) {
          return


       }
        if (params.newValue < 0) {
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'Form', value: Number(params.newValue), server: this.activeServer})
      },
      onOTChange (params) {
        if (params.oldValue === params.newValue) {
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'OT', value: params.newValue, server: this.activeServer})
      },
      onIVSChange (params) {
        if (params.oldValue === params.newValue) {
          return
        }
        let iv = Number(params.newValue)
        if (iv > 31 || iv < 1) {
          this.InvalidIV()
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'IVS', value: [Number(params.data.IV_ATK), Number(params.data.IV_DEF), Number(params.data.IV_SPD), Number(params.data.IV_SPATK), Number(params.data.IV_SPDEF), Number(params.data.IV_HP)], server: this.activeServer})
      },
      onTradelockChange (params) {
        if (params.oldValue === params.newValue) {
          return
        }
        this.emit('editPokemon', {id: params.data.ID, key: 'Tradelock', value: params.newValue, server: this.activeServer})
      },
      getPokemonfromMonsters (pokemon) {
        for (let i in Monsters) {
          if (Monsters[i].Name === pokemon) {
            return Monsters[i]
          }
        }
        return []
      },
      onOwnerChange (params) {
        let self = this
        if (params.oldValue !== params.newValue) {
          let ids = new Set([params.data.ID])
          let selectedRows = params.api.getSelectedRows()
          for (let i in selectedRows) {
            ids.add(selectedRows[i].ID)
          }
          if (ids.size > 1) {
            iziToast.question({
              timeout: 20000,
              close: true,
              overlay: true,
              toastOnce: true,
              id: 'question',
              zindex: 99999,
              message: 'You are about to transfer <strong>' + ids.size + '</strong> pokemon to <strong>' + params.newValue + '</strong>. Are you sure?',
              position: 'center',
              buttons: [
                ['<button><b>YES</b></button>', function (instance, toast) {
                  iziToast.hide({}, toast)
                  self.emit('transferPokemon', {
                    id: Array.from(ids),
                    value: params.newValue,
                    server: self.activeServer
                  })
                }, true]
              ]
            })
          } else {
            this.emit('transferPokemon', {
              id: [params.data.ID],
              value: params.newValue,
              server: this.activeServer
            })
          }
        }

      },
      switchServer (index) {
        this.activeServer = index
        this.gridOptions.api.setServerSideDatasource(new this.EnterpriseDatasource())
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      createRowData () {
        return this.records
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this;
        let selectedRows = params.api.getSelectedRows();
        selectedRows.push(params.node.data);
        console.log(selectedRows);
        let serverReadonly = self.servers[self.activeServer].readonly;
        let hasRecoverPermission = this.hasPermission(this.PokemonRecover);
        let hasRecoverScriptPermission = this.hasPermission(this.PokemonRecoverScript);

        let disableRecover = serverReadonly || selectedRows.some(row => {
          let position = row.Position;
          let invalidPosition = position > 0 || position < -3;
          let noRecoverPermission = position === -1 && !hasRecoverPermission;
          let noRecoverScriptPermission = position === -2 && !hasRecoverScriptPermission;

          return invalidPosition || noRecoverPermission || noRecoverScriptPermission;
        });
        let items = [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: 'Recover',
            icon: '<i class="fa fa-mail-reply"/>',
            disabled: disableRecover,
            action: function () {
              let ids = new Set([params.node.data.ID])
              let selectedRows = params.api.getSelectedRows()
              for (let i in selectedRows) {
                ids.add(selectedRows[i].ID)
              }
              self.emit('recoverPokemon',
                {
                  id: Array.from(ids),
                  value: params.node.data.Username,
                  server: self.activeServer
                })
            }
          },
          {
            name: 'Delevel',
            icon: '<i class="fa fa-level-down"/>',
            disabled: self.servers[self.activeServer].readonly || !params.node.data || params.node.data.LVL === 1 || !self.hasPermission(self.PokemonDelevel),
            action: function () {
              self.emit('delevelPokemon',
                {
                  id: params.node.data.ID,
                  value: params.node.data.Username,
                  server: self.activeServer
                })
            }
          },
          {
            name: 'Transfer',
            icon: '<i class="fa fa-spinner"/>',
            disabled: self.servers[self.activeServer].readonly || !params.node.data || !self.hasPermission(self.PokemonOwnerTransfer),
            action: function () {
              self.gridOptions.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: 'Username'
              })
            }
          },
          {
            name: 'Delete',
            icon: '<i class="fa fa-trash"/>',
            disabled: self.servers[self.activeServer].readonly || !params.node.data || params.node.data.Position < 1 || !self.hasPermission(self.PokemonDelete),
            action: function () {
              self.emit('deletePokemon',
                {
                  id: params.node.data.ID,
                  value: params.node.data.Username,
                  server: self.activeServer
                })
            }
          },
          'separator',
          'export'
        ]
        if (self.hasPermission(self.PokemonAddView)) {
          items.splice(3, 0, {
            name: 'Add',
            icon: '<i class="fa fa-plus"/>',
            action: function () {
              self.addPokemon = true
            }
          })
        }
        return items
      },
      createColumnDefs () {
        let self = this
        return [
          {
            headerName: 'Owner',
            sortable: true,
            field: 'Username',
            filter: 'agTextColumnFilter',
            checkboxSelection: true,
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals', 'notEqual'],
              defaultOption: 'equals'
            },
            width: 110,
            editable: self.hasPermission(self.PokemonOwnerTransfer),
            onCellValueChanged: this.onOwnerChange
          },
          {
            headerName: 'Pokemon',
            field: 'Pokemon',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals', 'notEqual'],
              defaultOption: 'equals'
            },
            width: 100
          },
          {
            headerName: 'GID',
            field: 'GID',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 85
          },
          {
            headerName: 'ID',
            field: 'ID',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 85
          },
          {
            headerName: 'Gender',
            field: 'Gender',
            width: 80,
            hide: true
          },
          {
            headerName: 'Region',
            field: 'CaughtRegion',
            width: 80,
            hide: true,
            valueFormatter: function (params) {
              return Regions[params.value - 1]
            }
          },
          {
            headerName: 'Position',
            field: 'Position',
            sortable: true,
            width: 85,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
          },
          {
            headerName: 'Level',
            field: 'LVL',
            sortable: true,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'Shiny',
            field: 'Special',
            filter: 'agNumberColumnFilter',
            suppressAndOrCondition: true,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual']},
            width: 70
          },
          {
            headerName: 'Form',
            field: 'Form',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditForm),
            onCellValueChanged: this.onFormChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'Nature',
            field: 'Nature',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals', 'notEqual'],
              defaultOption: 'equals'
            },
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: Natures
            },
            onCellValueChanged: this.onNatureChange,
            editable: self.hasPermission(self.PokemonEditNature),
            width: 80
          },
          {
            headerName: 'Ability',
            field: 'Ability',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              clearButton: true,
              newRowsAction: 'keep',
              filterOptions: ['equals', 'notEqual'],
              defaultOption: 'equals'
            },
            onCellValueChanged: this.onAbilityChange,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: params => {
              let abilitys = self.getPokemonfromMonsters(params.data.Pokemon).Ability.filter(el => el !== 'None')
              return {
                values: abilitys
              }
            },
            editable: self.hasPermission(self.PokemonEditAbility),
            width: 100
          },
          {
            headerName: 'OT',
            field: 'OT',
            filter: 'agTextColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ['equals', 'notEqual'],
              defaultOption: 'equals'
            },
            onCellValueChanged: this.onOTChange,
            editable: self.hasPermission(self.PokemonEditOT),
            width: 110
          },
          {
            headerName: 'ATK',
            field: 'IV_ATK',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'DEF',
            field: 'IV_DEF',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'SPD',
            field: 'IV_SPD',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'SPATK',
            field: 'IV_SPATK',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 80
          },
          {
            headerName: 'SPDEF',
            field: 'IV_SPDEF',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 80
          },
          {
            headerName: 'HP',
            field: 'IV_HP',
            filter: 'agNumberColumnFilter',
            editable: self.hasPermission(self.PokemonEditIVS),
            onCellValueChanged: this.onIVSChange,
            filterParams: {suppressAndOrCondition: true,filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan',]},
            width: 70
          },
          {
            headerName: 'ATK_EV',
            field: 'EVATK',
            hide: true,
            width: 90
          },
          {
            headerName: 'DEF_EV',
            field: 'EVDEF',
            hide: true,
            width: 90
          },
          {
            headerName: 'SPD_EV',
            field: 'EVSPD',
            hide: true,
            width: 90
          },
          {
            headerName: 'SPATK_EV',
            field: 'EVSPATK',
            hide: true,
            width: 95
          },
          {
            headerName: 'SPDEF_EV',
            field: 'EVSPDEF',
            hide: true,
            width: 95
          },
          {
            headerName: 'HP_EV',
            field: 'EVHP',
            hide: true,
            width: 90
          },
          {
            headerName: 'Caught Date',
            field: 'CaughtDate',
            width: 140
          },
          {
            headerName: 'Release Date',
            field: 'release_date',
            width: 140
          },
          {
            headerName: 'Item',
            field: 'Item',
            width: 100
          },
          {
            headerName: 'Trade locked',
            field: 'trade_locked',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: params => {
              return {
                values: [false, true]
              }
            },
            onCellValueChanged: this.onTradelockChange,
            editable: self.hasPermission(self.PokemonEditTradeLock),
            width: 110
          }
        ]
      }
    },
    beforeMount () {
      let self = this
      this.gridOptions = Object.assign({}, DefaultServerGridOptions, {
        rowModelType: 'serverSide',
        animateRows: true,
        rowMultiSelectWithClick: true,
        rowSelection: 'multiple',
        cacheBlockSize: 100,
        suppressRowDeselection: true,
        serverSideInfiniteScroll: true
      })
      this.gridOptions.getRowId = d => {
        return d.data.ID;
      }
      this.gridOptions.columnDefs = this.createColumnDefs()
      this.gridOptions.onGridReady = () => {
        self.EnterpriseDatasource.prototype.getRows = function (params) {
          let req = params.request
          req.server = self.activeServer
          self.emit('searchPokemon', req)
          self.cb = params.successCallback
        }
        self.gridOptions.api.setFilterModel(null)
        self.gridOptions.api.onFilterChanged()
        self.gridOptions.api.setServerSideDatasource(new this.EnterpriseDatasource())
      }
    },
    watch: {
      pokemonError: function (e) {
        switch (e.error) {
          case 1:
            this.ChangeSuccessful()
            this.addPokemon = false
            this.gridOptions.api.deselectAll()
            this.gridOptions.api.refreshServerSide()
            break
          case 2:
            this.UserOnline()
            break
          case 10:
            this.addPokemon = false
            this.RequestInProgress()
            break
          case 3:
            this.InvalidUser()
            this.gridOptions.api.refreshServerSide()
            break
        }
      },
      allPokemon: function (e) {
        this.cb(e.rows, e.lastRow)
      }
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      },
      InvalidIV: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Please pass a valid IV value'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      InvalidUser: {
        type: VueNotifications.types.error,
        title: 'Transfer failed!',
        message: 'Transfer failed, username is incorrect.'
      },
      RequestInProgress: {
        type: VueNotifications.types.info,
        title: 'Request in progress',
        message: 'It has been forwarded to an admin.'
      }
    }
  }
</script>
