<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 300px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :rowData="records">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        suppressMovable: true
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      createColumnDefs () {
        return [
          {
            headerName: 'Username',
            field: 'username',
            filter: 'agTextColumnFilter'
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    }
  }
</script>

<style>
</style>
