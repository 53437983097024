 <template>
   <b-card no-body>
     <b-tabs card>
       <b-tab title="Ranked" active>
         <div>
           <b-row>
             <b-col cols="6">
               <b-input-group prepend="Wins" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.ranked_wins"></b-form-input>
                </b-input-group>
             </b-col>
             <b-col cols="6">
               <b-input-group prepend="Losses" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.ranked_losses"></b-form-input>
               </b-input-group>
             </b-col>
           </b-row>
            <br>
           <div>
             <b-form inline>
               <b-input-group prepend="Rating" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :readonly="!hasPermission(UserRatingChange)" v-model="userData.pvp.ranked_rating"></b-form-input>
               </b-input-group>
               <b-button variant="primary"  :disabled="!hasPermission(UserRatingChange) || servers[userData.server].readonly || userLock" @click="changeRankedRating">Change</b-button>
             </b-form>
           </div>
         </div>
       </b-tab>
       <b-tab title="Random">
         <div>
           <b-row>
             <b-col cols="6">
               <b-input-group prepend="Wins" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.random_wins"></b-form-input>
               </b-input-group>
             </b-col>
             <b-col cols="6">
               <b-input-group prepend="Losses" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.random_losses"></b-form-input>
               </b-input-group>
             </b-col>
           </b-row>
           <br>
           <div>
             <b-form inline>
               <b-input-group prepend="Rating" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :readonly="!hasPermission(UserRatingChange)" v-model="userData.pvp.random_rating"></b-form-input>
               </b-input-group>
               <b-button variant="primary"  :disabled="!hasPermission(UserRatingChange) || servers[userData.server].readonly || userLock" @click="changeRandomRating">Change</b-button>
             </b-form>
           </div>
         </div>
       </b-tab>
       <b-tab title="Unranked">
         <div>
           <b-row>
             <b-col cols="6">
               <b-input-group prepend="Wins" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.unranked_wins"></b-form-input>
               </b-input-group>
             </b-col>
             <b-col cols="6">
               <b-input-group prepend="Losses" class="mb-2 mr-sm-2 mb-sm-0">
                 <b-form-input id="inline-form-input-username" :value="userData.pvp.unranked_losses"></b-form-input>
               </b-input-group>
             </b-col>
           </b-row>
         </div>
       </b-tab>
     </b-tabs>
   </b-card>
</template>
<script>
  import Servers from '../../../_servers'
  import IPHistory from './IPHistory'
  import BanHistory from './BanHistory'
  import Username from './Username'
  import Email from './Email'
  import Coins from './Coins'
  import Money from './Money'
  import Role from './Role'
  import { mapGetters, mapActions } from 'vuex'
  import VueNotifications from 'vue-notifications'
  import Roles from '../../../_roles'
  import iziToast from 'izitoast'
  import ServerMenu from "@/components/Servernenu.vue";

  export default {
    name: 'PvP',
    props: ['userData', 'userLock', 'servers'],

    data () {
      return {
      }
    },
    created () {
    },

    methods: {
      changeRankedRating () {
        let self = this
        iziToast.question({
          timeout: 20000,
          close: true,
          overlay: true,
          toastOnce: true,
          id: 'question',
          zindex: 99999,
          message: 'Are you sure that you want to change <strong>' + this.$route.params.username + '</strong>\'s pvp <strong>ranked</strong> rating to <strong> ' + self.userData.pvp.ranked_rating + '</strong>?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              iziToast.hide({}, toast)
              self.emit('changeRating', {
                id: self.userData.id,
                value: Number(self.userData.pvp.ranked_rating),
                server: self.userData.server
              })
            }, true]
          ]
        })
      },
      changeRandomRating () {
        let self = this
        iziToast.question({
          timeout: 20000,
          close: true,
          overlay: true,
          toastOnce: true,
          id: 'question',
          zindex: 99999,
          message: 'Are you sure that you want to change <strong>' + this.$route.params.username + '</strong>\'s pvp <strong>random</strong> rating to <strong> ' + self.userData.pvp.random_rating + '</strong>?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              iziToast.hide({}, toast)
              self.emit('changeRandomRating', {
                id: self.userData.id,
                value: Number(self.userData.pvp.random_rating),
                server: self.userData.server
              })
            }, true]
          ]
        })
      },
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      },
      UsernameTaken: {
        type: VueNotifications.types.error,
        title: 'Rename failed!',
        message: 'This username is already taken.'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      UserNotFound: {
        type: VueNotifications.types.error,
        title: 'Username not found!',
        message: 'Username doesn\'t exist.'
      },
      TransferProgress: {
        type: VueNotifications.types.info,
        title: 'Transfer in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      NotMerged: {
        type: VueNotifications.types.error,
        title: 'This account was not merged yet!',
        message: 'You might want to check Red and Blue!'
      },
      TransferErrorNotMerged: {
        type: VueNotifications.types.error,
        title: 'Server Transfer failed!',
        message: ' This account was not merged yet!'
      },
      UnmergeError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'Unmerge failed somehow!'
      },
      UnmergeTradeError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'This user has trades on Silver.'
      },
      UnmergeTransferError: {
        type: VueNotifications.types.error,
        title: 'Unmerge failed!',
        message: 'This user already transferred servers.'
      },
      OpenLendingTrades: {
        type: VueNotifications.types.error,
        title: 'Transfer failed!',
        message: 'You cannot transfer a user that has a pending lending trade.'
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getUserData', {'username': to.params.username, 'server': to.query.server})
      next()
    }
  }
</script>
