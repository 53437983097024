<template>
  <div class="sidebar">
    <SidebarHeader/>
    <SidebarForm/>
    <nav class="sidebar-nav">
      <div slot="header"></div>

      <ul class="nav">
        <template v-for="(item, index) in navItems">

          <template v-if="item.title">
          <SidebarNavTitle :name="item.name" :classes="item.class" :wrapper="item.wrapper"/>
        </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :classes="item.class"/>
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel :name="item.name" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class"/>
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown  :permission="item.permission" :name="item.name" :url="item.url" :icon="item.icon">
                <template v-for="(childL1, index) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown  :permission="item.permission"  :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                      <li class="nav-item" v-for="(childL2, index) in childL1.children">
                        <SidebarNavLink :name="childL2.name"  :permission="item.permission" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge" :variant="item.variant"/>
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :classes="item.class">
                      <SidebarNavLink :name="childL1.name"  :permission="item.permission"  :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant"/>
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>

            <template v-else>
              <SidebarNavItem :classes="item.class">
                <SidebarNavLink :urlName="item.urlName" :permission="item.permission" :name="item.name" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant"/>
              </SidebarNavItem>
            </template>
          </template>
          <template v-if="item.name === 'Dashboard'">
            <Autocomplete class="hidden-cn"/>


            <!--            <form action="#" method="get" class="sidebar-form">
                          <div class="input-group">
                            <input type="text" name="q" class="form-control" placeholder="Search..." data-cip-id="cIPJQ342845639">
                            <span class="input-group-btn">
                            <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                              <i class="fa fa-search"></i>
                            </button>
                      </span>
                          </div>
                        </form>-->
          </template>
        </template>
        <template v-if="stats">
          <li class="nav-item px-3 hidden-cn" v-for="stat, name in stats">
            <small>
              <b class="small-xs">{{name}}</b>
            </small><br>
            <template v-if="stat.online">
              <small class="text-muted small-xs"> Uptime: {{toHHMMSS(stat.cpu.uptime)}}<br></small>
              <div class="text-uppercase mb-2">
                <small>
                  <b class="small-xs">CPU Usage</b>
                </small>
              </div>
              <div class="progress progress-xs">
                <div class="progress-bar bg-info" role="progressbar" :style="{'width': stat.cpu.usage + '%'}" :aria-valuenow="stat.cpu.usage" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <small class="text-muted small-xs">{{stat.cpu.procs}} Processes. {{stat.cpu.cores}} Cores.</small><br>
              <div class="text-uppercase mb-1">
                <small>
                  <b class="small-xs"> Memory Usage</b>
                </small>
              </div>
              <div class="progress progress-xs">
                <div class="progress-bar bg-warning" role="progressbar"  :style="{'width': stat.memory.used / stat.memory.total * 100 + '%'}" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <small class="text-muted small-xs">{{bytesToSize(stat.memory.used)}}/{{bytesToSize(stat.memory.total)}}</small>

              <div class="text-uppercase mb-1">
                <small>
                  <b class="small-xs">SSD Usage</b>
                </small>
              </div>
              <div class="progress progress-xs">
                <div class="progress-bar bg-danger" role="progressbar"  :style="{'width': stat.hdd.used / stat.hdd.total * 100 + '%'}" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <small class="text-muted small-xs">{{bytesToSize(stat.hdd.used)}}/{{bytesToSize(stat.hdd.total)}}</small>
            </template>
            <small v-else class="text-danger small-xs"> OFFLINE<br></small>


          </li>
        </template>
        <li v-else>
          <div class="d-flex justify-content-center fa-2x">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </div>
        </li>



      </ul>
      <slot></slot>
    </nav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter'
import SidebarForm from './SidebarForm'
import SidebarHeader from './SidebarHeader'
import SidebarMinimizer from './SidebarMinimizer'
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'
import Autocomplete from './Autocomplete.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'sidebar',
  data () {
    return {
      value: '',
      suggestionAttribute: 'original_title',
      suggestions: [],
      selectedEvent: ''
    }
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel,
    Autocomplete
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    bytesToSize (bytes) {
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    toHHMMSS (secNum) {
      let hours = Math.floor(secNum / 3600)
      let minutes = Math.floor((secNum - (hours * 3600)) / 60)
      let seconds = secNum - (hours * 3600) - (minutes * 60)
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return hours + ':' + minutes + ':' + seconds
    }
  },
  computed: mapGetters({
    stats: 'serverStats'
  })
}
</script>

<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
