<template>
  <div>
    <b-modal title="Add pokemon" size="lg" v-model="modal" @ok="modal = false">
      <h6>Base</h6>
      <transition name="fade">
        <img v-if="image" class="img-thumbnail float-md-right" :src="image"/>
      </transition>
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Pokemon</span>
              <search @change="pokemon = null" @selectedItem="selectedItem" placeholder="Pokemon..." :values="monsters"></search>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Level</span>
              <input v-model="level" type="number" max="100" min="1" value="100" class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
      <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Ability</span>
                <select :disabled="pokemon == null" v-model="ability" class="form-control input-md" id="ccmonth">
                  <template v-if="pokemon != null">
                    <option v-if="item !== 'None'" v-for="(item, key) in pokemon.Ability" :value="key">{{item}}</option>
                  </template>
                </select>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Nature</span>
              <select v-model="nature" class="form-control">
              <option v-for="(item, key) in natures" :value="key">{{item}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <h6>Moveset</h6>
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Move #1</span>
              <search :values="moves" v-model="selectedMoves[0]" placeholder="Move..."></search>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Move #2</span>
              <search :values="moves" v-model="selectedMoves[1]" placeholder="Move..."></search>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Move #3</span>
              <search :values="moves" v-model="selectedMoves[2]" placeholder="Move..."></search>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Move #4</span>
              <search :values="moves" v-model="selectedMoves[3]" placeholder="Move..."></search>
            </div>
          </div>
        </div>
      </div>
      <h6>Individual Values</h6>
      <div class="form-group row">
        <div v-for="(item, key) in ivs.slice(0, 3)" class="col-sm-6 col-md-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{names[key]}}</span>
              <input v-model="ivs[key]" type="number" max="252" min="1" class="form-control" :class="ivs[key] > 31 || ivs[key] < 1 ? 'is-invalid' : ''">
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div v-for="(item, key) in ivs.slice(3, 6)" class="col-sm-6 col-md-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{names[key + 3]}}</span>
              <input v-model="ivs[key + 3]" type="number" max="252" min="1" class="form-control" :class="ivs[key + 3] > 31 || ivs[key + 3] < 1  ? 'is-invalid' : ''">
            </div>
          </div>
        </div>
      </div>
      <h6>Effort Values</h6>
      <div class="form-group row">
        <div v-for="(item, key) in evs.slice(0, 3)" class="col-sm-6 col-md-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{names[key]}}</span>
                <input v-model="evs[key]" type="number" max="252" min="1" class="form-control" :class="item > 252 || item < 0 || (invalidEVs && item !== 0) ? 'is-invalid' : ''">
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div v-for="(item, key) in evs.slice(3, 6)" class="col-sm-6 col-md-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{names[key + 3]}}</span>
              <input v-model="evs[key + 3]" type="number" max="252" min="1" class="form-control" :class="item > 252 || item < 0 || (invalidEVs && item !== 0) ? 'is-invalid' : ''">
            </div>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <div class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                 id="customInChk4"
                                                                                 value="true"
                                                                                 v-model="shiny"
                                                                                 class="custom-control-input">
          <label for="customInChk4" class="custom-control-label">Shiny</label></div>
        <div v-for="ser in servers" v-if="!serverObj[ser].readonly" class="custom-control custom-checkbox custom-control-inline"><input type="checkbox"
                                                                                 :id="ser"
                                                                                 :value="ser"
                                                                                 v-model="checked"
                                                                                 class="custom-control-input">
          <label :for="ser" class="custom-control-label">{{capitalize(ser)}}</label>
        </div>
        <b-btn :disabled="pokemon == null || selectedMoves.filter(move => move !== null).length === 0 || checked.length === 0 ||  invalidIVs || invalidEVs" @click="spawnPokemon" class="float-right" variant="primary"><i class="fa fa-plus"></i>
          Add
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import Search from './Search'
  import Monsters from './_monsters.js'
  import Natures from './_natures.js'
  import Moves from './_moves.js'
  import Servers from '../../_servers'

  export default {
    name: 'PokemonAdd',
    props: ['modal'],
    components: {
      Search
    },
    data () {
      return {
        pokemon: null,
        image: '',
        names: ['ATK', 'DEF', 'SPD', 'SPATK', 'SPDEF', 'HP'],
        evs: [0, 0, 0, 0, 0, 0],
        ivs: [31, 31, 31, 31, 31, 31],
        selectedMoves: [null, null, null, null],
        modal: false,
        monsters: Monsters,
        natures: Natures,
        checked: [],
        nature: 0,
        servers: Object.keys(Servers),
        serverObj: Servers,
        shiny: false,
        level: 100,
        ability: 0,
        moves: Moves
      }
    },
    computed: {
      invalidEVs () {
        if (this.evs.reduce((a, b) => Number(a) + Number(b), 0) > 510) {
          return true
        }
        for (let ev in this.evs) {
          let evNumber = Number(this.evs[ev])
          if (evNumber > 252 || evNumber < 0) {
            return true
          }
        }
        return false
      },
      invalidIVs () {
        for (let ev in this.ivs) {
          let evNumber = Number(this.ivs[ev])
          if (evNumber > 31 || evNumber < 1) {
            return true
          }
        }
        return false
      }
    },
    methods: {
      spawnPokemon () {
        let moves = this.selectedMoves.filter(item => item !== null)
        this.emit('addPokemon', {
          'monster_id': this.pokemon.ID,
          'shiny': this.shiny,
          'level': Number(this.level),
          'moves': moves,
          'nature': this.nature,
          'ability': this.ability + 1,
          'ivs': this.ivs.map(Number),
          'evs': this.evs.map(Number),
          'servers': this.checked
        })
        this.evs = [0, 0, 0, 0, 0, 0]
        this.ivs = [31, 31, 31, 31, 31, 31]
        this.selectedMoves = [null, null, null, null]
        this.checked = []
        this.nature = 0
        this.shiny = true
        this.pokemon = null
        this.level = 100
        this.ability = 0
        this.image = null
      },
      capitalize (str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
      },
      selectedItem (e) {
        this.pokemon = e
        this.image = '/static/img/monsters/' + e.File + '.png'
      },
      close () {
        this.$emit('input', false)
      }
    },
    watch: {
      modal: function (e) {
        this.$emit('update:modal', e)
      }
    }
  }
</script>
